<template>
  <common-loader relative minHeightAuto style="padding: 200px 0" v-if="loadingProductDetail"/>
  <div v-else-if="detailPanelInfo !== undefined">
    <h5 class="f20 black1 lato-light">
        {{ chooseText }}
    </h5>
    <div
      :class="{ not_available: !Number(gift.available) }"
      class="d-flex flex-column flex-md-row my-7"
    >
      <div class="single-gift__images col-12 col-md-5 pa-0">
        <div v-if="!Number(gift.available)" class="not_available_label pa-2">
          <h3 class="font-weight-light">Out of stock</h3>
        </div>
        <div class="d-flex flex-column preview-wrapper">
          <div
            v-if="Array.isArray(detailPanelInfo.media_gallery) && detailPanelInfo.media_gallery.length > 1"
            class="carousel-wrap"
            :style="{maxWidth: 800 + 'px', maxHeight: 800 + 'px'}"
          >
            <VueSlickCarousel
              ref="carousel"
              v-model="currentIdx"
              v-bind="settings"
              @afterChange="sliderAfterChange"
            >
              <div
                class="prev-min-img"
                v-for="path in detailPanelInfo.media_gallery"
                :key="path"
              >
                <img :src="path">
              </div>
            </VueSlickCarousel>
          </div>
          <div
            v-if="Array.isArray(detailPanelInfo.media_gallery)"
            class="prev-img-list d-flex flex-wrap mt-10"
            ref="mainImg"
          >
            <div
              class="prev-min-img pa-1"
              v-for="(path, index) in detailPanelInfo.media_gallery"
              :key="path"
              @click="() => {
                currentIdx = index
                $refs.carousel.goTo(index)
              }"
            >
              <img
                :src="path"
                :class="currentIdx === index ? 'selected' : ''"
              >
            </div>
          </div>
          <div
            v-else
            class="prev-img-list d-flex"
            ref="mainImg"
          >
            <img :src="previewImgPath" alt="">
          </div>
        </div>
        <div
          v-if="Array.isArray(detailPanelInfo.media_gallery) && detailPanelInfo.media_gallery.length === 1"
          class="mobile-mode my-6">
          <div
            class="prev-min-img mb-1"
            v-for="path in detailPanelInfo.media_gallery"
            :key="path"
          >
            <img :src="path">
          </div>
        </div>
      </div>
      <div
        ref="descriptionBlock"
        class="col-12 offset-md-2 col-md-5 pa-0 d-flex flex-column mt-7 mt-md-0"
        style="position: relative;"
      >
        <div class="gift-title">{{detailPanelInfo.name}}</div>
        <h3 v-if="detailPanelInfo.fake_product_title && detailPanelInfo.fake_product_title.length" class="text-subtitle-1 mt-2">{{ detailPanelInfo.fake_product_title }}</h3>
        <!-- <span class="text-caption gray2 mt-5">{{detailPanelInfo.sku}}</span> -->
        <div class="description-wrap mb-4">
          <div
            ref="giftDescription"
            :class="{
              'show-all': showAllDescription,
              'non-gradient': !showReadeMore || (showReadeMore && showAllDescription)
            }"
            class="description mt-7"
            v-html="detailPanelInfo.description"
          />
        </div>
        <a
          v-if="showReadeMore"
          @click="showAllDescription = !showAllDescription"
          class="green3 pointer f14 d-none d-md-block mb-6"
        >{{ !showAllDescription ? 'Read More' : 'Read Less' }}</a>
        <ValidationObserver
          v-slot="{ handleSubmit }"
          ref="formValidation"
        >
          <form
            @submit.prevent="handleSubmit(addCard)"
            :id="gift.id + 'detail'"
          >
            <div
              v-if="gift.options !== undefined && gift.options !== [] && fieldOptions.length > 0"
            >
              <div
                v-for="option in fieldOptions"
                :key="option.id"
                class="item-selector mb-8"
              >
                <div class="shipping-input__item">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">{{ option.title }}</p>
                  </div>
                  <div class="shipping-input__main">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="getOptionValidation(option)"
                      :custom-messages="getOptionValidationMessages(option)"
                    >
                      <input
                        type="text"
                        :name="'option['+gift.id+']['+option.id+']'"
                        :id="'option-'+option.id"
                        v-model="option.val"
                        :disabled="!Number(gift.available)"
                        :placeholder="option.title"
                        :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                        @change="changeHandler($event, option.title, option.id, null, option.type)"
                      />
                      <p
                        v-if="errors.length"
                        class="lato-italic f14 mb-2"
                      >{{errors[0]}}</p>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="gift.options !== undefined && gift.options !== [] && dropdownOptions.length > 0"
              class="d-flex flex-wrap"
            >
              <div
                class="item-selector mb-8"
                v-for="(option, index) in dropdownOptions"
                :key="option.id"
                :class="[dropdownOptions.length === (2 || 4) ? 'w50' : 'w100']"
              >
                <div class="shipping-input__item">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">{{ option.title }}</p>
                  </div>
                  <div class="shipping-input__main">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="+option.required ? 'required' : ''"
                      :custom-messages="{
                        required: `${option.title} is a required field.`
                      }"
                    >
                      <select
                        v-if="option.values.length"
                        @change="changeHandler($event, option.title, option.id, option.values, option.type)"
                        v-model="option.val"
                        :disabled="!Number(gift.available)"
                        :class="[ errors.length ? 'is-invalid' : 'is-valid', 'shipping-input select-attribute with-arrow col-12']"
                        :name="'option['+gift.id+']['+option.id+']'"
                        :id="'option-'+option.id + index"
                      >
                        <option value="" disabled selected>{{ option.title }}</option>
                        <option
                          v-for="value in getOptionValues(option, index)"
                          :key="value.id"
                          :disabled="value.disabled"
                          :value="value.id"
                        >
                          {{ value.title }}
                        </option>
                      </select>

                      <p v-if="errors.length" class="lato-italic f14 mb-2">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="additionalOptions.length" class="d-flex flex-wrap">
              <div
                v-for="(option, index) in additionalOptions"
                :key="option.id"
                class="item-selector mb-8 w100"
              >
                <div class="shipping-input__item">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">{{ option.title }}</p>
                  </div>
                  <div class="shipping-input__main">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="getOptionValidation(option)"
                      :custom-messages="getOptionValidationMessages(option)"
                    >
                      <select
                        v-if="option.type === 'drop_down'"
                        @change="changeHandler($event, option.title, option.id, option.values, option.type)"
                        v-model="option.val"
                        :disabled="!Number(gift.available)"
                        :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input select-attribute with-arrow col-12']"
                        :name="'option['+gift.id+']['+option.id+']'"
                        :id="'option-'+option.id + index"
                      >
                        <option value="" disabled selected>{{ option.title }}</option>
                        <option
                          v-for="value in getOptionValues(option, index)"
                          :key="value.id"
                          :value="value.id"
                        >
                          {{ value.title }}
                        </option>
                      </select>

                      <input
                        v-else
                        type="text"
                        :name="'option['+gift.id+']['+option.id+']'"
                        :id="'option-'+option.id"
                        v-model="option.val"
                        :disabled="!Number(gift.available)"
                        :placeholder="option.title"
                        :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                        @change="changeHandler($event, option.title, option.id, null, option.type, option.options)"
                      />

                      <p v-if="errors.length" class="lato-italic f14 mb-2">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <div class="button-wrap d-flex pa-0">
          <common-button
            :width="'100%'"
            :height="44"
            class="single-button col-md-6 col-sm ma-0 pa-0"
            :class="{ disabled: !Number(gift.available) }"
            :disabled="isUnavailable"
            @click="addCard"
          >
            Get This Gift
          </common-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import optionValidation from '@/mixins/optionValidation'

export default {
  name: 'SingleGift',
  mixins: [optionValidation],
  components: {
    VueSlickCarousel
  },
  props: {
    gift: { type: Object },
    isUnavailable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    missedList: [],
    selected: null,
    previewImgPath: '',
    currentIdx: 0,
    warningTxt: 'All fields are required',
    settings: {
      dots: true,
      dotsClass: 'slick-dots custom-dot-class',
      edgeFriction: 0.35,
      centerMode: true,
      centerPadding: '0px',
      adaptiveHeight: true,
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500
    },
    imgPathPrefix: process.env.VUE_APP_MAGENTO_URL + '/media/catalog/product',
    showAllDescription: false,
    showReadeMore: false
  }),
  computed: {
    ...mapState('eGift', ['detailPanelInfo', 'loadingProductDetail', 'chooseText', 'gifts']),
    fieldOptions () {
      return this.gift?.options?.filter(option => option.type === 'field') ?? []
    },
    dropdownOptions () {
      return this.gift?.options?.filter(option => option.type === 'drop_down') ?? []
    },
    additionalOptions () {
      return this.gift.option.reduce((acc, option) => {
        acc.push(...option.childOptions)
        return acc
      }, [])
    },
    hasOptionCombinations () {
      return !!this.gift?.option_combinations || false
    }
  },
  methods: {
    ...mapActions('eGift', ['getProductDetail']),
    selectCard (item) {
      this.$emit('selectCard', item)
    },
    getOptionVal (key, valId) {
      const optionId = Number(key.substring(14, 19))
      this.gift.options.forEach(option => {
        if (option.id === optionId) {
          option.values.forEach(val => {
            if (val.id === Number(valId)) {
              if (this.gift.option.length === 0) {
                this.gift.option.push({
                  title: option.title.replace('Choose ', ''),
                  val: val.title.replace('Choose ', ''),
                  key,
                  valueId: valId,
                  childOptions: option?.options ?? []
                })
              } else {
                this.gift.option.forEach(item => {
                  if (item.title === option.title) {
                    item.val = val.title.replace('Choose ', '')
                  }
                })
              }
            }
          })
        }
      })
    },
    changeHandler (event, title, id, values, type) {
      if (values) {
        const newMainImage = values.find(value => value.id === Number(event.target.value))?.image
        // change item image only for labels that are not related to size
        const shouldChangeItemImage = !['Size', 'Apparel Size'].includes(title)

        if (shouldChangeItemImage) {
          if (newMainImage && !newMainImage?.includes('no_selection')) {
            this.gift.image = newMainImage
            this.previewImgPath = newMainImage
          }
        }

        if (!this.gift.image) {
          this.gift.image = this.gift.initialMainImageLink
          this.previewImgPath = this.gift.initialMainImageLink
        }

        if (this.$refs.carousel && !newMainImage?.includes('no_selection')) {
          let indexOfNewImage = this.detailPanelInfo.media_gallery?.indexOf(newMainImage)
          if (indexOfNewImage !== -1) {
            this.$refs.carousel.goTo(indexOfNewImage)
          } else {
            // if there is no matching image add it to array
            this.detailPanelInfo.media_gallery.push(newMainImage)
            // update vue-slick-carousel slider count and move to it
            this.$refs.carousel.$children[0]._data.slideCount += 1
            indexOfNewImage = this.detailPanelInfo.media_gallery?.indexOf(newMainImage)
            setTimeout(() => {
              this.$refs.carousel.goTo(indexOfNewImage)
            }, 0)
          }
        }
      }
      this.gift.option = this.gift.option.filter(item => item.title !== title)
      if (event.target.value.length !== 0) {
        const selectedValue = event.target.value
        const childOptions = values?.find((value) => value.id === +selectedValue)?.options ?? []

        const resOptionObj = {
          id,
          title,
          val: selectedValue,
          type,
          childOptions
        }
        if (values) {
          Object.assign(resOptionObj, { valTitle: values.find(value => +value.id === +selectedValue).title })
        }
        this.gift.option.push(resOptionObj)
        const findOption = this.gift.options.find(item => item.id === resOptionObj.id)

        if (findOption) {
          findOption.selected = true
          findOption.val = selectedValue
        }
      }
    },
    async addCard () {
      if (!Number(this.gift.available)) {
        return
      }
      this.$refs.formValidation.validate()
        .then(res => {
          if (res) {
            this.$emit('selectCard', this.gift)
          }
        })
    },
    sliderAfterChange (val) {
      this.currentIdx = val
    },
    getOptionValues (option, optionIndex) {
      const { hasOptionCombinations, dropdownOptions, gift: { option_combinations: combinations } } = this
      const selectedId = dropdownOptions[0]?.val
      const availableCombinations = combinations?.[selectedId] || []

      return option.values.map((item) => {
        if (hasOptionCombinations && optionIndex !== 0 && availableCombinations.length) {
          return {
            ...item,
            disabled: !availableCombinations?.includes(item.id)
          }
        }
        return {
          ...item,
          disabled: false
        }
      })
    }
  },
  async created () {
    await this.$store.dispatch('eGift/getProductDetail', this.gift.id)
    this.previewImgPath = this.gift.image
    // this.gift.options.map(option => Object.assign(option, { selected: false, val: null }))
    if (((window.innerWidth > 960 && window.innerWidth < 1263) && this.$refs.giftDescription.clientHeight > 199) ||
    ((window.innerWidth > 1263 && window.innerWidth < 1900) && this.$refs.giftDescription.clientHeight > 349) ||
    ((window.innerWidth > 1900) && this.$refs.giftDescription.clientHeight > 449)) {
      this.showReadeMore = true
    }
  },
  mounted () {
    this.gift.options
      .filter(option => option.type === 'drop_down')
      .map(option => Object.assign(option, {
        selected: true,
        val: this.gift?.option
          ?.filter((item) => item.title === option.title)[0]?.val ?? option.values[0]?.id
      }))

    this.gift.options.forEach(option => {
      if (option.selected) {
        const resOptionObj = {
          id: option.id,
          title: option.title,
          val: option.values[0].id,
          valTitle: option.values[0]?.title ?? null,
          childOptions: option.values[0]?.options ?? []
        }

        if (!this.gift.option.map((item) => item.title)?.includes(resOptionObj.title)) {
          this.gift.option.push(resOptionObj)
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.single-gift{
  &__images{
    position: relative;
  }
}
.not_available{
    &_label{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #f25b5b;
      color: #fff;
      width: 150px;
      display: flex;
      justify-content: center;
    }
    &_select{
      color: #f5f5f5 !important;
      border: solid 1px #f5f5f5 !important;
    }
    & .mdi-chevron-down::before {
      color: #c8c8c8;
    }
    & img{
      opacity: 0.4;
    }
  }
.single-button{
  span{
    color: rgb(255, 255, 255) !important;
  }
}
.select-wrapper{
  width: 100%;
}
.select{
  position: relative;
}
.select-icon{
  color: black;
  position: absolute;
  right: 10px;
}
.slick-list{
  overflow: hidden;
  // height: 400px;
  img{
    width: 100%;
  }
}
.vue-slick-carousel__wraper{
  width: 1000px;
  img{
    width: 30%;
    height: 300px;
  }
}
.vue-slick-carousel{
  width: 100%;
  border: solid 3px red;
}

.product-detail-panel {
  padding-left: 0;

  .mobile-mode {
    position: relative;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 45px;
  }

  .panel-content {
    padding-right: 0;
    margin: 0!important;

    @media screen and (min-width: 1024px) {
      padding-right: 130px;
      margin-left: 28px!important;
    }

    .preview-wrapper {
      display: none !important;

      @media screen and (min-width: 1024px) {
        display: flex !important;
      }
    }

    .prevImg {
      max-width: 100%;
      max-height: 50vh;

    }

    .prev-min-img {
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 20%;

      &>img {
        max-width: 30%;
        max-height: 100%;
      }

      @media screen and (min-width: 1024px) {
        height: 80px;
        width: 80px;
      }

      img {
        border: 1px solid #E4E4E4;
        max-width: 30%;
        max-height: 100%;
      }

      &.selected {
        border: 3px solid #42B77A;
      }
    }

    .mobile-mode .prev-min-img {
      border: none;
    }

    .product-name {
      color: #161616;
      font-size: 25px;
      letter-spacing: 0;
      line-height: 35px;
    }

    .sku {
      color: #9F9F9F;
      font-size: 10px;
      letter-spacing: 0.13px;
      line-height: 12px;
    }

    .short_description {
      color: #9F9F9F;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 24px;
    }

  }

  .item-selector {
    margin-bottom: 10px;

    label {
      color: #959595;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .add-button {
    background: #000 !important;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    margin-top: auto;
    border-radius: 0;
    width: 100%;

    span:first-child {
      pointer-events: none;
    }

    span:last-child {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      cursor: pointer;
    }
  }

  .add-button.remove {
    background-color: white !important;
    border: 2px solid black;
  }

  .add-button.disabled {
    width: 100%;
    opacity: 0.3;
  }

  .gift-coin-value {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: none;

    strong {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      font-style: normal;
    }
  }

  .err-txt {
    height: 0;
  }

}
.preview-wrapper{
  img{
    width: 100%;
  }
}
.prev-min-img{
  cursor: pointer;
  box-sizing: border-box;
  width: 20%;
  display: flex;
  justify-content: center;
  img{
    border: solid 1px #E4E4E4;
    &.selected {
      border: 3px solid #42B77A;
    }
  }
}
.prev-img-list{
  width: 100%;
  img{
    width: 100%;
  }
}
::v-deep.carousel-wrap{
  img{
    border: none;
  }
  ul{
    padding: 0px !important;
    @media screen and (max-width: 500px) {
      bottom: -40px;
    }
    @media screen and (min-width: 960px) and (max-width: 1265px){
      bottom: -40px;
    }
  }
}
.prev-video {
  position: relative;
  .play-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
    height: 40%;
    border: none;
  }
}

.gift-title {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
}

.description{
  position: relative;
  overflow: hidden;
  max-height: fit-content;
  font-size: 14px;
  color: #95979D;
  line-height: 20px;

  &::v-deep ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  @media screen and (min-width: 960px) {
    max-height: 200px;
  }
  @media screen and (min-width: 1263px) {
    max-height: 350px;
  }
  @media screen and (min-width: 1900px) {
    max-height: 450px;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  &.show-all{
    max-height: fit-content;
  }
  &.non-gradient{
    &::after {
      display: none
    }
  }
}
.shipping-input{
  &__label{
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  &.is-invalid{
    background-color: #FBE7E9;
    border: 1px solid #FA5E5E;
  }
  &__main{
    input, select{
      width: 100%;
      height: 40px;
      padding: 9px 11px;
      border: 1px solid #D3D2D2;
      font-size: 15px;
      line-height: 22px;
      outline: none;
      &.is-invalid{
        background: #FBE7E9;
        border: 1px solid #FA5E5E;
      }
      &::placeholder{
        font-family: 'Lato-Italic';
        font-size: 15px;
        color: #979797;
      }
    }
    select{
      padding: 0px;
      padding-left: 11px;
    }
    p{
      margin-top: 10px;
      margin-bottom: 0px;
      color: #FA5E5E;
    }
    .select-attribute {
      padding: 9px 11px;
      font-weight: 400 !important;
      font-style: normal !important;
      color: #000 !important;
      width: 100%;
      border-radius: 0;
      height: 40px;
      // background-color: #fff;
      // border: 1px solid #d3d2d2;
      font-size: 14px;
      font-family: "Lato", Verdana, Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      &.with-arrow {
        background-image: url('/public/images/select-arrow-icon.svg');
        background-size: 16px 16px;
        background-position: 95% 45%;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
