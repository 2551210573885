<template>
  <div class="header">
    <div class="page-logo">
      <img
        :src="logoUrl"
        :style="!loading ? 'visibility: visible' : 'visibility: hidden'"
        @error="setLogoUrl('/images/default-header-logo.png')"
      >
    </div>
    <v-container class="d-flex py-2 justify-end">
      <div
        class="page-helper pr-6 mt-3 mb-4 pointer print-page"
        v-if="showPrintButton"
        @click="printPage()"
      >
        <icons-print style="margin-top: -4px; margin-right: 4px" />
        <div class="page-header__link">
          Print
        </div>
      </div>

      <div class="page-helper mt-3 mb-4 pointer" @click="showPanel = !showPanel">
        <icons-question style="margin-top: -4px; margin-right: 4px" />
        <div class="page-header__link">
          View FAQs
        </div>
      </div>
    </v-container>
    <e-gift-faq-panel
      :showPanel="showPanel"
      @close="showPanel = !showPanel"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    showPanel: false
  }),
  props: {
    showPrintButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState('eGift', [
      'logoUrl', 'loading'
    ])
  },
  methods: {
    ...mapMutations('eGift', ['setLogoUrl']),
    printPage () {
      window.print()
    }
  }
}
</script>

<style lang="scss">
.page-logo {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #D3D3D3;
  padding: 10px 0;

  img {
    max-width: 300px;
    max-height: 70px;
    min-height: 70px;
    object-fit: contain;
  }
}

.page-helper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.page-header__link {
  display: flex;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 15px;
  line-height: 23px;
  color: #62646A;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }

  #launcher {
    display: none;
  }

  @page {
    size: 21cm 29.7cm;
    margin: 0;
  }

  .footer {
    display: none !important;
  }

  .print-page {
    display: none;
  }
}
</style>
