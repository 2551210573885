import { render, staticRenderFns } from "./MultipleChoiceSelectedGiftsMobile.vue?vue&type=template&id=4ae3493c&scoped=true&"
import script from "./MultipleChoiceSelectedGiftsMobile.vue?vue&type=script&lang=js&"
export * from "./MultipleChoiceSelectedGiftsMobile.vue?vue&type=script&lang=js&"
import style0 from "./MultipleChoiceSelectedGiftsMobile.vue?vue&type=style&index=0&id=4ae3493c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae3493c",
  null
  
)

export default component.exports