<template>
  <div class="faq-panel">
    <v-navigation-drawer
      v-model='show'
      app
      temporary
      right
      width='953px'
      :style="{height: '100%'}"
      v-scroll-lock="!$vuetify.breakpoint.mobile && show"
    >
      <close-panel-button
        @close='show = !show'
      />
      <common-loader v-if="loadingFaq"/>
      <div v-else class="panel-content mt-7 mt-md-10 pl-4 pl-md-13">
        <p class="panel-content__title">FAQs</p>
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(item,i) in faq"
            :key="i"
          >
            <v-expansion-panel-header>{{item.question}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.answer"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    showPanel: Boolean
  },
  data: () => ({
    show: false
  }),
  created () {
  },
  methods: {
  },
  computed: {
    ...mapState('eGift', [
      'faq', 'loadingFaq'
    ])
  },
  watch: {
    showPanel (val) {
      this.show = val
    },
    show (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss">
.faq-panel {

  .panel-content {
    padding-right: 12px;

    @media screen and (min-width: 768px) {
      padding-right: 120px;
    }
    &__title{
      font-style: normal;
      font-weight: 300;
      font-size: 30px;
      line-height: 30px;
      @media screen and (min-width: 768px) {
        font-size: 40px;
        line-height: 40px;
      }
    }
  }

  .mdi-chevron-down::before {
    content: '+';
    color: #42B77A;
    font-size: 20px;

    @media screen and (min-width: 768px) {
      font-size: 32px;
    }
  }

  .v-expansion-panel--active > .v-expansion-panel-header--active {
    color: #42B77A;
    transition: all 0.5s;
  }

  .v-expansion-panel--active > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
    &::before {
      content: '-';
      color: #000;
    }
    margin-top: 4px;
    transform: rotate(-180deg);

    @media screen and (min-width: 768px) {
      transform: rotate(-135deg);
      margin-top: 0px;

      &::before {
        content: '+';
        color: #42B77A;
      }
    }
  }

  .v-expansion-panels {
    margin-right: 40px;
    border-top: 1px solid #0000001f;
    border-bottom: 1px solid #0000001f;
  }
  .v-expansion-panel::before {
    box-shadow: none;
  }

  .v-expansion-panel-header,
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }

  .v-expansion-panel-header {
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: black;
    font-weight: 700;
    font-size: 15px;

    .v-expansion-panel-header__icon {
      margin-left: 0;
      margin-right: 16px;
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: flex-start;
      color: #000000;
      transition: all 0.5s;

      .v-expansion-panel-header__icon {
        margin-left: auto;
        font-weight: bold;
        font-family: 'Lato-Bold';
      }
    }
  }
}
.v-navigation-drawer--temporary {
    z-index: 9999999;
}
</style>
