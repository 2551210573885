<template>
  <v-container class="thank-you-page d-flex flex-column justify-center align-center">
    <img src="../assets/like.png" alt="">
    <div class="gift-sent">
      <h1 class="mt-5 text-h3 text-center font-weight-light">Thank you</h1>
      <h3 class="mt-5 text-h5 text-center font-weight-light">
        <span class="font-weight-bold">Your order number is  {{ successReGiftNum }}</span><br>
        We'll send you a confirmation email shortly.
      </h3>
      <p class="mt-8 text-center font-weight-bold">Liked the eGift experience?<br>
      <a href="https://corporategift.com/request-demo" class="text-decoration-none">Schedule a demo</a> to see if it’s a right fit for your company</p>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GiftThankYouPage',
  computed: {
    ...mapState('eGift', ['recipientHash', 'successReGiftNum'])
  }
}
</script>

<style lang="scss">
.thank-you-page{
  height: 100%;
  color: #16181f;
  img{
    width: 30px;
  }
}
</style>
