<template>
  <div class="setting mx-auto">
    <common-loader v-if="loadingSendGift"/>
    <v-container class="py-0" v-if="!loadingSendGift">
      <h1 class="heading lato-light pa-3 pt-0">Where should we send your gift?</h1>
      <div
        v-if="showNotAlcoholError"
        class="error-message px-5 py-3 d-flex align-center"
      >
        <img
          src="@/assets/error-icon.svg"
          class="lighten-1 d-flex justify-center mr-6"
        />
        <p class="gray4 pa-0 ma-0">Your gift has not been sent. {{ redeemErrorMessage }}</p>
      </div>
      <div class="row mt-9 mx-0 px-0">
        <div class="col-md-6">
          <ValidationObserver
            v-slot="{ handleSubmit }"
            ref="formValidation"
          >
            <form
              @submit.prevent="handleSubmit(getGift)"
              class="preview-shipping-block"
              autocomplete="off"
            >
              <div class="shipping-inputs-wrapper">
                <div class="shipping-input__row-wrapper d-flex mb-8">
                  <div class="shipping-input__item col-6 pa-0 pr-1">
                    <div class="shipping-input__header d-flex mb-3">
                      <p class="shipping-input__label ma-0">First Name</p>
                    </div>
                    <div class="shipping-input__main d-flex">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'First name is a required field.'
                        }"
                        class="col-12 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="sendGiftData.first_name"
                          placeholder="First name"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="shipping-input__item col-6 pa-0 pl-1">
                    <div class="shipping-input__header d-flex mb-3">
                      <p class="shipping-input__label ma-0">Last Name</p>
                    </div>
                    <div class="shipping-input__main d-flex">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'Last name is a required field.'
                        }"
                        class="col-12 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="sendGiftData.last_name"
                          placeholder="Last name"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div
                  v-show="showingFullAddressForm && (countryList === null || countryList.length > 1)"
                  class="shipping-input__row-wrapper mb-8"
                >
                  <div cass="shipping-input__item col-12 pa-0">
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">Country</p>
                    </div>
                    <div
                      class="shipping-input__main"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'Country is a required field.'
                        }"
                      >
                        <country-select
                          id="country"
                          className="setting__input region-attr-select-form"
                          v-model="sendGiftData.country"
                          :country="sendGiftData.country"
                          :removePlaceholder="true"
                          :whiteList="countryList"
                          topCountry="US"
                          :class="[countryList === null || countryList.length > 1 ? 'with-arrow' : '', errors.length ? 'is-invalid' : 'is-valid', 'shipping-input select-wrapper']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div v-if="showAddressForm" class="shipping-input__item mb-8">
                  <div class="shipping-input__header d-flex mb-3">
                    <p class="shipping-input__label ma-0 mr-2">Street Address</p>
                    <Popper
                      class="d-flex mt-n1"
                      :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0,10px' } }
                      }"
                    >
                      <div
                        class="popper pa-6 text-left"
                        style="max-width: 330px"
                      >
                        <span
                          v-if="giftInternationalShipping"
                        >We ship worldwide. Start entering your street address for suggestions.</span>
                        <span
                          v-if="!giftInternationalShipping && !showCanada"
                        >Shipping to the United States only. Start entering your street address for suggestions.</span>
                        <span
                          v-if="!giftInternationalShipping && showCanada"
                        >Shipping to the United States or Canada. Start entering your street address for suggestions.</span>
                      </div>
                      <icons-question slot="reference" :width="20" :height="20" />
                    </Popper>
                  </div>
                  <AddressAutocomplete
                    :showingAddressInputWithoutSearching.sync="showingAddressInputWithoutSearching"
                    :showingFullAddressForm.sync="showingFullAddressForm"
                    :countryForAddressSearch="loqateCountryList"
                    :showDeliveryAddressIsRequired.sync="showLoqateDeliveryAddressIsRequired"
                    @autocompleteItemClickHandler="setAddressInfo"
                    @autocompleteInput="autocompleteInputHandler"
                    @enableVerificationManuallyEnteredAddress="checkManuallyEnteredAddressByLoqate = true"
                  />
                  <p
                    v-if="showingFullAddressForm && !showingSecondAddressField"
                    @click="showingSecondAddressField = !showingSecondAddressField"
                    class="ma-0 pointer"
                  >
                    <span class="green3">+ Add Company, C/O, Apt, Suite, Unit</span>
                  </p>
                </div>
                <div
                  v-if="showingSecondAddressField"
                  class="shipping-input__row-wrapper d-flex mb-8"
                >
                  <div class="shipping-input__item col-12 pa-0">
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">Address Line 2 (Optional)</p>
                    </div>
                    <div class="shipping-input__main">
                      <input
                        type="text"
                        v-model="sendGiftData.address_apt"
                        placeholder="Company, C/O, APT, Suite, Unit"
                        class="shipping-input"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="showingFullAddressForm"
                  class="shipping-input__row-wrapper d-flex mb-8"
                >
                  <div
                    v-if="selectedCountryShippingFormSettings.cityInputSowingCondition"
                    :class="[selectedCountryShippingFormSettings.order.city === 1 ? 'pr-1' : '',
                    selectedCountryShippingFormSettings.order.city === 2 ? 'px-1' : '',
                    selectedCountryShippingFormSettings.order.city === 3 ? 'pl-1' : '',
                    'shipping-input__item pa-0']"
                    :style="{order: selectedCountryShippingFormSettings.order.city}"
                  >
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">City</p>
                    </div>
                    <div class="shipping-input__main">
                      <ValidationProvider
                        v-slot="{ errors }"
                        ref="citySelectProvider"
                        :rules="`${selectedCountryShippingFormSettings.cityInputSowingCondition ? 'required' : ''}|${sendGiftData.country === 'US' ? 'militaryAddress' : ''}`"
                        :custom-messages="{
                          required: 'City is a required field.'
                        }"
                      >
                        <input
                          type="text"
                          v-model="sendGiftData.city"
                          placeholder="City"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div
                    v-if="selectedCountryShippingFormSettings.stateSelectSowingCondition && !selectedCountryShippingFormSettings.showStateInput"
                    :class="[selectedCountryShippingFormSettings.order.state === 1 ? 'pr-1' : '',
                    selectedCountryShippingFormSettings.order.state === 2 ? 'px-1' : '',
                    selectedCountryShippingFormSettings.order.state === 3 ? 'pl-1' : '',
                    'shipping-input__item pa-0']"
                    :style="{order: selectedCountryShippingFormSettings.order.state}"
                  >
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">{{ selectedCountryShippingFormSettings.stateLabelText }}</p>
                    </div>
                    <div class="shipping-input__main">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :rules="selectedCountryShippingFormSettings.stateSelectSowingCondition ? 'required' : ''"
                        ref="stateSelectProvider"
                        :custom-messages="{
                          required: 'State is a required field.'
                        }"
                      >
                        <region-select
                          id="state"
                          ref="stateSelect"
                          disablePlaceholder
                          :placeholder="selectedCountryShippingFormSettings.stateLabelText"
                          v-model="sendGiftData.state"
                          :region="sendGiftData.state"
                          :country="sendGiftData.country"
                          :blackList="stateBlackList"
                          className="setting__input region-attr-select-form"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input  select-wrapper with-arrow']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div
                    v-if="!selectedCountryShippingFormSettings.stateSelectSowingCondition &&selectedCountryShippingFormSettings.showStateInput"
                    :class="[selectedCountryShippingFormSettings.order.state === 1 ? 'pr-1' : '',
                    selectedCountryShippingFormSettings.order.state === 2 ? 'px-1' : '',
                    selectedCountryShippingFormSettings.order.state === 3 ? 'pl-1' : '',
                    'shipping-input__item pa-0']"
                    :style="{order: selectedCountryShippingFormSettings.order.state}"
                  >
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">State</p>
                    </div>
                    <div class="shipping-input__main">
                      <input
                        type="text"
                        v-model="sendGiftData.state"
                        placeholder="State"
                        className="setting__input"
                      >
                    </div>
                  </div>
                  <div
                    v-if="selectedCountryShippingFormSettings.postCodeInputCondition"
                    :class="[selectedCountryShippingFormSettings.order.postCode === 1 ? 'pr-1' : '',
                    selectedCountryShippingFormSettings.order.postCode === 2 ? 'px-1' : '',
                    selectedCountryShippingFormSettings.order.postCode === 3 ? 'pl-1' : '',
                    'shipping-input__item pa-0']"
                    :style="{order: selectedCountryShippingFormSettings.order.postCode}"
                  >
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">{{ selectedCountryShippingFormSettings.postalCodeLabelText }}</p>
                    </div>
                    <div class="shipping-input__main">
                      <ValidationProvider
                        v-slot="{ errors }"
                        ref="postCodeSelectProvider"
                        :rules="selectedCountryShippingFormSettings.postCodeInputCondition ? 'required' : ''"
                        :custom-messages="{
                          required: `${postalCodePlaceholder} is a required field.`
                        }"
                      >
                        <input
                          type="text"
                          v-model="sendGiftData.zip"
                          :placeholder="postalCodePlaceholder"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                          @change="changeZipCode"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="shipping-input__row-wrapper d-flex mb-8">
                  <div
                    class="shipping-input__item col-6 pa-0 pr-1"
                    :class="[!((filteredProducts.length === 1 && !filteredProducts[0].is_virtual) || filteredProducts.length !== 1) ? 'col-12' : 'col-6']"
                  >
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">Email</p>
                      <Popper
                        class="d-flex mt-n1"
                        :options="{
                          placement: 'top',
                          modifiers: { offset: { offset: '0,10px' } }
                        }">
                        <div
                          class="popper  pa-6 text-left"
                          style="max-width: 300px"
                        >
                          <span>We'll send you order confirmation here.</span>
                        </div>
                        <icons-question slot="reference" :width="20" :height="20" />
                      </Popper>
                    </div>
                    <div class="shipping-input__main">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'Email name is a required field.'
                        }"
                        class="col-6 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="sendGiftData.email"
                          placeholder="Email"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div
                    v-if="(filteredProducts.length === 1 && !filteredProducts[0].is_virtual) || filteredProducts.length !== 1"
                    class="shipping-input__item col-6 pa-0 pl-1"
                  >
                    <div class="shipping-input__header d-flex align-center mb-3">
                      <p class="shipping-input__label ma-0 mr-2">Phone</p>
                      <Popper
                        class="d-flex mt-n1"
                        :options="{
                          placement: 'top',
                          modifiers: { offset: { offset: '0,10px' } }
                        }">
                        <div
                          class="popper pa-6 text-left"
                          style="max-width: 300px"
                        >
                          <span>It is rare, but in some cases, we may need the recipient’s phone number in order to complete delivery.</span>
                        </div>
                        <icons-question slot="reference" :width="20" :height="20" />
                      </Popper>
                    </div>
                    <div class="shipping-input__main">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'Phone number is a required field.'
                        }"
                        class="col-6 pa-0 mb-0"
                      >
                        <cg-phone-input
                          v-model="phoneNumber"
                          id="phone-number-input"
                          :placeholder="!userSelectInternationalCountry && '(123) 456-7890'"
                          :error="!!errors.length"
                        />

                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
          <div class="d-flex flex-wrap col-12 pa-0 mt-4">
            <common-button
              :width="pageWidth > 375 ? '110px' : '100%'"
              :height="40"
              outlined
              class="mr-md-3 pa-0"
              @click="goBack"
            >
              Back
            </common-button>
            <common-button
              :width="pageWidth > 375 ? '185px' : '100%'"
              :height="40"
              class="setting__button get-gift pa-0 mr-0 mb-3 mb-md-0"
              :loading="sendingGift"
              @click="getGift"
            >
              Get your gift
            </common-button>
          </div>
          <AcceptPolicy />

          <template v-if="!giftInternationalShipping || showCanada">
            <div class="state-restriction-text pt-10" v-if="showCanada">
              This gift can be shipped to the United States and Canada Only
            </div>

            <div class="state-restriction-text pt-10" v-else>
              This gift can be shipped to the continental United States only.
            </div>
          </template>
        </div>
        <div class="col-md-1" />
        <div class="col-md-5">
          <div
            v-if="filteredProducts.length === 1"
            class="one-selected-gift"
          >
            <img
              :src="(filteredProducts[0].image && !filteredProducts[0].image.includes('no_selection'))
                ? filteredProducts[0].image
                : '/images/empty.png'"
              :alt="filteredProducts[0].name"
              class="col-12 mb-3"
            >
            <div class="f18 lato-bold black1 mb-3">{{filteredProducts[0].name}}</div>
            <div v-if="filteredProducts[0].option">
              <div v-for="(opt, index) in filteredProducts[0].option" :key="index" class="mt-auto">
                <div class="f14 gray4 mb-2">{{opt.title + ': ' + formatedOptionValue(opt)}}</div>
              </div>
            </div>
          </div>
          <div
            v-if="filteredProducts.length > 1"
            class="few-selected-gifts"
          >
            <div
              class="f18 open-sans-bold black1 mb-5"
            >
              {{ filteredProducts.length > 1 ? 'Selected gifts:' : 'Selected gift:' }}
            </div>
            <v-divider></v-divider>
            <div
              v-for="(item, index) in filteredProducts"
              :key='index'
              class="mt-5">
              <div class="d-flex mb-4">
                <img
                  :src="(item.image && !item.image.includes('no_selection')) ? item.image : '/images/empty.png'"
                  alt='Image' class="thumb"/>
                <div class="d-flex flex-column flex-start justify-center ml-4">
                  <div class="f15 lato-bold black1">{{item.name}}</div>
                  <!-- <div class="f12 gray2">{{item.sku}}</div> -->
                  <div v-if="item.option.length">
                    <div v-for="(opt, index) in item.option" :key="index" class="mt-auto">
                      <div class="f14 gray4">{{opt.title + ': ' + formatedOptionValue(opt)}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider />
            </div>
          </div>
        </div>
      </div>
      <RestrictionPanel
        :stateName="stateName"
        :showPanel="showRestrictionPanel"
        :zipCode="sendGiftData.zip"
        :canBeSendToTheInputtedZipCode="canBeSendToTheInputtedZipCode"
        @close="showRestrictionPanel = !showRestrictionPanel"
      />
      <LoqateValidationResultModal
        :show.sync="showLoqateValidationResultModal"
        :userName="sendGiftData.first_name"
        :userLastName="sendGiftData.last_name"
        :addressData="{
          address: sendGiftData.address,
          address_apt: sendGiftData.address_apt,
          city: sendGiftData.city,
          state: sendGiftData.state,
          zip: sendGiftData.zip,
          country: sendGiftData.country
        }"
        @continueWithEnteredAddress="sendingEGift"
        @continueWithLoqateAddress="sendingEGiftWithVerifiedAddressFromLoqate"
      />
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import vueCountryRegionSelect from 'vue-country-region-select'
import Popper from 'vue-popperjs'
import RestrictionPanel from '../components/eGift/RestrictionPanel.vue'
import AcceptPolicy from '../components/eGift/AcceptPolicy.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import 'vue-popperjs/dist/vue-popper.css'
import AddressAutocomplete from '../components/eGift/AddressAutocomplete'
import shippingFormSetting from '../utils/shippingFormSetting'
import LoqateValidationResultModal from '../components/eGift/LoqateValidationResultModal'
import { CgPhoneInput } from '@corporategift/design-system'

Vue.use(vueCountryRegionSelect)

export default {
  components: {
    Popper,
    CgPhoneInput,
    RestrictionPanel,
    AcceptPolicy,
    AddressAutocomplete,
    LoqateValidationResultModal
  },
  data: () => ({
    country: '',
    region: '',
    sendGiftData: {
      senderName: '',
      thankyou_message: '',
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      address_apt: '',
      city: '',
      state: '',
      zip: '',
      telephone: '',
      country: '',
      location_type: null
    },
    stateBlackList: ['AA', 'AE'],
    phoneNumber: '',
    showRestrictionPanel: false,
    showNotAlcoholError: false,
    stateName: null,
    sendingGift: false,
    location: '',
    searchResults: [],
    service: null,
    pageWidth: null,
    canBeSendToTheSelectedState: false,
    canBeSendToTheInputtedZipCode: false,
    showingAddressInputWithoutSearching: false,
    showingFullAddressForm: false,
    showLoqateDeliveryAddressIsRequired: false,
    showingSecondAddressField: false,
    blackListForTheFirstNames: [
      'Store',
      'Manager',
      'Happy',
      'Customer',
      'Valued',
      'Employee',
      'Owner',
      'Partner',
      'Welcome',
      'Team',
      '&',
      'and'
    ],
    checkManuallyEnteredAddressByLoqate: false,
    showLoqateValidationResultModal: false
  }),
  created () {
    this.sendGiftData.email = this.preEmail
    this.sendGiftData.last_name = this.recipientLastName?.trim() || null
    if (this.recipientFirstName && !this.blackListForTheFirstNames.some(item => this.recipientFirstName.toLowerCase().includes(item.toLowerCase()))) {
      this.sendGiftData.first_name = this.recipientFirstName
    }
    if (!this.giftInternationalShipping && !this.showCanada) this.sendGiftData.country = 'US'
    document.body.style.overflow = 'visible'
    if (localStorage.getItem('recipientHash').length && !this.selectedGifts.length) {
      if (Number(localStorage.getItem('maxGiftNum')) > 1 || !localStorage.getItem('multiMode')) {
        this.$store.commit('eGift/setSelectedGifts', [])
      }
      this.$router.push({
        path: `/recipient/${localStorage.getItem('recipientHash')}/gift`
      })
    }
    if (!this.showAddressForm) {
      this.canBeSendToTheSelectedState = true
      this.canBeSendToTheInputtedZipCode = true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    document.body.style.position = 'relative'
    this.pageWidth = window.innerWidth
  },
  beforeRouteLeave (to, from, next) {
    if (!['/thanks', '/success'].includes(to.fullPath)) {
      if (Number(this.maxGiftNum) === 1) {
        let allCoins = 0
        this.selectedGifts.forEach(item => {
          allCoins = allCoins + item.coins
        })
        this.$store.commit('eGift/setCoinLeft', this.coinLeft + allCoins)
        this.$store.commit('eGift/setSelectedGifts', [])
      }
    }
    next()
  },
  computed: {
    ...mapState('eGift', [
      'preEmail',
      'selectedGifts',
      'recipientFirstName',
      'recipientLastName',
      'coinLeft',
      'updateGiftsLink',
      'consentContent',
      'redeemStatus',
      'redeemAlcoholickError',
      'loadingSendGift',
      'redeemErrorMessage',
      'successNum',
      'multiMode',
      'maxGiftNum',
      'giftInternationalShipping',
      'showCanada',
      'recipientHash',
      'stateShortCode',
      'hiddenGiftsOnRedeemList',
      'loqateAVC',
      'skipThankYouStep',
      'loqateVerifiedAddress'
    ]),
    filteredProducts () {
      return this.selectedGifts
        .filter((gift) => Number(gift.available))
        .filter((gift) => !gift?.hidden)
    },
    includesAlcoholicGifts () {
      return this.selectedGifts.some(gift => gift.isAlcoholic)
    },
    countryList () {
      if (!this.giftInternationalShipping && !this.showCanada) return ['US']
      if (!this.giftInternationalShipping && this.showCanada) return ['US', 'CA']
      return null
    },
    loqateCountryList () {
      return this.countryList?.join(',') || null
    },
    showAddressForm () {
      const { selectedGifts } = this
      const virtualInfo = selectedGifts.map((gift) => gift?.is_virtual || false)

      return virtualInfo.some((virtual) => virtual === false)
    },
    userSelectInternationalCountry () {
      const { country } = this.sendGiftData
      return country ? !['US', 'CA'].includes(country) : false
    },
    selectedCountryShippingFormSettings () {
      const settings = shippingFormSetting.find(item => item.code === this.sendGiftData.country)
      return settings || {
        cityInputSowingCondition: true,
        stateSelectSowingCondition: false,
        showStateInput: true,
        stateLabelText: 'State',
        postCodeInputCondition: true,
        postalCodeLabelText: 'ZIP Code',
        order: {
          city: 1,
          state: 2,
          postCode: 3
        }
      }
    },
    postalCodePlaceholder () {
      return this.selectedCountryShippingFormSettings?.postalCodeLabelText ?? 'ZIP Code'
    }
  },
  watch: {
    phoneNumber (val) {
      this.sendGiftData.telephone = this.phoneNumber
    },
    'sendGiftData.state' (newVal) {
      if (this.sendGiftData.country === 'US') {
        this.setStateShortCode(newVal)
        const giftsRestrictedStates = this.selectedGifts.map(gift => gift.restricted_states).flat()
        if (giftsRestrictedStates.length && this.stateShortCode) {
          this.canBeSendToTheSelectedState = ![...new Set(giftsRestrictedStates)].includes(this.stateShortCode)
          return
        }
      }
      this.canBeSendToTheSelectedState = true
    },
    'sendGiftData.zip' () {
      this.changeZipCode()
    },
    location (newValue) {
      if (newValue) {
        this.service.getPlacePredictions({
          input: this.location,
          types: ['(cities)']
        }, this.displaySuggestions)
      }
    },
    'sendGiftData.country' () {
      this.sendGiftData.state = ''
    },
    selectedCountryShippingFormSettings: {
      deep: true,
      handler: function () {
        this.$nextTick(() => {
          if (this.$refs.stateSelectProvider) {
            this.$refs.stateSelectProvider.reset()
            this.$refs.stateSelect.$el.value = ''
          }
          if (this.$refs.postCodeSelectProvider) {
            this.$refs.postCodeSelectProvider.reset()
          }
          if (this.$refs.citySelectProvider) {
            this.$refs.citySelectProvider.reset()
          }
        })
      }
    },
    triggerRedeemAlcoholickError: function (val) {
      if (val) this.showRestrictionPanel = true
    }
  },
  methods: {
    ...mapMutations('eGift', ['setStateShortCode']),
    ...mapActions('eGift', ['getVerifiedAddresses']),
    changeZipCode () {
      if (this.sendGiftData.country === 'US') {
        const giftRestrictedZipCodes = this.selectedGifts.map(gift => gift.restricted_zip_codes).flat()
        if (giftRestrictedZipCodes.length && this.sendGiftData.zip.length) {
          this.canBeSendToTheInputtedZipCode = ![...new Set(giftRestrictedZipCodes)].includes(this.sendGiftData.zip)
          return
        }
      }
      this.canBeSendToTheInputtedZipCode = true
    },
    setAddressInfo (addressInfo) {
      this.sendGiftData.address = addressInfo.Line2 || addressInfo?.Line1
      this.sendGiftData.zip = addressInfo.PostalCode
      this.sendGiftData.city = addressInfo.City
      this.stateName = addressInfo.ProvinceCode
      this.sendGiftData.state = addressInfo.ProvinceCode
      this.sendGiftData.country = addressInfo.CountryIso2
      this.sendGiftData.location_type = addressInfo?.Type ?? null
    },
    goBack () {
      this.$router.go(-1)
    },
    async getGift () {
      const { address, country, city, state, zip } = this.sendGiftData
      this.showLoqateDeliveryAddressIsRequired = false

      if (!await this.$refs.formValidation.validate()) {
        if (address && !city && !state && !zip) this.showLoqateDeliveryAddressIsRequired = true
        return
      }
      if (address && !city && !state && !zip) {
        this.showLoqateDeliveryAddressIsRequired = true
        return
      }
      if (country === 'US' && (!this.canBeSendToTheSelectedState || !this.canBeSendToTheInputtedZipCode)) {
        this.showRestrictionPanel = !this.showRestrictionPanel
        return
      }
      await this.getVerifiedAddresses({
        street: `${this.sendGiftData.address.trim()} ${this.sendGiftData.address_apt.trim()}`.trim(),
        city: this.sendGiftData.city,
        state: this.sendGiftData.state,
        postcode: this.sendGiftData.zip,
        country: this.sendGiftData.country
      })

      const { verificationStatusWithPostStatus: verificationStatus, matchScore } = this.loqateAVC
      const condition1 = ['P4', 'V4', 'V5'].includes(verificationStatus) && matchScore <= 96 && matchScore >= 80
      const condition2 = !['P4', 'V4', 'V5'].includes(verificationStatus)

      if ((condition1 || condition2) && this.showAddressForm) {
        this.showLoqateValidationResultModal = true
        return
      }

      this.sendingEGift()
    },
    async sendingEGift () {
      // added to avoid duplicated request to get egift
      if (this.sendingGift) { return }
      this.sendingGift = true

      const locationTypeValues = new Map([
        ['Residential', 1],
        ['Home', 1],
        ['Commercial', 2],
        ['Business', 2],
        ['Hospital', 3],
        ['Church', 4],
        ['School', 5]
      ])

      const {
        first_name: firstName,
        last_name: lastName,
        address_apt: addressApt,
        address,
        country,
        city,
        state,
        zip,
        email,
        telephone,
        location_type: locationType
      } = this.sendGiftData

      await this.$store.dispatch('eGift/sendGift', {
        product_id: [...this.selectedGifts.map(gift => gift.id), ...this.hiddenGiftsOnRedeemList],
        firstname: firstName,
        lastname: lastName,
        street: `${address.trim()} ${addressApt.trim()}`.trim(),
        postcode: zip,
        city,
        state,
        country,
        email,
        phone: telephone,
        location_type: locationTypeValues.get(locationType)
      })
        .then(() => {
          if (!this.successNum) {
            this.showNotAlcoholError = !this.showNotAlcoholError
          } else {
            this.goToNextStep()
          }
        })
        .finally(() => (this.sendingGift = false))
      this.showRestrictionPanel = this.redeemAlcoholickError
      if (this.redeemErrorMessage) {
        this.showNotAlcoholError = true
      }
    },
    async sendingEGiftWithVerifiedAddressFromLoqate () {
      if (this.sendingGift) { return }
      this.sendingGift = true

      const {
        first_name: firstName,
        last_name: lastName,
        telephone,
        email
      } = this.sendGiftData
      const {
        address,
        premiseNumber,
        city,
        zip,
        state,
        country
      } = this.loqateVerifiedAddress

      await this.$store.dispatch('eGift/sendGift', {
        product_id: [...this.selectedGifts.map(gift => gift.id), ...this.hiddenGiftsOnRedeemList],
        firstname: firstName,
        lastname: lastName,
        street: `${address?.trim()} ${premiseNumber?.trim()}`.trim(),
        postcode: zip,
        city,
        state,
        country,
        email,
        phone: telephone
      })
        .then(() => {
          if (!this.redeemStatus) {
            this.showNotAlcoholError = !this.showNotAlcoholError
          } else {
            this.goToNextStep()
          }
        })
        .finally(() => (this.sendingGift = false))
      this.showRestrictionPanel = this.redeemAlcoholickError
      if (this.redeemErrorMessage) {
        this.showNotAlcoholError = true
      }
    },
    goToNextStep () {
      const { skipThankYouStep } = this
      this.$router.push({ name: skipThankYouStep ? 'Success' : 'Thanks' })
    },
    isNumber (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    formatedOptionValue (option) {
      if (option.type) {
        return option.type === 'drop_down' ? option.valTitle : option.val
      }
      if (option.valTitle) {
        return option.valTitle
      }
      return option.val
    },
    MapsInit () {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions (predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions.map(prediction => prediction.description)
    },
    autocompleteInputHandler (value) {
      this.sendGiftData.address = value
    }
  }
}
</script>

<style lang="scss" scoped>
.shipping-input{
  &__label{
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  &.is-invalid{
    background-color: #FBE7E9;
    border: 1px solid #FA5E5E;
  }
  &__item{
    width: 100%
  }
  &__main{
    input, select{
      width: 100%;
      height: 40px;
      padding: 11px;
      border: 1px solid #D3D2D2;
      font-size: 15px;
      line-height: 22px;
      outline: none;
      &#state.is-invalid,
      &.is-invalid{
        background: #FBE7E9;
        border: 1px solid #FA5E5E;
      }
      &::placeholder{
        font-family: 'Lato-Italic';
        font-size: 15px;
        color: #979797;
      }
    }
    p{
      margin-top: 10px;
      margin-bottom: 0px;
      color: #FA5E5E;
    }
    .address-autocomplete{
      &__item{
        &--with-arrow{
          &.v-list-item{
            background-color: red;
          }
        }
      }
      ::v-deep.v-input__slot{
        margin: 0;
        height: 40px;
      }
      ::v-deep.v-text-field__details{
        display: none;
      }
    }
  }
}
#state{
  height: 40px;
  &::placeholder{
    font-family: "Lato-Regular";
    font-style: italic;
    font-size: 13px;
    font-weight: 500;
    color: #979797;
  }
}
.setting .select-icon{
  position: absolute;
  right: 5px;
  top: 3px;
  color: black;
}

.select-wrapper{
  position: relative;
  &.with-arrow {
    background-image: url('/public/images/select-arrow-icon.svg');
    background-size: 25px 25px;
    background-position: 99% 50%;
  }
}
.error-message{
  background-color: rgba(250,227,227,1);
}

.setting {
  max-width: 100%;

  &__button{
    &.back{
      margin-right: 12px;
      @media screen and (max-width: 375px) {
        width: 100%;
        margin-right: 0px;
        order: 2;
      }
    }
    &.get-gift{
      @media screen and (max-width: 375px) {
        width: 100%;
        order: 1;
      }
    }
    width: auto;
    @media screen and (max-width: 375px) {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 1400px;
  }

  .heading {
    color: #000000;
    font-size: 38px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 54px;
    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }

  .preview-shipping-block {
    width: 100%;

    @media screen and (min-width: 768px) {
      // width: 368px;
      // padding-right: 40px;
    }

    .shipping-input-row {
      clear: both;
      padding: 6px 0;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      letter-spacing: 0;

      label {
        font-weight: bold;
      }
    }

    .recipient-name-group {
      display: flex;
      height: 40px;
      min-width: 100%;

      @media screen and (min-width: 768px) {
        min-width: 368px;
      }

      &>div {
        width: 100%;
      }

      .recipient-name {
        max-width: 100%;
        min-width: 100%;
        border: none;
      }

      .company-name,
      .street-address,
      .city-name,
      .region-select-form {
        min-width: 100%;

        @media screen and (min-width: 768px) {
          min-width: 368px;
        }
      }
    }

    .region-attr-select-form {
      width: 100%;
      border-radius: 0;
      min-height: 30px !important;
      border: 1px solid #d3d2d2;
      padding: 0 10px;
      // width: 368px;

      &::placeholder {
        font-style: italic;
      }
    }

    .phone-number {
      width: 100%;
      min-width: 100%;

      @media screen and (min-width: 768px) {
        width: 254px;
        min-width: 254px;
      }
    }

    .ext-number {
      width: 30%;
      min-width: 30%;

      @media screen and (min-width: 768px) {
        width: 113px;
        min-width: 113px;
      }
    }

    .submit {
      width: 196px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      color: white;
      outline: none;

      @media screen and (min-width: 768px) {
        width: 196px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .thumb-group {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    border: 1px solid #E4E4E4;
    padding: 0;
    margin-top: 24px;
  }

  .thumb-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    border: 1px solid #E4E4E4;
  }

  .bg-more {
    background-color: #F6F6F6;
  }

  .thumb {
    max-width: 120px;
    max-height: 120px;
  }

  .more-img {
    color: #9F9F9F;
    font-size: 70px;
    letter-spacing: 0;
    line-height: 84px;
  }
  .popper{
    max-width: 190px;
  }
}
.v-menu__content{
  .address-autocomplete{
    &__item{
      &--with-arrow{
        &.v-list-item{
          background-image: url('/public/images/select-arrow-icon.svg');
          background-size: 25px 25px;
          background-position: 99% 50%;
        }
      }
    }
  }
}

.state-restriction-text {
  font-family: 'Lato-Italic', 'Lato', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #A1A1A1;

  @media screen and (max-width: 430px) {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
