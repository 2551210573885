<template>
  <div>
    <div v-if="!previewMode" class="coin-status flex-column">
      <div class="coin-status_label f15 black1">
        <span>{{multiMode ? 'Selected items' : 'GiftCoin Left'}}</span>
        <span v-if="multiMode" class="lato-bold">{{selectedGifts.length}}/{{maxGiftNum}}</span>
        <span v-else class="lato-bold">{{coinLeft}}/100</span>
      </div>
      <div v-if="!multiMode" class="coin-status_bar">
        <v-progress-linear
          v-model="left"
          color="#3DA7A1"
          height="12"
        ></v-progress-linear>
      </div>
    </div>
    <div v-if="previewMode" class="coin-status flex-column">
      <div class="coin-status_label f15 black1">
        <span>{{!previewIsGiftCoin ? 'Selected items' : 'GiftCoin Left'}}</span>
        <span v-if="!previewIsGiftCoin" class="lato-bold">{{ previewSelectedGifts.length }}/{{previewMaxSelectItems}}</span>
        <span v-else class="lato-bold">{{ previewCoinLeft }}/100</span>
      </div>
      <div v-if="previewIsGiftCoin" class="coin-status_bar">
        <v-progress-linear
          v-model="previewCoinLeft"
          color="#3DA7A1"
          height="12"
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CoinsStatus',
  props: {
    left: Number
  },
  computed: {
    ...mapState('eGift', [
      'multiMode', 'maxGiftNum', 'selectedGifts', 'coinLeft', 'previewMode'
    ]),
    ...mapState('preview', [
      'previewIsGiftCoin', 'previewMaxSelectItems', 'previewSelectedGifts'
    ]),
    ...mapGetters('preview', ['previewCoinLeft'])
  }
}
</script>

<style lang="scss">
.coin-status {
  background: #DFF3F2;
  padding: 17px 18px 18px 14px;

  @media screen and (min-width: 768px) {
    padding: 15px 18px 18px 14px;
  }

  &_label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_bar {
    border: 1px solid #B9CFCE;
    border-radius: 8px;
    overflow: hidden;
    .v-progress-linear__background {
      background-color: white !important;
      opacity: 1 !important;
    }
  }
}
</style>
