import Vue from 'vue'
import { VueMaskFilter } from 'v-mask'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/validation'
import VLockScroll from './plugins/vLockScroll'
import { VueMasonryPlugin } from 'vue-masonry'
import './components'
import VueMeta from 'vue-meta'

import { priceFilter } from '@/filters'

import { ACPPaymentProcessor } from '@/utils'

// @ts-ignore
if (!window.ACPPaymentProcessor) {
// @ts-ignore
  window.ACPPaymentProcessor = ACPPaymentProcessor
}

Vue.filter('priceFilter', priceFilter)
Vue.filter('phoneMask', VueMaskFilter)

Vue.use(VueMasonryPlugin)
Vue.use(VueMeta)
Vue.use(VLockScroll)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
