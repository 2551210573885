<template>
  <div class="review-single-gift">
    <common-loader v-if="previewSingleGiftLoading"/>
    <div v-if="previewDetailPanelInfo !== undefined">
      <div
        :class="{ not_available: !Number(gift.available) }"
        class="d-flex flex-column flex-md-row my-7"
      >
        <div class="single-gift__images col-12 col-md-5 pa-0">
          <div v-if="!Number(gift.available)" class="not_available_label pa-2">
            <h3 class="font-weight-light">Out of stock</h3>
          </div>
          <div class="d-flex flex-column preview-wrapper">
            <div
              v-if="Array.isArray(previewDetailPanelInfo.media_gallery) && previewDetailPanelInfo.media_gallery.length > 1"
              class="carousel-wrap"
              :style="{maxWidth: 800 + 'px', maxHeight: 800 + 'px'}"
            >
              <VueSlickCarousel
                ref="carousel"
                v-model="currentIdx"
                v-bind="settings"
                @afterChange="sliderAfterChange"
              >
                <div
                  class="prev-min-img"
                  v-for="path in previewDetailPanelInfo.media_gallery"
                  :key="path"
                >
                  <img :src="path">
                </div>
              </VueSlickCarousel>
            </div>
            <div
              v-if="Array.isArray(previewDetailPanelInfo.media_gallery)"
              class="prev-img-list d-flex flex-wrap mt-10"
              ref="mainImg"
            >
              <div
                class="prev-min-img pa-1"
                v-for="(path, index) in previewDetailPanelInfo.media_gallery"
                :key="path"
                @click="() => {
                  currentIdx = index
                  $refs.carousel.goTo(index)
                }"
              >
                <img
                  :src="path"
                  :class="currentIdx === index ? 'selected' : ''"
                >
              </div>
            </div>
            <div
              v-else
              class="prev-img-list d-flex"
              ref="mainImg"
            >
              <img :src="previewImgPath" alt="">
            </div>
          </div>
          <div
            v-if="Array.isArray(previewDetailPanelInfo.media_gallery) && previewDetailPanelInfo.media_gallery.length === 1"
            class="mobile-mode my-6">
            <div
              class="prev-min-img mb-1"
              v-for="path in detailPanelInfo.media_gallery"
              :key="path"
            >
              <img :src="path">
            </div>
          </div>
        </div>
        <div
          ref="descriptionBlock"
          class="col-12 offset-md-1 offset-lg-2 col-md-5 pa-0 d-flex flex-column mt-7 mt-md-0"
          style="position: relative;"
        >
          <h1 class="text-title text-h5">{{previewDetailPanelInfo.name}}</h1>
          <h3 v-if="previewDetailPanelInfo.fake_product_title && previewDetailPanelInfo.fake_product_title.length" class="text-subtitle-1 mt-2">{{ previewDetailPanelInfo.fake_product_title }}</h3>
          <!-- <span class="text-caption gray2 mt-5">{{previewDetailPanelInfo.sku}}</span> -->
          <div
            ref="giftDescription"
            id="gift-description"
            :class="{
              'show-all': showAllDescription,
              'non-gradient': !showAllDescription
            }"
            class="description gray2 mt-7" v-html="previewDetailPanelInfo.description"
          >
          </div>
          <a
            v-if="showReadeMore"
            @click="showAllDescription = !showAllDescription"
            class="green3 d-none d-md-block mt-4"
          >{{ showAllDescription ? 'Read more' : 'Read Less' }}</a>
          <form
            :id="gift.id + 'detail'"
            class="option-group mt-6 mb-4"
          >
            <div
              v-if="gift.options && gift.options.length && gift.options.filter(option => option.type === 'field').length"
            >
              <div
                class="item-selector"
                v-for="option in gift.options.filter(option => option.type === 'field')"
                :key="option.id"
              >
                <div class="gift-card-input__item">
                  <div class="gift-card-input__main">
                    <input
                      type="text"
                      :name="'option['+gift.id+']['+option.id+']'"
                      :id="'option-'+option.id"
                      v-model="option.val"
                      :disabled="!Number(gift.available)"
                      class="txt-field gift-card-input"
                      :class="missedList.find(item => item === option.id)? 'pink lighten-5' : ''"
                      :placeholder="option.title"
                      @change="changeHandler($event, option.title, option.id, null, option.type)"
                      @focus="showErrF = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="gift.options && gift.options.length && gift.options.filter(option => option.type === 'drop_down').length"
              class="d-flex flex-wrap"
            >
              <div
                class="item-selector mb-8"
                v-for="(option, index) in gift.options.filter(option => option.type === 'drop_down')"
                :key="option.id + index"
                :class="[gift.options.filter(option => option.type === 'drop_down').length === (2 || 4) ? 'w50' : 'w100']"
              >
                <div class="select-wrapper d-flex flex-column">
                  <div class="select">
                    <div class="gift-card-input__item">
                      <div class="shipping-input__header d-flex align-center mb-3">
                        <p class="shipping-input__label ma-0 mr-2">{{ option.title }}</p>
                      </div>
                      <div class="gift-card-input__main">
                        <select
                          v-if="option.values !== []"
                          :disabled="!Number(gift.available)"
                          class="select-attribute with-arrow col-12"
                          :class="missedList.find(item => item === option.id)? 'pink lighten-5' : ''"
                          :name="'option['+gift.id+']['+option.id+']'"
                          :id="'option-'+option.id + index"
                          v-model="gift.giftOptionsValues[`option${option.id}`]"
                          @change="changeHandler($event, option.title, option.id, option.values, option.type)"
                        >
                          <option
                            v-for="value in option.values"
                            :key="value.id"
                            :value="value.id"
                            :disabled="!value.is_available"
                          >
                            {{ value.title }} {{ !value.is_available ? '(out of stock)' : '' }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="button-wrap d-flex">
            <common-button
              width="95%"
              class="col-md-6 col-sm ma-0 pa-0"
              :disabled="!Number(gift.available)"
              @do="$store.commit('preview/setPreviewStep', previewStep + 1)"
            >
              Get This Gift
            </common-button>
          </div>
          <span v-if="showErrF" class="red1 err-txt pt-3">{{warningTxt}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else-if="previewDetailPanelInfo === undefined && !loadingProductDetail" class="f24 lato-light text-center my-auto">
    <h1>No information</h1> -->
  <!-- </div> -->
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'PreviewSingleGift',
  components: {
    VueSlickCarousel
  },
  props: {
    gift: {
      type: Object
    }
  },
  data: () => ({
    missedList: [],
    selected: null,
    previewImgPath: '',
    currentIdx: 0,
    warningTxt: 'All fields are required',
    settings: {
      dots: true,
      dotsClass: 'slick-dots custom-dot-class',
      edgeFriction: 0.35,
      centerMode: true,
      centerPadding: '0px',
      adaptiveHeight: true,
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500
    },
    showErrF: false,
    imgPathPrefix: process.env.VUE_APP_MAGENTO_URL + '/media/catalog/product',
    showAllDescription: false,
    showReadeMore: false,
    previewSingleGiftLoading: false
  }),
  computed: {
    ...mapState('preview', ['previewDetailPanelInfo', 'previewLoading', 'previewChooseText', 'previewProducts', 'previewStep'])
  },
  methods: {
    ...mapActions('eGift', ['getProductDetail']),
    selectCard (item) {
      this.$emit('selectCard', item)
    },
    changeHandler (event, title, id, values, type) {
      const newMainImage = this.gift.options.find(item => item.id === id).values.find(value => value.id === Number(event.target.value))?.image
      // change item image only for labels that are not related to size
      const shouldChangeItemImage = !['Size', 'Apparel Size'].includes(title)

      if (shouldChangeItemImage && newMainImage) {
        this.gift.image = newMainImage
        this.previewImgPath = newMainImage
      }

      this.gift.option = this.gift.option.filter(item => item.title !== title)
      if (event.target.value.length !== 0) {
        const resOptionObj = {
          id,
          title,
          val: event.target.value,
          type
        }
        if (values) {
          Object.assign(resOptionObj, { valTitle: values.find(value => +value.id === +event.target.value).title })
        }
        this.gift.option.push(resOptionObj)
        this.gift.options.find(item => item.id === resOptionObj.id).selected = true
      }
      if (this.gift.option_combinations && this.gift.options.length === 2) {
        const firstSelectValue = this.gift.giftOptionsValues[`option${this.gift.options[0].id}`]
        const listAvianbleOptions = this.gift.option_combinations[`${firstSelectValue}`]
        this.gift.options[1].values = this.initialValueSecondSelect.filter(item => listAvianbleOptions.includes(item.id))
      }
    },
    sliderAfterChange (val) {
      this.currentIdx = val
    }
  },
  watch: {
    previewDetailPanelInfo () {
      const giftDescription = document.querySelectorAll('#gift-description')[0]
      this.$nextTick(() => {
        if (((window.innerWidth > 960 && window.innerWidth < 1263) && giftDescription.clientHeight > 199) ||
        ((window.innerWidth > 1263 && window.innerWidth < 1900) && giftDescription.clientHeight > 349) ||
        ((window.innerWidth > 1900) && giftDescription.clientHeight > 449)) {
          this.showReadeMore = true
          this.showAllDescription = true
        }
      })
    }
  },
  async created () {
    this.previewSingleGiftLoading = true
    await this.$store.dispatch('preview/getSingleGiftData', this.gift.id)
    this.previewImgPath = this.gift.image
    this.gift.options.map(option => Object.assign(option, { selected: false, val: null }))
    this.previewSingleGiftLoading = false
  }
}
</script>

<style lang="scss" scoped>
.single-gift{
  &__images{
    position: relative;
  }
}
.gift-card-input{
  &__label{
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  &.is-invalid{
    background-color: #FBE7E9;
    border: 1px solid #FA5E5E;
  }
  &__main{
    input, select{
      width: 100%;
      height: 40px;
      padding: 9px 11px;
      background-color: #fff;
      border: 1px solid #D3D2D2;
      font-size: 15px;
      line-height: 22px;
      outline: none;
      &#state.is-invalid,
      &.is-invalid{
        background: #FBE7E9;
        border: 1px solid #FA5E5E;
      }
      &::placeholder{
        font-family: 'Lato-Italic';
        font-size: 15px;
        color: #979797;
      }
      &.disabled{
        background-color: #F4F4F4;
      }
    }
    p{
      // margin-top: 10px;
      // margin-bottom: 0px;
      color: #FA5E5E;
    }
  }
}
.not_available{
    &_label{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #f25b5b;
      color: #fff;
      width: 150px;
      display: flex;
      justify-content: center;
    }
    &_select{
      color: #f5f5f5 !important;
      border: solid 1px #f5f5f5 !important;
    }
    & .mdi-chevron-down::before {
      color: #c8c8c8;
    }
    & img{
      opacity: 0.4;
    }
  }
.single-button{
  span{
    color: rgb(255, 255, 255) !important;
  }
}
.select-wrapper{
  width: 95%;
}
.select{
  position: relative;
}
.select-icon{
  color: black;
  position: absolute;
  right: 10px;
}

.slick-list{
  overflow: hidden;
  // height: 400px;
  img{
    width: 100%;
  }
}
.vue-slick-carousel__wraper{
  width: 1000px;
  img{
    width: 30%;
    height: 300px;
  }
}
.vue-slick-carousel{
  width: 100%;
  border: solid 3px red;
}

.select-attribute {
  padding: 8px 22px 8px 12px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0;
  height: 28px;
  background-color: #fff;
  border: 1px solid #d3d2d2;
  font-size: 14px;
  font-family: "Lato", Verdana, Arial, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  &.with-arrow {
    background-image: url('/public/images/select-arrow-icon.svg');
    background-size: 16px 16px;
    background-position: right 4px bottom 45%;
  }
}

.product-detail-panel {
  padding-left: 0;

  .mobile-mode {
    position: relative;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 45px;
  }

  .panel-content {
    padding-right: 0;
    margin: 0!important;

    @media screen and (min-width: 1024px) {
      padding-right: 130px;
      margin-left: 28px!important;
    }

    .preview-wrapper {
      display: none !important;

      @media screen and (min-width: 1024px) {
        display: flex !important;
      }
    }

    .prevImg {
      max-width: 100%;
      max-height: 50vh;

    }

    .prev-min-img {
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 20%;

      &>img {
        max-width: 30%;
        max-height: 100%;
      }

      @media screen and (min-width: 1024px) {
        height: 80px;
        width: 80px;
      }

      img {
        border: 1px solid #E4E4E4;
        max-width: 30%;
        max-height: 100%;
      }

      &.selected {
        border: 3px solid #42B77A;
      }
    }

    .mobile-mode .prev-min-img {
      border: none;
    }

    .product-name {
      color: #161616;
      font-size: 25px;
      letter-spacing: 0;
      line-height: 35px;
    }

    .sku {
      color: #9F9F9F;
      font-size: 10px;
      letter-spacing: 0.13px;
      line-height: 12px;
    }

    .short_description {
      color: #9F9F9F;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 24px;
    }

    .txt-field {
      width: 100%;
      height: 28px;
      border: 1px solid #d3d2d2;
      padding-left: 10px;
      outline: none;
    }
  }

  .item-selector {
    margin-bottom: 10px;

    label {
      color: #959595;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .add-button {
    background: #000 !important;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    margin-top: auto;
    border-radius: 0;
    width: 100%;

    span:first-child {
      pointer-events: none;
    }

    span:last-child {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      cursor: pointer;
    }
  }

  .add-button.remove {
    background-color: white !important;
    border: 2px solid black;
  }

  .add-button.disabled {
    width: 100%;
    opacity: 0.3;
  }

  .gift-coin-value {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: none;

    strong {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      font-style: normal;
    }
  }

  .err-txt {
    height: 0;
  }

  .txt-field {
    width: 100%;
    height: 28px;
    border: 1px solid #d3d2d2;
    padding-left: 10px;
    outline: none;
  }
}
.preview-wrapper{
  img{
    width: 100%;
  }
}
.prev-min-img{
  cursor: pointer;
  box-sizing: border-box;
  width: 20%;
  display: flex;
  justify-content: center;
  img{
    border: solid 1px #E4E4E4;
    &.selected {
      border: 3px solid #42B77A;
    }
  }
}
.prev-img-list{
  width: 100%;
  img{
    width: 100%;
  }
}
::v-deep.carousel-wrap{
  img{
    border: none;
  }
  ul{
    padding: 0px !important;
    @media screen and (max-width: 500px) {
      bottom: -40px;
    }
    @media screen and (min-width: 960px) and (max-width: 1265px){
      bottom: -40px;
    }
  }
}
.prev-video {
  position: relative;
  .play-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
    height: 40%;
    border: none;
  }
}
.description{
  position: relative;
  overflow: hidden;
  // height: 500px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  &.show-all{
    @media screen and (min-width: 960px) {
      max-height: 200px;
    }
    @media screen and (min-width: 1263px) {
      max-height: 350px;
    }
    @media screen and (min-width: 1900px) {
      max-height: 450px;
    }
  }
  &.non-gradient{
    &::after {
      display: none
    }
  }
}
</style>
