export default {
  methods: {
    getOptionValidation (option) {
      const { required, max_characters: maxChars } = option

      const result = []

      if (required) { result.push('required') }
      if (maxChars) { result.push(`max:${maxChars}`) }

      return result.join('|')
    },
    getOptionValidationMessages (option) {
      const { required, max_characters: maxChars, title } = option

      const result = {}

      if (required) { result.required = `${title} is a required field.` }
      if (maxChars) { result.max = `This line can only have ${maxChars} characters` }

      return result
    }
  }
}
