<template>
  <v-select
    v-model="modelValue"
    :items="items"
    v-bind="$attrs"
    v-on="$listeners"
    :attach="attachTo"
    class="rounded-0 thin-border update-gift-select v-input--errors"
    color="black"
    validate-on-blur
    append-icon="mdi-chevron-down"
    outlined
    solo
    flat
    dense
    tile
    :height="30"
    :menu-props="{
      bottom: true,
      offsetY: true,
      left: true,
      tile: true,
      contentClass: 'update-gift-select-items'
    }"
  />
</template>

<script>
export default {
  name: 'TheUpdatePanelGiftCardSelect',
  props: {
    value: {
      type: [String, Number, Object, Array],
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    attachTo: null
  }),
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.attachTo = this.$el
  }
}
</script>

<style lang="scss">
.update-gift-select-items {
  padding: 0;
  .v-list {
    padding: 0;
    > .v-list-item {
      padding: 2px 10px;
      max-height: 32px;
      min-height: 32px;
      color: #16181F;
      &:hover {
        background-color: #F5F5F5;
        color: #16181F !important;
      }
    }
  }
}
.update-gift-select {
  &.v-text-field--outlined {
    .v-input__append-inner {
      margin-top: 0px !important;
    }
    .v-input__icon {
      margin-top: 4px !important;
      padding-left: 14px;
    }
  }
}
</style>
