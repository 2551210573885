<template>
  <v-container :class="!userIsUsingMobileDevice && 'px-12 pt-3 py-0'">
    <v-row dense justify="space-between">
      <StepHeader :class="!userIsUsingMobileDevice && 'pb-6'">
        <template #title>
          Payment
        </template>
        <template #subtitle-with-heart>
          Don't worry, your choice to upgrade or re-gift won't be shared with anyone
        </template>
      </StepHeader>

      <v-col cols="12" md="7" order="2" order-md="1">
        <v-row dense>
          <v-col cols="12" :class="userIsUsingMobileDevice && 'py-10'">
            <v-divider />
          </v-col>
          <v-col cols="12" class="payment-form__step--title py-8">
            1. Enter your billing details:
          </v-col>
          <v-col cols="12" class="pb-6">
            <PaymentFormCheckbox v-model="billingAddressSameAsShippingAddress">
              Billing address is the same as shipping address
            </PaymentFormCheckbox>
          </v-col>

          <v-col
            cols="12"
            v-if="billingAddressSameAsShippingAddress"
            class="pb-8"
          >
            <v-row no-gutters class="user-billing-address">
              <v-col cols="12">
                {{ addressFullName }}
              </v-col>
              <v-col cols="12">
                {{ addressHomeAddress }}
              </v-col>
              <v-col cols="12">
                {{ userAddress.city }}
              </v-col>
              <v-col cols="12">
                {{ userAddress.state }}
              </v-col>
              <v-col cols="12">
                {{ userAddress.zip }}
              </v-col>
              <v-col cols="12">
                {{ userAddress.telephone }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-else>
            <ContactInfoForm
              :userFirstName.sync="billingAddress.first_name"
              :userLastName.sync="billingAddress.last_name"
              :userEmail.sync="billingAddress.email"
              :userAddress.sync="billingAddress.address"
              :userAddressApt.sync="billingAddress.address_apt"
              :userZip.sync="billingAddress.zip"
              :userCity.sync="billingAddress.city"
              :userState.sync="billingAddress.state"
              :userTelephone.sync="billingAddress.telephone"
            />
          </v-col>

          <v-col cols="12" class="pt-6">
            <v-divider />
          </v-col>
          <v-col cols="12" class="payment-form__step--title pt-6">
            2. Pay by Credit Card
          </v-col>

          <v-col cols="12"></v-col>

          <v-col cols="12" v-if="loadingBluesnap">
            <common-loader relative resetPadding minHeightAuto />
          </v-col>

          <v-col cols="12" v-show="!loadingBluesnap">
            <v-row dense>
              <label for="card-number">Credit card number</label>
              <v-col cols="12" md="8">
                <div class="iframe-handler" id="card-number" data-bluesnap="ccn" />
              </v-col>
              <label for="exp-date">Expiry date</label>
              <v-col cols="12" md="8">
                <div class="iframe-handler" id="exp-date" data-bluesnap="exp" />
              </v-col>
              <label for="cvv">
                Card verification code
              </label>
              <v-col cols="10" md="4">
                <div class="iframe-handler" id="cvv" data-bluesnap="cvv" />
              </v-col>
              <v-col cols="2" md="1" class="d-flex align-center">
                <Popper
                  class="d-flex"
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0,10px' } }
                  }">
                  <div
                    class="popper px-4 py-2 text-left"
                    style="max-width: 300px"
                  >
                    Mocked tooltip text
                  </div>
                  <icons-question slot="reference" :width="20" :height="20" />
                </Popper>
              </v-col>

              <v-col cols="12" class="py-8">
                <PaymentFormCheckbox v-model="isTermsOfUseAccepted">
                  I accept the
                  <a class="green3 pl-1" href="https://corporategift.com/terms-and-conditions/" target="_blank">
                    Terms of Use
                  </a>
                </PaymentFormCheckbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="pb-10">
            <common-button
              :disabled="!isFormFilled"
              @click="goToNextPage()"
              :block="userIsUsingMobileDevice"
            >
              Place order
            </common-button>

            <common-button
              v-if="userIsUsingMobileDevice"
              outlined
              class="mt-5"
              @click="$emit('goToPreviousStep')"
              :block="userIsUsingMobileDevice"
            >
              Back
            </common-button>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="4" order="1" order-md="2">
        <SelectedGiftPreview
          :selectedItem="selectedItem"
          :shippingAddress="userAddress"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* global bluesnap */
import Api from '@/axios/api'

import StepHeader from '../StepHeader.vue'
import SelectedGiftPreview from '../SelectedGiftPreview.vue'
import ContactInfoForm from '../ContactInfoForm.vue'
import PaymentFormCheckbox from '../PaymentFormCheckbox.vue'
import Popper from 'vue-popperjs'

export default {
  name: 'PaymentForm',
  components: {
    StepHeader,
    ContactInfoForm,
    SelectedGiftPreview,
    PaymentFormCheckbox,
    Popper
  },
  props: {
    selectedItem: {
      type: Object,
      required: true
    },
    userAddress: {
      type: Object,
      required: true
    },
    blueSnapToken: {
      type: String,
      required: false,
      default: null
    },
    billingAddress: {
      type: Object,
      required: true
    },
    termsOfUseAccepted: {
      type: Boolean,
      required: true
    },
    billingSameAsAddress: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    loadingBluesnap: false,
    invalidValues: ['ccn', 'exp', 'cvv']
  }),
  watch: {
    billingAddressSameAsShippingAddress: function (val) {
      if (val) {
        Object.assign(this.billingAddress, this.userAddress)
      } else {
        this.$emit('clearBilling')
      }
    }
  },
  computed: {
    userIsUsingMobileDevice () {
      return this.$vuetify.breakpoint.mobile
    },
    addressFullName () {
      const { billingAddress } = this

      return `${billingAddress.first_name} ${billingAddress.last_name}`
    },
    addressHomeAddress () {
      const { billingAddress } = this
      let result = billingAddress.address
      if (billingAddress.address_apt) result += `/${billingAddress.address_apt}`

      return result
    },
    isTermsOfUseAccepted: {
      get () {
        return this.termsOfUseAccepted
      },
      set (val) {
        this.$emit('update:termsOfUseAccepted', val)
      }
    },
    billingAddressSameAsShippingAddress: {
      get () {
        return this.billingSameAsAddress
      },
      set (val) {
        this.$emit('update:billingSameAsAddress', val)
      }
    },
    isFormFilled () {
      const { billingAddress, isTermsOfUseAccepted, invalidValues } = this
      return billingAddress.first_name &&
        billingAddress.last_name &&
        billingAddress.email &&
        billingAddress.address &&
        billingAddress.zip &&
        billingAddress.city &&
        billingAddress.state &&
        billingAddress.telephone &&
        isTermsOfUseAccepted &&
        invalidValues.length === 0
    }
  },
  created () {
    if (this.billingAddressSameAsShippingAddress) Object.assign(this.billingAddress, this.userAddress)
    this.initBluesnap()
  },
  methods: {
    async takeToken () {
      return Api.get('bluesnap/payment-fields-token').then((data) => {
        this.$emit('update:blueSnapToken', data)
        return data
      })
    },
    initBluesnap () {
      this.loadingBluesnap = true
      this.takeToken().then((apiToken) => {
        const bsObj = {
          token: apiToken,
          onFieldEventHandler: {
            setupComplete: function () {
              this.updateLoadingState(false)
            },
            onFocus: function (tagId) {},
            onBlur: function (tagId) {},
            onType: function (tagId, cardType, cardData) {},
            onEnter: function (tagId) {},
            onValid: function (tagId) {
              this.removeInvalidValues(tagId)
            },
            onError: function (tagId, errorCode) {
              if (errorCode === '10') console.log('Invalid values')
              if (errorCode === '14040') this.updateToken() || console.log('Token is expired')
              if (errorCode === '14041') this.updateToken() || console.log('Could not find token')
              if (errorCode === '400') console.log('Session expired - refrresh page to continue')
            }
          },
          style: {
            input: {
              'font-family':
                'Lato-Regular ,RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
              'font-size': '15px',
              'line-height': '20px',
              color: '#000000de',
              'border-color': '#d3d2d2',
              'border-width': '1px',
              'border-style': 'solid',
              display: 'inherit',
              padding: '0 8px'
            },
            ':focus': {
              color: 'black'
            },
            '::placeholder': {
              'font-family':
                'Lato-Italic, sans-serif',
              'font-style': 'italic',
              'font-size': '15px',
              'line-height': '18px',
              color: '#979797'
            },
            '.invalid': {
              color: '#FA5E5E',
              'border-color': '#FA5E5E',
              'background-color': '#FBE7E9'
            }
          },
          ccnPlaceHolder: 'Enter credit card number',
          cvvPlaceHolder: ' ',
          expPlaceHolder: 'MM / YY'
        }

        bsObj.onFieldEventHandler.updateToken = this.updateToken
        bsObj.onFieldEventHandler.setInvalidValues = this.setInvalidValues
        bsObj.onFieldEventHandler.removeInvalidValues = this.removeInvalidValues
        bsObj.onFieldEventHandler.updateLoadingState = (val) => {
          this.loadingBluesnap = val
        }

        bluesnap.hostedPaymentFieldsCreate(bsObj)

        // BlueSnap adds iframe to the body and that element adds another scrollbar
        // this query select hides all BlueSnap iframes
        document.querySelectorAll('#bs-fraud-sid').forEach(element => {
          element.style.display = 'none'
        })
      })
    },
    updateToken () {
      this.takeToken().then(token => bluesnap.hostedPaymentFieldsUpdateToken(token))
    },
    setInvalidValues (tagId) {
      if (!this.invalidValues.includes(tagId)) this.invalidValues.push(tagId)
    },
    removeInvalidValues (tagId) {
      this.invalidValues = this.invalidValues.filter(item => item !== tagId)
    },
    goToNextPage () {
      bluesnap.hostedPaymentFieldsSubmitData((callback) => {
        if (callback.cardData != null) {
          this.$emit('goToNextStep')
        }
      })
    }
  }
}
</script>

<style lang="scss">
.iframe-handler {
  iframe {
    max-height: 32px;
  }
}
</style>

<style lang="scss" scoped>
.payment-form {
  &__step--title {
    font-family: 'Lato-Bold', sans-serif;
    color: #000000;
    line-height: 22px;
    font-size: 18px;
  }
}
.user-billing-address > .col {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 15px;
  color: #16181F;
}

label {
  font-family: 'Lato-Bold', sans-serif;
  font-size: 15px;
  color: #000000;
  margin-top: 14px;
  padding: 0 4px;
  width: 100%;
}
.selected-update-gift{
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
  &__info{
    &--name{
      line-height: 18px;
    }
    &--add-price{
      line-height: 22px;
      background-color: #EDFCFB;
    }
  }
}
</style>
