import { render, staticRenderFns } from "./Egift.vue?vue&type=template&id=561c4ed6&scoped=true&"
import script from "./Egift.vue?vue&type=script&lang=js&"
export * from "./Egift.vue?vue&type=script&lang=js&"
import style0 from "./Egift.vue?vue&type=style&index=0&id=561c4ed6&prod&lang=scss&"
import style1 from "./Egift.vue?vue&type=style&index=1&id=561c4ed6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561c4ed6",
  null
  
)

export default component.exports