import Api from '../axios/api'
import ApiCms from '../axios/apiCms'
import ApiLoqate from '../axios/apiLoqate'

const getCookie = (cookieName) => {
  const cookie = {}
  document.cookie.split(';').forEach((el) => {
    const [key, value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie[cookieName] ?? null
}

export default {
  namespaced: true,

  state: () => ({
    id: undefined,
    recipientFirstName: undefined,
    recipientLastName: undefined,
    giftId: undefined,
    logoUrl: undefined,
    upgradable: undefined,
    redeemPageTitle: null,
    respondToText: null,
    declinable: null,
    reGiftable: null,
    videoUrl: undefined,
    videoOpeningLine: null,
    canSelectAlternatives: true,
    vimeoVideoId: '',
    gifts: undefined,
    hiddenGiftsOnRedeemList: [],
    gifts0: undefined,
    selectedGifts: [],
    loadingSendGift: false,
    loadingSendReGift: false,
    loadingSendThankYouNote: false,
    errorSendThankYouNote: false,
    errorSendThankYouNoteMessage: '',
    getGiftErrorStatus: '',
    usedGiftStatus: null,
    usedGiftMessage: '',
    coinLeft: 100,
    contactNumber: '646-751-7075',
    thanksNotes: [],
    loading: false,
    redeemStatus: null,
    successNum: undefined,
    redeemAlcoholickError: false,
    redeemErrorMessage: '',
    successReGiftNum: null,
    recipientHash: '',
    loadingProductDetail: false,
    detailPanelInfo: undefined,
    faq: undefined,
    loadingFaq: false,
    sender: '',
    preEmail: '',
    redeemed: undefined,
    updateGiftsLink: '',
    chooseText: '',
    welcomeMessage: '',
    consentContent: null,
    upsellProducts: [],
    allProductsInGiftAreAvailable: true,
    videoPassed: false,
    showDemoText: null,
    stateShortCode: null,
    giftInternationalShipping: null,
    showCanada: null,
    cardRedeemUrl: null,
    cardCode: null,
    groupedGift: false,
    calendlyLink: '',
    previewMode: false,
    isPreProduction: false,
    skipThankYouStep: false,
    addressesListFromLoqate: [],
    giftCardsInfo: [],
    loqateAQI: '',
    loqateAVC: '',
    loqateVerifiedAddress: null,
    freeFormLink: {
      url: null,
      button: 'Go!'
    },

    // multi-gift
    multiMode: undefined,
    totalGiftNum: undefined,
    maxGiftNum: undefined,
    multiSelectMode1: undefined,
    multiSelectMode2: undefined,
    mainGift: undefined
  }),

  getters: {
    allAvailableSlotsSelected (state) {
      return state.multiMode ? Number(state.maxGiftNum) - state.selectedGifts.length === 0 : false
    },
    allGiftCoinsWereSpent (state) {
      return !state.multiMode ? state.coinLeft <= 0 : false
    }
  },

  mutations: {
    setId (state, id) {
      state.id = id
    },
    setRecipientFirstName (state, str) {
      state.recipientFirstName = str
    },
    setRecipientLastName (state, str) {
      state.recipientLastName = str
    },
    setGiftId (state, id) {
      state.giftId = id
    },
    setLogoUrl (state, url) {
      state.logoUrl = url
    },
    setUpgradable (state, f) {
      state.upgradable = f
    },
    setDeclinable (state, data) {
      state.declinable = data
    },
    setReGiftable (state, data) {
      state.reGiftable = data
    },
    setGetGiftErrorStatus (state, data) {
      state.getGiftErrorStatus = data
      state.loading = false
    },
    setVideoUrl (state, url) {
      state.videoUrl = url
    },
    setVideoOpeningLine (state, data = null) {
      state.videoOpeningLine = data
    },
    setVimeoVideoId (state, url) {
      state.vimeoVideoId = url
    },
    setMaxSelectItem (state, data) {
      state.maxGiftNum = data !== null ? data : '1'
      // state.maxGiftNum = 3
    },
    setGifts (state, items) {
      state.gifts = items.map(item => ({
        ...item,
        image: (item.image.includes('.com') ? item.image : (('https://cf.corporategift.com') + item.image)),
        coins: state.multiMode ? 1 : parseInt(item.coins, 10),
        option: [],
        initialMainImageLink: item.image,
        giftOptionsValues: Object.assign({}, ...item.options.map((item) => ({ [`option${item.id}`]: '' })))
      })).filter(gift => !gift.is_hidden_on_redeem)
      state.hiddenGiftsOnRedeemList = items.filter(gift => gift.is_hidden_on_redeem).map(gift => gift.id)
      state.gifts0 = state.gifts
      if (state.gifts.filter(gift => gift.selected === true) !== undefined) {
        if (state.gifts.filter(gift => gift.selected === true).length === 1) {
          state.multiSelectMode1 = true
          state.mainGift = state.gifts.filter(gift => gift.selected === true)[0]
          state.selectedGifts.push(state.mainGift)
          state.gifts = state.gifts.filter(gift => gift.selected !== true)
        }
        if (state.gifts.filter(gift => gift.selected === true).length > 1) {
          state.multiSelectMode2 = true
          state.mainGift = state.gifts.filter(gift => gift.selected === true)[0]
          state.selectedGifts.push(state.mainGift)
          state.gifts = state.gifts.filter(gift => gift.selected !== true)
        }
      }
      if (state.multiMode) {
        state.coinLeft = state.multiSelectMode1 ? (state.maxGiftNum - 1) : state.maxGiftNum
      }
      state.loading = false
    },
    setUsedGiftStatus (state, data) {
      state.usedGiftStatus = data
    },
    setUsedGiftMessage (state, data) {
      state.usedGiftMessage = data
    },
    setCoinLeft (state, coins) {
      state.coinLeft = coins
    },
    setSelectedGifts (state, items) {
      state.selectedGifts = items
    },
    setLoadingSendGift (state, data) {
      state.loadingSendGift = data
    },
    setLoadingSendReGift (state, data) {
      state.loadingSendReGift = data
    },
    setLoadingSendThankYouNote (state, data) {
      state.loadingSendThankYouNote = data
    },
    setErrorSendThankYouNote (state, data) {
      state.errorSendThankYouNote = data
    },
    setErrorSendThankYouNoteMessage (state, data) {
      state.errorSendThankYouNoteMessage = data
    },
    setUpsellProducts (state, data) {
      state.upsellProducts = data
    },
    setThanksNotes (state, data) {
      state.thanksNotes = data
    },
    setRecipientHash (state, hash) {
      state.recipientHash = hash
    },
    setDetailPanelInfo (state, info) {
      state.detailPanelInfo = info
      state.loadingProductDetail = false
    },
    setFaq (state, data) {
      state.faq = data
      state.loadingFaq = false
    },
    setSender (state, data) {
      state.sender = data
    },
    setPreEmail (state, data) {
      state.preEmail = data
    },
    setRedeemed (state, data) {
      state.redeemed = data
      state.loading = false
    },
    setMultiMode (state, data) {
      state.multiMode = !data
    },
    setTotalGiftNum (state, data) {
      state.totalGiftNum = data
    },
    setUpdateGiftsLink (state, data) {
      state.updateGiftsLink = data
    },
    setChooseText (state, data) {
      state.chooseText = data
    },
    setWelcomeMessage (state, data) {
      state.welcomeMessage = data
    },
    setSuccessReGiftNum (state, data) {
      state.successReGiftNum = data
    },
    setConsentContent (state, data) {
      state.consentContent = {
        gdpr_text: data.gdpr_text,
        read_more_text: data.read_more_text
      }
    },
    setRedeemStatus (state, data) {
      state.redeemStatus = data
    },
    setSuccessNum (state, data) {
      state.successNum = data
    },
    setRedeemErrorMessage (state, data) {
      state.redeemErrorMessage = data
    },
    setRedeemAlcoholickError (state, data = false) {
      state.redeemAlcoholickError = data
    },
    setAllProductsInGiftAreAvailable (state, data) {
      state.allProductsInGiftAreAvailable = data
    },
    setVideoPassed (state, data) {
      state.videoPassed = data
    },
    setShowDemoText (state, data) {
      state.showDemoText = data
    },
    setStateShortCode (state, data) {
      state.stateShortCode = data
    },
    setGiftInternationalShipping (state, data) {
      state.giftInternationalShipping = !!Number(data)
    },
    setShowCanada (state, data) {
      state.showCanada = !!Number(data)
    },
    setCardRedeemUrl (state, data) {
      state.cardRedeemUrl = data
    },
    setCardCode (state, data) {
      state.cardCode = data
    },
    setGroupedGift (state, data) {
      state.groupedGift = data
    },
    setCalendlyLink (state, data) {
      state.calendlyLink = data
    },
    setPreviewMode (state, data) {
      state.previewMode = data
    },
    setIsPreProduction (state, data = false) {
      state.isPreProduction = data
    },
    setSkipThankYouStep (state, data = false) {
      state.skipThankYouStep = data ?? false
    },
    setAddressesListFromLoqate (state, data) {
      state.addressesListFromLoqate = data
    },
    setGiftCardsInfo (state, data) {
      state.giftCardsInfo = data || []
    },
    setRedeemPageTitle (state, data = null) {
      state.redeemPageTitle = data
    },
    setRespondToText (state, data = null) {
      state.respondToText = data
    },
    setLoqateAQI (state, data) {
      state.loqateAQI = data
    },
    setLoqateAVC (state, data) {
      const parsedAVC = data.split('-')
      const parsedStatus = parsedAVC[0].split('')
      state.loqateAVC = {
        verificationStatus: parsedStatus[0],
        postVerificationStatus: parsedStatus[1],
        preVerificationStatus: parsedStatus[2],
        verificationStatusWithPostStatus: parsedStatus[0] + parsedStatus[1],
        parsingStatus: parsedAVC[1],
        postcodeStatus: parsedAVC[2],
        matchScore: Number(parsedAVC[3])
      }
    },
    setLoqateVerifiedAddress (state, data) {
      state.loqateVerifiedAddress = data
    },
    setFreeFormLink (state, data) {
      state.freeFormLink = { ...data }
    },
    setSelectAlternatives (state, data = true) {
      state.canSelectAlternatives = data
    }
  },

  actions: {
    async getGifts ({ state, commit }, recipientHash) {
      state.loading = true
      commit('setRecipientHash', recipientHash)
      if (process.env.VUE_APP_TEST_MODE) await Api.get('/recipient/' + recipientHash + '/refresh')

      const lastCreatedGiftId = getCookie('last_created_gift_id')
      const params = {}

      if (lastCreatedGiftId) {
        params.last_created_gift_id = lastCreatedGiftId
      }

      await Api.get('/recipient/' + recipientHash + '/gift', { params })
        .then(res => {
          if (res.data) {
            commit('setId', res.data.id)
            commit('setRecipientFirstName', res.data.firstname)
            commit('setRecipientLastName', res.data.lastname)
            commit('setPreEmail', res.data.email)
            commit('setGiftId', res.data.gift_id)
            commit('setLogoUrl', res.data.logo_url)
            commit('setTotalGiftNum', res.data.items_count)
            commit('setMaxSelectItem', res.data.max_select_items)
            commit('setMultiMode', res.data.is_gift_coin)
            commit('setDeclinable', res.data.can_decline)
            commit('setUpgradable', res.data.can_upgrade_egift)
            commit('setReGiftable', res.data.can_regift)
            commit('setVideoUrl', res.data.video_url)
            commit('setVideoOpeningLine', res.data?.video_greeting_opening_line)
            commit('setGifts', res.data.products)
            commit('setSender', res.data.sender)
            commit('setUpdateGiftsLink', res.data.upgrade_url)
            commit('setChooseText', res.data.choose_text)
            commit('setWelcomeMessage', res.data.welcome_message)
            commit('setAllProductsInGiftAreAvailable', res.data.is_available)
            commit('setShowDemoText', res.data.show_demo_text)
            commit('setGiftInternationalShipping', res.data.gift_international_shipping)
            commit('setShowCanada', res.data.show_canada)
            commit('setGroupedGift', res.data.is_type_grouped)
            commit('setCalendlyLink', res.data.calendly_link)
            commit('setIsPreProduction', res.data?.is_pre_production)
            commit('setSkipThankYouStep', res.data?.skip_thankyou_message)
            commit('setRedeemPageTitle', res.data?.redeem_page_title)
            commit('setRespondToText', res.data?.respond_to)
            commit('setSelectAlternatives', res.data?.can_show_alternatives ?? true)

            if (res?.data?.free_form_url) {
              commit('setFreeFormLink', {
                url: res?.data?.free_form_url,
                button: res?.data?.free_form_button || 'Go!'
              })
            }

            if (res?.data?.gift_claim_button_color) {
              commit('setButtonColor', res?.data?.gift_claim_button_color, { root: true })
            }
          } else {
            commit('setGetGiftErrorStatus', res.status)
            commit('setRecipientFirstName', res.firstname)
          }
        })
        .catch(err => {
          if (err.response.status === 500 || err.response.status === 404) {
            commit('setGetGiftErrorStatus', 'NotAvailable')
          }
          commit('setRedeemed', err.response.data)
          commit('setLogoUrl', err.response.data.logo)
        })
      state.loadingFaq = true
      await Api.get('/recipient/faq')
        .then(res => {
          commit('setFaq', res.data)
        })
    },
    async getThankyounotes ({ commit, state }) {
      await Api.get('/recipient/' + state.recipientHash + '/thankyounotes')
        .then(res => {
          commit('setThanksNotes', res.data)
        })
    },
    async fetchOrderNumber ({ commit, state }) {
      await Api.get('/recipient/' + state.recipientHash + '/order')
        .then(res => {
          commit('setSuccessNum', res.order_number)
        })
    },
    async getUpSellProducts ({ commit, state }) {
      state.loading = true
      const res = await Api.get('/recipient/' + state.recipientHash + '/gift/cross-sells' + '?available_states_filter[]=' + state.stateShortCode)
      commit('setUpsellProducts', res.data.map(item => ({
        ...item,
        image: (item.image.includes('.com') ? item.image : (('https://cf.corporategift.com') + item.image)),
        coins: state.multiMode ? 1 : parseInt(item.coins, 10),
        option: [],
        initialMainImageLink: item.image,
        giftOptionsValues: Object.assign({}, ...item.options.map((item) => ({ [`option${item.id}`]: '' })))
      })))
      state.gifts0 = state.upsellProducts
      state.loading = false
    },
    selectCard ({ state, commit }, item) {
      if (!state.multiSelectMode1 && item?.id !== state.mainGift?.id) {
        if (state.selectedGifts.filter(gift => gift.id === item?.id).length > 0) {
          commit('setSelectedGifts', state.selectedGifts.filter(gift => gift.id !== item?.id))
          state.coinLeft += state.multiMode ? 1 : (item?.coins || 0)
        } else {
          state.selectedGifts.push(item)
          state.coinLeft -= state.multiMode ? 1 : (item?.coins || 0)
        }
      }
    },
    async sendGift ({ state, commit }, conf) {
      commit('setLoadingSendGift', true)
      const options = {}
      state.selectedGifts.forEach(gift => {
        if (gift.option && gift.option.length > 0) {
          Object.assign(options, { [`${gift.id}`]: {} })
          gift.option.forEach(option => {
            Object.assign(options[`${gift.id}`], { [`${option.id}`]: option.val })
          })
        }
      })
      const fullRedeemObject = { ...conf }
      if (Object.keys(options).length) {
        Object.assign(fullRedeemObject, { option: options })
      }
      return Api.post('/recipient/' + state.recipientHash + '/redeem', fullRedeemObject).then((res) => {
        commit('setRedeemStatus', res.success)
        commit('setRedeemErrorMessage', res.message)
        commit('setRedeemAlcoholickError', res.shipping_error)
        commit('setSuccessNum', res.increment_id)
        if (res.success) {
          commit('setCardRedeemUrl', res.card_redeem_url)
          commit('setCardCode', res.card_code)
          commit('setGiftCardsInfo', res.gift_cards || [])
        }
      }).catch((error) => {
        if (error.response.data) {
          const { errors, shipping_error: shippingError } = error.response.data || {}
          commit('setRedeemErrorMessage', Object.values(errors).flat().join(', '))
          commit('setRedeemAlcoholickError', shippingError ?? false)
        }
      }).finally(() => {
        commit('setLoadingSendGift', false)
      })
    },
    async sendNote ({ state, commit }, {
      note,
      color,
      sender
    }) {
      commit('setLoadingSendThankYouNote', true)
      return Api.post('/recipient/' + state.recipientHash + '/thankyounotes', {
        note,
        color,
        sender
      }).then(res => {
        console.log('res: ', res)
      }).catch((e) => {
        commit('setErrorSendThankYouNote', true)
        commit('setUsedGiftStatus', !e.response.data.success)
        commit('setUsedGiftMessage', e.response.data.message)
        commit('setErrorSendThankYouNoteMessage', e.response.data.message)
      }).finally(() => {
        commit('setLoadingSendThankYouNote', false)
      })
    },
    async getProductDetail ({ state, commit }, id) {
      state.loadingProductDetail = true
      await Api.get('/recipient/' + state.recipientHash + '/details/' + id)
        .then(res => {
          commit('setDetailPanelInfo', res.data)
          commit('setVimeoVideoId', res.data.vimeo_video_id)
        })
        .catch(err => {
          console.log(err)
          commit('setDetailPanelInfo', undefined)
        })
    },
    async getConsentContent ({ commit }) {
      const res = await ApiCms.get('/recipient-consent-content')
      commit('setConsentContent', res.data?.data?.attributes)
    },
    async getAddressDataList ({ commit }, payload) {
      return ApiLoqate.get('/Capture/Interactive/Find/v1.10/json3.ws', {
        params: {
          Text: payload.searchText,
          Container: payload.searchContainerId,
          Countries: payload.country,
          Limit: 20,
          Language: 'en-us'
        }
      })
        .then(({ data: { Items } }) => (commit('setAddressesListFromLoqate', Items)))
        .catch(() => (commit('setAddressesListFromLoqate', [])))
    },
    async getVerifiedAddresses ({ commit }, payload) {
      const res = await ApiLoqate.post('/Cleansing/International/Batch/v1.00/json4.ws', {
        Geocode: false,
        Addresses: [
          {
            Address1: payload.street,
            Locality: payload.city,
            Address3: payload.postcode,
            Country: payload.country,
            AdministrativeArea: payload.state,
            PostalCode: payload.postcode
          }
        ],
        Options: {
          Certify: false,
          ServerOptions: {
            FieldStatus: true
          },
          Version: true
        }
      })
      const addressObject = res.data[0].Matches[0]
      commit('setLoqateAQI', addressObject.AQI)
      commit('setLoqateAVC', addressObject.AVC)
      commit('setLoqateVerifiedAddress', {
        address: addressObject.Address1,
        premiseNumber: addressObject.PremiseNumber,
        street: addressObject.Thoroughfare,
        city: addressObject.Locality,
        state: addressObject.AdministrativeArea,
        zip: addressObject.PostalCode,
        country: addressObject.Country
      })
    }
  }
}
