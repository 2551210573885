import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import { createSimpleFunctional } from 'vuetify/es5/util/helpers'

Vue.use(Vuetify)

const skipThankYouTransition = createSimpleFunctional(
  'skip-thank-you-transition'
)

Vue.component('skip-thank-you-transition', skipThankYouTransition)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        black: '#4a4a4a',
        secondary: '#d8d8d8',
        success: '#00A86B',
        primary: '#000'
      }
    }
  }
})
