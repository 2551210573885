var render = function render(){var _vm=this,_c=_vm._self._c;return _c('common-cg-validation',{attrs:{"rules":!_vm.hideRules ?
      _vm.email ?
        'required|email' :
        'required' :
      null,"custom-messages":{
    required: 'This field is required'
  }}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0 thin-border v-input--errors containct-info-input",attrs:{"color":"black","outlined":"","validate-on-blur":"","hide-details":"","solo":"","flat":"","dense":"","tile":"","height":30},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(props){return [_vm._t(slot,null,null,props)]}}})],null,true),model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},'v-text-field',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,slot){return [_c('template',{slot:slot},[_vm._t(slot)],2)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }