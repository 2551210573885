<template>
  <div class="email-preview">
    <p class="pa-0 pl-12 pl-lg-14 pl-xl-15 ma-0 email-subject lato-bold">{{ checkedPreviewEmailSubjectLine }}</p>
    <p class="sender-name pl-12 pl-lg-14 mt-4">{{ previewSender }}</p>
    <div class="email-preview__gift-data gift-data d-flex flex-column align-center mt-10">
      <v-img
        :src="logoFromQueryString"
        alt="Logo preview"
        max-height="40"
        contain
        class="my-4"
      />

      <div class="delimiter mb-4"></div>
      <img
        :src="checkedPreviewAnimatedGiftUrl"
        class="animated-gift-icon mb-4"
      >
      <h3 class="email-header-text lato-light mb-3" v-html="checkedPreviewWelcomeMessage"/>
      <p class="lato-bold mb-8">{{ previewChooseText }}</p>

      <v-img
        :src="previewEmailCoverImage"
        v-if="previewEmailCoverImage"
        max-width="350px"
        class="mb-5"
      />

      <div class="email-preview__images mb-8" v-else>
        <img
          v-for="product in imagesToDisplay"
          :key="product.id"
          :src="product.image"
          :alt="product.name"
          :width="imagesToDisplay.length > 1 ? 120 : 240"
          :height="imagesToDisplay.length > 1 ? 120 : 240"
        >

        <div class="email-preview__additional-images" v-if="false">
          +{{ numberOfAdditionalImages }}
        </div>
      </div>

      <div
        v-if="numberOfAdditionalImages > 1 && !previewEmailCoverImage"
        class="mb-5 email-preview__link"
        @click="goToNextStep()"
      >
        More options
      </div>

      <common-button
        class="mb-10"
        @click="goToNextStep"
        width="170"
      >
        {{ previewProducts.length > 1 ? 'Choose your gift' : 'Get this gift' }}
      </common-button>

      <div v-if="!hideGreetingMessage" class="greeting-message d-flex py-5 px-6 mb-12">
        <img class="mr-5" src="./../../../assets/quotes.png" alt="quotes">
        <p class="mt-3 mb-0 lato-italic" v-html="greetingMessage" />
      </div>

      <div class="email-preview__footer">
        <template v-if="!customFooter">
          <div>Find out more about eGifting | FAQ</div>
          <img src="@/assets/preview-cg-logo.png" alt="" height="20" width="15">
          <div>Powered by CorporateGift.com</div>
          <div>4 Washington St, Tenafly, NJ 07670</div>
          <div>Tel: 646-751-7075</div>
        </template>

        <template v-else>
          <div v-html="customFooter" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'EmailPreview',
  computed: {
    ...mapState('preview',
      [
        'linkType',
        'customFooter',
        'previewLogoUrl',
        'previewFirstname',
        'previewLastname',
        'previewChooseText',
        'previewProducts',
        'previewEmailSubjectLine',
        'previewEmailCoverImage',
        'previewGreetingMessage',
        'previewSender',
        'previewWelcomeMessage',
        'previewVideoUrl',
        'previewStep',
        'previewAnimatedGiftUrl'
      ]
    ),
    checkedPreviewEmailSubjectLine () {
      return this.previewEmailSubjectLine ? this.previewEmailSubjectLine : `${this.previewSender} sent you a gift`
    },
    checkedPreviewGreetingMessage () {
      return this.previewGreetingMessage
        ? this.previewGreetingMessage
        : `Dear ${this.previewFirstname},<br>
        It was a pleasure working with you in the past year. Thanks for all the hard work, the creativity, the patient and of course - the fruits of success!
        Looking forward to another great year.<br>
        Best, ${this.previewSender}`
    },
    greetingMessage () {
      const { checkedPreviewGreetingMessage, previewFirstname, previewLastname } = this

      let message = checkedPreviewGreetingMessage

      message = message?.replaceAll(/(?:@First_name|@first_name)/g, previewFirstname || '@First_name')
      message = message?.replaceAll(/(?:@Last_name|@last_name)/g, previewLastname || '@Last_name')

      return message
    },
    checkedPreviewWelcomeMessage () {
      if (this.previewWelcomeMessage.includes('Surprise!')) {
        const wordsArray = this.previewWelcomeMessage.split(' ')
        const textWithoutSurprise = wordsArray.splice(1, wordsArray.length).join(' ')
        return `${wordsArray[0]}<br>${textWithoutSurprise}`
      }
      if (this.previewWelcomeMessage && this.previewSender) {
        return `${this.previewWelcomeMessage}`
      }
      if (!this.previewWelcomeMessage && this.previewSender) {
        return `Surprise!<br>A gift from ${this.previewSender}`
      }
      return `${this.previewFirstname} ${this.previewLastname}`
    },
    logoFromQueryString () {
      const logoUrl = this.$route.query?.logo_url
      return (logoUrl && !logoUrl.includes('File')) ? logoUrl : this.previewLogoUrl
    },
    checkedPreviewAnimatedGiftUrl () {
      return this.previewAnimatedGiftUrl.length ? this.previewAnimatedGiftUrl : require('../../../assets/no-animated-gift-icon.gif')
    },
    productsImages () {
      const { previewProducts: products } = this ?? {}

      return products?.map((product, index) => ({
        id: product?.id ?? index,
        name: product?.name ?? null,
        image: (product.image && !product.image.includes('no_selection')) ? product.image : '/images/empty.png'
      })) ?? []
    },
    numberOfImages () {
      return this.productsImages?.length ?? 0
    },
    imagesToDisplay () {
      const { productsImages, numberOfImages } = this

      return numberOfImages > 8 ? productsImages.slice(0, 8) : productsImages
    },
    numberOfAdditionalImages () {
      const { numberOfImages } = this

      return Math.max(numberOfImages - 7, 0)
    },
    hideGreetingMessage () {
      return ['single_link_restricted', 'single_link_unrestricted'].includes(this.linkType)
    }
  },
  methods: {
    ...mapMutations('preview', ['setPreviewStep']),
    goToNextStep () {
      this.setPreviewStep(this.previewStep + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.email-preview{
  height: auto;
  background-image: url('/public/images/gmail_frame.jpg');
  background-size: 63%;
  background-position-x: center;
  background-position-y: 20px;
  // image-rendering: pixelated;
  padding-top: 100px;
  padding-left: 22%;
  background-color: #fff;

  h3{
    width: 60%;
  }

  .email-subject{
    font-size: 10px;
  }

  .sender-name{
    font-size: 10px;
  }

  .animated-gift-icon{
    width: 60%;
  }

  @media screen and (min-width: 1264px) {
    padding-top: 112px;

    .email-subject{
      font-size: 15px;
    }
  }

  &__gift-data{
    height: auto;
    width: 60%;
    .greeting-message{
      width: 80%;
      background-color: rgba(237,252,251,1);
      img{
        width: 30px;
        height: 30px;
      }
      p{
        width: 80%;
      }
    }
    .email-header-text{
      text-align: center;
      font-size: 32px;
      font-weight: 300;
      line-height: 39px;
    }
    .email-products-preview-images{
      width: 50%;
      img{
        width: 43%;
      }
    }
    .delimiter{
      width: 80%;
      height: 1px;
      background-color: rgb(234,234,234);
    }
  }

  &__images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px 8px;
    max-width: 248px;

    & > img {
      object-fit: contain;
    }
  }

  &__additional-images {
    padding: 12px;
    aspect-ratio: 1 / 1;
    background-color: #dff3f2;
    display: inline-flex;
    align-items: center;
    font-family: 'Lato-Regular', sans-serif;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
    color: #62646a;
    width: 120px;
  }

  &__link {
    cursor: pointer;
    color: #42B77A;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 15px;
    line-height: 23px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    padding-bottom: 80px;
    text-align: center;
    align-items: center;
    color: #8f8f8f;
    font-size: 14px;
  }
}
</style>
