<template>
  <div class="footer">
    <v-container class="footer pt-4">
      <div class="win-footer footer-content d-none d-md-flex align-center">
        <img src="/images/logo_symbol-grey.svg">
        <div class="d-flex align-center ml-4 f14">
          <span>Powered by <a href="https://corporategift.com" target="_black">Corporategift.com</a></span>
          <span class="f13 mx-3 delimiter">|</span>
          <span>4 Washington St Tenafly, NJ 07670</span>
          <span class="f13 mx-3 delimiter">|</span>
          <span>Tel: <a href="tel:646-751-7075">646-751-7075</a></span>
          <span class="f13 mx-3 delimiter">|</span>
          <span>Find out <a href="https://corporategift.com/egift-it" target="_black">more about eGifting</a></span>
          <span class="f13 mx-3 delimiter">|</span>
          <span class="pointer" @click="showPanel = !showPanel">FAQ</span>
        </div>
      </div>
      <div class="mobile-footer footer-content text-center align-end py-5 d-block d-md-none">
        <div class="d-flex mx-auto">
          <div class="ml-auto">Find out more <a href="https://corporategift.com/egift-it" target="_black">about eGifting</a></div>
          <div class="f13 mx-3">|</div>
          <div class="mr-auto pointer" @click="showPanel = !showPanel">FAQ</div>
        </div>
        <img class="my-3" src="/images/logo_symbol-grey.svg">
        <div>Powered by <a href="https://corporategift.com" target="_black">Corporategift.com</a></div>
        <div class="py-2">4 Washington St Tenafly, NJ 07670</div>
        <div>Tel: <a href="tel:646-751-7075">646-751-7075</a></div>
      </div>
    </v-container>
    <e-gift-faq-panel
      :showPanel="showPanel"
      @close="showPanel = !showPanel"
    />
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data: () => ({
    showPanel: false
  })
}
</script>

<style lang="scss" scoped>
.footer{
  .footer-content {
    border-top: 1px solid #D3D2D2;
    padding: 30px 0px;
    color: #8F8F8F;
    line-height: 14px;
    font-weight: 100;
    a {
      color: #8F8F8F;
    }
    &.win-footer {
      img{
        width: 18px;
        height: 24px;
        margin-bottom: 5px;
      }
    }
    &.mobile-footer {
      margin-bottom: 80px;
      line-height: 28px;
      img{
        width: 21px;
        height: 27px;
      }
    }
  }
}
</style>
