<template>
  <div class="common-loader">
    <lottie-animation
      ref="loading"
      v-bind="$attrs"
      v-on="$listeners"
      :animationData="require('@/assets/loader/black-loader.json')"
      :loop="true"
      :autoPlay="true"
      :speed="1"
    />
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'CommonLoader',
  components: {
    LottieAnimation
  }
}
</script>

<style lang="scss" scoped>
.common-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  padding: 200px 0;
  height: 100%;
}
</style>
