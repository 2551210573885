<template>
  <div class="min-gift-card">
    <div class="img-wrap col-4 pa-0">
      <img
        class="min-gift-card_img"
        :src="(item.initialMainImageLink && !item.initialMainImageLink.includes('no_selection')) ? item.initialMainImageLink : '/images/empty.png'"
      />
    </div>
    <div class="mt-1 ml-3 mr-2 f13 black1 col-6 pa-0">
      <span class="lato-bold min-gift-card_name mb-1">{{item.name}}</span>
      <span v-if="!multiMode && !previewMode">{{item.coins}}</span>
      <span v-if="!multiMode  && !previewMode" class="ml-1">GiftCoin</span>
      <span v-if="previewMode && previewIsGiftCoin">{{+item.coins}}</span>
      <span v-if="previewMode && previewIsGiftCoin" class="ml-1">GiftCoin</span>
      <div v-for="opt, index in item.option" :key="index" class="options">
        <span>{{opt.title}}: </span><span>{{opt.valTitle ? opt.valTitle : opt.val}}</span>
      </div>
    </div>
    <div class="d-flex align-center pointer remove-card col-1 pa-0 remove-wrap-button">
      <v-btn
        icon
        color="pink"
        @click="next"
      >
        <img src="/images/icon-delete.svg" width="14" height="16" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    item: Object
  },
  methods: {
    next () {
      this.$emit('do', this.item)
    }
  },
  computed: {
    ...mapState('eGift', [
      'multiMode', 'previewMode'
    ]),
    ...mapState('preview', ['previewIsGiftCoin'])
  }
}
</script>

<style lang="scss">
.min-gift-card {
  display: flex;
  width: 90%;
  @media screen and (max-width: 598px) {
    width: 100%;
  }

  &_img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: auto 0;
  }

  &_name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
.options{
    color: rgba(74,74,74,1)
  }
</style>
