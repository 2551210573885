<template>
  <v-container
    class="single-link-campaign-preview"
    :class="{ 'fill-height': $vuetify.breakpoint.mdAndUp }"
  >
    <v-row no-gutters justify="center">
      <v-col class="single-ling-campaign-card__wrapper">
        <div class="single-ling-campaign-card__title" v-if="$vuetify.breakpoint.xsOnly">
          <h1>Hi there,</h1>
          <h3>{{ linkTypeMessage }}</h3>
        </div>

        <the-campaign-images :images="giftImages" :size="$vuetify.breakpoint.xsOnly ? 80 : 180" />

        <div class="single-ling-campaign-card__content">
          <div class="single-ling-campaign-card__title" v-if="$vuetify.breakpoint.mdAndUp">
            <h1>Hi there,</h1>
            <h3>{{ linkTypeMessage }}</h3>
          </div>

          <the-campaign-form
            :firstName.sync="form.firstName"
            :lastName.sync="form.lastName"
            :email.sync="form.email"
            :showOnlyEmail="isRestrictedGiftLink"
          />

          <common-button
            @click="setPreviewStep(previewStep + 1)"
            class="single-ling-campaign-card__button"
          >
            {{ isRestrictedGiftLink ? 'Continue' : 'Send link to my Email' }}
          </common-button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import TheCampaignImages from '@/components/singleLinkCampaign/TheCampaignImages.vue'
import TheCampaignForm from '@/components/singleLinkCampaign/TheCampaignForm.vue'

const { mapState, mapMutations } = createNamespacedHelpers('preview')

export default {
  name: 'SingleLinkCampaignPreview',
  components: {
    TheCampaignImages,
    TheCampaignForm
  },
  data: () => ({
    form: {
      firstName: null,
      lastName: null,
      email: null
    }
  }),
  computed: {
    ...mapState([
      'previewEmailCoverImage',
      'linkTypeMessage',
      'previewProducts',
      'previewStep',
      'linkType'
    ]),
    giftImages () {
      const { previewProducts, previewEmailCoverImage } = this

      if (previewEmailCoverImage) { return [{ image: previewEmailCoverImage, name: '' }] }

      return previewProducts?.map((item) => ({
        image: item.image,
        name: item.name
      })) ?? [{ image: '/images/empty.png', name: 'No image' }]
    },
    isRestrictedGiftLink () {
      return this.linkType === 'single_link_restricted'
    }
  },
  methods: {
    ...mapMutations(['setPreviewStep'])
  }
}
</script>

<style lang="scss" scoped>
.single-link-campaign-preview {
  max-width: 100%;
  min-height: 800px;
  background-color: #fff;
}
</style>
