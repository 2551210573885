const shippingFormSetting = [
  {
    code: 'US',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'State',
    postCodeInputCondition: true,
    postalCodeLabelText: 'ZIP Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'AU',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'State',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'BR',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'State',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'GB',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 0,
      postCode: 3
    }
  },
  {
    code: 'DK',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'NO',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'IL',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'FR',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'NL',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'SE',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'PL',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'CH',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'DE',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'AT',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'FI',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'BE',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: false,
    stateLabelText: null,
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 3,
      state: 0,
      postCode: 1
    }
  },
  {
    code: 'CA',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Province',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'CN',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Province',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'CO',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Province',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'TH',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Province',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'AR',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Province',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 2,
      state: 3,
      postCode: 1
    }
  },
  {
    code: 'IT',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Province',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 2,
      state: 3,
      postCode: 1
    }
  },
  {
    code: 'RO',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'County',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 2,
      state: 3,
      postCode: 1
    }
  },
  {
    code: 'IE',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'County',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'IN',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'State',
    postCodeInputCondition: true,
    postalCodeLabelText: 'PIN Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'SG',
    cityInputSowingCondition: false,
    stateSelectSowingCondition: false,
    stateLabelText: 'State',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 0,
      state: 0,
      postCode: 0
    }
  },
  {
    code: 'NZ',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Region',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'PT',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Region',
    postCodeInputCondition: true,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 2,
      postCode: 3
    }
  },
  {
    code: 'AE',
    cityInputSowingCondition: true,
    stateSelectSowingCondition: true,
    stateLabelText: 'Emirate',
    postCodeInputCondition: false,
    postalCodeLabelText: 'Postal Code',
    order: {
      city: 1,
      state: 3,
      postCode: 0
    }
  }
]

export default shippingFormSetting
