<template>
  <div class="multiple-choice-selected-gifts-mobile-wrap">
    <div v-if="show"  class="multiple-choice-selected-gifts-mobile-background"></div>
    <div
      id="multiple-choice-selected-gifts-mobile"
      class="multiple-choice-selected-gifts-mobile"
      :style="{height: `${show ? 'auto' : '70px'}`}"
    >
      <div class="multiple-choice-selected-gifts-header d-flex align-center"
        :style="{height:'70px'}"
      >
        <CoinStatus
          :left="coinLeft"
          class="col-6 pa-3"
        />
        <div class="button-wrap col-6 pa-3">
          <common-button
            id="add-button__continues"
            class="add-button"
            :disabled="!selectedGifts.length > 0 || (!this.multiMode ? this.coinLeft < 0 : false)"
            @click="handlerClickContinueButton"
            elevation="0"
          >
            <span
              class="text--white text-uppercase"
            >CONTINUE</span>
          </common-button>
        </div>
        <div
          class="rownd"
        >
          <common-button
            :ripple="false"
            icon
            @click="clickHandler"
            class="rownd-button"
          >
            <v-icon
              :class="[show ? 'up' : '', 'rownd-icon']"
            >mdi-chevron-down</v-icon>
          </common-button>
        </div>
      </div>
      <div class="gifts-list pa-3" >
        <div
          v-if="!selectedGifts.length"
          class="empty-card">
        </div>
        <div
          v-else
        >
          <div
            v-for='(item, index) in selectedGifts'
            :key='item.id'
            class="not-empty-gift"
          >
            <e-gift-min-gift-card
              :item='item'
              @do="selectCard"
            />
            <v-divider
              v-if="index + 1 !== selectedGifts.length"
              class="my-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoinStatus from '../eGift/CoinStatus.vue'

export default {
  name: 'MultipleChoiceSelectedGiftsMobile',
  components: {
    CoinStatus
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapState('eGift', ['maxGiftNum', 'selectedGifts', 'multiMode', 'coinLeft']),
    allGiftCoinsWereSpent () {
      return !this.multiMode ? this.coinLeft < 0 : false
    }
  },
  methods: {
    clickHandler () {
      if (this.show === false) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
      this.show = !this.show
    },
    selectCard (item) {
      this.$store.dispatch('eGift/selectCard', item)
    },
    handlerClickContinueButton () {
      this.show = false
      this.$emit('addCards')
    }
  }
}
</script>

<style lang="scss" scoped>
#multiple-choice-selected-gifts-mobile{
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background-color: rgb(223, 243, 242);
  z-index: 999999;
  transition: height 0.5s;
}
.multiple-choice-selected-gifts-mobile-background{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.39);
}
.multiple-choice-selected-gifts-mobile-wrap{
  position: relative;
}
.rownd-button{
  display: flex;
  align-items: center;
}
.rownd{
  cursor: pointer;
  position: absolute;
  top: -22px;
  left: calc(50% - 22px);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: rgb(223, 243, 242);
  z-index: -10;
  display: flex;
  justify-content: center;
  align-self: center;
  &-icon{
    width: 50%;
    z-index: 10;
    transform: rotate(180deg);
    transition: transform 0.5s;
    &.up{
      transform: rotate(360deg);
    }
  }
}
.gifts-list{
  background: #ffffff;
  width: 100%;
}

.add-button {
  background: #000 !important;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  margin-top: 0px;
  border-radius: 0;
  width: 100%;
  padding: 0px;

  span:first-child {
    pointer-events: none;
  }

  span:last-child {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    cursor: pointer;
  }
  &.disabled {
    width: 100%;
    opacity: 0.3;
  }
}
.v-btn::before {
  background-color: transparent;
}
// .added-group{
//   padding: 12px;
//   max-height: 80vh;
//   overflow: auto;
//   background-color: #F5F5F5;
//   &__header{
//     background-color: rgba(223,243,242,1);
//   }
//   &__not-empty-gift{
//     width: 100%;
//   }
// }

// .empty-card {
//   box-sizing: border-box;
//   border: 1px dashed #aaa;
//   width: 33%;
//   height: 110px;
//   @media screen and (min-width: 598px) {
//     width: 30%;
//     height: 80px;
//   }
//   @media screen and (min-width: 960px) {
//     width: 30%;
//     height: 50px;
//   }
//   @media screen and (min-width: 1262px) {
//     width: 30%;
//     height: 70px;
//   }
//   // @media screen and (min-width: 1263px) {
//   // }
// }
</style>
