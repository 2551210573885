<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 14"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M14.144 0.148438L14.851 0.855937L7.35605 8.35094L6.64905 7.64394L14.144 0.148438Z"
      fill="white"
    />
    <path
      d="M15 5H14V1H10V0H15V5ZM12.5 14H2.5C1.65 14 1 13.35 1 12.5V2.5C1 1.65 1.65 1 2.5
        1H8V2H2.5C2.2 2 2 2.2 2 2.5V12.5C2 12.8 2.2 13 2.5 13H12.5C12.8 13 13 12.8 13
        12.5V7H14V12.5C14 13.35 13.35 14 12.5 14Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 16
    },
    height: {
      type: [Number, String],
      required: false,
      default: 14
    }
  }
}
</script>
