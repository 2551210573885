<template>
  <v-row dense>
    <v-col cols="12" v-if="userIsUsingMobileDevice">
      <v-divider />
    </v-col>

    <v-col cols="6" md="12">
      <v-img
        :src="(selectedItem.image && !selectedItem.image.includes('no_selection')) ? selectedItem.image : '/images/empty.png'"
        :alt="selectedItem.name"
        :height="userIsUsingMobileDevice ? 160 : 270"
        :max-height="userIsUsingMobileDevice ? 160 : 270"
        :width="userIsUsingMobileDevice ? 160 : null"
      />
    </v-col>

    <v-col cols="6" md="12">
      <v-row no-gutters class="h100">
        <v-col cols="12" class="product-name">
          {{ selectedItem.name }}
        </v-col>

        <v-col cols="12" v-if="!userIsUsingMobileDevice">
          <div class="product-description" v-html="selectedItem.short_description" />
        </v-col>

        <v-col cols="12" class="product-price">
          Add
          {{
            +selectedItem.price | priceFilter({
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            })
          }}
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="userIsUsingMobileDevice">
      <v-divider />
    </v-col>

    <v-col cols="12" :class="userIsUsingMobileDevice && 'pt-6'" v-if="shippingAddress">
      <v-row no-gutters>
        <v-col cols="12" class="shipping-title pb-4">
          Deliver To:
        </v-col>
        <v-col cols="12" class="shipping-text">
          {{ shippingFullName }}
        </v-col>
        <v-col cols="12" class="shipping-text">
          {{ shippingHomeAddress }}
        </v-col>
        <v-col cols="12" class="shipping-text">
          {{ shippingCountry }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SelectedGiftPreview',
  props: {
    selectedItem: {
      type: Object,
      required: true
    },
    shippingAddress: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    userIsUsingMobileDevice () {
      return this.$vuetify.breakpoint.mobile
    },
    shippingFullName () {
      const { shippingAddress } = this

      return `${shippingAddress.first_name} ${shippingAddress.last_name}`
    },
    shippingHomeAddress () {
      const { shippingAddress } = this
      let result = shippingAddress.address
      if (shippingAddress.address_apt) result += `/${shippingAddress.address_apt}`

      return result
    },
    shippingCountry () {
      const { shippingAddress } = this

      return `${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zip}`
    }
  }
}
</script>

<style lang="scss" scoped>
.product-name {
  font-size: 18px;
  font-family: 'Lato-Regular', sans-serif;
  line-height: 22px;
  color: #000;
  display: -webkit-box;
  padding: 0;
  margin: 16px 0;
  height: 66px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-price {
  background: #EDFCFB;
  color: #000;
  font-size: 18px;
  font-family: 'Lato-Regular', sans-serif;
  line-height: 22px;
  padding: 12px 24px !important;
  align-self: flex-end
}

.shipping-title {
  font-size: 18px;
  font-family: 'Lato-Bold', sans-serif;
  color: #000;
  line-height: 22px;
}

.shipping-text {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 15px;
  color: #16181F;
}

@media (min-width: 960px) {
  .product-name {
    height: 44px;
    -webkit-line-clamp: 2;
  }

  .product-price {
    font-size: 18px;
    line-height: 22px;
    margin: 16px 0 32px !important
  }
}
</style>
