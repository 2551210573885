<template>
  <v-dialog
    v-model="show"
    max-width="700"
    class="rounded-0"
    :content-class="$vuetify.breakpoint.mobile ? 'ma-0' : ''"
  >
    <v-card class="modal-content rounded-0 thanks-warning-modal pa-8">
      <v-icon @click="$emit('update:show', false)" class="close-icon">mdi-close</v-icon>
      <v-card-title class="loqate-validation-modal__title" :class="$vuetify.breakpoint.mobile && 'px-0'">
        Address Verification
      </v-card-title>

      <v-card-text class="pt-4" :class="$vuetify.breakpoint.mobile && 'px-0'">
        <div class="pb-8 loqate-validation-modal__info-text" v-if="!showLoqateVerifiedAddress">
          {{ showLoqateVerifiedAddress
            ? 'Use our verified shipping address to avoid any shipping delays or mishaps.'
            : 'We are unable to verify this address or offer any alternative address suggestions. You may use this address, but it may cause delivery delays.'
          }}
        </div>

        <v-row
          no-gutters
          class="loqate-validation-modal__text"
          style="gap: 80px"
          v-if="showLoqateVerifiedAddress"
        >
          <v-col>
            <v-row no-gutters class="flex-column" style="gap: 8px">
              <v-col cols="12" class="lato-bold f15">You entered:</v-col>
              <v-col cols="12">
                {{ addressData.address }} <br />
                {{ addressData.city }} {{ addressData.state }} {{ addressData.zip }} <br />
                {{ addressData.country }}
              </v-col>
              <v-col cols="12" class="mt-2">
                <common-button
                  block
                  outlined
                  @click="useOriginalAddress()"
                >
                  Use original address
                </common-button>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row no-gutters class="flex-column" style="gap: 8px">
              <v-col cols="12" class="lato-bold f15">We suggest:</v-col>
              <v-col cols="12">
                <span
                  :class="{
                    'address-difference': stringsAreDifferent(
                      addressData.address,
                      loqateVerifiedAddress.address
                    )
                  }"
                >
                  {{ loqateVerifiedAddress.address }}
                </span>
                <br />

                <span
                  :class="{
                    'address-difference': stringsAreDifferent(
                      addressData.city,
                      loqateVerifiedAddress.city
                    )
                  }">
                  {{ loqateVerifiedAddress.city }}
                </span>
                <span
                  class="px-1"
                  :class="{
                    'address-difference': stringsAreDifferent(
                      addressData.state,
                      loqateVerifiedAddress.state
                    )
                  }">
                  {{ loqateVerifiedAddress.state }}
                </span>
                <span
                  :class="{
                    'address-difference': stringsAreDifferent(
                      addressData.zip,
                      loqateVerifiedAddress.zip
                    )
                  }">
                  {{ loqateVerifiedAddress.zip }}
                </span>
                <br />
                <span
                  :class="{
                    'address-difference': stringsAreDifferent(
                      addressData.country,
                      loqateVerifiedAddress.country
                    )
                  }">
                  {{ loqateVerifiedAddress.country }}
                </span>
              </v-col>
              <v-col cols="12" class="mt-2">
                <common-button
                  block
                  @click="useSuggestedAddress()"
                >
                  Use suggested address
                </common-button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="loqate-validation-modal__text" v-else>
          <v-col cols="12" md="4">
            <v-row class="flex-column align-end" no-gutters>
              <v-col cols="12" class="lato-bold f15">
                Unverified address:
              </v-col>
              <v-col cols="11">
                {{ userName }} {{ userLastName }}
              </v-col>
              <v-col cols="11">
                {{ addressData.address }}
              </v-col>
              <v-col cols="11">
                {{ addressData.city }} {{ addressData.state }} {{ addressData.zip }}
              </v-col>
              <v-col cols="11">
                {{ addressData.country }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8" class="d-flex align-center" style="gap: 10px">
            <common-button
              class="flex-grow-1"
              @click="$emit('update:show', false)"
            >
              Edit this address
            </common-button>
            <common-button
              outlined
              @click="useOriginalAddress()"
            >
              Use this address
            </common-button>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoqateValidationResultModal',
  props: {
    show: Boolean,
    userName: {
      type: String,
      required: false,
      default: null
    },
    userLastName: {
      type: String,
      required: false,
      default: null
    },
    addressData: Object
  },
  computed: {
    ...mapState('eGift', ['loqateVerifiedAddress', 'loqateAVC']),
    showLoqateVerifiedAddress () {
      const { verificationStatusWithPostStatus: verificationStatus, matchScore } = this.loqateAVC

      if (['P4', 'V4', 'V5'].includes(verificationStatus) && matchScore <= 96 && matchScore >= 80) {
        return true
      }
      return false
    }
  },
  methods: {
    stringsAreDifferent (string1, string2) {
      return string1?.trim() !== string2?.trim()
    },
    useOriginalAddress () {
      this.$emit('continueWithEnteredAddress')
    },
    useSuggestedAddress () {
      this.$emit('continueWithLoqateAddress')
    }
  }
}
</script>

<style lang="scss" scoped>
.loqate-validation-modal {
  &__title {
    font-size: 30px !important;
    line-height: 22px;
    font-family: 'Lato-Bold', sans-serif;
    color: #000;
    padding-bottom: 24px !important;
  }

  &__text {
    color: #000;
    font-size: 15px;
    line-height: 23px;
    font-family: 'Lato-Regular', sans-serif !important;

    & span {
      display: inline-block;

      &.address-difference {
        color: #38A39C;
      }
    }
  }

  &__info-text {
    font-size: 16px;
    color: #000;
    font-family: 'Lato-Regular', sans-serif;
  }

  &__verified-text {
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'Lato-Bold', sans-serif;

    &--verified {
      color: #219358;
    }

    &--unverified {
      color: #9E040A;
    }
  }
}
</style>
