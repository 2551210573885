<template>
  <v-app>
    <common-header
      v-if="!checkPreviewMode"
      :show-print-button="['Success'].includes($route.name)"
    />
    <v-main
      :class="[checkPreviewMode ? 'gray-background' : '', 'pt-0']"
    >
      <router-view />
    </v-main>
    <common-footer
      v-if="!checkPreviewMode"
    />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
  }),
  computed: {
    checkPreviewMode () {
      return window.location.pathname.includes('preview')
    }
  },
  created () {
    this.$store.dispatch('token/get')

    if (!this.isScriptLoaded('web-sdk/4/bluesnap.js')) {
      this.loadBluesnapScript()
    }
  },
  methods: {
    isScriptLoaded (scriptUrlOrPart) {
      const scripts = [...document.getElementsByTagName('script')]
      const result = scripts.map(script => script.src.includes(scriptUrlOrPart)).includes(true)
      return result
    },
    loadBluesnapScript () {
      const bluesnapScript = document.createElement('script')
      const bluesnapUrl = process.env.NODE_ENV === 'production' ? 'https://ws.bluesnap.com' : 'https://sandbox.bluesnap.com'
      bluesnapScript.setAttribute('type', 'text/javascript')
      bluesnapScript.setAttribute('src', `${bluesnapUrl}/web-sdk/4/bluesnap.js`)
      document.head.append(bluesnapScript)
    }
  }
}
</script>

<style lang="scss">
@import "./css/index.scss";

* {
  font-family: 'Lato-Regular';
}

.v-application--wrap {
  min-height: 100%!important;
}

.v-main {
  min-height: calc(100vh - 235px);

  @media only screen and (max-width: 565px) {
    min-height: calc(100vh - 200px);
  }
}

.gray-background {
  background-color: rgba(69,67,67,1);
  min-height: 100vh
}

.vc-text {
  font-size: 18px!important;
  padding: 0 20px!important;
  margin-top: 16px!important;
}

#ot-sdk-btn-floating{
  visibility: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}
</style>
