<template>
  <v-container class="thanks-feedback d-flex flex-column px-6 py-6">
    <img class="mx-auto" src="/images/img-feedback.svg">
    <h1 class="text-md-h3 text-h5 font-weight-light mt-10 text-center">
      Thanks for your feedback.
    </h1>
    <p class="text-center font-weight-black mt-7">
      We’ll let the sender know you declined this gift and <br>remove you from their contact list.
    </p>
  </v-container>
</template>

<script>
export default {
  name: 'Feedback',
  data: () => ({

  }),
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
  .thanks-feedback{
    img{
      width: 175px;
    }
  }
</style>
