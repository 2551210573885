<template>
  <v-form ref="form">
    <v-row dense>
      <v-col cols="12">
        <div class="d-flex align-center pb-2" style="gap: 10px">
          <label for="sender">
            From
          </label>
          <TheContactInfoFormPopper :iconSize="16">
            Mocked tooltip
          </TheContactInfoFormPopper>
        </div>
        <v-text-field
          v-model="userSender"
          id="sender"
          outlined
          placeholder="Sender name"
          hide-details
          :rules="[requiredRule]"
          validate-on-blur
          dense
          flat
          tile
          class="pa-0 pb-10 ma-0 rounded-0 sender-name-input"
          solo
        />
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between pb-2">
          <label for="greeting-text">
            Your message
          </label>
          <div class="d-flex see-ideas-link" @click="showThankYouIdeas = true">
            See ideas
          </div>
        </div>
        <v-textarea
          v-model="userMessage"
          id="greeting-text"
          auto-grow
          outlined
          hide-details
          :rules="[requiredRule]"
          validate-on-blur
          dense
          flat
          rows="8"
          ref="greeting-note"
          :style="`--background-color: ${selectedColor}`"
          class="pa-0 ma-0 thank-you-textarea"
          solo
        />
      </v-col>
      <v-col cols="12" class="d-flex pt-2" style="gap: 10px" v-if="false">
        <template v-for="(color, index) in availableColors">
          <input
            type="radio"
            :key="index"
            :value="color"
            :style="`--chip-color: ${color}`"
            v-model="selectedColor"
          />
        </template>
      </v-col>

      <ThankYouIdeasPanel
        v-model="showThankYouIdeas"
        @changeThankYouNote="(val) => userMessage = val"
      />
    </v-row>
  </v-form>
</template>

<script>
import ThankYouIdeasPanel from '@/components/updateGift/ThankYouIdeasPanel.vue'
import TheContactInfoFormPopper from '@/components/updateGift/TheContactInfoFormPopper.vue'

export default {
  name: 'ThankYouForm',
  components: {
    ThankYouIdeasPanel,
    TheContactInfoFormPopper
  },
  props: {
    message: {
      type: String,
      required: false,
      default: null
    },
    sender: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    showThankYouIdeas: false,
    availableColors: [
      '#D3C5D7',
      '#FCEBA4',
      '#EDCFC3',
      '#A5DEC0',
      '#D2F5F3',
      '#A7DAD8',
      '#CDD8E4',
      '#FAE3E3'
    ],
    requiredRule: v => !!v || ''
  }),
  computed: {
    userSender: {
      get () {
        return this.sender
      },
      set (val) {
        this.$emit('update:sender', val)
      }
    },
    userMessage: {
      get () {
        return this.message
      },
      set (val) {
        this.$emit('update:message', val)
        setTimeout(() => {
          this.calcaulateTextAreaHeight()
        }, 0)
      }
    },
    selectedColor: {
      get () {
        return this.color
      },
      set (val) {
        this.$emit('update:color', val)
      }
    }
  },
  created () {
    this.selectedColor = this.availableColors[0]
  },
  mounted () {
    this.calcaulateTextAreaHeight()
    this.$refs['greeting-note'].focus()
  },
  methods: {
    calcaulateTextAreaHeight () {
      if (!this.$vuetify.breakpoint.mobile) {
        const scrollHeight = this.$refs['greeting-note']?.$refs?.input?.scrollHeight || 30
        this.$refs['greeting-note'].$refs.input.style.height = 'auto'
        this.$refs['greeting-note'].$refs.input.style.height = `${scrollHeight}px`
      }
    },
    validateForm () {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss">
.sender-name-input {
  fieldset {
    border: none !important
  }

  &.error--text {
    & .v-input__slot {
      border-color: #FA5E5E !important;
      background-color: #FBE7E9 !important;
    }
  }
}

.thank-you-textarea {
  --background-color: inherit;
  & .v-input__slot {
    background: var(--background-color) !important;
    border-color: #D3D2D2;
  }

  &.error--text {
    & .v-input__slot {
      border-color: #FA5E5E;
    }
  }

  & textarea {
    padding: 55px 43px !important;
    min-height: 200px !important;
    margin: 0 !important;
    font-family: 'Lato-Italic', sans-serif !important;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.32px;
    color: #000;
    text-align: center;
  }

  & fieldset {
    padding: 0 !important;
    border: none !important
  }
}
</style>

<style lang="scss" scoped>
label {
  font-family: 'Lato-Bold', sans-serif !important;
  line-height: 18px;
  font-size: 15px;
  color: #000;
}

.see-ideas-link {
  font-family: 'Lato-Regular', sans-serif !important;
  line-height: 18px;
  color: #42B77A;
  font-size: 15px;
  cursor: pointer;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 34px;
  height: 34px;
  border: none;
  border-radius: 50%;

  &::before {
    content: '';
    width: 22px;
    height: 22px;
    border-radius: 50%;
    box-shadow: inset 1em 1em var(--chip-color);
  }

  &:checked {
    border: 1px solid #D3D2D2;
  }
}
</style>
