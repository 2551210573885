<template>
  <v-form ref="form" class="single-ling-campaign-card-form">
    <div v-if="!showOnlyEmail" class="single-ling-campaign-card-form__two-fields">
      <div class="single-ling-campaign-card-form__input-wrapper">
        <label for="given-name">First name</label>
        <common-input
          v-model="formFirstName"
          id="given-name"
          autocomplete="given-name"
          :rules="[rules.required]"
        />
      </div>
      <div class="single-ling-campaign-card-form__input-wrapper">
        <label for="family-name">Last name</label>
        <common-input
          v-model="formLastName"
          id="family-name"
          autocomplete="family-name"
          :rules="[rules.required]"
        />
      </div>
    </div>
    <div class="single-ling-campaign-card-form__input-wrapper">
      <label for="email">Email</label>
      <common-input
        v-model="formEmail"
        id="email"
        type="email"
        autocomplete="email"
        :class="{
          'single-ling-campaign-card-form__input-error': emailIsNotRecognized
        }"
        :rules="[rules.required, rules.validEmail]"
        @keypress.prevent.enter
      />
      <div
        class="single-ling-campaign-card-form__error-message"
        v-if="emailIsNotRecognized"
      >
        {{ errorMessage || 'This email address is not recognized as eligible for this eGift, try again' }}
      </div>
    </div>
    <div v-if="!showOnlyEmail" class="single-ling-campaign-card-form__input-wrapper">
      <label for="company_name" class="optional">Company name</label>
      <common-input
        v-model="formCompany"
        id="company_name"
        @keypress.prevent.enter
      />
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'TheCampaignForm',
  props: {
    firstName: {
      type: String,
      required: false,
      default: null
    },
    lastName: {
      type: String,
      required: false,
      default: null
    },
    email: {
      type: String,
      required: false,
      default: null
    },
    company: {
      type: String,
      required: false,
      default: null
    },
    showOnlyEmail: {
      type: Boolean,
      required: false,
      default: false
    },
    emailIsNotRecognized: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    rules: {
      required: (v) => !!v || '',
      validEmail: (v) => /.+@.+\..+/.test(v) || ''
    }
  }),
  computed: {
    formFirstName: {
      get () { return this.firstName },
      set (val) { this.$emit('update:firstName', val) }
    },
    formLastName: {
      get () { return this.lastName },
      set (val) { this.$emit('update:lastName', val) }
    },
    formEmail: {
      get () { return this.email },
      set (val) { this.$emit('update:email', val) }
    },
    formCompany: {
      get () { return this.company },
      set (val) { this.$emit('update:company', val) }
    }
  },
  watch: {
    formEmail () {
      this.$emit('update:emailIsNotRecognized', false)
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.single-ling-campaign-card-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__input-wrapper {
    display: flex;
    gap: 10px;
    flex-direction: column;
    flex: 1 1 auto;

    & > label {
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #000;

      &.optional::after {
        content: '(Optional)';
        margin-left: 4px;
        font-family: 'Lato-Regular', sans-serif;
      }
    }
  }

  &__two-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
  }

  &__error-message {
    font-family: 'Lato-Italic', sans-serif;
    font-size: 14px;
    letter-spacing: 0.186667px;
    color: #FA5E5E;
  }

  &__input-error {
    &::v-deep .v-input__slot {
      border-color: #FA5E5E !important;
      background: #FBE7E9 !important;
    }
  }

  @media (max-width: 425px) {
    &__two-fields {
      flex-direction: column;
      gap: 20px;
    }
  }
}
</style>
