import store from '../store/'

import Vue from 'vue'
import VueRouter from 'vue-router'

import Egift from '../views/Egift.vue'
import Setting from '../views/Setting.vue'
import Thanks from '../views/Thanks.vue'
import Success from '../views/Success.vue'
import Feedback from '../views/Feedback.vue'
import Video from '../views/Video.vue'
import MessageThankYouPage from '../views/MessageThankYouPage.vue'
import GiftThankYouPage from '../views/GiftThankYouPage.vue'
import AlreadyRedeemed from '../views/AlreadyRedeemed.vue'
import UpsellProducts from '../views/UpsellProducts.vue'
import MultipleChoices from '../views/MultipleChoices.vue'
import AlreadyDeclined from '../views/AlreadyDeclined.vue'
import AlreadyUsedGift from '../views/AlreadyUsedGift.vue'
import AlreadyExpired from '../views/AlreadyExpired.vue'
import AlreadyCanceled from '../views/AlreadyCanceled.vue'
import NotAvailableGift from '../views/NotAvailableGift.vue'
import SingleLinkCampaignPage from '../views/SingleLinkCampaignPage.vue'
import Preview from '../views/Preview.vue'

Vue.use(VueRouter)

const getIncrementId = async (to, from, next) => {
  store.state.loadingSendGift = true
  if (store.state.successNum) {
    next()
  } else {
    await store.dispatch('eGift/fetchOrderNumber').finally(() => (next()))
  }
}

const routes = [
  {
    path: '/setting',
    name: 'Setting',
    component: Setting
    // beforeEnter: hasToken
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks
    // beforeEnter: hasToken
  },
  {
    path: '/message-thank-you-page',
    name: 'MessageThankYouPage',
    component: MessageThankYouPage
    // beforeEnter: hasToken
  },
  {
    path: '/gift-thank-you-page',
    name: 'GiftThankYouPage',
    component: GiftThankYouPage
    // beforeEnter: hasToken
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
    beforeEnter: getIncrementId
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
    // beforeEnter: hasToken
  },
  {
    path: '/recipient/:hash/video',
    name: 'Video',
    component: Video
    // beforeEnter: hasToken
  },
  {
    path: '/recipient/:hash/exchange-products',
    name: 'UpsellProducts',
    component: UpsellProducts
    // beforeEnter: hasToken
  },
  {
    path: '/recipient/:hash/already-redeemed',
    name: 'AlreadyRedeemed',
    component: AlreadyRedeemed,
    props: true
  },
  {
    path: '/recipient/already-used-gift',
    name: 'AlreadyUsedGift',
    component: AlreadyUsedGift,
    props: true
  },
  {
    path: '/recipient/:hash/already-declined',
    name: 'AlreadyDeclined',
    component: AlreadyDeclined
  },
  {
    path: '/recipient/:hash/already-expired',
    name: 'AlreadyExpired',
    component: AlreadyExpired
  },
  {
    path: '/recipient/:hash/already-canceled',
    name: 'AlreadyCanceled',
    component: AlreadyCanceled
  },
  {
    path: '/recipient/:hash/not-available-gift',
    name: 'NotAvailableGift',
    component: NotAvailableGift
  },
  {
    path: '/recipient/:hash/gift/multiple-choices',
    name: 'MultipleChoices',
    component: MultipleChoices
  },
  {
    path: '/recipient/:hash/verify',
    name: 'SingleLinkCampaignPage',
    component: SingleLinkCampaignPage,
    props: true
  },
  {
    path: '/recipient/preview/c/:hash/gift',
    name: 'Preview',
    component: Preview
  },
  {
    path: '*',
    component: Egift
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
