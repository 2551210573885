<template>
  <div
    class="product-detail-panel"
    :style="{height: '100%'}"
  >
    <v-navigation-drawer
      v-model='show'
      app
      temporary
      right
      width='1065px'
      class='product-detail-panel'
      v-lock-scroll="!$vuetify.breakpoint.mobile && show"
      :style="{height: '100%'}"
    >
      <close-panel-button
        @close='show = !show'
        text='Back'
      />
      <common-loader v-if="loadingProductDetail"/>
      <div v-else-if="detailPanelInfo !== undefined" class="panel-content mt-7 mt-md-10 px-4 px-md-13">
        <div class="d-flex flex-column flex-md-row">
          <div class="col-md-6 d-flex flex-column preview-wrapper pa-0">
            <img :src="previewImgPath" class="prevImg mx-auto">
            <div
              v-if="thumbArr.length > 1"
              class="prev-img-list d-flex flex-wrap mt-3"
            >
              <div
                class="prev-min-img mr-3 mb-1"
                v-for="path in thumbArr"
                :key="path"
                @click="previewImgPath = path"
                :class="previewImgPath === path ? 'selected' : ''"
              >
                <img :src="path">
              </div>
            </div>
          </div>
          <VueSlickCarousel
            v-if="thumbArr.length > 1"
            v-model="currentIdx"
            v-bind="settings"
            class="mobile-mode my-6">
            <div
              class="prev-min-img px-3 mb-1"
              v-for="path in thumbArr"
              :key="path"
            >
              <img :src="path">
            </div>
          </VueSlickCarousel>
          <div
            v-if="thumbArr.length === 1"
            class="mobile-mode my-6">
            <div
              class="prev-min-img mb-1"
              v-for="path in thumbArr"
              :key="path"
            >
              <img :src="path">
            </div>
          </div>
          <div class="col-md-5 offset-md-1 pa-0" style="position: relative;">
            <div class="product-name mb-2">
              {{detailPanelInfo.name}}
            </div>
            <h3 v-if="detailPanelInfo.fake_product_title && detailPanelInfo.fake_product_title.length" class="text-subtitle-1 mt-3">{{ detailPanelInfo.fake_product_title }}</h3>
            <div class="sku mb-8">
              {{detailPanelInfo.sku}}
            </div>
            <!-- <div class="short_description mb-3" v-html="detailPanelInfo.short_description" /> -->
            <ValidationObserver
              ref="optionValidation"
              v-slot="{ handleSubmit }"
            >
              <form
                :id="gift.id + 'detail'"
                class="option-group"
                @submit.prevent="handleSubmit(getGift)"
              >
                <div
                  v-if="gift.options !== undefined && gift.options !== [] && fieldOptions.length > 0"
                >
                  <div
                    class="item-selector mb-8"
                    v-for="option in fieldOptions"
                    :key="option.id"
                  >
                    <div class="gift-card-input__item">
                      <div class="gift-card-input__header d-flex align-center mb-3">
                        <p class="gift-card-input__label ma-0 mr-2">{{option.title}}</p>
                      </div>
                      <div class="gift-card-input__main">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="getOptionValidation(option)"
                          :custom-messages="getOptionValidationMessages(option)"
                        >
                          <input
                            type="text"
                            v-model="gift.giftOptionsValues[`option${option.id}`]"
                            :name="'option['+gift.id+']['+option.id+']'"
                            :id="'option-'+option.id"
                            :class="[!Number(gift.available) ? 'not_available_select' : '', errors.length ? 'is-invalid' : 'is-valid', 'gift-card-input']"
                            :placeholder="option.title"
                            :v-validate="{ excluded: [''] }"
                            @change="changeHandler($event, option.title, option.id, null, option.type)"
                          />
                          <p
                            v-if="errors.length"
                            class="lato-italic f14 mb-2"
                          >{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-wrap"
                  v-if="gift.options !== undefined && gift.options !== [] && dropdownOptions.length > 0"
                >
                  <div
                    class="item-selector mb-8"
                    v-for="(option, index) in dropdownOptions"
                    :class="[dropdownOptions.length === (2 || 4) ? 'w50' : 'w100']"
                    :key="option.id">
                    <div class="gift-card-input__item">
                      <div class="gift-card-input__header d-flex align-center mb-3">
                        <p class="gift-card-input__label ma-0 mr-2">{{option.title}}</p>
                      </div>
                      <div class="gift-card-input__main">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="+option.required ? 'required' : ''"
                          :custom-messages="{
                            required: `${option.title} is a required field.`
                          }"
                        >
                          <select
                            v-if="option.values !== []"
                            :class="[
                            groupedGift && gift.option_combinations && index === 1 && !gift.giftOptionsValues[`option${gift.options[0].id}`].toString().length ? 'disabled' : '',
                            errors.length ? 'is-invalid' : 'is-valid', !Number(gift.available) ? 'not_available_select' : '', 'gift-card-input select-wrapper select-attribute with-arrow mb-2']"
                            :disabled="groupedGift && gift.option_combinations && index === 1 && !gift.giftOptionsValues[`option${gift.options[0].id}`].toString().length"
                            :name="'option['+gift.id+']['+option.id+']'"
                            :id="'option-'+option.id"
                            :v-validate="{ excluded: [''] }"
                            @change="changeHandler($event, option.title, option.id, option.values, option.type)"
                            v-model="gift.giftOptionsValues[`option${option.id}`]"
                          >
                            <option value="" disabled selected>{{option.title}}</option>
                            <option
                              v-for="value in option.values"
                              :key="value.id"
                              :value="value.id"
                              :disabled="!value.is_available"
                            >
                              {{ value.title }} {{ !value.is_available ? '(out of stock)' : '' }}
                            </option>
                          </select>
                          <p
                            v-if="errors.length"
                            class="lato-italic f14 mb-2"
                          >{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="additionalOptions.length" class="d-flex flex-wrap">
                  <div
                    v-for="(option, index) in additionalOptions"
                    :key="option.id"
                    class="item-selector mb-8 w100"
                  >
                    <div class="gift-card-input__item">
                      <div class="gift-card-input__header d-flex align-center mb-3">
                        <p class="gift-card-input__label ma-0 mr-2">{{ option.title }}</p>
                      </div>
                      <div class="gift-card-input__main">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="getOptionValidation(option)"
                          :custom-messages="getOptionValidationMessages(option)"
                        >
                          <select
                            v-if="option.type === 'drop_down'"
                            @change="changeHandler($event, option.title, option.id, option.values, option.type)"
                            v-model="option.val"
                            :disabled="!Number(gift.available)"
                            :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input select-attribute with-arrow col-12']"
                            :name="'option['+gift.id+']['+option.id+']'"
                            :id="'option-'+option.id + index"
                          >
                            <option value="" disabled selected>{{ option.title }}</option>
                            <option
                              v-for="value in getOptionValues(option, index)"
                              :key="value.id"
                              :value="value.id"
                            >
                              {{ value.title }}
                            </option>
                          </select>

                          <input
                            v-else
                            type="text"
                            :name="'option['+gift.id+']['+option.id+']'"
                            :id="'option-'+option.id"
                            v-model="option.val"
                            :disabled="!Number(gift.available)"
                            :placeholder="option.title"
                            :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                            @change="changeHandler($event, option.title, option.id, null, option.type, option.options)"
                          />

                          <p v-if="errors.length" class="lato-italic f14 mb-2">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
            <div
              v-if="!groupedGift"
              class="product-detail__button mb-8"
            >
              <popper
                v-if="showErrTxt !== ''"
                class="mt-auto"
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0,10px' } }
                }">
                <div class="popper text-left px-4 py-4">
                  {{showErrTxt}}
                </div>
                <div
                  class="mt-auto d-flex"
                  slot="reference"
                >
                  <common-button
                    class="add-button"
                    :class="showErrTxt !== '' ? 'disabled' : ''"
                    @click="addCard"
                    disabled
                  >
                    <span v-if="!multiMode" class="gift-coin-value"><strong>{{gift.coins}}</strong><v-icon>chevron-right</v-icon> GiftCoin</span>
                    <span v-if="!multiMode">|</span>
                    <span class="text-uppercase">{{isMainGift ? 'remove' : 'get this gift'}}</span>
                  </common-button>
                </div>
              </popper>
              <common-button
                v-else
                class="add-button text--white"
                :class="selected ? 'remove' : ''"
                @click="addCard"
              >
                <span
                  v-if="!multiMode"
                  class="gift-coin-value"
                  :class="selected ? 'gray5' : ''"
                >
                  <strong>{{gift.coins}}</strong>
                  GiftCoin
                </span>
                <span v-if="!multiMode" :class="selected ? 'gray5' : ''">|</span>
                <span
                  class="text-uppercase"
                  :class="selected ? 'black1' : ''"
                >
                  {{ selected
                    ? 'remove'
                    : showPrice
                    ? `get this gift | +$${gift.price}`
                    : 'get this gift'
                  }}
                </span>
              </common-button>
            </div>
          </div>
        </div>
        <div class="delimiter d-none d-md-block mt-11 mb-8" />
        <div class="d-flex">
          <div class="col-12 pa-0" v-html="detailPanelInfo.description">
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Vue from 'vue'
import Popper from 'vue-popperjs'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapState } from 'vuex'
import VScrollLock from 'v-scroll-lock'

import optionValidation from '@/mixins/optionValidation'

Vue.use(VScrollLock)

export default {
  name: 'ProductDetailPanel',
  mixins: [optionValidation],
  components: {
    popper: Popper,
    VueSlickCarousel
  },
  props: {
    showPanel: Boolean,
    id: {
      type: Number,
      default: 1
    },
    selectedGift: {
      type: Object,
      required: false,
      default: null
    },
    showPrice: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    currentIdx: undefined,
    settings:
    {
      dots: true,
      dotsClass: 'slick-dots custom-dot-class pa-0',
      edgeFriction: 0.35,
      centerMode: true,
      centerPadding: '46px',
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500
    },
    show: false,
    previewImgPath: '',
    thumbArr: [],
    showErrTxt: 'You don’t have enough giftCoins for this gift. If you want to add this item, remove one of your selected items',
    selected: false,
    gift: {},
    isMainGift: false,
    optionValues: {},
    initialValueSecondSelect: null
  }),
  methods: {
    changeHandler (event, title, id, values, type) {
      const newMainImage = this.gift.options.find(item => item.id === id)?.values?.find(value => value.id === Number(event.target.value))?.image
      // change item image only for labels that are not related to size
      const shouldChangeItemImage = !['Size', 'Apparel Size'].includes(title)

      if (shouldChangeItemImage) {
        if (newMainImage && !newMainImage.includes('no_selection')) {
          this.gift.image = newMainImage
          this.previewImgPath = newMainImage
        }
      }

      if (!this.gift.image) {
        this.gift.image = this.gift.initialMainImageLink
        this.previewImgPath = this.gift.initialMainImageLink
      }

      this.gift.option = this.gift.option.filter(item => item.title !== title)
      if (event.target.value.length !== 0) {
        const selectedValue = event.target.value
        const childOptions = values?.find((value) => value.id === +selectedValue)?.options ?? []

        const resOptionObj = {
          id,
          title,
          val: selectedValue,
          type,
          childOptions
        }
        if (values) {
          Object.assign(resOptionObj, { valTitle: values.find(value => +value.id === +selectedValue).title })
        }
        this.gift.option.push(resOptionObj)
      }
      if (this.gift.option_combinations && this.gift.options.length === 2) {
        const firstSelectValue = this.gift.giftOptionsValues[`option${this.gift.options[0].id}`]
        const listAvianbleOptions = this.gift.option_combinations[`${firstSelectValue}`]
        this.gift.options[1].values = this.initialValueSecondSelect.filter(gift => listAvianbleOptions.includes(gift.id))
      }
    },
    async addCard () {
      if (!Number(this.gift.available)) return
      if (!await this.$refs.optionValidation.validate()) return
      if (this.selectedGift) {
        this.$emit('do', this.gift)
        this.$emit('close')
      }
      if (this.selected) {
        Object.keys(this.gift.giftOptionsValues).forEach(item => {
          this.gift.giftOptionsValues[item] = ''
        })
        this.gift.image = this.gift.initialMainImageLink
        this.$nextTick(() => {
          this.$refs.optionValidation.reset()
        })
      }
      if (this.coinLeft >= this.gift.coins) {
        this.show = !this.show
        this.$emit('do', this.gift)
      }
    }
  },
  computed: {
    ...mapState('eGift', [
      'gifts0', 'detailPanelInfo', 'loadingProductDetail', 'coinLeft', 'selectedGifts', 'multiMode', 'maxGiftNum', 'multiSelectMode1', 'mainGift', 'gifts', 'groupedGift'
    ]),
    fieldOptions () {
      return this.gift?.options?.filter(option => option.type === 'field') ?? []
    },
    dropdownOptions () {
      return this.gift?.options?.filter(option => option.type === 'drop_down') ?? []
    },
    additionalOptions () {
      return this.gift.option?.reduce((acc, option) => {
        acc.push(...option.childOptions)
        return acc
      }, []) ?? []
    }
  },
  watch: {
    showPanel (val) {
      this.show = val
      if (val && this.id !== undefined) {
        this.$store.dispatch('eGift/getProductDetail', this.id)
      }
    },
    show (val) {
      if (!val) {
        this.$emit('close')
      } else {
        if (this.selectedGifts.length > 0) {
          this.selected = this.selectedGifts.filter(gift => gift.id === this.id).length > 0
        } else {
          this.selected = false
        }
        if (!this.selectedGift) {
          this.gift = this.gifts0.filter(gift => gift.id === this.id)[0]
        } else {
          this.gift = Object.assign({}, this.selectedGift)
          this.gift.option = []
          this.gift.giftOptionsValues = Object.assign({}, ...this.selectedGift.options.map((item) => ({ [`option${item.id}`]: '' })))
        }
        this.gift.options.forEach((option) => {
          Object.assign(this.optionValues, {
            [`option${option.id}Value`]: ''
          })
        })
        this.previewImgPath = this.gift.image
        if (this.gift.option_combinations && this.gift.options.length === 2) {
          this.initialValueSecondSelect = this.gift.options[1].values
        }
        this.showErrTxt = (this.coinLeft < this.gift.coins && !this.selected)
          ? (this.multiMode ? 'You’ve selected ' + this.maxGiftNum + ' items already. If you want to add this item, remove one of your selected items' : 'You don’t have enough giftCoins for this gift. If you want to add this item, remove one of your selected items')
          : ''
      }
    },
    detailPanelInfo (val) {
      if (typeof (val.media_gallery) === 'object') {
        if (!this.gift.option?.length) {
          this.previewImgPath = val.media_gallery[0]
        }
        this.thumbArr = val.media_gallery
      } else if (typeof (val.media_gallery) === 'string') {
        if (!this.gift.option?.length) {
          this.previewImgPath = val.media_gallery
        }
        this.thumbArr = []
        this.thumbArr.push(val.media_gallery)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-detail-panel {
  padding-left: 0;

  .mobile-mode {
    position: relative !important;
  }

  .select-icon{
    position: absolute;
    right: 5px;
    color: black;
  }

  .select-wrapper{
    position: relative;

  }

  .panel-content {
    padding-right: 0;
    // margin: 0!important;

    @media screen and (min-width: 1024px) {
      padding-right: 130px;
      // margin-left: 28px!important;
    }

    .preview-wrapper {
      display: none !important;

      @media screen and (min-width: 1024px) {
        display: flex !important;
      }
    }

    .prevImg {
      max-width: 100%;
      max-height: 50vh;

    }

    .prev-min-img {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vw;
      border: 1px solid #E4E4E4;

      @media screen and (min-width: 1024px) {
        height: 80px;
        width: 80px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      &.selected {
        border: 3px solid #42B77A;
      }
    }

    .mobile-mode .prev-min-img {
      border: none;
    }

    .product-name {
      color: #161616;
      font-size: 25px;
      letter-spacing: 0;
      line-height: 35px;
    }

    .sku {
      color: #9F9F9F;
      font-size: 10px;
      letter-spacing: 0.13px;
      line-height: 12px;
    }

    .short_description {
      color: #9F9F9F;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 24px;
    }

    .txt-field {
      width: 100%;
      height: 28px;
      border: 1px solid #d3d2d2;
      padding-left: 10px;
      outline: none;
    }
  }

  .item-selector {
    margin-bottom: 10px;

    label {
      color: #959595;
      font-weight: 400;
      margin-bottom: 0;
    }

    .select-attribute {
      padding: 0 20px 0 10px;
      font-weight: 400 !important;
      font-style: normal !important;
      color: #000 !important;
      width: 100%;
      border-radius: 0;
      // height: 28px;
      // background-color: #fff;
      // border: 1px solid #d3d2d2;
      font-size: 14px;
      font-family: "Lato", Verdana, Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      &.with-arrow {
        background-image: url('/public/images/select-arrow-icon.svg');
        background-size: 16px 16px;
        background-position: 95% 45%;
        background-repeat: no-repeat;
      }
    }
  }

  .add-button {
    background: #000;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    margin-top: auto;
    border-radius: 0;
    transition: background-color 0.5s;
    letter-spacing: 0;
    &:hover{
      background-color: #919191 !important;
      transition: background-color 0.5s;
    }

    span {
      color: white;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      &.gift-coin{
        font-size: 13px;
        font-weight: 100;
      }
    }

    &.v-btn--has-bg.remove {
      background-color: white;
      border: 2px solid black;
      transition: all 0.5s;
      span {
        color: #000;
        transition: all 0.5s;
      }
      &:hover{
        background-color: white !important;
        border: 2px solid #919191;
        span {
          color: #919191 !important;
        }
      }
    }

    &.disabled {
      width: 100%;
      background-color: #C0C0C0 !important;
      color: #fff !important;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0;
    }
  }

  .gift-coin-value {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: none;

    strong {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      font-style: normal;
    }
  }

  .err-txt {
    height: 0;
  }

  .txt-field {
    width: 100%;
    height: 28px;
    border: 1px solid #d3d2d2;
    padding-left: 10px;
    outline: none;
  }
}
.v-navigation-drawer--temporary {
    z-index: 9999999;
}
.gift-card-input{
  &__label{
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  &.is-invalid{
    background-color: #FBE7E9;
    border: 1px solid #FA5E5E;
  }
  &__main{
    input, select{
      width: 100%;
      height: 40px;
      padding: 11px;
      border: 1px solid #D3D2D2;
      font-size: 15px;
      line-height: 22px;
      outline: none;
      &#state.is-invalid,
      &.is-invalid{
        background: #FBE7E9;
        border: 1px solid #FA5E5E;
      }
      &::placeholder{
        font-family: 'Lato-Italic';
        font-size: 15px;
        color: #979797;
      }
    }
    p{
      // margin-top: 10px;
      // margin-bottom: 0px;
      color: #FA5E5E;
    }
  }
}
#state{
  height: 40px;
  &::placeholder{
    font-family: "Lato-Regular";
    font-style: italic;
    font-size: 13px;
    font-weight: 500;
    color: #979797;
  }
}
.setting .select-icon{
  position: absolute;
  right: 5px;
  top: 3px;
  color: black;
}

.select-wrapper{
  position: relative;
  &.with-arrow {
    background-image: url('/public/images/select-arrow-icon.svg');
    background-size: 25px 25px;
    background-position: 99% 50%;
  }
}
.delimiter{
  width: 100%;
  height: 1px;
  background-color: rgb(234,234,234);
}
</style>
