<template>
  <v-container class="thanks-page pt-0">
    <v-row dense class="flex-column align-center">
      <v-col cols="12" sm="7" md="5" v-if="sendingError">
        <div class="error-message py-4 px-13 mb-4 lighten-4 d-flex align-center">
          <img
            src="@/assets/error-icon.svg"
            class="lighten-1 d-flex justify-center mr-4"
          />
          <p class="mb-0">We've encountered a technical error, note has not been sent. Please try again later.</p>
        </div>
      </v-col>

      <template v-if="!loadingSendThankYouNote && !movingToSuccessPage">
        <v-col cols="12" sm="7" md="5" class="thanks-page__title">
          Send a
          <span class="lato-bold">
            Thank you
          </span>
          note to {{ sender }}
        </v-col>

        <v-col cols="12" sm="7" md="5" class="pt-10">
          <ThankYouForm
            :message.sync="message"
            :sender.sync="messageSender"
            :color.sync="messageColor"
            ref="form"
          />
        </v-col>

        <v-col cols="12" sm="7" md="5" class="d-flex pt-10 align-center">
          <common-button
            outlined
            style="min-width: 100px"
            @click="showSkipThanksPanelF = true"
          >
            Skip
          </common-button>
          <common-button
            class="ml-3"
            @click="sendNote()"
            style="min-width: 148px"
          >
            Send note
          </common-button>

          <div class="ml-3 red1" v-if="showErrors">
            {{ errorMessage }}
          </div>
        </v-col>
      </template>

      <common-loader v-else />
    </v-row>

    <e-gift-skip-thanks-modal
      :show="showSkipThanksPanelF"
      :inCustomPanel="true"
      @close="closeSkipThanksPanel"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ThankYouForm from '@/components/ThankYouForm.vue'
export default {
  components: {
    ThankYouForm
  },
  data: () => ({
    message: null,
    messageSender: null,
    messageColor: null,
    showSkipThanksPanelF: false,
    sendingError: false,
    errorMessage: null,
    showErrors: false,
    movingToSuccessPage: false
  }),
  computed: {
    ...mapState('eGift', [
      'loadingSendThankYouNote',
      'recipientFirstName',
      'recipientLastName',
      'usedGiftStatus',
      'sender'
    ])
  },
  created () {
    if (this.recipientFirstName || this.recipientLastName) {
      let result = ''
      if (this.recipientFirstName) result += `${this.recipientFirstName} `
      if (this.recipientLastName) result += this.recipientLastName
      this.messageSender = result
    }
  },
  methods: {
    sendNote () {
      if (!this.$refs.form.validateForm()) {
        this.showErrors = true
        this.setErrorMessage()
        return
      } else {
        this.showErrors = false
        this.errorMessage = false
        this.movingToSuccessPage = false
      }

      this.$store.dispatch('eGift/sendNote', {
        note: this.message,
        color: this.messageColor,
        sender: this.messageSender
      })
        .then(() => {
          this.movingToSuccessPage = true
          this.goToNext()
        })
        .catch(() => {
          this.sendingError = true
          window.scrollTo(0, 0)
        })
    },
    goToNext () {
      this.$router.push({
        name: 'Success'
      })
    },
    setErrorMessage () {
      const { message, messageSender } = this
      if (!message && !messageSender) {
        this.errorMessage = 'Please insert all options'
      } else if (!messageSender) {
        this.errorMessage = 'Message sender is required'
      } else if (!message) {
        this.errorMessage = 'Message text is required'
      }
    },
    closeSkipThanksPanel () {
      this.showSkipThanksPanelF = !this.showSkipThanksPanelF
    }
  }
}
</script>

<style lang="scss">
.thanks-page {
  .error-message{
    background-color: rgba(250,227,227,1);
  }
  &__title {
    font-size: 30px;
    font-family: 'Lato-Light', sans-serif !important;
    line-height: 36px;
    color: #000000;
    @media screen and (min-width: 768px) {
      font-size: 42px;
      line-height: 50px;
    }
  }
}
</style>
