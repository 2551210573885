<template>
  <v-col cols="12">
    <v-row no-gutters>
      <v-col cols="12" class="step-header__title">
        <slot name="title" />
      </v-col>
      <v-col cols="12" class="step-header__subtitle">
        <slot name="subtitle" />
      </v-col>
      <v-col cols="12" class="step-header__subtitle-hearth d-flex align-start">
        <v-icon color="#000" size="18" class="mr-2">
          mdi-heart
        </v-icon>
        <slot name="subtitle-with-heart" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'StepHeader'
}
</script>

<style lang="scss" scoped>
.step-header {
  &__title {
    font-size: 30px;
    font-family: 'Lato-Light', sans-serif !important;
    line-height: 36px;
    font-weight: 300;
    color: #000;
  }

  &__subtitle {
    font-size: 15px;
    font-family: 'Lato-Light', sans-serif !important;
    line-height: 18px;
    font-weight: 400;
    color: #A1A1A1;
  }

  &__subtitle-hearth {
    font-size: 15px;
    font-family: 'Lato-Light', sans-serif !important;
    line-height: 18px;
    font-weight: 400;
    color: #A1A1A1;
  }

    &__title, &__subtitle, &__subtitle-hearth {
      padding-bottom: 16px !important;
    }
}

@media (min-width: 960px) {
  .step-header {
    &__title {
      font-size: 42px;
      line-height: 36px;
    }

    &__title, &__subtitle, &__subtitle-hearth {
      padding-bottom: 8px !important;
    }
  }
}
</style>
