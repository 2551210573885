<template>
  <div class="success-preview d-flex flex-column">
    <PreviewPageHeader />
    <div
      class="success-page success-preview-page mx-auto d-flex flex-column align-center pl-6 pr-6 pa-md-0"
    >
      <div class="success-preview__header d-flex flex-column align-center mb-0">
        <h1 class="success-page__title">Woo hoo!</h1>
        <p class="success-page__sub-text">You’ve redeemed your gift from {{ previewSender }}<br>
        <p class="success-page__sub-text"><b>Your confirmation number is 100055276</b> (we’ve emailed you the details)</p>
      </div>
      <div
        v-if="showCalendlyOrFreeFormLink"
        class="success-main-messages-wrapper col-8 pa-0 mb-5 mb-md-7 d-flex flex-wrap justify-space-between"
      >
        <div
          class="col-12 calendar-background-image success-main-message calendar-link-message pa-0 mb-0 d-flex flex-column align-center justify-end"
        >
          <p class="success-main-message__text mb-5 mb-md-8">
            <template v-if="respondToText">
              <b>{{ respondToText }}</b>
            </template>
            <template v-else>
              <b>Respond to {{ previewSender }}</b> (optional)
            </template>
          </p>
          <common-button
            :width="buttonsStyles.width"
            :height="buttonsStyles.height"
            :fontSize="buttonsStyles.fontSize"
            class="success-buttons mb-8 mb-md-12"
          >
            {{ previewCalendlyLink ? 'book a meeting' : previewFreeFormButton }}

            <template v-slot:rightIcon>
              <v-icon
                v-if="previewCalendlyLink"
                light
                dense
                color="#fff"
                class="close-icon ml-3 mb-1 align-center"
              >mdi-calendar-blank-outline</v-icon>
            </template>
          </common-button>
        </div>
      </div>
      <div
        v-if="!showCalendlyOrFreeFormLink && !(actualPreviewSelectedGifts.length === 1 && actualPreviewSelectedGifts[0].is_virtual)"
        class="delimiter mb-4"
      />
      <p
        v-if="!(actualPreviewSelectedGifts.length === 1 && actualPreviewSelectedGifts[0].is_virtual)"
        class="mb-7"
      >You’ll receive:</p>
      <div
        v-if="(pageWidth >= 768 && actualPreviewSelectedGifts.length <= 3) || (pageWidth < 768 && actualPreviewSelectedGifts.length === 1)"
        class="selected-gifts col-12 col-md-8 d-flex pa-0 mb-11 justify-center"
      >
        <SuccessGiftCard
          v-for="(gift, index) in actualPreviewSelectedGifts"
          :key="index"
          :gift="gift"
          class="col-12 col-md-4"
        />
      </div>
      <VueSlickCarousel
        v-if="(pageWidth >= 768 && actualPreviewSelectedGifts.length > 3) || (pageWidth < 768 && actualPreviewSelectedGifts.length > 1)"
        v-model="currentIdx"
        v-bind="settings"
        class="success-selected-gifts-carousel col-10 col-md-8 pa-0 mb-5"
      >
        <SuccessGiftCard
          v-for="(gift, index) in actualPreviewSelectedGifts"
          :key="index"
          :gift="gift"
        />
        <template v-slot:prevArrow>
          <v-btn
            x-small
            fab
            shaped
            elevation="0"
            color="#000"
            class="success-selected-gifts-carousel__navigation-button left-arrow"
          >
            <span/>
          </v-btn>
        </template>
        <template v-slot:nextArrow>
          <v-btn
            x-small
            fab
            shaped
            elevation="0"
            color="#000"
            class="success-selected-gifts-carousel__navigation-button right-arrow"
          >
            <span/>
          </v-btn>
        </template>
      </VueSlickCarousel>
    </div>
    <common-footer
      class="success-preview__footer mx-auto"
    />
  </div>
</template>

<script>
import PreviewPageHeader from './../PreviewPageHeader.vue'
import { mapState, mapGetters } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import SuccessGiftCard from '../../eGift/SuccessGiftCard.vue'

export default {
  name: 'SuccessPreview',
  components: {
    PreviewPageHeader,
    SuccessGiftCard,
    VueSlickCarousel
  },
  data: () => ({
    currentIdx: 1,
    settings: {
      arrows: true,
      slidesToShow: 3,
      dots: true,
      dotsClass: 'slick-dots custom-dot-class',
      edgeFriction: 0.35,
      centerPadding: '46px',
      infinite: true,
      speed: 500
    },
    showPopper: false,
    pageWidth: 0
  }),
  methods: {
    formatedOptionValue (option) {
      return option.valTitle ? option.valTitle : option.val
    },
    copyLink () {
      this.showPopper = true
      setTimeout(() => {
        this.showPopper = false
      }, 1500)
    }
  },
  computed: {
    ...mapState('preview',
      [
        'previewCalendlyLink',
        'previewFreeFormLink',
        'previewFreeFormButton',
        'previewItemsCount',
        'respondToText',
        'previewSender'
      ]
    ),
    ...mapGetters('preview', ['actualPreviewSelectedGifts']),
    buttonsStyles () {
      return {
        width: window.innerWidth > 960 ? 200 : 179,
        height: window.innerWidth > 960 ? 46 : 40,
        fontSize: window.innerWidth > 960 ? '15px' : '13px'
      }
    },
    showCalendlyOrFreeFormLink () {
      const { previewCalendlyLink, previewFreeFormButton } = this

      return !!(previewCalendlyLink || previewFreeFormButton)
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
      this.pageWidth = window.innerWidth
      if (this.pageWidth < 768) {
        this.settings.slidesToShow = 1
        this.settings.arrows = false
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.success-preview{
  background-color: #fff;
  max-width: 100%;
  &__footer{
    width: 1048px;
  }
}
.success-preview-page{
  width: 1185px;
  .delimiter{
    width: 80%;
  }
}

.success-page {
  &__title {
    font-family: 'Lato-Light', 'Lato-Regular', sans-serif;
    font-weight: 300;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    color: #000000;
  }

  &__sub-text {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #000000;

    & > b {
      font-family: 'Lato-Bold', sans-serif;
    }
  }
}
</style>
