<template>
  <common-cg-validation
    :rules="
      !hideRules ?
        email ?
          'required|email' :
          'required' :
        null"
    :custom-messages="{
      required: 'This field is required'
    }"
  >
    <v-text-field
      v-model="modelValue"
      v-bind="$attrs"
      v-on="$listeners"
      class="rounded-0 thin-border v-input--errors containct-info-input"
      color="black"
      outlined
      validate-on-blur
      hide-details=""
      solo
      flat
      dense
      tile
      :height="30"
    >
      <template v-for="(_, slot) in $slots">
        <template :slot="slot">
          <slot :name="slot" />
        </template>
      </template>
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-text-field>
  </common-cg-validation>
</template>

<script>
export default {
  name: 'TheContactInfoInput',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    email: {
      type: Boolean,
      required: false,
      default: false
    },
    hideRules: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.containct-info-input {
  .v-input__append-outer, .v-input__prepend-outer {
    margin-bottom: 0 !important;
  }
}
input[type=number] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
  -webkit-appearance: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>
