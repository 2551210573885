<template>
  <div class="decline-panel">
    <v-navigation-drawer
      v-model="show"
      app
      temporary
      right
      width="1065px"
      class="decline-panel ma-0 pa-0"
      v-scroll-lock="!$vuetify.breakpoint.mobile && show"
      :style="{ height: '100%' }"
    >
      <close-panel-button @close="show = !show" text="Cancel" />
      <div class="decline-panel__main mt-7 mt-md-10 px-4 px-md-13">
        <div class="re-gift-panel__header">
          <p class="text-h3 font-weight-light">Alcohol shipping restriction</p>
        </div>
        <div class="re-gift-panel__body mt-9 col-lg-10 pa-0">
          <div class="col-12 yellow lighten-3 pa-3 pl-0 mt-5 d-flex">
            <v-icon class="gray--text ma-3 ma-mt-6"
              >mdi-information-outline</v-icon
            >
            <p v-html="errorMessage" class="ma-0" ref="errorMessage" />
          </div>
          <div class="f15 open-sans-bold text-start mt-16">
            What can you do?
          </div>
          <div class="d-flex flex-wrap mt-5">
            <common-button
              outlined
              width="100%"
              @click="show = !show"
              class="col-12 col-sm-4 col-md-3 pa-0"
            >
              Change address
            </common-button>
            <common-button
              v-if="canSelectAlternatives"
              outlined
              width="100%"
              @click="updateGifts"
              class="col-12 col-sm-4 col-md-3 pa-0 mt-4 mt-sm-0 ml-sm-4"
            >
              Select another gift
            </common-button>
          </div>
        </div>
        <!-- <div class="mt-5 f15 gray1 font-italic">
          <a href="#" class="text-decoration-none">Where</a> can this item ship?
        </div> -->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import { mapActions, mapMutations, mapState } from 'vuex'
Vue.use(VScrollLock)

export default {
  name: 'RestrictionPanel',
  props: ['showPanel', 'stateName', 'zipCode', 'canBeSendToTheInputtedZipCode'],
  data: () => ({
    show: false
  }),
  computed: {
    ...mapState('eGift', [
      'recipientHash',
      'updateGiftsLink',
      'redeemErrorMessage',
      'selectedGifts',
      'coinLeft',
      'gifts',
      'stateShortCode',
      'canSelectAlternatives',
      'maxGiftNum'
    ]),
    errorMessage () {
      return `Each state has different alcoholic beverage laws by which we must abide.\nFor this reason, this product cannot be shipped to ${
        !this.canBeSendToTheInputtedZipCode
          ? 'the entered postal code'
          : this.stateName
      }`
    }
  },
  watch: {
    showPanel: {
      immediate: true,
      handler: function (val) {
        this.show = val
      }
    },
    show (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  methods: {
    ...mapMutations('eGift', ['setSelectedGifts', 'setGifts']),
    ...mapActions('eGift', ['getUpSellProducts']),
    updateGifts () {
      this.getUpSellProducts()
      if (this.selectedGifts[0]) {
        this.$store.commit(
          'eGift/setCoinLeft',
          this.coinLeft + this.selectedGifts[0].coins
        )
      }
      this.show = !this.show
      const giftsForSelectedState = this.gifts
        .filter(
          (gift) =>
            !gift?.restricted_states?.includes(this.stateShortCode) &&
            !gift?.restricted_zip_codes?.includes(this.zipCode?.trim())
        )
        .filter((gift) => +gift.available)
      if (giftsForSelectedState.length) {
        const selectedGiftsIdArray = this.selectedGifts.map(
          (selectedGift) => selectedGift.id
        )

        this.setGifts(giftsForSelectedState)
        this.setSelectedGifts(
          giftsForSelectedState.filter((giftForSelectedState) =>
            selectedGiftsIdArray?.includes(giftForSelectedState.id)
          )
        )
        if (this.maxGiftNum > 1) {
          this.$router.push({ path: `/recipient/${this.recipientHash}/gift/multiple-choices` })
          return
        }
        this.$router.push({ path: `/recipient/${this.recipientHash}/gift` })
        return
      }
      this.$router.push({ path: `/recipient/${this.recipientHash}/exchange-products` })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer--temporary {
  z-index: 9999999;
}
</style>
