<template>
  <v-container :class="!userIsUsingMobileDevice && 'pa-0'">
    <v-row dense justify="space-between">
      <StepHeader
        class="pa-0"
      >
        <template #title>
          Where should we send your gift?
        </template>
        <template #subtitle-with-heart>
          Don't worry, your choice to upgrade or re-gift won't be shared with anyone
        </template>
      </StepHeader>

      <v-col cols="12" md="7" order="2" order-md="1" >
        <ContactInfoForm
          :userFirstName.sync="userAddress.first_name"
          :userLastName.sync="userAddress.last_name"
          :userEmail.sync="userAddress.email"
          :userAddress.sync="userAddress.address"
          :userAddressApt.sync="userAddress.address_apt"
          :userZip.sync="userAddress.zip"
          :userCity.sync="userAddress.city"
          :userState.sync="userAddress.state"
          :userTelephone.sync="userAddress.telephone"
        />
      </v-col>

      <v-col cols="12" md="4" order="1" order-md="2" >
        <SelectedGiftPreview
          :selectedItem="selectedItem"
        />
      </v-col>

      <v-col
        cols="12"
        class="py-6"
        :class="userIsUsingMobileDevice && 'd-flex flex-column'"
        order="3"
      >
        <common-button
          outlined
          class="order-2 order-md-1"
          @click="$emit('goToPreviousStep')"
          :block="userIsUsingMobileDevice"
        >
          Back
        </common-button>
        <common-button
          class="order-1 order-md-2"
          :class="userIsUsingMobileDevice ? 'mb-5' : 'ml-4'"
          :disabled="!isFormFilled"
          :block="userIsUsingMobileDevice"
          @click="$emit('goToNextStep')"
        >
          Get your gift
        </common-button>
      </v-col>

      <v-col cols="12" md="7" class="lato-italic f15 gray1" order="4">
        By clicking “Get your gift” you agree to the CorporateGift.com
        <span class="green3 lato-italic">Terms of Service and Payments Policy.</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StepHeader from '../StepHeader.vue'
import ContactInfoForm from '../ContactInfoForm.vue'
import SelectedGiftPreview from '../SelectedGiftPreview.vue'

export default {
  name: 'ShippingForm',
  components: {
    SelectedGiftPreview,
    ContactInfoForm,
    StepHeader
  },
  props: {
    selectedItem: {
      type: Object,
      required: true
    },
    userAddress: {
      type: Object,
      required: true
    }
  },
  computed: {
    userIsUsingMobileDevice () {
      return this.$vuetify.breakpoint.mobile
    },
    isFormFilled () {
      const { userAddress } = this
      return userAddress.first_name &&
        userAddress.last_name &&
        userAddress.email &&
        userAddress.address &&
        userAddress.zip &&
        userAddress.city &&
        userAddress.state &&
        userAddress.telephone
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-update-gift{
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
  &__info{
    &--name{
      line-height: 18px;
    }
    &--add-price{
      line-height: 22px;
      background-color: #EDFCFB;
    }
  }
}
</style>
