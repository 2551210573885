<template>
  <v-container :class="{ 'fill-height': $vuetify.breakpoint.mdAndUp }">
    <v-row no-gutters justify="center">
      <v-col class="single-ling-campaign-card__wrapper" v-if="!loading.data">
        <div class="single-ling-campaign-card__title" v-if="$vuetify.breakpoint.xsOnly">
          <h1>Hi there,</h1>
          <h3>{{ welcomeMessage }}</h3>
        </div>

        <the-campaign-images :images="giftImages" :size="$vuetify.breakpoint.xsOnly ? 80 : 180" />

        <div class="single-ling-campaign-card__content">
          <div class="single-ling-campaign-card__title" v-if="$vuetify.breakpoint.mdAndUp">
            <h1>Hi there,</h1>
            <h3>{{ welcomeMessage }}</h3>
          </div>

          <template v-if="!loading.response">
            <template v-if="!isGiftAlreadyRedeemed && !responseData">
              <the-campaign-form
                :firstName.sync="form.firstName"
                :lastName.sync="form.lastName"
                :email.sync="form.email"
                :company.sync="form.company"
                :showOnlyEmail="giftLinkType === 'single_link_restricted'"
                :emailIsNotRecognized.sync="emailIsNotRecognized"
                :errorMessage="errorMessage"
                ref="campaignForm"
              />

              <common-button
                @click="redeemGiftLink()"
                :color="buttonColor"
                class="single-ling-campaign-card__button"
              >
                {{ giftLinkType === 'single_link_restricted' ? 'Continue' : 'Send link to my Email' }}
              </common-button>
            </template>

            <template v-else-if="showSuccessMessage">
              <div
                class="single-ling-campaign-card__message
                  single-ling-campaign-card__message--success"
              >
                <div class="single-ling-campaign-card__check" />

                <div>
                  <h6>
                    Gift link sent successfully to your email!
                  </h6>

                  <span>
                    Follow instructions in email to redeem your gift <br />
                    Need help? <a href="https://corporategift.com/contact-us/">contact our support</a>
                  </span>
                </div>
              </div>
            </template>

            <template v-else-if="showErrorMessageForUnrestrictedLink || showErrorMessageForRestrictedLink">
              <div
                class="single-ling-campaign-card__message
                  single-ling-campaign-card__message--error"
              >
                Something happened, please try to refresh the page. If you’re still experiencing
                issue, please <a
                  href="https://corporategift.com/contact-us/"
                  target="_blank"
                  style="text-decoration: underline; color: inherit"
                >
                  contact our support
                </a>
              </div>
            </template>

            <template v-else>
              <div
                class="single-ling-campaign-card__message
                  single-ling-campaign-card__message--error"
              >
                Oops! This eGift has already been redeemed.
              </div>
            </template>
          </template>

          <common-loader v-else style="padding: 0" />
        </div>
      </v-col>

      <common-loader v-else />
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/axios/api'

import TheCampaignImages from '@/components/singleLinkCampaign/TheCampaignImages.vue'
import TheCampaignForm from '@/components/singleLinkCampaign/TheCampaignForm.vue'

export default {
  name: 'SingleLinkCampaignPage',
  components: {
    TheCampaignForm,
    TheCampaignImages
  },
  data: () => ({
    loading: {
      data: false,
      response: false
    },
    form: {
      firstName: null,
      lastName: null,
      email: null,
      company: null
    },
    giftData: null,
    responseData: null,
    giftNotAvailable: false,
    emailIsNotRecognized: false,
    errorMessage: null
  }),
  computed: {
    giftHash () {
      return this.$route.params?.hash ?? null
    },
    buttonColor () {
      return this.giftData?.gift_claim_button_color ?? '#000'
    },
    giftImages () {
      const { products, cover_image_url: coverImage } = this.giftData

      if (coverImage) { return [{ image: coverImage, name: '' }] }

      return products?.map((item) => ({
        image: item.image,
        name: item.name
      })) ?? [{ image: '/images/empty.png', name: 'No image' }]
    },
    giftLinkType () {
      return this.giftData?.link_type ?? null
    },
    welcomeMessage () {
      return this.giftData?.welcome_message ?? 'Enter your details to claim your gift'
    },
    isGiftAlreadyRedeemed () {
      return this.giftData?.is_redeemed ?? true
    },
    showSuccessMessage () {
      const { isGiftAlreadyRedeemed, responseData } = this

      return !isGiftAlreadyRedeemed && responseData && responseData.success
    },
    showErrorMessageForRestrictedLink () {
      const { isGiftAlreadyRedeemed, responseData, giftLinkType } = this

      return !isGiftAlreadyRedeemed &&
        responseData &&
        !responseData.success &&
        giftLinkType === 'single_link_restricted'
    },
    showErrorMessageForUnrestrictedLink () {
      const { isGiftAlreadyRedeemed, responseData, giftLinkType } = this

      return !isGiftAlreadyRedeemed &&
        responseData &&
        !responseData.success &&
        giftLinkType === 'single_link_unrestricted'
    }
  },
  watch: {
    showErrorMessageForRestrictedLink: function (val) {
      if (val) {
        this.emailIsNotRecognized = true
        this.responseData = null
      }
    }
  },
  created () {
    this.loading.data = true
    Api.get(`/recipient/${this.giftHash}/verify/gift`)
      .then(({ data }) => {
        this.giftData = data
        this.$store.commit('eGift/setLogoUrl', data?.logo_url ?? null)
      })
      .catch(({ response: { status } }) => {
        if (status === 404) this.giftNotAvailable = true
      })
      .finally(() => (this.loading.data = false))
  },
  methods: {
    redeemGiftLink () {
      if (this.$refs.campaignForm.validate()) {
        const { form, giftHash, giftLinkType } = this
        const requestBody = {}

        requestBody.email = form.email

        if (giftLinkType === 'single_link_unrestricted') {
          requestBody.first_name = form.firstName
          requestBody.last_name = form.lastName
        }
        if (form.company) { requestBody.company_name = form.company }

        this.loading.response = true
        this.responseData = null
        this.errorMessage = null

        Api.post(`/recipient/${giftHash}/verify/send-link`, requestBody)
          .then((response) => {
            this.responseData = response

            if (giftLinkType === 'single_link_restricted') window.location.href = response?.data?.redeem_url
          })
          .catch(({ response: { data } }) => {
            this.responseData = { success: false }
            this.errorMessage = data?.message ?? null
          })
          .finally(() => (this.loading.response = false))
      }
    }
  }
}
</script>

<style lang="scss">
.single-ling-campaign-card {
  &__wrapper {
    display: flex;
    flex: 0 1 920px;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 60px;
  }

  &__content {
    display: flex;
    gap: 24px;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__title {
    display: flex;
    flex-direction: column;
    color: #000;

    & > h1 {
      font-size: 45px;
      line-height: 54px;
      font-weight: 300;
      margin-left: -3px;
      font-family: 'Lato-Light', sans-serif;
    }

    & > h3 {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 20px;
      font-weight: 400;
      margin-left: -1px;
      line-height: 24px;
    }
  }

  &__button {
    max-width: 260px;
  }

  &__message {
    padding: 16px;
    width: 100%;
    font-family: 'Lato-Regular', sans-serif;

    &--error {
      background-color: #FAE3E3;
      font-size: 16px;
      line-height: 27px;
      color: #222325;

      & > span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &--success {
      background-color: #D8F1E4;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > h6 {
          font-family: 'Lato-Bold', sans-serif;
          font-size: inherit;
          line-height: 18px;
        }

        & a {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }

  &__check {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #42B77A;

    &::before {
      content: '';
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      width: 12px;
      height: 8px;
      transform: rotate(-45deg) translateX(1px) translateY(-1px);
    }
  }

  @media (max-width: 425px) {
    &__wrapper {
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }

    &__title {
      gap: 10px;
      align-items: center;

      & > h3 {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__content {
      width: 100%;
      gap: 50px;
    }

    &__button {
      max-width: unset;
    }

    &__message {
      &--success {
        flex-direction: column;
        align-items: center;

        & > div {
          align-items: center;

          & > h6 {
            font-size: 16px;
            line-height: 19px;
          }

          & > span {
            text-align: center;
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

.loading_style {
  position: unset;
  min-height: unset;
  height: unset;
  padding: 0;
  left: 0;
  transform: unset;
  z-index: unset;
}
</style>
