<template>
  <div class="gift-card mb-11" :id="'gift'+item.id" :class="{ not_available: !Number(item.available) }">
    <div v-if="!Number(item.available)" class="not_available_label ma-3 pa-2">
      <h3 class="font-weight-light">Out of stock</h3>
    </div>
    <img v-if="isSelected" class="gift-card_checked-mark ma-3" src="/images/selected_gift.svg" alt="">
    <img
      :src="(item.image && !item.image.includes('no_selection')) ? item.image : '/images/empty.png'"
      class="gift-card_img"
    />
    <div
      :class="[item.is_virtual ? 'virtual' : '', 'gift-card__information-block']"
    >
      <div class="gift-card_name">
        <span>{{item.name}}</span>
      </div>
      <div class="gift-card_info" id="short-description" v-html="item.short_description">
      </div>
    </div>
    <div class="green3 pointer f14 mt-2 mb-4">
      <span>Read more</span>
    </div>
    <form
      :id="item.id"
      class="option-group"
      @submit.prevent="handleSubmit(getGift)"
    >
      <div
        v-if="item.options !== undefined && item.options !== [] && item.options.filter(option => option.type === 'field').length > 0"
      >
        <div
          class="item-selector"
          v-for="option in item.options.filter(option => option.type === 'field')"
          :key="option.id"
        >
          <div class="preview-gift-card-input__main">
            <input
              type="text"
              :name="'option['+item.id+']['+option.id+']'"
              :id="'option-'+option.id"
              :disabled="!Number(item.available) || allAvailableSlotsSelected || notEnoughGiftCoins || isSelected"
              class="txt-field mb-2"
              :class="[!Number(item.available) ? 'not_available_select' : '', 'preview-gift-card-input']"
              :placeholder="option.title"
              @change="changeHandler($event, option.title, option.id, null, option.type)"
              v-model="item.giftOptionsValues[`option${option.id}`]"
            />
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-wrap"
        v-if="item.options !== undefined && item.options !== [] && item.options.filter(option => option.type === 'drop_down').length > 0"
      >
        <div
          class="item-selector pb-1"
          v-for="option in item.options.filter(option => option.type === 'drop_down')"
          :class="[item.options.filter(option => option.type === 'drop_down').length === (2 || 4) ? 'w50' : 'w100']"
          :key="option.id"
        >
          <div class="preview-gift-card-input__main">
            <select
              v-if="option.values !== []"
              class="select-attribute with-arrow mb-2"
              :disabled="!Number(item.available) || allAvailableSlotsSelected || notEnoughGiftCoins || isSelected"
              :class="[!Number(item.available) ? 'not_available_select' : '', 'preview-gift-card-input']"
              :name="'option['+item.id+']['+option.id+']'"
              :id="'option-'+option.id"
              @change="changeHandler($event, option.title, option.id, option.values, option.type)"
              v-model="item.giftOptionsValues[`option${option.id}`]"
            >
              <option value="" disabled selected>{{option.title}}</option>
              <option
                v-for="value in option.values"
                :key="value.id"
                :value="value.id"
                :disabled="!value.is_available"
              >
                {{ value.title }} {{ !value.is_available ? '(out of stock)' : '' }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <common-button
      v-if="!previewIsTypeGrouped"
      class="add-button"
      :disabled="!Number(item.available)"
      @click="selectGift"
    >
      <span v-if="previewIsGiftCoin" class="gift-coin-value"><strong>{{+item.coins}}</strong><v-icon>chevron-right</v-icon> GiftCoin</span>
      <span v-if="previewIsGiftCoin">|</span>
      <span class="text-uppercase">{{isSelected ? 'remove' : 'get this gift'}}</span>
    </common-button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PreviewGiftCard',
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    initialValueSecondSelect: null
  }),
  computed: {
    ...mapState('preview', [
      'previewMaxSelectItems', 'previewMaxSelectItems', 'previewIsGiftCoin', 'previewIsTypeGrouped', 'previewSelectedGifts'
    ]),
    ...mapGetters('preview', ['previewCoinLeft']),
    isSelected () {
      return this.previewSelectedGifts.findIndex(product => product.id === this.item.id) !== -1
    },
    allAvailableSlotsSelected () {
      return (this.previewMaxSelectItems > 1 && !this.isSelected) && !this.previewIsGiftCoin ? this.previewMaxSelectItems <= this.previewSelectedGifts.length : false
    },
    notEnoughGiftCoins () {
      return this.previewIsGiftCoin && !this.isSelected ? this.previewCoinLeft <= +this.item.coins : false
    }
  },
  methods: {
    selectGift () {
      if (this.allAvailableSlotsSelected || this.notEnoughGiftCoins) return
      this.$emit('selectGift', this.item)
    },
    changeHandler (event, title, id, values, type) {
      const newMainImage = this.item.options.find(item => item.id === id).values.find(value => value.id === Number(event.target.value))?.image
      // change item image only for labels that are not related to size
      const shouldChangeItemImage = !['Size', 'Apparel Size'].includes(title)

      if (shouldChangeItemImage && newMainImage) {
        this.item.image = newMainImage
      }

      this.item.option = this.item.option.filter(item => item.title !== title)
      if (event.target.value.length !== 0) {
        const resOptionObj = {
          id,
          title,
          val: event.target.value,
          type
        }
        if (values) {
          Object.assign(resOptionObj, { valTitle: values.find(value => +value.id === +event.target.value).title })
        }
        this.item.option.push(resOptionObj)
      }
      if (this.item.option_combinations && this.item.options.length === 2) {
        const firstSelectValue = this.item.giftOptionsValues[`option${this.item.options[0].id}`]
        const listAvianbleOptions = this.item.option_combinations[`${firstSelectValue}`]
        this.item.options[1].values = this.initialValueSecondSelect.filter(item => listAvianbleOptions.includes(item.id))
      }
    }
  },
  created () {
    if (this.item.option_combinations && this.item.options.length === 2) {
      this.initialValueSecondSelect = this.item.options[1].values
    }
  }
}
</script>

<style lang='scss' scoped>
.gift-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // height: 100%;
  position: relative;
  border: 2px solid #ff3c0000;

  .select-icon{
    position: absolute;
    right: 5px;
    color: black;
  }

  .select-wrapper{
    position: relative;
  }
  &__information-block{
    height: 116px;
    overflow: hidden;
    &.virtual{
      height: 80px;
    }
  }
  &_checked-mark{
    width: 37px;
    min-height: 37px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &_img {
    height: 269px;
    max-width: 100%;
    object-fit: contain;

    @media screen and (min-width: 1024px) {
      height: 25vw;
      max-height: 269px;
    }
  }

  &_name {
    margin-top: 12px;
    margin-bottom: 20px;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    height: 32px;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  &_info {
    color: #AAAAAA;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .preview-gift-card-input{
    &__label{
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 10px;
      letter-spacing: 0;
    }
    &.is-invalid{
      background-color: #FBE7E9;
      border: 1px solid #FA5E5E;
    }
    &__main {
      input, select {
        width: 100%;
        height: 40px;
        padding: 9px 11px;
        background-color: #fff;
        border: 1px solid #D3D2D2;
        font-size: 15px;
        line-height: 22px;
        outline: none;
        &#state.is-invalid,
        &.is-invalid{
          background: #FBE7E9;
          border: 1px solid #FA5E5E;
        }
        &::placeholder{
          font-family: 'Lato-Italic';
          font-size: 15px;
          color: #979797;
        }
        &.disabled{
          background-color: #F4F4F4;
        }
        &.with-arrow {
          background-image: url('/public/images/select-arrow-icon.svg');
          background-size: 16px 16px;
          background-position: right 4px bottom 45%;
        }
      }

      select {
        padding: 8px 22px 8px 12px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        color: #FA5E5E;
      }
    }
  }
  .add-button {
    background: #000;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    margin-top: auto;
    border-radius: 0;
    transition: background-color 0.5s;
    letter-spacing: 0;
    &:hover{
      background-color: #919191 !important;
      transition: background-color 0.5s;
    }

    span {
      color: white;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      &.gift-coin{
        font-size: 13px;
        font-weight: 100;
      }
    }

    &.v-btn--has-bg.remove {
      background-color: white;
      border: 2px solid black;
      transition: all 0.5s;
      span {
        color: #000;
        transition: all 0.5s;
      }
      &:hover{
        background-color: white !important;
        border: 2px solid #919191;
        span {
          color: #919191 !important;
        }
      }
    }

    &.disabled {
      width: 100%;
      background-color: #C0C0C0 !important;
      color: #fff !important;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0;
    }
  }

  .gift-coin-value {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: none;

    strong {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      font-style: normal;
    }
  }

  .err-txt {
    height: 0;
  }

  .txt-field {
    width: 100%;
    height: 28px;
    border: 1px solid #d3d2d2;
    padding-left: 10px;
    outline: none;
  }
}

.not_available{
    &_label{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #f25b5b;
      color: #fff;
      width: 50%;
      display: flex;
      justify-content: center;
    }
    &_select{
      color: #f5f5f5 !important;
      border: solid 1px #f5f5f5 !important;
    }
    & .mdi-chevron-down::before {
      color: #c8c8c8;
    }
    & img{
      opacity: 0.4;
    }
  }

</style>

<style lang="scss">
#short-description ul{
  padding: 0;
}
</style>
