// import axios from 'axios'

export default {
  namespaced: true,

  state: () => ({
    token: undefined,
    refreshing: false,
    promises: [],
    showRestoreSession: false
  }),

  getters: {
    token (state) {
      return state.token
    }
  },

  mutations: {
    setToken (state, token) {
      const arr = token.split('|')
      state.token = arr[0] + '|' + arr[1]
      state.showRestoreSession = arr[2] === '1'
      state.refreshing = false
    },
    clearToken (state) {
      state.token = undefined
    },
    refreshToken (state) {
      state.refreshing = true
    },
    registerPromise (state, promise) {
      state.promises.push(promise)
    },
    clearPromises (state) {
      state.promises = []
    }
  },

  actions: {
    async refresh ({ state, commit, dispatch }, promise) {
      commit('registerPromise', promise)

      if (!state.refreshing) {
        commit('clearToken')
        await dispatch('get')

        if (state.token) {
          state.promises.forEach(promise => promise.resolve())
        } else {
          state.promises.forEach(promise => promise.reject())
        }

        return commit('clearPromises')
      }
    },

    async get ({ state, commit, dispatch }) {
      try {
        if (!state.token && !state.refreshing) {
          commit('refreshToken')
          // if (process.env.VUE_APP_TEST_MODE) {
          //   await dispatch('getPasswordToken')
          // } else {
          //   await dispatch('getSessionToken')
          // }

          if (!state.token) {
            dispatch('logout')
          }
        }

        return state.token
      } catch (error) {
        dispatch('logout')
        throw error
      }
    },

    async getSessionToken ({ commit, dispatch }) {
      // await axios.get('/customer-token/token', {
      //   baseURL: process.env.VUE_APP_MAGENTO_URL,
      //   withCredentials: true
      // })
      //   .then(response => {
      //     commit('setToken', response.data)
      //     return true
      //   })
      //   .catch(e => console.error(e))
    },

    async getPasswordToken ({ commit, dispatch }) {
      // await axios.post('/sanctum/token', {
      //   email: process.env.VUE_APP_TEST_EMAIL,
      //   password: process.env.VUE_APP_TEST_PASSWORD
      // }, {
      //   baseURL: process.env.VUE_APP_API_URL
      // })
      //   .then(response => {
      //     commit('setToken', response.data)
      //     return true
      //   })
      //   .catch(e => console.error(e))
    },

    logout ({ commit }) {
      commit('clearToken')
      // window.location.href = process.env.VUE_APP_MAGENTO_URL + '/customer/account/login'
    }
  }
}
