<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
    :style="`
      min-width: ${width}px;
      min-height: ${height}px;
    `"
  >
    <g id="info icon">
      <path
        id="Fill 1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0001 19.0221C5.02522 19.0221 0.977905 14.9746 0.977905 9.99988C0.977905 5.02509 5.02522 0.97788 10.0001 0.97788C14.975 0.97788 19.0221 5.02509 19.0221 9.99988C19.0221 14.9746 14.975 19.0221 10.0001 19.0221ZM10.0001 0C4.48618 0 0 4.48603 0 9.99988C0 15.5139 4.48618 20 10.0001 20C15.5141 20 20 15.5139 20 9.99988C20 4.48603 15.5141 0 10.0001 0Z"
        fill="#4A4A4A"
      />
      <path
        id="Fill 3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99998 8.27588C9.80964 8.27588 9.65515 8.49577 9.65515 8.7667V14.6816C9.65515 14.9525 9.80964 15.1724 9.99998 15.1724C10.1903 15.1724 10.3448 14.9525 10.3448 14.6816V8.7667C10.3448 8.49577 10.1905 8.27588 9.99998 8.27588Z"
        fill="#4A4A4A"
      />
      <path
        id="Fill 5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0024 6.20689C9.80753 6.20689 9.65523 6.05579 9.65523 5.86281C9.65523 5.66911 9.80792 5.51723 10.0024 5.51723C10.1911 5.51723 10.3449 5.6722 10.3449 5.86281C10.3449 6.05231 10.1911 6.20689 10.0024 6.20689ZM10.0021 4.82758C9.43026 4.82758 8.96558 5.29193 8.96558 5.8627C8.96558 6.43286 9.43026 6.89654 10.0021 6.89654C10.5712 6.89654 11.0345 6.43286 11.0345 5.8627C11.0345 5.29193 10.5712 4.82758 10.0021 4.82758Z"
        fill="#4A4A4A"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Info',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    }
  }
}
</script>
