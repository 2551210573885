export class ACPPaymentProcessor {
  static parseQueryString (str) {
    const vars = []
    const arr = str.split('&')
    let pair

    for (let i = 0; i < arr.length; i++) {
      pair = arr[i].split('=')
      vars[pair[0]] = unescape(pair[1])
    }

    return vars
  }

  static onReceivedMessage (payload) {
    const preparedResponse = this.parseQueryString(payload.qstr)

    if (!preparedResponse.response) {
      return
    }

    const response = JSON.parse(preparedResponse.response)
    this.fillPaymentForm(response)
  }

  static fillPaymentForm (data) {
    document.querySelector('#ahp_token_id').value = data.transId
  }
}
