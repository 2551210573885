<template>
  <div
    class="payment-form-checkbox"
    @click="checkboxValue = !checkboxValue"
  >
    <div
      class="d-flex payment-form-checkbox__checkbox"
      :class="value && 'payment-form-checkbox__checkbox--active'"
    >
    </div>
    <div class="d-flex payment-form-checkbox__content f15">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentFormCheckbox',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    checkboxValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-form-checkbox {
  cursor: pointer;
  font-family: 'Lato-Regular', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  &__checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #DEDEDE;
    background-color: #fff;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    &--active {
      border: none;
      background-color: #42B77A;

      &::after {
        content: ' ';
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  &__content {
    color: #000 !important;
    font-size: 15px;
  }
}
</style>
