<template>
  <div class="added-group__wrap">
    <CoinStatus
      :left="coinLeft"
    />
    <div class="added-group flex-column pa-4">
      <div
        v-if="!checkedModeSelectedGifts.length"
        class="empty-card">
      </div>
      <div v-else>
        <div
          v-for='(item, index) in checkedModeSelectedGifts'
          :key='item.id'
          class="not-empty-gift"
        >
          <e-gift-min-gift-card
            :item='item'
            @do="selectCard"
          />
          <v-divider
            v-if="index + 1 !== checkedModeSelectedGifts.length"
            class="my-3"
          />
        </div>
        <!-- <div
          v-for="n in selectedGifts.length"
          :key="n"
        >
          <div class="empty-card"></div>
          <v-divider
            v-if="n !== (Number(maxGiftNum) - selectedGifts.length)"
            class="my-3"
          />
        </div> -->
      </div>
      <common-button
        :disabled="!checkedModeSelectedGifts.length > 0 || (!this.multiMode ? this.coinLeft < 0 : false)"
        class="mt-6"
        width="100%"
        @click="$emit('addCards')"
      >
        Continue
      </common-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoinStatus from '../eGift/CoinStatus.vue'

export default {
  name: 'MultipleChoiceSelectedGifts',
  components: {
    CoinStatus
  },
  data: () => ({

  }),
  computed: {
    ...mapState('eGift', ['maxGiftNum', 'selectedGifts', 'multiMode', 'coinLeft', 'previewMode']),
    ...mapState('preview', ['previewSelectedGifts']),
    allGiftCoinsWereSpent () {
      return !this.multiMode ? this.coinLeft < 0 : false
    },
    checkedModeSelectedGifts () {
      return this.previewMode ? this.previewSelectedGifts : this.selectedGifts
    }
  },
  methods: {
    selectCard (item) {
      if (this.previewMode) {
        this.$emit('selectGift', item)
        return
      }
      this.$store.dispatch('eGift/selectCard', item)
    }
  }
}
</script>

<style scoped lang="scss">
.added-group{
  padding: 12px;
  max-height: 80vh;
  overflow: auto;
  background-color: #F5F5F5;
  &__header{
    background-color: rgba(223,243,242,1);
  }
  &__not-empty-gift{
    width: 100%;
  }
}

.empty-card {
  box-sizing: border-box;
  border: 1px dashed #aaa;
  width: 33%;
  height: 110px;
  @media screen and (min-width: 598px) {
    width: 30%;
    height: 80px;
  }
  @media screen and (min-width: 960px) {
    width: 30%;
    height: 50px;
  }
  @media screen and (min-width: 1262px) {
    width: 30%;
    height: 70px;
  }
  // @media screen and (min-width: 1263px) {
  // }
}
</style>
