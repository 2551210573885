<template>
  <div class="video">
    <v-container class="d-flex flex-column">
      <div class="video__header">
        <h1 class="hero-recipient black1 lato-light">Hi {{ recipientFirstName }}!</h1>
        <h5 class="egift-subtext">
          <template v-if="videoOpeningLine">{{ videoOpeningLine }}</template>
          <template v-else>
            You’ve received a gift from {{ sender }}! Check out their video greeting:
          </template>
        </h5>
      </div>
      <div class="video__main mt-sm-6 mt-md-12 d-flex flex-column align-center">
        <common-loader v-if="!videoUrlString.length" />
        <div v-if="videoUrlString.length" class="iframe-wrap col-12 col-md-10 d-flex flex-column align-center">
          <iframe
            :width="100 + '%'"
            :height="iFrameHeight"
            :src="videoUrlString"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />

          <common-button
            :width="pageWidth > 425 ? '350px' : '100%'"
            :height="40"
            class="my-8 pl-5 pr-4"
            @click="goToNext"
          >
            Continue to redeem your gift

            <template v-slot:rightIcon>
              <img src="/images/button-arrow.svg" alt="" class="ml-3">
            </template>
          </common-button>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapMutations } = createNamespacedHelpers('eGift')

export default {
  name: 'VideoPage',
  data: () => ({
    pageWidth: null
  }),
  computed: {
    ...mapState([
      'sender',
      'videoUrl',
      'multiMode',
      'maxGiftNum',
      'getGiftErrorStatus',
      'videoOpeningLine',
      'recipientFirstName'
    ]),
    videoUrlString () {
      const newUrl = new URL(this.videoUrl)
      if (this.videoUrl.includes('youtu')) {
        if (!newUrl.search) { return `https://www.youtube.com/embed${newUrl.pathname}` }

        function getYoutubeId (url) {
          const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
          const match = url.match(regExp)

          return (match && match[2].length === 11)
            ? match[2]
            : null
        }
        return `https://www.youtube.com/embed/${getYoutubeId(newUrl.href)}`
      }
      if (this.videoUrl.includes('vimeo')) {
        const array = this.videoUrl.split('/')
        return `https://player.vimeo.com/video/${array[array.length - 1]}`
      }
      if (this.videoUrl.includes('loom')) {
        const array = this.videoUrl.split('/')
        return `https://www.loom.com/embed/${array[array.length - 1]}`
      }

      if (this.videoUrl.includes('vidyard')) {
        const array = this.videoUrl.split('/')
        return `https://play.vidyard.com/${array.at(-1)}?disable_popouts=1&v=4.3.10&type=inline`
      }
      return ''
    },
    iFrameHeight () {
      return window.innerWidth > 768 ? 500 : 300
    }
  },
  methods: {
    ...mapMutations(['setVideoPassed', 'setSelectedGifts']),
    goToNext () {
      this.setVideoPassed(true)
      if (Number(this.maxGiftNum) > 1 || (!this.multiMode && !this.getGiftErrorStatus.length)) {
        this.setSelectedGifts([])
        this.$router.push({
          path: `/recipient/${this.getRecipientHash()}/gift/multiple-choices`
        })
        return
      }
      this.$router.push({
        path: `/recipient/${this.getRecipientHash()}/gift`
      })
    },
    getRecipientHash () {
      return window.location.pathname.replace('/recipient/', '').replace('/video', '')
    }
  },
  mounted () {
    this.pageWidth = window.innerWidth
  }
}
</script>

<style lang="scss" scoped>
.egift-subtext {
  font-size: 15px;
  line-height: 18px;
  font-family: 'Lato-Regular', sans-serif;
  font-weight: 400;
  color: #000;
}
</style>
