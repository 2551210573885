<template>
  <v-dialog
    v-model="show"
    :persistent="isUnavailable"
    max-width="475"
    max-height="425"
  >
    <v-card class="modal-content warning-modal pa-10 rounded-0">
      <v-icon v-if="!isUnavailable" @click="show = !show" class="close-icon">mdi-close</v-icon>
      <div>
        <img src="../../../public/images/img-cat.png">
        <h3 class="pt-8 text-left font-weight-medium">
          <template>
            We're sorry  the gift(s) {{ sender }} selected for you is/are no longer in stock
          </template>
        </h3>
      </div>
      <div v-if="canSelectAlternatives" class="d-flex pt-8">
        <common-button @click="updateGifts">
          Pick Another Gift
        </common-button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NotAvaibleGiftsModal',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    isUnavailable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show: false
  }),
  methods: {
    updateGifts () {
      this.$emit('loadingUpSellProducts')
      this.show = !this.show
    }
  },
  computed: {
    ...mapState('eGift',
      [
        'updateGiftsLink',
        'upgradable',
        'sender',
        'canSelectAlternatives'
      ]
    ),
    userCanExchangeGift () {
      return !!Number(this.upgradable) ?? false
    }
  },
  watch: {
    showDialog: {
      handler: function (val) {
        this.show = val
      },
      immediate: true
    },
    show (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
