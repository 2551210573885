<template>
  <div class="preview">
    <PreviewHeader @closePreview="closePreviewHandler" />
    <common-loader v-if="previewLoading" />
    <div
      v-if="!previewLoading"
      class="preview-pages-wrapper d-flex justify-center pt-0"
    >
      <v-btn
        x-large
        fab
        shaped
        :disabled="previewStep === 1"
        @click="setPreviewStep(previewStep - 1)"
        class="preview-pages__navigation-button mr-8"
      >
        <span class="left-arrow" :class="{ 'disabled': previewStep === 1 }" />
      </v-btn>

      <div class="preview-main mb-10" >
        <component :is="availablePages[previewStep - 1]" />
      </div>

      <v-btn
        x-large
        fab
        shaped
        :disabled="previewStep === availablePages.length"
        @click="setPreviewStep(previewStep + 1)"
        class="preview-pages__navigation-button ml-8"
      >
        <span class="right-arrow" :class="{ 'disabled': previewStep === availablePages.length }" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SuccessPreview from './../components/preview/steps/SuccessPreview.vue'
import PreviewHeader from './../components/preview/PreviewHeader.vue'
import EmailPreview from './../components/preview/steps/EmailPreview.vue'
import ProductSelectPreview from './../components/preview/steps/ProductSelectPreview.vue'
import ShippingFormPreview from './../components/preview/steps/ShippingFormPreview.vue'
import ThankYouNotePreview from './../components/preview/steps/ThankYouNotePreview'
import VideoPreview from './../components/preview/steps/VideoPreview.vue'
import SingleLinkCampaignPreview from './../components/preview/steps/SingleLinkCampaignPreview.vue'

export default {
  name: 'Preview',
  components: {
    EmailPreview,
    VideoPreview,
    PreviewHeader,
    SuccessPreview,
    ThankYouNotePreview,
    ShippingFormPreview,
    ProductSelectPreview,
    SingleLinkCampaignPreview
  },
  computed: {
    ...mapState('preview', [
      'previewLoading',
      'previewProducts',
      'previewStep',
      'previewVideoUrl',
      'hideEmailStep',
      'linkType',
      'skipThankYouStep'
    ]),
    availablePages () {
      const { hideEmailStep, previewVideoUrl, skipThankYouStep, linkType } = this
      const result = []

      if (['single_link_restricted', 'single_link_unrestricted'].includes(linkType)) {
        result.push('SingleLinkCampaignPreview')
      }

      if (!hideEmailStep || linkType === 'single_link_unrestricted') result.push('EmailPreview')
      if (previewVideoUrl) result.push('VideoPreview')

      result.push('ProductSelectPreview')
      result.push('ShippingFormPreview')
      if (!skipThankYouStep) result.push('ThankYouNotePreview')
      result.push('SuccessPreview')

      return result
    }
  },
  async created () {
    this.setPreviewMode(true)
    await this.$store.dispatch('preview/getPreviewData', { hash: this.getHashFromUrl(), params: this.$route.query })
  },
  updated () {
    this.$nextTick(function () {
      window.scrollTo(0, 0)
    })
  },
  methods: {
    ...mapMutations('eGift', ['setPreviewMode']),
    ...mapMutations('preview', ['setPreviewStep']),
    closePreviewHandler () {
      window.close()
    },
    getHashFromUrl () {
      const hash = window.location.pathname.replace('/recipient/preview/c/', '').replace('/gift', '')
      return hash
    }
  }
}
</script>

<style lang="scss" scoped>
.preview{
  .preview-main{
    width: 1200px;
    height: auto;
    box-shadow: 0 0 24px 0 rgba(0,0,0,0.21);
  }
  .preview-pages{
    &__navigation-button{
      background-color: rgba(145,145,145,1);
      position: sticky;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      span{
        position: relative;
        &.left-arrow{
          transform: rotate(180deg);
        }
        &.disabled{
          &:before, &:after{
            background-color: #6a6868;
          }
        }
        &:before, &:after{
          content: '';
          position: absolute;
          width: 17px;
          height: 3px;
          background-color: #fff;
        }
        &:before{
          top: 3px;
          left: -7px;
          transform: rotate(-45deg);
        }
        &:after{
          top: -7px;
          left: -7px;
          transform: rotate(45deg);
        }
      }
    }
  }
  &__header-description{
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    background-color:  rgba(252,235,164,1);
    z-index: 10;
  }
}
</style>
