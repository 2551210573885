<template>
  <div class='e-gift mt-3 px-xs-3 px-sm-5'>
    <common-loader v-if="loading"/>
    <v-container
      v-if="!loading && !redeemed"
      class='d-flex flex-column'
    >
      <div class="e-gift__header">
        <h1 class="hero-recipient black1 lato-light">{{welcomeMessage}},</h1>
      </div>
      <div class="mt-2 mx-0">
        <div v-if="upsellProducts.length">
          <SingleGift
            v-if="!loading && upsellProducts.length === 1"
            :gift="upsellProducts[0]"
            @selectCard="selectCard"
          />
          <FewGifts
            v-if="!loading && upsellProducts.length > 1"
            :gifts="upsellProducts"
            @selectCard="selectCard"
            @showProductDetailPanel="showProductDetailPanel"
          />
          <MoreOptions
            @openPanel="openPanel"
          />
        </div>
      </div>
      <ReGiftPanel
        :showPanel="showReGiftPanel"
        :gifts="upsellProducts"
        @close="showReGiftPanel = !showReGiftPanel"
      />
      <DeclinePanel
        :showPanel="showDeclineF"
        @close="showDeclineF = !showDeclineF"
        @openReGiftPopUp="showReGiftPanel = !showReGiftPanel"
      />
      <ProductDetailPanel
        :showPanel="showProductDetailPanelF"
        :id="showingProductId"
        @do='selectCard'
        @close="showProductDetailPanelF = !showProductDetailPanelF"
      />
      <UpdateGiftPanel
        v-model="showUpdateGiftsPanel"
      />
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import { mapMutations, mapState } from 'vuex'
import ReGiftPanel from '../components/eGift/ReGiftPanel.vue'
import DeclinePanel from '../components/eGift/DeclinePanel.vue'
import MoreOptions from '../components/eGift/MoreOptions.vue'
import SingleGift from '../components/eGift/SingleGift.vue'
import FewGifts from '../components/eGift/FewGifts.vue'
import ProductDetailPanel from '../components/eGift/ProductDetailPanel.vue'
import UpdateGiftPanel from '../components/updateGift/UpdateGiftPanel.vue'

Vue.use(VScrollLock)

export default {
  name: 'Egift',
  components: {
    ReGiftPanel,
    DeclinePanel,
    SingleGift,
    FewGifts,
    MoreOptions,
    ProductDetailPanel,
    UpdateGiftPanel
  },
  data () {
    return {
      imgPathPrefix: process.env.VUE_APP_MAGENTO_URL + '/media/catalog/product/',
      showReGiftPanel: false,
      showWarningF: false,
      showDeclineF: false,
      showProductDetailPanelF: false,
      showUpdateGiftsPanel: false,
      showingProductId: undefined,
      chatEle: null,
      expanded: false,
      showNotAvaibleGiftsModal: false
    }
  },
  methods: {
    ...mapMutations('eGift', ['setAllProductsInGiftAreAvailable', 'setSelectedGifts']),
    openPanel (flag) {
      this[flag] = !this[flag]
    },
    selectCard (item) {
      this.$store.dispatch('eGift/selectCard', item)
      this.goToNext()
    },
    addCards () {
      let unSelected = this.upsellProducts
      this.selectedGifts.forEach(gift => {
        unSelected = unSelected.filter(item => item.id !== gift.id)
      })
      this.showWarningF = (unSelected.filter(gift => gift.coins <= this.coinLeft).length > 0)
      if (!this.showWarningF) {
        this.goToNext()
      }
    },
    goToNext () {
      this.$router.push({
        name: 'Setting'
      })
    },
    chatNow () {
      // this.$zendesk.show()
    },
    strongEle () {
      let unSelected = this.upsellProducts
      this.selectedGifts.forEach(gift => {
        unSelected = unSelected.filter(item => item !== gift && item.coins <= this.coinLeft)
      })
      unSelected.forEach(item => {
        const card = document.getElementById('gift' + item.id)
        if (card !== null) {
          card.classList.add('strongCard')
        }
      })
      this.hideAfter = setTimeout(() => {
        unSelected.forEach(item => {
          const card = document.getElementById('gift' + item.id)
          if (card !== null) {
            card.classList.remove('strongCard')
          }
        })
      }, 2000)
    },
    getRecipientHash () {
      const hash = window.location.pathname.replace('/recipient/', '').replace('/gift', '')
      return hash
    },
    showmainGiftDetailPanel () {
      this.showProductDetailPanel(this.mainGift.id)
    },
    showProductDetailPanel (id) {
      this.showProductDetailPanelF = !this.showProductDetailPanelF
      this.showingProductId = id
    }
  },
  mounted () {
    this.setSelectedGifts([])
  },
  computed: {
    ...mapState('eGift', [
      'gifts', 'recipientHash',
      'sender', 'coinLeft', 'selectedGifts', 'contactNumber', 'recipientFirstName', 'recipientLastName', 'loading', 'redeemed', 'maxGiftNum', 'multiMode', 'multiSelectMode1', 'mainGift', 'detailPanelInfo', 'loadingProductDetail', 'upsellProducts', 'updateGiftsLink', 'welcomeMessage', 'allProductsInGiftAreAvailable'
    ])
  },
  watch: {
    showWarningF (val) {
      if (!val) {
        this.strongEle()
      }
    }
  }
}
</script>

<style lang="scss">
.e-gift {
  .slider__images-list{
    img{
      box-sizing: border-box;
      border: solid 1px#E4E4E4;
      width: 15%;
      &.active{
        box-sizing: border-box;
        border: solid 3px #42B77A
      }
    }
  }
  .black-border-button {
    border: 2px solid black;
    border-radius: 0;
    color: black;
    font-size: 12px;
  }
  .more-options__cards_item{
    border: solid 8px#F1F1F1;
  }
  .more-options__cards{
    &_text{
      width: 100%;
    }
    &_img-block{
      width: 100%;
      button{
        width: 50%;
      }
    }
  }
  .page-logo {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: 12px;
  }

  .page-helper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  .hero-recipient {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 120%;

    @media screen and (min-width: 768px) {
      font-size: 45px;
      font-weight: 300;
    }
  }

  .hero-desc {
    font-weight: 300;
    font-size: 15px;

    @media screen and (min-width: 768px) {
      font-weight: 400;
    }
  }

  .default-gift-wrapper {
    padding-right: 46px;
  }

  .selected-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 262px;
    height: 262px;
    margin-right: 37px;

    img {
      max-width: 262px;
      max-height: 262px;
      object-fit: contain;
    }
  }

  .added-group {
    background-color: #F5F5F5;
    padding: 12px;
    max-height: 80vh;
    overflow: auto;
  }

  .empty-card {
    width: 40%;
    border: 1px dashed #aaa;
    height: 100px;
  }

  .mobile-mode {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: none;

    .min-wrapper {
      display: flex;
      width: 100%;
      height: fit-content;
      align-items: flex-end;
      transition-property: all;
      transition-duration: 0.5s;
      background: #3330;

      &.expanded {
        height: 100vh;
        background: #3335;
      }

      .v-expansion-panel-header {
        padding: 0;

        .v-expansion-panel-header__icon {
          position: absolute;
          top: -20px;
          left: calc(50% - 30px);
          background-color: #dff3f2;
          border-radius: 50%;
          transform: rotate(180deg);

          .v-icon {
            font-size: 40px;
            margin-top: 10px;
            padding: 0 10px;
          }
        }
      }
    }
  }

  .win-mode {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .win-mode {
      display: block;
    }

    .mobile-mode {
      display: none;
    }
  }
}

.more-option-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 218px;
  width: 290px;
  border: 8px solid #F1F1F1;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (min-width: 768px) {
    display: none;
  }

  &_title {
    color: #000000;
    font-family: "OpenSans-Bold";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  &_desc {
    color: #000000;
    font-family: 'Lato-Regular';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  }

  &_btn {
    width: 167px;
    height: 33px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-transform: uppercase !important;
    border: 2px solid black !important;
    border-radius: 0   !important;
    font-size: 11px !important;
    color: black !important;
    font-family: 'Lato-Bold' !important;
    font-weight: 700 !important;
    margin-top: 10px !important;
    background-color: white !important;
  }

  p {
    margin-bottom: 10px !important;
  }
}

.min-gifts-wrapper {
  max-height: 60vh;
  overflow: auto;
}
</style>
