<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7625 14.0422C10.3242 14.0422 9.96918 14.392 9.96918 14.824C9.96918 15.256 10.3242
        15.6067 10.7625 15.6067C11.2008 15.6067 11.5558 15.256 11.5558 14.824C11.5558 14.392
        11.2008 14.0422 10.7625 14.0422M10.7625 5.82879C9.12584 5.82879 7.78584 7.14937 7.78584
        8.76232C7.78168 9.08589 8.04418 9.3528 8.37334 9.35691C8.70168 9.36183 8.97168 9.10314
        8.97668 8.77874C8.97668 8.77299 8.97668 8.76807 8.97668 8.76232C8.97668 7.78338 9.76918
        7.00236 10.7625 7.00236C11.7558 7.00236 12.5483 7.78338 12.5483 8.76232C12.5483 9.37087
        12.2992 9.78642 11.8908 10.2102C11.4825 10.6348 10.9167 11.0183 10.3908 11.433C10.2492
        11.5439 10.1675 11.7131 10.1675 11.8913V12.6731C10.1625 12.9975 10.425 13.2636 10.7542
        13.2685C11.0825 13.2727 11.3533 13.014 11.3575 12.6896C11.3575 12.6846 11.3575 12.6789
        11.3575 12.6731V12.1779C11.7917 11.8519 12.3008 11.493 12.7592 11.0175C13.2933 10.4623
        13.7392 9.71415 13.7392 8.76232C13.7392 7.14937 12.3992 5.82879 10.7625 5.82879M10.7625
        3.09154C15.0433 3.09154 18.5008 6.49894 18.5008 10.7177C18.5008 14.9365 15.0433 18.3439
        10.7625 18.3439C6.48168 18.3439 3.02418 14.9365 3.02418 10.7177C3.02418 6.49894 6.48168
        3.09154 10.7625 3.09154M10.7625 1.91797C5.83834 1.91797 1.83334 5.86493 1.83334
        10.7177C1.83334 15.5705 5.83834 19.5175 10.7625 19.5175C15.6867 19.5175 19.6917 15.5705
        19.6917 10.7177C19.6917 5.86493 15.6867 1.91797 10.7625 1.91797"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'Question',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 22
    },
    height: {
      type: [Number, String],
      required: false,
      default: 21
    }
  }
}
</script>
