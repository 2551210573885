import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

import TokenStore from './token.store'
import EGiftStore from './eGift.store'
import PreviewStore from './preview.store'

Vue.use(Vuex, VueAxios, axios)

export default new Vuex.Store({
  modules: {
    token: TokenStore,
    eGift: EGiftStore,
    preview: PreviewStore
  },
  state: {
    buttonColor: null
  },
  mutations: {
    setButtonColor (state, val = null) {
      state.buttonColor = val
    }
  }
})
