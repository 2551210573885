<template>
  <v-navigation-drawer
    v-model="show"
    app
    temporary
    right
    width="900px"
    class="upgrade-panel"
    stateless
    v-scroll-lock="!$vuetify.breakpoint.mobile && show"
  >
    <v-alert class="upgrade-panel__success ma-0 py-3" tile v-if="step === 4">
      <template #prepend>
        <v-img
          alt="green check icon"
          src="/images/green-check.svg"
          width="20"
          max-width="20"
          height="20"
          max-height="20"
        />
      </template>
      eGift upgraded successfully
    </v-alert>

    <close-panel-button
      @close="tryClosePanel()"
      :text="(step === 1 || step === 4) ? 'Close' : 'Back'"
    />

    <common-loader v-if="loading" />

    <template v-else>
      <div class="px-4 px-md-13 py-7 py-md-10" v-if="[1, 2].includes(step)">
        <ProductSelect
          v-if="step === 1"
          :items="availableItems"
          @selectItem="selectProduct"
          @showProductDetails="showProductDetails"
        />

        <ShippingForm
          v-if="step === 2"
          :selectedItem="selectedItem"
          :userAddress="shippingAddress"
          @goToPreviousStep="step -= 1"
          @goToNextStep="step += 1"
        />
      </div>
      <PaymentForm
        v-if="step === 3"
        :selectedItem="selectedItem"
        :userAddress="shippingAddress"
        :billingAddress="billingAddress"
        :termsOfUseAccepted.sync="termsOfUseAccepted"
        :billingSameAsAddress.sync="billingSameAsAddress"
        :blueSnapToken.sync="blueSnapToken"
        @clearBilling="Object.assign($data.billingAddress, $options.data().billingAddress)"
        @goToPreviousStep="step -= 1"
        @goToNextStep="sendUpgradeInfo()"
      />

      <ThankYouNote
        v-if="step === 4"
        :message.sync="message"
        :color.sync="messageColor"
        :messageSender.sync="messageSender"
        @sendThankYouNote="sendThankYouNote()"
        @skipThankYouNote="skipThankYouNote()"
      />

      <div class="f15 error--text pa-3 pa-mb-9 pb-0" v-if="errors">
        <span>
          Errors:
        </span>
        <ul class="pl-2">
          <li v-for="(error, index) in errors" :key="index">
            - {{ error }}
          </li>
        </ul>
      </div>
    </template>

    <ProductDetailPanel
      :showPanel="showProductDetailPanel"
      style="height: unset"
      :id="detailPanelProductId"
      :selectedGift="detailPanelProduct"
      showPrice
      @do="selectCard"
      @close="showProductDetailPanel = false"
    />
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import { mapState } from 'vuex'

import ProductSelect from './steps/ProductSelect.vue'
import ShippingForm from './steps/ShippingForm.vue'
import PaymentForm from './steps/PaymentForm.vue'
import ThankYouNote from './steps/ThankYouNote.vue'
import ProductDetailPanel from '../eGift/ProductDetailPanel.vue'

Vue.use(VScrollLock)

export default {
  name: 'UpdateGiftPanel',
  components: {
    ProductSelect,
    ShippingForm,
    PaymentForm,
    ThankYouNote,
    ProductDetailPanel
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    loading: false,
    errors: null,
    showProductDetailPanel: false,
    detailPanelProductId: null,
    detailPanelProduct: null,
    step: 1,
    availableItems: [],
    selectedItem: null,
    shippingAddress: {
      first_name: null,
      last_name: null,
      email: null,
      address: null,
      address_apt: null,
      zip: null,
      city: null,
      state: null,
      telephone: null
    },
    billingSameAsAddress: true,
    billingAddress: {
      first_name: null,
      last_name: null,
      email: null,
      address: null,
      address_apt: null,
      zip: null,
      city: null,
      state: null,
      telephone: null
    },
    blueSnapToken: null,
    message: 'Thank you for your gift!',
    messageColor: null,
    messageSender: null,
    termsOfUseAccepted: false
  }),
  computed: {
    ...mapState('eGift', [
      'recipientHash', 'preEmail', 'recipientFirstName', 'recipientLastName'
    ]),
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loading = true
        Api.get('/recipient/' + this.recipientHash + '/gift/upgrade').then(({ data }) => {
          this.availableItems = data.sort((a, b) => Number(a.price) - Number(b.price))
          this.shippingAddress.first_name = this.recipientFirstName
          this.shippingAddress.last_name = this.recipientLastName
          this.shippingAddress.email = this.preEmail
          this.loading = false
        })
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    },
    step: function (val) {
      this.errors = null
      this.scrollToTop()

      if (val === 4) {
        let result = ''
        const { first_name: firstName, last_name: lastName } = this.shippingAddress
        if (firstName) result += `${firstName} `
        if (lastName) result += lastName
        this.messageSender = result
      }
    }
  },
  methods: {
    tryClosePanel () {
      if (this.step === 1) {
        this.show = false
      } else if (this.step === 4) {
        this.$router.push({ name: 'Success' })
        this.show = false
      } else {
        this.step -= 1
      }
    },
    scrollToTop () {
      this.$el.querySelector('.v-navigation-drawer__content')
        .scrollTo({ top: 0, behavior: 'smooth' })
    },
    selectProduct (gift) {
      this.selectedItem = gift
      this.step = 2
    },
    showProductDetails (gift) {
      this.detailPanelProductId = gift.id
      this.detailPanelProduct = gift
      this.showProductDetailPanel = true
    },
    selectCard (gift) {
      const selectedOptions = {}
      for (const [key, value] of Object.entries(gift.giftOptionsValues)) {
        selectedOptions[key.replace(/option/g, '')] = value
      }
      gift.selectedOptions = selectedOptions
      this.selectProduct(gift)
    },
    sendUpgradeInfo () {
      this.loading = true
      this.errors = null
      const { shippingAddress, billingAddress, blueSnapToken, message, selectedItem } = this

      const selectedItems = []
      selectedItems.push({
        id: selectedItem.id,
        options: Object.entries(selectedItem.selectedOptions || {}).map((option) => ({
          [option[0]]: option[1]
        }))
      })

      Api.post(`/recipient/${this.recipientHash}/gift/upgrade`, {
        products: selectedItems,
        shipping_address: shippingAddress,
        billing_address: billingAddress,
        bluesnap_token: blueSnapToken,
        thank_note_message: message
      }).then((res) => {
        if (res.success) {
          this.$store.commit('eGift/setSuccessNum', res.increment_id)
          this.$store.commit('eGift/setSelectedGifts', [selectedItem])
          this.step += 1
        } else {
          this.errors = res?.message ? [res?.message] : ['']
        }

        this.loading = false
      }).catch(({ response: { data: { errors, message } } }) => {
        this.loading = false
        this.errors = errors ? Object.values(errors)?.map(error => error[0]) : [message]
      })
    },
    sendThankYouNote () {
      this.loading = true
      this.errors = null

      Api.post(`/recipient/${this.recipientHash}/thankyounotes`, {
        note: this.message,
        color: this.messageColor,
        sender: this.messageSender
      }).then(() => {
        this.loading = false
        this.$router.push({ name: 'Success' })
      }).catch(({ response: { data: { errors, message } } }) => {
        this.loading = false
        this.errors = errors ? Object.values(errors)?.map(error => error[0]) : [message]
      })
    },
    skipThankYouNote () {
      this.$router.push({ name: 'Success' })
    }
  }
}
</script>

<style lang="scss">
.upgrade-panel {
  .v-btn {
    &__content {
      font-family: 'Lato-Bold', sans-serif !important;
      font-size: 15px;
    }

    &:not(&--outlined):hover {
      background: #919191 !important;
    }

    &:is(&--outlined):hover {
      color: #919191 !important;
      border-color: #919191 !important;
    }

    &--disabled {
      background: #C0C0C0 !important;
      color: #fff !important;
    }
  }

  .theme--light.v-btn.v-btn--disabled {
    background: #C0C0C0 !important;
    color: #fff !important;
  }

  input {
    &::placeholder {
      font-family: 'Lato-Italic', sans-serif !important;
      font-weight: normal !important;
      font-size: 15px !important;
      line-height: 18px !important;
      color: #979797 !important;
    }
  }

  .error--text {
    .v-input__slot {
      background-color: #FBE7E9 !important;
    }
    fieldset {
      border-color: #FA5E5E !important;
    }
  }

  &__success {
    background: #D8F1E4 !important;
    .v-alert {
      &__wrapper {
        gap: 16px
      }
      &__content {
        font-size: 15px;
        font-family: 'Lato-Bold', sans-serif !important;
        color: hsla(220, 4%, 14%, 1);
      }
    }
  }
}
</style>
