<template>
  <v-form ref="contact-form">
    <v-row no-gutters class="d-flex flex-column">
      <label for="full-name">
        Full name
      </label>
      <div class="d-flex">
        <v-col cols="6" class="pa-0">
          <TheContactInfoInput
            v-model="firstName"
            id="first_name"
            placeholder="First Name"
          />
        </v-col>
        <v-col cols="6" class="pa-0">
          <TheContactInfoInput
            v-model="lastName"
            id="last_name"
            placeholder="Last Name"
            :append-outer-icon="addEmptySpaceInInputAppendOuterIcon"
          />
        </v-col>
      </div>

      <div class="label-wrapper">
        <label for="email">
          Email
        </label>
        <TheContactInfoFormPopper :iconSize="15" v-if="userIsUsingMobileDevice">
          We'll send you order confirmation here.
        </TheContactInfoFormPopper>
      </div>
      <v-col cols="12">
        <TheContactInfoInput
          v-model="email"
          id="email"
          type="email"
          email
          placeholder="Email"
          :append-outer-icon="addEmptySpaceInInputAppendOuterIcon"
        >
          <template #append-outer v-if="!userIsUsingMobileDevice">
            <TheContactInfoFormPopper>
              We'll send you order confirmation here.
            </TheContactInfoFormPopper>
          </template>
        </TheContactInfoInput>
      </v-col>

      <label for="street-address">
        Street address
      </label>
      <v-col cols="12" class="d-flex">
        <common-cg-validation
          rules="required"
          :custom-messages="{
            required: 'This field is required'
          }"
          style="flex: 1 1 auto"
        >
          <vue-google-autocomplete
            id="address"
            class="required v-google-autocomplete"
            placeholder="House number and street name"
            v-model="address"
            v-on:placechanged="getAddressData"
            ref="address"
            :country="countryList"
            @blur="addressBlurHandler"
          />
        </common-cg-validation>

        <TheContactInfoInput
          v-model="addressApt"
          hideRules
          id="address_apt"
          placeholder="APT/Suite"
          style="max-width: 130px"
          :append-outer-icon="addEmptySpaceInInputAppendOuterIcon"
        />
      </v-col>

      <label for="postal-code">
        ZIP code
      </label>
      <v-col cols="12">
        <TheContactInfoInput
          v-model="zip"
          id="zip"
          placeholder="ZIP Code"
          :append-outer-icon="addEmptySpaceInInputAppendOuterIcon"
        />
      </v-col>

      <label for="city">
        City
      </label>
      <v-col cols="12">
        <TheContactInfoInput
          v-model="city"
          id="city"
          placeholder="City"
          :append-outer-icon="addEmptySpaceInInputAppendOuterIcon"
        />
      </v-col>

      <label for="country">
        State/Region
      </label>
      <v-col cols="12" :class="!userIsUsingMobileDevice && 'pr-8'">
        <region-select
          id="state"
          ref="stateSelect"
          placeholder="State"
          v-model="state"
          regionName
          className="region-attr-select-form v-google-autocomplete"
          :region="state"
          country="US"
        />
      </v-col>

      <div class="label-wrapper">
      <label for="telephone">
        Mobile phone
      </label>
        <TheContactInfoFormPopper
          :iconSize="15"
          :maxWidth="300"
          v-if="userIsUsingMobileDevice"
        >
          It is rare, but in some cases, we may need the recipient’s phone number in order to complete delivery.
        </TheContactInfoFormPopper>
      </div>
      <v-col cols="12">
        <TheContactInfoInput
          v-model="telephone"
          id="telephone"
          placeholder="(123) 456-7890"
          :append-outer-icon="addEmptySpaceInInputAppendOuterIcon"
        >
          <template #append-outer v-if="!userIsUsingMobileDevice">
            <TheContactInfoFormPopper :maxWidth="300">
              It is rare, but in some cases, we may need the recipient’s phone number in order to complete delivery.
            </TheContactInfoFormPopper>
          </template>
        </TheContactInfoInput>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import TheContactInfoInput from './TheContactInfoInput.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import TheContactInfoFormPopper from './TheContactInfoFormPopper.vue'

import { mapState } from 'vuex'

export default {
  name: 'ContactInfoForm',
  components: {
    TheContactInfoInput,
    VueGoogleAutocomplete,
    TheContactInfoFormPopper
  },
  props: {
    userFirstName: {
      type: String,
      required: false,
      default: null
    },
    userLastName: {
      type: String,
      required: false,
      default: null
    },
    userEmail: {
      type: String,
      required: false,
      default: null
    },
    userAddress: {
      type: String,
      required: false,
      default: null
    },
    userAddressApt: {
      type: String,
      required: false,
      default: null
    },
    userZip: {
      type: String,
      required: false,
      default: null
    },
    userCity: {
      type: String,
      required: false,
      default: null
    },
    userState: {
      type: String,
      required: false,
      default: null
    },
    userTelephone: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapState('eGift', [
      'giftInternationalShipping', 'showCanada'
    ]),
    userIsUsingMobileDevice () {
      return this.$vuetify.breakpoint.mobile
    },
    addEmptySpaceInInputAppendOuterIcon () {
      return !this.userIsUsingMobileDevice ? ' ' : null
    },
    countryList () {
      if (!this.giftInternationalShipping && !this.showCanada) {
        return ['US']
      } else if (!this.giftInternationalShipping && this.showCanada) {
        return ['US', 'CA']
      } else {
        return null
      }
    },
    firstName: {
      get () {
        return this.userFirstName
      },
      set (val) {
        this.$emit('update:userFirstName', val)
      }
    },
    lastName: {
      get () {
        return this.userLastName
      },
      set (val) {
        this.$emit('update:userLastName', val)
      }
    },
    email: {
      get () {
        return this.userEmail
      },
      set (val) {
        this.$emit('update:userEmail', val)
      }
    },
    address: {
      get () {
        return this.userAddress
      },
      set (val) {
        this.$emit('update:userAddress', val)
      }
    },
    addressApt: {
      get () {
        return this.userAddressApt
      },
      set (val) {
        this.$emit('update:userAddressApt', val)
      }
    },
    zip: {
      get () {
        return this.userZip
      },
      set (val) {
        this.$emit('update:userZip', val)
      }
    },
    city: {
      get () {
        return this.userCity
      },
      set (val) {
        this.$emit('update:userCity', val)
      }
    },
    state: {
      get () {
        return this.userState
      },
      set (val) {
        this.$emit('update:userState', val)
      }
    },
    telephone: {
      get () {
        return this.userTelephone
      },
      set (val) {
        this.$emit('update:userTelephone', val)
      }
    }
  },
  methods: {
    addressBlurHandler () {
      this.address = this.$refs.address._vnode.data.domProps.value
      this.$refs.address.update(this.$refs.address._vnode.data.domProps.value)
    },
    getAddressData (newAddress) {
      this.address = `${newAddress.street_number ? newAddress.street_number : ''} ${newAddress.route}`
      this.zip = newAddress.postal_code
      this.city = newAddress.locality
      this.state = newAddress.administrative_area_level_1
      document.getElementById('address').value = this.address
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-size: 15px;
  color: #000;
  font-family: 'Lato-Bold', sans-serif;
  margin-top: 14px;
}

.label-wrapper {
  margin-top: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1px;

  & > label {
    margin-top: 0 !important;
  }
}

.v-google-autocomplete {
  border-radius: 0;
  border: 1px solid #dbdbdb;
  width: 100%;
  height: 30px;
  padding: 0 12px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: 'Lato-Bold';
    font-size: 13px;
    line-height: 16px;
    color: #a1a1a1;
    font-style: italic;
  }
}
select {
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
}
</style>
