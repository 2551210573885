<template>
  <v-container class="already-redeemed">
    <h1>This eGift is expired</h1>
    <img class="mt-12" src="../assets/no-gift-icon.png" alt="Gift Icon">
  </v-container>
</template>

<script>
export default {
  name: 'AlreadyExpired'
}
</script>

<style lang="scss" scoped>
  .already-redeemed{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
      font-family: 'Lato-Light';
      font-size: 42px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 50px;
      @media screen and (min-width: 960px) {
        line-height: 60px;
      }
    text-align: center;
    }
    img{
      width: 100px;
    }
  }
</style>
