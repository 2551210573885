<template>
  <div class="preview-header d-flex flex-column">
    <div class="preview-header__header-description d-flex justify-center align-center">
      <h3 class="ma-0 mr-2 lato-bold font-italic">eGift preview</h3>
      <p class="ma-0 lato-italic">Click inside the screens to preview your recipient experience</p>
      <div class="preview-header__close close-block d-flex align-center mr-6">
        <p class="ma-0 lato-italic mr-4">exit preview</p>
        <span
          @click="closeHandler"
        />
      </div>
    </div>
    <div class="preview-navigation mt-7 mb-4 d-flex flex-column align-center justify-center">
      <div class="preview-navigation__items-wrapper d-flex mb-4">
        <div
          v-for="(item, index) in availableSteps"
          :key="index"
          @click="setPreviewStep(index + 1)"
          :class="[
            previewStep === index + 1 ? 'active' : '',
            index + 1 !== 6 ? 'mr-4' : '',
            'preview-navigation__item'
          ]"
        />
      </div>
      <p class="ma-0 lato-italic navigation-text">{{ navigationText }}</p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'PreviewHeader',
  computed: {
    ...mapState(
      'preview',
      [
        'previewVideoUrl',
        'previewStep',
        'hideEmailStep',
        'skipThankYouStep',
        'linkType'
      ]
    ),
    navigationText () {
      return this.availableSteps[this.previewStep - 1].text
    },
    availableSteps () {
      const { hideEmailStep, previewVideoUrl, skipThankYouStep, linkType } = this
      const steps = []

      const unrestrictedGiftLink = linkType === 'single_link_unrestricted'

      if (['single_link_restricted', 'single_link_unrestricted'].includes(linkType)) {
        steps.push({ id: 1, text: 'Get your gift', show: true })
      }
      steps.push({
        id: unrestrictedGiftLink ? 2 : 1,
        text: 'eGift notification email',
        show: !hideEmailStep || unrestrictedGiftLink
      })
      steps.push({
        id: unrestrictedGiftLink ? 3 : 2,
        text: 'Video greeting',
        show: !!previewVideoUrl
      })
      steps.push({ id: unrestrictedGiftLink ? 3 : 4, text: 'Select gift(s)', show: true })
      steps.push({ id: unrestrictedGiftLink ? 3 : 5, text: 'Shipping address', show: true })
      steps.push({
        id: unrestrictedGiftLink ? 3 : 6,
        text: 'Thank you note',
        show: !skipThankYouStep
      })
      steps.push({ id: unrestrictedGiftLink ? 3 : 7, text: 'Order complete', show: true })

      return steps.filter((step) => step.show)
    }
  },
  methods: {
    ...mapMutations('eGift', ['setPreviewMode']),
    ...mapMutations('preview', ['setPreviewStep']),
    closeHandler () {
      this.setPreviewMode(false)
      this.$emit('closePreview')
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-header{
  background: #454343;
  position: sticky;
  top: 0;
  margin: 0 -20px;
  padding: 0 20px;
  z-index: 10;
  &__header-description{
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    background-color:  rgba(252,235,164,1);
    z-index: 10;
  }
  &__close{
    position: absolute;
    right: 0;
    cursor: pointer;
    span{
      height: 30px;
      width: 30px;
      position: relative;
      &:before, &:after{
        content: '';
        position: absolute;
        width: 35px;
        height: 3px;
        background-color: rgba(0, 0, 0, 0.87);
      }
      &:before{
        top: 13px;
        transform: rotate(-45deg);
      }
      &:after{
        top: 13px;
        transform: rotate(45deg);
      }
    }
  }
  .preview-navigation{
    &__title{
      height: 40px;
      width: 150px;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 20px;
      border: solid 1px #fff;
      color: #fff;
    }
    &__item{
      height: 20px;
      width: 20px;
      border: 2px solid rgba(255,255,255,1);
      border-radius: 50%;
      cursor: pointer;
      &.active{
        background-color: #fff;
      }
    }
    .navigation-text{
      color: #fff;
    }
  }
}
</style>
