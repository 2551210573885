<template>
  <div class="product-select d-flex flex-column">
    <PreviewPageHeader />
    <div :class="[previewIsTypeGrouped ? 'mb-8' : '', 'product-select__header col-12 pa-0 mt-4 px-16']">
      <h1
        :class="[previewItemsCount === 1 ? 'ml-0' : 'ml-6', 'font-weight-light']"
      >Hi {{ previewFirstname }},</h1>
      <p
        v-if="!(previewMaxSelectItems > 1 || previewIsGiftCoin) && !previewIsTypeGrouped"
        :class="[previewItemsCount === 1 ? 'ml-0' : 'ml-6', 'lato-bold mt-2 mb-0']"
      >{{ previewChooseText }}</p>
      <p
        v-if="previewIsTypeGrouped"
        :class="[previewItemsCount === 1 ? 'ml-0' : 'ml-6', 'mt-2 mb-0 ml-6']"
      >
        Enjoy <span class="lato-bold">your</span> gift bundle:
      </p>
    </div>

    <h5
      v-if="redeemPageTitle && previewMaxSelectItems === 1 && !previewIsGiftCoin && previewItemsCount === 1"
      v-html="redeemPageTitle"
      class="px-16 f20 black1 lato-light"
      :class="previewItemsCount === 1 ? 'ml-0' : 'ml-6'"
    />

    <div v-if="previewProducts.length === 1" class="product-select__main col-12 px-16">
      <PreviewSingleGift
        :gift="previewProducts[0]"
      />
    </div>
    <div
      v-else
      :class="[previewIsTypeGrouped ? 'product-select__grooped-gift' : '', 'product-select__main col-12 pa-0 d-flex px-16']"
    >
      <div
        :class="[previewMaxSelectItems > 1 || previewIsGiftCoin ? 'col-9' : 'col-12', 'gifts-wrap d-flex flex-wrap']"
      >

        <template v-if="redeemPageTitle">
          <p
            class="mt-2 mb-0 ml-3"
            v-html="redeemPageTitle"
          />
        </template>

        <template v-else>
          <p
            v-if="previewMaxSelectItems > 1"
            class="mt-2 mb-0 ml-3"
          >
            Select <span class="lato-bold">{{ previewMaxSelectItems }}</span> items that fancy you, click <span class="lato-bold">continue</span> when you’re done:
          </p>
          <p
            v-else-if="previewIsGiftCoin"
            class="mt-2 mb-0 ml-3"
          >
            You've received 100 GiftCoin to spend. Choose items below:
          </p>
        </template>

        <div class="gifts-wrap d-flex flex-wrap pa-0">
          <PreviewGiftCard
            v-for="product in filteredProducts"
            :key="product.id"
            :item="product"
            :class="[previewMaxSelectItems > 1 || previewIsGiftCoin ? 'col-4' : 'col-3']"
            @selectGift="previewSelectGift"
          />
        </div>
        <div
          v-if="previewIsTypeGrouped"
          class="grooped-gifts__button d-flex justify-center mb-8 col-12 pa-0"
        >
          <Popper
            v-if="!allPreviewOptionsAreSelected"
            class="mt-auto"
            :options="{
              placement: 'top',
              modifiers: { offset: { offset: '0,10px' } }
            }">
            <div class="popper text-left px-4 py-4">
              You must select all required attributes for gifts in this bundle
            </div>
            <div
              class="mt-auto product-card_product-info_greet-msg d-flex"
              slot="reference"
            >
              <common-button width="220">
                Get your gift
              </common-button>
            </div>
          </Popper>

          <common-button
            v-else
            width="220"
            @do="$store.commit('preview/setPreviewStep', previewStep + 1)"
          >
            Get your gift
          </common-button>
        </div>
      </div>
      <div
        v-if="previewMaxSelectItems > 1 || previewIsGiftCoin"
        class="sidebar-wrap flex-wrap col-3 py-0"
      >
        <MultipleChoiceSelectedGifts
          class="col-12"
          @selectGift="previewSelectGift"
          @addCards="$store.commit('preview/setPreviewStep', previewStep + 1)"
        />
        <ContactInfoCard
          class="col-12 col-sm-6 col-md-12"
        />
      </div>
    </div>
    <div class="px-16">
      <v-container>
        <PreviewMoreOptions />
      </v-container>
      <common-footer/>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import PreviewGiftCard from '../PreviewGiftCard.vue'
import MultipleChoiceSelectedGifts from './../../eGift/MultipleChoiceSelectedGifts.vue'
import ContactInfoCard from './../../eGift/ContactInfoCard.vue'
import PreviewPageHeader from './../PreviewPageHeader.vue'
import PreviewSingleGift from './../PreviewSingleGift.vue'
import PreviewMoreOptions from './../PreviewMoreOptions.vue'
import Popper from 'vue-popperjs'

export default {
  name: 'ProductSelect',
  components: {
    PreviewPageHeader,
    PreviewGiftCard,
    ContactInfoCard,
    MultipleChoiceSelectedGifts,
    PreviewSingleGift,
    PreviewMoreOptions,
    Popper
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState(
      'preview',
      [
        'previewProducts',
        'previewMaxSelectItems',
        'previewChooseText',
        'previewFirstname',
        'previewItemsCount',
        'previewIsGiftCoin',
        'redeemPageTitle',
        'previewIsTypeGrouped',
        'previewSelectedGifts',
        'previewStep',
        'linkType'
      ]
    ),
    filteredProducts () {
      return this.previewProducts?.filter((item) => Number(item.available)) ?? []
    },
    allPreviewOptionsAreSelected () {
      const arrayWithOptions = this.previewProducts.filter(gift => Number(gift.available) === 1)
        .filter(gift => gift.options.length)
        .map(gift => Object.values(gift.giftOptionsValues)).flat()
        .filter(option => !option.length)
      return !arrayWithOptions.length
    }
  },
  methods: {
    ...mapMutations('preview', ['addItemToPreviewSelectedGifts']),
    previewSelectGift (gift) {
      this.addItemToPreviewSelectedGifts(gift)

      this.handleSingleLinkCampaign()
    },
    handleSingleLinkCampaign () {
      if (this.previewSelectedGifts.length) {
        const { previewStep, linkType } = this
        const isSingleLinkCampaign = ['single_link_restricted', 'single_link_unrestricted']
          .includes(linkType)

        if (isSingleLinkCampaign) { this.$store.commit('preview/setPreviewStep', previewStep + 1) }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-select{
  background-color: #fff;
  &__grooped-gift{
    background-color: #f6f6f6;
  }
}
</style>
