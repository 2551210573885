import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, max } from 'vee-validate/dist/rules'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// No message specified.
extend('email', {
  ...email,
  message: 'Email address is invalid'
})

// Override the default message.
extend('required', {
  ...required,
  message: 'This field is a required field.'
})

// Override the default message.
extend('max', {
  ...max,
  message: 'The character length is too long.'
})

extend('poBox', {
  message: 'Address must not specify a P.O. Box',
  validate: value => {
    const poBox = /\bP(ost|ostal)?([ /.]*(O|0)(ffice)?)?([ /.]*Box)\b/i
    return !value.match(poBox)
  }
})

extend('militaryAddress', {
  message: 'We cannot ship to Military Addresses',
  validate: value => {
    const militaryAddress = /^([af][\s.]?[p][\s.]?[o0])+$/gi
    return !value.match(militaryAddress)
  }
})
