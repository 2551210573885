import Api from '../axios/api'
import getCookie from '@/utils/getCookie'
import setCookie from '@/utils/setCookie'

export default {
  namespaced: true,

  state: () => ({
    previewStep: 1,
    previewCompanyHash: '',
    previewLoading: false,
    previewCalendlyLink: null,
    previewFreeFormLink: null,
    previewFreeFormButton: null,
    previewCanDecline: false,
    previewCanRegift: false,
    previewCanUpgradeEgift: false,
    previewChooseText: '',
    previewEmail: '',
    previewFirstname: '',
    previewGiftId: '',
    previewHideGiftInRedeem: false,
    previewId: '',
    previewIsAvailable: false,
    previewIsGiftCoin: false,
    previewItemsCount: null,
    previewLastname: '',
    previewLogoUrl: '',
    previewMaxSelectItems: null,
    previewProducts: [],
    previewRecipientFaqId: '',
    previewSender: null,
    previewUpgradeUrl: '',
    previewVideoUrl: '',
    videoOpeningLine: null,
    previewWelcomeMessage: '',
    previewDetailPanelInfo: {},
    previewGreetingMessage: '',
    previewEmailSubjectLine: '',
    previewEmailCoverImage: null,
    previewIsTypeGrouped: false,
    hideEmailStep: false,
    skipThankYouStep: false,
    previewSelectedGifts: [],
    previewAnimatedGiftUrl: '',
    redeemPageTitle: null,
    respondToText: null,
    linkType: null,
    linkTypeMessage: null,
    customFooter: null
  }),
  getters: {
    defaultPreviewSelectedGifts (state) {
      if (state.previewItemsCount === 1) {
        return [state.previewProducts[0]]
      }
      if (state.previewMaxSelectItems === 1 && !state.previewIsGiftCoin && !state.previewIsTypeGrouped) {
        return [state.previewProducts[0]]
      }
      if (state.previewMaxSelectItems > 1 && !state.previewIsGiftCoin && !state.previewIsTypeGrouped) {
        return state.previewProducts.slice(0, state.previewMaxSelectItems)
      }
      if (state.previewIsGiftCoin) {
        const newArray = []
        state.previewProducts.reduce((sum, item) => {
          if (sum - +item.coins > 0) {
            newArray.push(item)
          }
          return sum - item.coins
        }, 100)
        return newArray
      }
      return state.previewProducts
    },
    actualPreviewSelectedGifts (state, getters) {
      return state.previewSelectedGifts.length ? state.previewSelectedGifts : getters.defaultPreviewSelectedGifts
    },
    previewCoinLeft (state) {
      if (state.previewIsGiftCoin) {
        return 100 - state.previewSelectedGifts.reduce((sum, gift) => sum + +gift.coins, 0)
      }
      return 100
    }
  },
  mutations: {
    setPreviewStep (state, data) {
      state.previewStep = data
    },
    setPreviewCompanyHash (state, data) {
      state.previewCompanyHash = data
    },
    setPreviewLoading (state, data) {
      state.previewLoading = data
    },
    setPreviewCalendlyLink (state, data) {
      state.previewCalendlyLink = data
    },
    setPreviewFreeFormLink (state, data = null) {
      state.previewFreeFormLink = data
    },
    setPreviewFreeFormButton (state, data = null) {
      state.previewFreeFormButton = data
    },
    setPreviewCanDecline (state, data) {
      state.previewCanDecline = data
    },
    setPreviewCanRegift (state, data) {
      state.previewCanRegift = data
    },
    setPreviewCanUpgradeEgift (state, data) {
      state.previewCanUpgradeEgift = data
    },
    setPreviewChooseText (state, data) {
      state.previewChooseText = data
    },
    setPreviewEmail (state, data) {
      state.previewEmail = data
    },
    setPreviewFirstname (state, data) {
      state.previewFirstname = data
    },
    setPreviewGiftId (state, data) {
      state.previewGiftId = data
    },
    setPreviewHideGiftInRedeem (state, data) {
      state.previewHideGiftInRedeem = data
    },
    setPreviewId (state, data) {
      state.previewId = data
    },
    setPreviewIsAvailable (state, data) {
      state.previewIsAvailable = data
    },
    setPreviewIsGiftCoin (state, data) {
      state.previewIsGiftCoin = data
    },
    setPreviewItemsCount (state, data) {
      state.previewItemsCount = data
    },
    setPreviewLastname (state, data) {
      state.previewLastname = data
    },
    setPreviewLogoUrl (state, data) {
      state.previewLogoUrl = data
    },
    setPreviewMaxSelectItems (state, data) {
      state.previewMaxSelectItems = data
    },
    setPreviewProducts (state, data) {
      state.previewProducts = data
        .filter((item) => !item?.hidden)
        .map(item => ({
          ...item,
          option: [],
          initialMainImageLink: item.image,
          giftOptionsValues: Object.assign({}, ...item.options.map((item) => ({ [`option${item.id}`]: '' })))
        }))
    },
    setPreviewRecipientFaqId (state, data) {
      state.previewRecipientFaqId = data
    },
    setPreviewSender (state, data) {
      state.previewSender = data
    },
    setPreviewUpgradeUrl (state, data) {
      state.previewUpgradeUrl = data
    },
    setVideoOpeningLine (state, data = null) {
      state.videoOpeningLine = data
    },
    setPreviewVideoUrl (state, data) {
      state.previewVideoUrl = data
    },
    setPreviewWelcomeMessage (state, data) {
      state.previewWelcomeMessage = data
    },
    setPreviewDetailPanelInfo (state, data) {
      state.previewDetailPanelInfo = data
    },
    setPreviewGreetingMessage (state, data) {
      state.previewGreetingMessage = data
    },
    setPreviewEmailSubjectLine (state, data) {
      state.previewEmailSubjectLine = data
    },
    setPreviewEmailCoverImage (state, data) {
      state.previewEmailCoverImage = data
    },
    setPreviewIsTypeGrouped (state, data) {
      state.previewIsTypeGrouped = data
    },
    setPreviewSelectedGifts (state, data) {
      state.previewSelectedGifts = data
    },
    setPreviewAnimatedGiftUrl (state, data) {
      state.previewAnimatedGiftUrl = data
    },
    setHideEmailStep (state, data) {
      state.hideEmailStep = data
    },
    setSkipThankYouStep (state, data = false) {
      state.skipThankYouStep = data ?? false
    },
    addItemToPreviewSelectedGifts (state, product) {
      const giftIndex = state.previewSelectedGifts.findIndex(item => item.id === product.id)
      if (state.previewMaxSelectItems === 1 && !state.previewIsGiftCoin) {
        state.previewSelectedGifts = []
      }
      if (giftIndex === -1) {
        state.previewSelectedGifts.push(product)
      }
      if (giftIndex !== -1) {
        state.previewSelectedGifts.splice(giftIndex, 1)
      }
    },
    setRedeemPageTitle (state, data = null) {
      state.redeemPageTitle = data
    },
    setRespondToText (state, data = null) {
      state.respondToText = data
    },
    setPreviewLinkType (state, data) {
      state.linkType = data
    },
    setPreviewLinkTypeMessage (state, data) {
      state.linkTypeMessage = data
    },
    setCustomFooter (state, data = null) {
      state.customFooter = data
    }
  },
  actions: {
    async getPreviewData ({ commit }, { hash, params }) {
      commit('setPreviewLoading', true)
      const marketplaceLink = params.marketplace === 'true'
      const checkedURL = marketplaceLink ? 'shared/egift/preview' : `/recipient/preview/c/${hash}/gift`
      if (marketplaceLink) {
        delete params.marketplace
        params.product_id = +atob(hash)
      }
      if (!marketplaceLink) {
        commit('setPreviewCompanyHash', hash)
      }

      const cgToken = getCookie('cgToken')
      const productsToExclude = getCookie('products_to_exclude')
      const headers = {}

      if (productsToExclude) {
        params.products_to_exclude = productsToExclude
        setCookie('products_to_exclude', null, 0)
      }

      if (cgToken) { headers.Authorization = `Bearer ${cgToken}` }

      Api.post(checkedURL, null, { params, headers })
        .then(({ data }) => {
          commit('setPreviewCalendlyLink', data.calendly_link)
          commit('setPreviewCanDecline', data.can_decline)
          commit('setPreviewCanRegift', !!Number(params?.can_regift))
          commit('setPreviewCanUpgradeEgift', !!Number(params?.can_upgrade))
          commit('setPreviewChooseText', data.choose_text)
          commit('setPreviewEmail', data.email)
          commit('setPreviewFirstname', data.firstname)
          commit('setPreviewGiftId', data.gift_id)
          commit('setPreviewHideGiftInRedeem', data.hide_gift_in_redeem)
          commit('setPreviewId', data.id)
          commit('setPreviewIsAvailable', data.is_available)
          commit('setPreviewIsGiftCoin', data.is_gift_coin)
          commit('setPreviewItemsCount', data.items_count)
          commit('setPreviewLastname', data.lastname)
          commit('setPreviewLogoUrl', data.logo_url)
          commit('setPreviewMaxSelectItems', data.max_select_items)
          commit('setPreviewProducts', data.products)
          commit('setPreviewSender', data.sender)
          commit('setPreviewUpgradeUrl', data.upgrade_url)
          commit('setPreviewVideoUrl', data.video_url)
          commit('setVideoOpeningLine', data?.video_greeting_opening_line)
          commit('setPreviewWelcomeMessage', data.welcome_message)
          commit('setPreviewGreetingMessage', data.greeting_message)
          commit('setPreviewEmailSubjectLine', data.email_subject_line)
          commit('setPreviewEmailCoverImage', data.email_cover_image)
          commit('setPreviewIsTypeGrouped', data.is_type_grouped)
          commit('setPreviewAnimatedGiftUrl', data.animated_gift_url)
          commit('setHideEmailStep', params?.hide_email_step === 'true')
          commit('setSkipThankYouStep', data?.skip_thankyou_message)
          commit('setPreviewFreeFormLink', data?.free_form_url)
          commit('setPreviewFreeFormButton', data?.free_form_button)
          commit('setPreviewLinkType', params?.link_type)
          commit('setPreviewLinkTypeMessage', params?.welcome_message)
          commit('setRedeemPageTitle', data?.redeem_page_title)
          commit('setRespondToText', data?.respond_to)

          if (data?.gift_claim_button_color) {
            commit('setButtonColor', data?.gift_claim_button_color, { root: true })
          }
          if (data?.custom_footer) {
            commit('setCustomFooter', data?.custom_footer)
          }
        })
        .finally(() => {
          commit('setPreviewLoading', false)
        })
        // greeting_message
    },
    getSingleGiftData ({ commit }, id) {
      Api.get(`/recipient/preview/details/${id}`)
        .then(res => {
          commit('setPreviewDetailPanelInfo', res.data)
        })
        .catch(err => {
          console.log(err)
          commit('setPreviewDetailPanelInfo', undefined)
        })
    }
  }
}
