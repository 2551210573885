<template>
  <v-container
    class="pa-0"
  >
    <div class="multi-gift">
      <template v-if="!redeemPageTitle">
        <h5
          v-if="((!allProductsInGiftAreAvailable || upsellProducts.length) || multiMode) && !groupedGift"
          class="f20 black1 lato-light px-4 px-md-0"
        >
          Please choose one of the following options:
        </h5>
        <h5
          v-else
          class="f20 black1 lato-light px-4 px-md-0">
          {{ chooseText }}
        </h5>
      </template>

      <div class="row align-stretch pt-6 pt-md-12 px-4 px-md-0">
        <GiftCard
          v-for="(item, index) in filteredGifts"
          :key="index"
          class="col-md-3 col-sm-6"
          :item="item"
          @do="selectCard"
          @showProductDetailPanel="showProductDetailPanel"
        />
      </div>
      <div
        v-if="groupedGift"
        class="grooped-gifts__button d-flex justify-center pb-10"
      >
        <Popper
          v-if="!allOptionsAreSelected"
          class="mt-auto"
          :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0,10px' } }
          }">
          <div class="popper text-left px-4 py-4">
            You must select all required attributes for gifts in this bundle
          </div>
          <div
            class="mt-auto product-card_product-info_greet-msg d-flex"
            slot="reference"
          >
            <common-button
              :width="220"
              :height="40"
              @click="selectGroupedGift"
            >
              Continue
            </common-button>
          </div>
        </Popper>
        <common-button
          v-else
          :width="220"
          :height="40"
          @click="selectGroupedGift"
        >
          Continue
        </common-button>
      </div>
    </div>
  </v-container>
</template>

<script>
import GiftCard from './GiftCard.vue'
import { mapState } from 'vuex'
import Popper from 'vue-popperjs'

export default {
  name: 'FewGifts',
  components: {
    GiftCard,
    Popper
  },
  props: {
    gifts: {
      type: Array
    }
  },
  computed: {
    ...mapState('eGift', [
      'chooseText',
      'upsellProducts',
      'allProductsInGiftAreAvailable',
      'multiMode',
      'groupedGift',
      'redeemPageTitle'
    ]),
    filteredGifts () {
      const filteredProducts = this.gifts
        .filter((gift) => Number(gift.available))
        .filter((gift) => !gift?.hidden)
      if (!filteredProducts.length) return this.gifts
      return filteredProducts
    },
    allOptionsAreSelected () {
      const arrayWithOptions = this.gifts
        .filter(gift => Number(gift.available) === 1)
        .filter(gift => gift.options.length)
        .reduce((acc, item) => {
          const { options, giftOptionsValues: values } = item
          const selectedValues = options.every(({ id }) => !!values[`option${id}`])
          acc.push(selectedValues)
          return acc
        }, [])

      return arrayWithOptions.every((item) => item)
    }
  },
  methods: {
    selectCard (item) {
      this.$emit('selectCard', item)
    },
    showProductDetailPanel (id) {
      this.$emit('showProductDetailPanel', id)
    },
    selectGroupedGift () {
      if (!this.allOptionsAreSelected) return
      this.$emit('selectGroupedGift')
    }
  }
}
</script>

<style lang="scss" scoped>
.grooped-gifts{
  &__button{
    width: 100%
  }
}
</style>
