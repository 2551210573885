<template>
  <v-navigation-drawer
    v-model="show"
    app
    temporary
    right
    width="900px"
    stateless
    v-scroll-lock="!$vuetify.breakpoint.mobile && show"
  >
    <close-panel-button
      @close="show = false"
      text="Close"
    />

    <common-loader v-if="loading" />

    <v-container :class="!$vuetify.breakpoint.mobile && 'px-9 py-0'" v-else>
      <v-row no-gutters>
        <v-col cols="12" class="lato-light f42 black1 pt-10">
          <span class="lato-bold">
            Thank You
          </span>
          replies ideas
        </v-col>

        <v-col cols="12" class="lato-bold f18 black1 pt-10">
          Choose a template. You can edit it later:
        </v-col>

        <v-col cols="12" class="thank-you-ideas pt-6">
          <div
            v-for="(message, index) in thankYouIdeas"
            :key="index"
            @click="changeThankYouNote(message.name)"
            class="thank-you-ideas__message"
            :style="{ background: randomColor(index) }"
          >
            {{ message.name }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'

Vue.use(VScrollLock)

export default {
  name: 'ThankYouIdeasPanel',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    thankYouIdeas () {
      return this.$store.state.eGift.thanksNotes[0]?.notes || []
    }
  },
  watch: {
    show: function (val) {
      if (val && !this.thankYouIdeas.length) {
        this.loading = true
        this.$store.dispatch('eGift/getThankyounotes')
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  methods: {
    randomColor (index) {
      const colors = ['#FBE7E9', '#FCEBA4', '#DFDDF0', '#B6D8CD', '#D1F5F3', '#E5EBE9']
      const colorIndex = Math.floor(Math.abs(Math.cos(index)) * colors.length)
      return colors[colorIndex]
    },
    changeThankYouNote (newMessage) {
      this.$emit('changeThankYouNote', newMessage)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.thank-you-ideas {
  columns: 1;
  column-gap: 12px;
  max-width: 820px;

  @media (min-width: 1000px) {
    columns: 3 255px;
  }

  &__message {
    width: 100%;
    display: inline-block;
    border-radius: 4px;
    margin: 0 12px 12px 0;
    padding: 18px;
    cursor: pointer;
    word-break: break-word;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    color: #000;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
