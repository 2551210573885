<template>
  <v-container class="already-used-gift">
    <h1>Hi {{ recipientFirstName }},<br>{{ usedGiftMessage }}...</h1>
    <img class="mt-12" src="../assets/no-gift-icon.png" alt="Gift Icon">
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AlreadyUsedGift',
  computed: {
    ...mapState('eGift', ['recipientFirstName', 'usedGiftMessage'])
  }
}
</script>

<style lang="scss" scoped>
.already-used-gift{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1{
    max-width: 500px;
    font-family: 'Lato-Light';
    font-size: 42px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 50px;
    @media screen and (max-width: 768px) {
      max-width: 300px;
    }
    @media screen and (min-width: 960px) {
      line-height: 60px;
    }
  text-align: center;
  }
  img{
    width: 100px;
  }
}
</style>
