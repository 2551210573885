<template>
  <div class="gift-card mb-11" :id="'gift'+item.id" :class="{ not_available: !Number(item.available) }">
    <div v-if="!Number(item.available)" class="not_available_label ma-3 pa-2">
      <h3 class="font-weight-light f16">Out of stock</h3>
    </div>
    <div class="gift-card_img-wrapper pa-3 d-flex justify-center">
      <img v-if="isSelected()" class="gift-card_checked-mark ma-3" src="/images/selected_gift.svg" alt="">
      <img
        :src="(item.image && !item.image.includes('no_selection')) ? item.image : '/images/empty.png'"
        class="gift-card_img"
      />
    </div>
    <div
      :class="[item.is_virtual ? 'virtual' : '', 'gift-card__information-block']"
    >
      <div class="gift-card_name">
        <span>{{item.name}}</span>
      </div>
      <div class="gift-card_info" id="short-description" v-html="item.short_description">
      </div>
    </div>
    <div
      v-if="((!allAvailableSlotsSelected && !allGiftCoinsWereSpent)|| isSelected()) && Number(item.available)"
      class="green3 pointer f14 mt-2 mb-4"
    >
      <span
        @click="showProductDetailPanel"
      >Read more</span>
    </div>
    <ValidationObserver
      ref="optionValidation"
      v-slot="{ handleSubmit }"
      :class="[!(((!allAvailableSlotsSelected && !allGiftCoinsWereSpent)|| isSelected()) && Number(item.available)) ? 'without-read-me' : '']"
    >
      <form
        :id="item.id"
        class="option-group"
        @submit.prevent="handleSubmit(getGift)"
      >
        <div
          v-if="item.options !== undefined && item.options !== [] && fieldOptions.length > 0"
        >
          <div
            class="item-selector"
            v-for="option in fieldOptions"
            :key="option.id"
          >
            <div class="gift-card-input__item">
              <div class="gift-card-input__main">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="getOptionValidation(option)"
                  :custom-messages="getOptionValidationMessages(option)"
                >
                  <input
                    type="text"
                    v-model="item.giftOptionsValues[`option${option.id}`]"
                    :name="'option['+item.id+']['+option.id+']'"
                    :id="'option-'+option.id"
                    :disabled="(!Number(item.available) || allAvailableSlotsSelected || allGiftCoinsWereSpent)"
                    :class="[errors.length ? 'is-invalid' : 'is-valid', (!Number(item.available) || allAvailableSlotsSelected || allGiftCoinsWereSpent) ? 'disabled' : '', 'gift-card-input txt-field mb-2']"
                    :placeholder="option.title"
                    :v-validate="{ excluded: [''] }"
                    @change="changeHandler($event, option.title, option.id, null, option.type)"
                  />
                  <p
                    v-if="errors.length"
                    class="lato-italic f14 mb-2"
                  >{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-wrap"
          v-if="item.options !== undefined && item.options !== [] && dropdownOptions.length > 0"
        >
          <div
            class="item-selector pb-1"
            v-for="(option, index) in dropdownOptions"
            :class="[dropdownOptions.length === (2 || 4) ? 'w50' : 'w100']"
            :key="option.id"
          >
            <div class="gift-card-input__item">
              <div class="gift-card-input__main">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="+option.required ? 'required' : ''"
                  :custom-messages="{
                    required: `${option.title} is a required field.`
                  }"
                >
                  <select
                    v-if="option.values !== []"
                    :class="[(!Number(item.available) || allAvailableSlotsSelected || allGiftCoinsWereSpent)
                    || (groupedGift && item.option_combinations && index === 1 && !item.giftOptionsValues[`option${item.options[0].id}`].toString().length) ? 'disabled' : '', errors.length ? 'is-invalid' : 'is-valid', 'gift-card-input select-wrapper select-attribute with-arrow mb-2']"
                    :disabled="(!Number(item.available) || allAvailableSlotsSelected || allGiftCoinsWereSpent)
                    || (groupedGift && item.option_combinations && index === 1 && !item.giftOptionsValues[`option${item.options[0].id}`].toString().length)"
                    :name="'option['+item.id+']['+option.id+']'"
                    :id="'option-'+option.id"
                    :v-validate="{ excluded: [''] }"
                    @change="changeHandler($event, option.title, option.id, option.values, option.type)"
                    v-model="item.giftOptionsValues[`option${option.id}`]"
                  >
                    <option value="" disabled selected>{{option.title}}</option>
                    <option
                      v-for="value in option.values"
                      :key="value.id"
                      :value="value.id"
                      :disabled="!value.is_available"
                    >
                      {{ value.title }} {{ !value.is_available ? '(out of stock)' : '' }}
                    </option>
                  </select>
                  <p
                    v-if="errors.length"
                    class="lato-italic f14 mb-2"
                  >{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>

        <div v-if="additionalOptions.length" class="d-flex flex-wrap">
          <div
            v-for="(option, index) in additionalOptions"
            :key="option.id"
            class="item-selector pb-1 w100"
          >
            <div class="gift-card-input__item">
              <div class="gift-card-input__main">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="getOptionValidation(option)"
                  :custom-messages="getOptionValidationMessages(option)"
                >
                  <select
                    v-if="option.type === 'drop_down'"
                    @change="changeHandler($event, option.title, option.id, option.values, option.type)"
                    v-model="option.val"
                    :disabled="!Number(item.available)"
                    :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input select-attribute with-arrow col-12']"
                    :name="'option['+item.id+']['+option.id+']'"
                    :id="'option-'+option.id + index"
                  >
                    <option value="" disabled selected>{{ option.title }}</option>
                    <option
                      v-for="value in getOptionValues(option, index)"
                      :key="value.id"
                      :value="value.id"
                    >
                      {{ value.title }}
                    </option>
                  </select>

                  <input
                    v-else
                    type="text"
                    :name="'option['+item.id+']['+option.id+']'"
                    :id="'option-'+option.id"
                    v-model="option.val"
                    :disabled="!Number(item.available)"
                    :placeholder="option.title"
                    :class="[errors.length ? 'is-invalid' : 'is-valid', 'shipping-input']"
                    @change="changeHandler($event, option.title, option.id, null, option.type, option.options)"
                  />

                  <p v-if="errors.length" class="lato-italic f14 mb-2">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div
      v-if="!groupedGift"
      class="gift-card__button mt-auto"
    >
      <popper
        v-if="showErrTxt.length && isSelected() === false"
        class="mt-auto"
        :options="{
          placement: 'top',
          modifiers: { offset: { offset: '0,10px' } }
        }">
        <div class="popper text-left px-4 py-4">
          {{showErrTxt}}
        </div>
        <!-- Not Selected gift -->
        <div
          class="mt-auto product-card_product-info_greet-msg d-flex"
          slot="reference"
        >
          <common-button
            class="add-button"
            :disabled="(!Number(item.available) || allAvailableSlotsSelected || allGiftCoinsWereSpent || item.coins > coinLeft)"
            :class="(!Number(item.available) || allAvailableSlotsSelected || allGiftCoinsWereSpent || item.coins > coinLeft) ? 'disabled' : ''"
            @click="addCard"
          >
          <!-- || (!this.multiMode ? this.coinLeft < 0 : false) -->
            <span v-if="!Number(item.available)">Out of stock</span>
            <template v-else>
              <span v-if="!multiMode" class="gift-coin-value f13">
                <strong>{{item.coins}}</strong>
                <v-icon>chevron-right</v-icon>
                <span class="f13 gift-coin"> GiftCoin</span>
              </span>
              <span v-if="!multiMode">|</span>
              <span class="text-uppercase">get this gift</span>
            </template>
          </common-button>
        </div>
      </popper>
      <!-- Selected gift -->
      <common-button
        v-else
        class="add-button text--white"
        :class="[((!Number(item.available) || allAvailableSlotsSelected || allGiftCoinsWereSpent) && !isSelected()) ? 'disabled' : '']"
        @click="addCard"
      >
        <span v-if="!Number(item.available)">Out of stock</span>
        <template v-else>
          <span
            v-if="!multiMode"
            class="gift-coin-value"
          >
            <strong>{{item.coins}}</strong>
            <span class="f13 gift-coin"> GiftCoin</span>
          </span>
          <span v-if="!multiMode">|</span>
          <span
            class="text-uppercase"
          >{{isSelected() ? 'remove' : 'get this gift'}}</span>
        </template>
      </common-button>
    </div>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import { mapGetters, mapState } from 'vuex'

import optionValidation from '@/mixins/optionValidation'

export default {
  name: 'GiftCard',
  mixins: [optionValidation],
  components: {
    popper: Popper
  },
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    showErrTxt: '',
    optionValues: {},
    initialValueSecondSelect: null
  }),
  computed: {
    ...mapState('eGift', [
      'selectedGifts', 'coinLeft', 'multiMode', 'maxGiftNum', 'groupedGift'
    ]),
    ...mapGetters('eGift', ['allAvailableSlotsSelected', 'allGiftCoinsWereSpent']),
    fieldOptions () {
      return this.item?.options?.filter(option => option.type === 'field') ?? []
    },
    dropdownOptions () {
      return this.item?.options?.filter(option => option.type === 'drop_down') ?? []
    },
    additionalOptions () {
      return this.item.option.reduce((acc, option) => {
        acc.push(...option.childOptions)
        return acc
      }, [])
    }
  },
  methods: {
    showProductDetailPanel () {
      if (((!this.allAvailableSlotsSelected && !this.allGiftCoinsWereSpent) || this.isSelected()) && Number(this.item.available)) {
        this.$emit('showProductDetailPanel', this.item.id)
      }
    },
    isSelected () {
      return this.selectedGifts.filter(gift => gift.id === this.item.id).length > 0
    },
    changeHandler (event, title, id, values, type) {
      const newMainImage = this.item.options.find(item => item.id === id)?.values?.find(value => value.id === Number(event.target.value))?.image
      // change item image only for labels that are not related to size
      const shouldChangeItemImage = !['Size', 'Apparel Size'].includes(title)

      if (shouldChangeItemImage) {
        if (newMainImage && !newMainImage.includes('no_selection')) {
          this.item.image = newMainImage
        }
      }

      if (!this.item.image) {
        this.item.image = this.item.initialMainImageLink
      }

      this.item.option = this.item.option.filter(item => item.title !== title)
      if (event.target.value.length !== 0) {
        const selectedValue = event.target.value
        const childOptions = values?.find((value) => value.id === +selectedValue)?.options ?? []

        const resOptionObj = {
          id,
          title,
          val: selectedValue,
          type,
          childOptions
        }
        if (values) {
          Object.assign(resOptionObj, { valTitle: values.find(value => +value.id === +selectedValue).title })
        }
        this.item.option.push(resOptionObj)
      }
      if (this.item.option_combinations && this.item.options.length === 2) {
        const firstSelectValue = this.item.giftOptionsValues[`option${this.item.options[0].id}`]
        const listAvianbleOptions = this.item.option_combinations[`${firstSelectValue}`]
        this.item.options[1].values = this.initialValueSecondSelect.filter(item => listAvianbleOptions.includes(item.id))
      }
    },
    async addCard () {
      if (!Number(this.item.available)) return
      if (!await this.$refs.optionValidation.validate()) return
      if (this.isSelected()) {
        Object.keys(this.item.giftOptionsValues).forEach(item => {
          this.item.giftOptionsValues[item] = ''
        })
        this.item.image = this.item.initialMainImageLink
        this.$nextTick(() => {
          this.$refs.optionValidation.reset()
        })
      }
      if ((this.coinLeft >= this.item.coins || this.isSelected()) || !this.allAvailableSlotsSelected || !this.allGiftCoinsWereSpent) {
        this.$emit('do', this.item)
      }
    }
  },
  watch: {
    coinLeft (val) {
      this.showErrTxt = (val < this.item.coins && !this.isSelected())
        ? (this.multiMode ? 'You’ve selected ' + this.maxGiftNum + ' items already. If you want to add this item, remove one of your selected items' : 'You don’t have enough giftCoins for this gift. If you want to add this item, remove one of your selected items')
        : ''
    },
    'item.giftOptionsValues': {
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          this.$refs.optionValidation.reset()
        })
      },
      deep: true
    },
    'item.option': {
      handler: function (val, oldVal) {
        this.item.option.forEach((option) => {
          this.optionValues[`option${option.id}Value`] = option.val
        })
      },
      deep: true
    }
  },
  created () {
    this.showErrTxt = (this.coinLeft < this.item.coins && !this.isSelected() && !this.allGiftCoinsWereSpent)
      ? 'You don’t have enough giftCoins for this gift. If you want to add this item, remove one of your selected items'
      : ''
    if (this.multiMode) {
      this.item.coins = 1
    }
    if (this.item.option_combinations && this.item.options.length === 2) {
      this.initialValueSecondSelect = this.item.options[1].values
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // height: 100%;
  position: relative;
  border: 2px solid #ff3c0000;

  .select-icon{
    position: absolute;
    right: 5px;
    color: black;
  }

  .select-wrapper{
    position: relative;
  }
  &__information-block{
    height: 116px;
    overflow: hidden;
    &.virtual{
      height: 80px;
    }
  }
  &_checked-mark{
    width: 37px;
    min-height: 37px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &_img {
    height: 269px;
    max-width: 100%;
    object-fit: contain;

    @media screen and (min-width: 960px) {
      height: 15vw;
      max-height: 269px;
    }

    &-wrapper{
      background-color: #fff;
    }
  }

  &_name {
    margin-top: 12px;
    margin-bottom: 20px;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    height: 32px;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  &_info {
    color: #AAAAAA;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .item-selector {
    margin-bottom: 10px;

    label {
      color: #959595;
      font-weight: 400;
      margin-bottom: 0;
    }

    .select-attribute {
      padding: 8px 22px 8px 12px;
      font-weight: 400 !important;
      font-style: normal !important;
      color: #000 !important;
      width: 100%;
      border-radius: 0;
      height: 40px;
      // background-color: #fff;
      // border: 1px solid #d3d2d2;
      font-size: 14px;
      font-family: "Lato", Verdana, Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      &.with-arrow {
        background-image: url('/public/images/select-arrow-icon.svg');
        background-size: 16px 16px;
        background-position: right 4px bottom 45%;
        background-repeat: no-repeat;
      }
    }
  }

  .add-button {
    background: #000;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    margin-top: auto;
    border-radius: 0;
    transition: background-color 0.5s;
    letter-spacing: 0;
    &:hover{
      background-color: #919191 !important;
      transition: background-color 0.5s;
    }

    span {
      color: white;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      &.gift-coin{
        font-size: 13px;
        font-weight: 100;
      }
    }

    &.v-btn--has-bg.remove {
      background-color: white;
      border: 2px solid black;
      transition: all 0.5s;
      span {
        color: #000;
        transition: all 0.5s;
      }
      &:hover{
        background-color: white !important;
        border: 2px solid #919191;
        span {
          color: #919191 !important;
        }
      }
    }

    &.disabled {
      width: 100%;
      background-color: #C0C0C0 !important;
      color: #fff !important;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0;
    }
  }

  .gift-coin-value {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: none;

    strong {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
      font-style: normal;
    }
  }

  .err-txt {
    height: 0;
  }

  .txt-field {
    width: 100%;
    height: 40px;
    border: 1px solid #d3d2d2;
    padding-left: 10px;
    outline: none;
  }
}

.not_available{
    &_label{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #f15b5b;
      color: #fff;
      width: 120px;
      height: 38px;
      display: flex;
      justify-content: center;
    }
    &_select{
      color: #f5f5f5 !important;
      border: solid 1px #f5f5f5 !important;
    }
    & .mdi-chevron-down::before {
      color: #c8c8c8;
    }
    & img{
      opacity: 0.4;
    }
  }

.strongCard {
  animation-name: strongCard;
  animation-duration: 2s;
}

.bg-warning {
  background-color: #FBE7E9;
}

@keyframes strongCard {
  0% { box-shadow: 0 0 0 0px #ff3c00ff; }
  50% { box-shadow: 0 0 10px 0px #ff3c00ff; }
  100% { box-shadow: 0 0 0 0px #ff3c00ff; }
}
.without-read-me{
  margin-top: 45px;
}
.gift-card-input{
  &__label{
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  &.is-invalid{
    background-color: #FBE7E9;
    border: 1px solid #FA5E5E;
  }
  &__main{
    input, select{
      width: 100%;
      height: 40px;
      padding: 9px 11px;
      background-color: #fff;
      border: 1px solid #D3D2D2;
      font-size: 15px;
      line-height: 22px;
      outline: none;
      &#state.is-invalid,
      &.is-invalid{
        background: #FBE7E9;
        border: 1px solid #FA5E5E;
      }
      &::placeholder{
        font-family: 'Lato-Italic';
        font-size: 15px;
        color: #979797;
      }
      &.disabled{
        background-color: #F4F4F4;
      }
    }
    p{
      // margin-top: 10px;
      // margin-bottom: 0px;
      color: #FA5E5E;
    }
  }
}
#state{
  height: 40px;
  &::placeholder{
    font-family: "Lato-Regular";
    font-style: italic;
    font-size: 13px;
    font-weight: 500;
    color: #979797;
  }
}
.setting .select-icon{
  position: absolute;
  right: 5px;
  top: 3px;
  color: black;
}

.select-wrapper{
  position: relative;
  &.with-arrow {
    background-image: url('/public/images/select-arrow-icon.svg');
    background-size: 25px 25px;
    background-position: 99% 50%;
  }
}
</style>

<style lang="scss">
#short-description ul{
  padding: 0;
  small{
    color: #AAAAAA;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
}
</style>
