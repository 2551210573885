<template>
  <div class="single-ling-campaign-card__images">
    <campaign-image
      v-for="(image, index) in availableImages"
      :key="index"
      :image="image.image"
      :name="image.name"
      :size="imagesCount > 1 ? size : $vuetify.breakpoint.xsOnly ? 160 : 360"
      :class="{
        'mt-auto': index === 1 && imagesCount === 2,
        'single-ling-campaign-card__image--revert-border': index === 1 && imagesCount === 2,
        'single-ling-campaign-card__image--hide-border': imagesCount === 1
      }"
    />

    <multiple-images v-if="imagesCount > 4">
      +{{ imagesCount - 3 }}
    </multiple-images>
  </div>
</template>

<script>
import CampaignImage from './TheCampaignImage.vue'
import MultipleImages from './TheCampaignImagesMultiple.vue'

export default {
  name: 'TheCampaignImages',
  components: {
    CampaignImage,
    MultipleImages
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 180
    }
  },
  computed: {
    imagesCount () {
      return this.images?.length ?? 0
    },
    availableImages () {
      const { images, imagesCount } = this

      return imagesCount > 4 ? images.slice(0, 3) : images
    }
  }
}
</script>

<style lang="scss" scoped>
.single-ling-campaign-card {
  &__images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
    flex: 0 1 362px;
    min-width: 362px;
    min-height: 362px;

    & > .v-image {
      &:first-child {
        border-right: 1px solid #E4E4E4;
        border-bottom: 1px solid #E4E4E4;
      }

      &:nth-child(2) {
        border-bottom: 1px solid #E4E4E4;
      }

      &:nth-child(3) {
        border-right: 1px solid #E4E4E4;
      }
    }
  }

  &__image {
    &--revert-border {
      border-top: 1px solid #E4E4E4;
      border-left: 1px solid #E4E4E4;
      border-bottom: none !important;
      margin-bottom: 1px;
    }

    &--hide-border {
      border: none !important;
    }
  }

  @media (max-width: 425px) {
    &__images {
      flex: 0 1 162px;
      max-width: 162px;
      min-width: 162px;
      min-height: 162px;
    }
  }
}
</style>
