<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <g clip-path="url(#clip0_680_1720)">
      <path
        d="M5.30277 8.39493H13.2146V1.25238H5.30277V8.39493ZM13.967 5.19026H16.1677C17.4582 5.19026
          18.5185 6.24946 18.5185 7.54103V13.9335C18.5185 15.2239 17.4593 16.2842 16.1677
          16.2842H13.967V20.045C13.967 20.2526 13.7978 20.4206 13.5914 20.4206H4.92714C4.71959
          20.4206 4.55152 20.2514 4.55152 20.045V16.2842H2.35077C1.06033 16.2842 0 15.225 0
          13.9335V7.54103C0 6.25059 1.0592 5.19026 2.35077 5.19026H4.55152V0.875627C4.55152
          0.668073 4.72072 0.5 4.92714 0.5H13.5914C13.7989 0.5 13.967 0.669201 13.967
          0.875627V5.19026ZM4.55039 5.94264H2.34964C1.4698 5.94264 0.751254 6.66006 0.751254
          7.54103V11.7722H17.765V7.54103C17.765 6.66118 17.0476 5.94264 16.1666
          5.94264H13.9659V8.77056C13.9659 8.97811 13.7967 9.14619 13.5902 9.14619H4.92601C4.71846
          9.14619 4.55039 8.97698 4.55039 8.77056V5.94264ZM13.967 15.5307H16.1677C17.0476 15.5307
          17.7661 14.8133 17.7661 13.9323V12.5234H13.967V15.5296V15.5307ZM4.55039
          15.5307V12.5246H0.752382V13.9335C0.752382 14.8133 1.4698 15.5318 2.35077
          15.5318H4.55152L4.55039 15.5307ZM13.2146 12.5246H5.30277V19.6671H13.2146V12.5246Z"
        fill="#95979D"
      />
      <path
        d="M15.7368 6.78857C16.3832 6.78857 16.9122 7.31761 16.9122 7.96396C16.9122 8.61031 16.3832
          9.13934 15.7368 9.13934C15.0905 9.13934 14.5615 8.61031 14.5615 7.96396C14.5615 7.31761
          15.0905 6.78857 15.7368 6.78857ZM15.7368 7.54096C15.5011 7.54096 15.3138 7.72821 15.3138
          7.96396C15.3138 8.19971 15.5011 8.38696 15.7368 8.38696C15.9726 8.38696 16.1599 8.19971
          16.1599 7.96396C16.1599 7.72821 15.9726 7.54096 15.7368 7.54096Z"
        fill="#95979D"
      />
    </g>
    <defs>
      <clipPath id="clip0_680_1720">
        <rect width="19" height="20" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Print',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 19
    },
    height: {
      type: [Number, String],
      required: false,
      default: 21
    }
  }
}
</script>
