import axios from 'axios'
import store from '../store'

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api',
  headers: {
    Accept: 'application/json'
  }
})

/**
 * Use request interceptor to fetch token.
 * Token is fetched asynchronously and attached to request as Authorization header.
 */
Api.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
)

/**
 * Use response interceptor to catch 401 (Unauthorized) api response.
 * If caught, refresh the token and retry the request.
 */
Api.interceptors.response.use(
  response => response.data,

  async error => {
    const { config: request, response: { status } } = error

    if (status === 401 && !request.retryRequest) {
      request.retryRequest = true

      return new Promise((resolve, reject) => {
        return store.dispatch('token/refresh', { resolve, reject })
      }).then(() => {
        return Api.request(request)
      })
    }

    throw error
  }
)

export default Api
