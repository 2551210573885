<template>
  <v-dialog
    v-model='showModal'
    v-scroll-lock="showModal"
    max-width='441'
    :max-height="(inCustomPanel && $vuetify.breakpoint.mobile) ? 520 : 403"
    :content-class="(inCustomPanel && $vuetify.breakpoint.mobile) ? 'mobile-skip-thank-you-in-panel' : null"
    style="top: 50px!important; overflow: visible;"
    :transition="(inCustomPanel && $vuetify.breakpoint.mobile) ? 'skip-thank-you-transition' : 'dialog-transition'"
  >
    <v-card class="modal-content skip-thanks-modal rounded-0 warning-modal px-lg-9 px-11 py-9">
      <div
        @click='showModal = !showModal'
        class='skip-thanks-modal__close-icon pointer'
      />
      <img
        class="mx-auto"
        width="190"
        height="190"
        src="../../../public/images/img-cat.png">
      <div class="mt-8 mt-lg-4 d-flex flex-column text-left px-1">
        <span class="f18 black1 font-weight-bold">Skip the Thank You note?</span>
        <span class="f15 black1 pt-2">It’s a small thing but big message of appreciation!</span>
      </div>
      <div
         v-if="(inCustomPanel && !$vuetify.breakpoint.mobile)"
        class="d-flex mt-0 mt-lg-0 pt-7"
      >
        <common-button
          outlined
          :width="82"
          :height="40"
          class="mr-3"
          @click="skip"
        >
          Skip
        </common-button>
        <common-button
          :width="136"
          :height="40"
          @click="sendNote"
        >
          Send note
        </common-button>
      </div>
      <v-row v-else class="pt-10">
        <v-col cols="6">
          <common-button
            outlined
            height="40"
            block
            @click="skip"
          >
            Skip
          </common-button>
        </v-col>
        <v-col cols="6">
          <common-button
            tile
            height="40"
            block
            @click="sendNote"
          >
            Send Note
          </common-button>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import ClickOutside from 'vue-click-outside'

Vue.use(VScrollLock)

export default {
  props: {
    show: Boolean,
    inCustomPanel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    showModal: false,
    declineOption: '',
    feedback: ''
  }),
  methods: {
    cancelDecline () {},
    doDecline () {
      this.$router.push('/feedback')
    },
    skip () {
      this.$router.push('/success')
    },
    sendNote () {
      this.showModal = !this.showModal
      this.$emit('sendNote')
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss">
.skip-thanks-modal {
  &__close-icon {
    position: absolute;
    right: -60px;
    top: 0;
    width: 35px;
    height: 35px;
    &::before,&::after{
      content: '';
      width: 49px;
      height: 4px;
      background-color: #fff;
      position: absolute;
      top: 17px;
      left: -7px;
      transform: rotate(45deg);
    }
    &::after{
      top: 17px;
      right: 17px;
      transform: rotate(-45deg);
    }

    @media screen and (max-width: 1024px) {
      top: 22px;
      right: 14px;
      width: 20px;
      height: 20px;
      &::before,&::after{
        top: 10px;
        left: -5px;
        width: 28px;
        height: 1px;
        background-color: rgba(74,74,74,1);
      }
    }
  }
}
.warning-modal {
  text-align: center;
  .skip-button{
    transition: all 0.5s;
    &--black.theme--light.v-btn.v-btn--has-bg{
      background-color: #000;
      &:hover{
        background-color: #919191
      }
    }
    &--border{
      background-color: white !important;
      border: 2px solid black;
      transition: all 0.5s;
      span{
        color: #000;
        transition: all 0.5s;
      }
      &::before{
        background-color: white !important;
      }
      &:hover{
        transition: all 0.5s;
        border: 2px solid #919191;
        background-color: #fff !important;
        span{
          color: #919191;
        }
        &::before{
          background-color: white !important;
        }
      }
    }
  }
}

.v-dialog {
  overflow: visible;
}

.mobile-skip-thank-you-in-panel {
  margin: 0;
  position: absolute;
  bottom: 0;
}
</style>
