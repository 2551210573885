<template>
  <div class="multiple-choices">
    <common-loader v-if="loading"/>
    <v-container
      v-if="!loading"
      class="d-flex flex-column"
    >
      <div class="multiple-choices__header mb-4">
        <h1 class="hero-recipient black1 lato-light">{{welcomeMessage}},</h1>
        <!-- <h5 class='hero-desc gray0 font-italic font-weight-light mt-1'>You received a gift package from {{ sender }}</h5> -->
      </div>
      <div class="multiple-choices__main row">
        <div class="multiple-choices__gifts col-12 col-md-9">
          <template v-if="redeemPageTitle">
            <h5
              v-html="redeemPageTitle"
              class="f20 black1 lato-light"
            />
          </template>
          <template v-else>
            <h5
              v-if="multiMode"
              class="f20 black1 lato-light"
            >
              Select <span>{{ maxGiftNum }}</span> items that fancy you, click <span>continue</span> when you’re done:
            </h5>
            <h5 v-else class="f20 black1 lato-light">
              You've received 100 GiftCoin to spend. Choose items below:
            </h5>
          </template>
          <div class="row my-10 align-stretch">
            <GiftCard
              v-for="(item, index) in filteredGifts"
              :key="index"
              class="col-md-4 col-sm-6"
              :item="item"
              @do="selectCard"
              @showProductDetailPanel="showProductDetailPanel"
            />
          </div>
        </div>
        <div class="multiple-choices__sidebar col-12 col-md-3 pa-md-0 flex-column flex-sm-row flex-md-column d-none d-md-block">
          <MultipleChoiceSelectedGifts
            @addCards="checkAllAvailableProductsSelected"
            class="col-12 col-sm-6 col-md-12"
          />
          <ContactInfoCard
            class="col-12 col-sm-6 col-md-12"
          />
        </div>
      </div>
      <MoreOptions
        @openPanel="openPanel"
      />
    </v-container>
    <MultipleChoiceSelectedGiftsMobile
      @addCards="checkAllAvailableProductsSelected"
      class="d-block d-md-none"
    />
    <SelectedNotAllAvailableProductsPopUp
      :showDialog="showSelectedNotAllAvailableProductsPopUp"
      :numberRemainingSlots="Number(maxGiftNum) - selectedGifts.length"
      @do="goToNext"
      @close="showSelectedNotAllAvailableProductsPopUp = !showSelectedNotAllAvailableProductsPopUp"
    />
    <ReGiftPanel
      :showPanel="showReGiftPanel"
      :gifts="gifts"
      @close="showReGiftPanel = !showReGiftPanel"
    />
    <DeclinePanel
      :showPanel="showDeclineF"
      @close="showDeclineF = !showDeclineF"
      @openReGiftPopUp="showReGiftPanel = !showReGiftPanel"
    />
    <ProductDetailPanel
      :showPanel="showProductDetailPanelF"
      :id="showingProductId"
      @do="selectCard"
      @close="showProductDetailPanelF = !showProductDetailPanelF"
    />
    <UpdateGiftPanel
      v-model="showUpdateGiftsPanel"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import GiftCard from '../components/eGift/GiftCard.vue'
import MultipleChoiceSelectedGifts from '../components/eGift/MultipleChoiceSelectedGifts.vue'
import ContactInfoCard from '../components/eGift/ContactInfoCard.vue'
import ProductDetailPanel from '../components/eGift/ProductDetailPanel.vue'
import UpdateGiftPanel from '../components/updateGift/UpdateGiftPanel.vue'
import MoreOptions from '../components/eGift/MoreOptions.vue'
import DeclinePanel from '../components/eGift/DeclinePanel.vue'
import ReGiftPanel from '../components/eGift/ReGiftPanel.vue'
import SelectedNotAllAvailableProductsPopUp from '../components/eGift/SelectedNotAllAvailableProductsPopUp.vue'
import MultipleChoiceSelectedGiftsMobile from '../components/eGift/MultipleChoiceSelectedGiftsMobile.vue'

export default {
  name: 'MultipleChoices',
  components: {
    ContactInfoCard,
    GiftCard,
    MultipleChoiceSelectedGifts,
    ProductDetailPanel,
    MoreOptions,
    DeclinePanel,
    ReGiftPanel,
    UpdateGiftPanel,
    SelectedNotAllAvailableProductsPopUp,
    MultipleChoiceSelectedGiftsMobile
  },
  data: () => ({
    showProductDetailPanelF: false,
    showUpdateGiftsPanel: false,
    showReGiftPanel: false,
    showWarningF: false,
    showDeclineF: false,
    showingProductId: undefined,
    showSelectedNotAllAvailableProductsPopUp: false
  }),
  computed: {
    ...mapState(
      'eGift',
      [
        'gifts',
        'redeemPageTitle',
        'recipientHash',
        'sender',
        'welcomeMessage',
        'maxGiftNum',
        'selectedGifts',
        'multiMode',
        'errorDecline',
        'loading',
        'coinLeft',
        'updateGiftsLink',
        'getGiftErrorStatus',
      ]
    ),
    allAvailableSlotsSelected () {
      return this.multiMode ? Number(this.maxGiftNum) - this.selectedGifts.length === 0 : false
    },
    filteredGifts () {
      const filteredProducts = this.gifts.filter((gift) => Number(gift.available))
      if (!filteredProducts.length) return this.gifts
      return filteredProducts
    },
    allGiftCoinsWereSpent () {
      return !this.multiMode ? this.coinLeft <= 0 : false
    },
    moreCoinsThenMinimumGiftPrice () {
      const selectedGiftsId = this.selectedGifts.map(gift => gift.id)
      const filteredGiftsArray = this.gifts
        .filter(gift => gift.available === '1')
        .filter(gift => !selectedGiftsId.includes(gift.id))
      return !this.multiMode ? this.coinLeft > Math.min(...filteredGiftsArray.map(gift => gift.coins)) : true
    }
  },
  methods: {
    ...mapMutations('eGift', ['setMaxSelectItem']),
    openPanel (flag) {
      this[flag] = !this[flag]
    },
    selectCard (item) {
      this.$store.dispatch('eGift/selectCard', item)
    },
    showProductDetailPanel (id) {
      this.showProductDetailPanelF = !this.showProductDetailPanelF
      this.showingProductId = id
    },
    goToNext () {
      this.$router.push({
        name: 'Setting'
      })
    },
    checkAllAvailableProductsSelected () {
      if (this.moreCoinsThenMinimumGiftPrice) {
        if (!this.allAvailableSlotsSelected && !this.allGiftCoinsWereSpent) {
          this.showSelectedNotAllAvailableProductsPopUp = !this.showSelectedNotAllAvailableProductsPopUp
          return
        }
        if (!this.multiMode && this.coinLeft) {
          this.showSelectedNotAllAvailableProductsPopUp = !this.showSelectedNotAllAvailableProductsPopUp
          return
        }
      }
      this.goToNext()
    },
    getRecipientHash () {
      const hash = window.location.pathname.replace('/recipient/', '').replace('/gift/multiple-choices', '')
      return hash
    }
  },
  async created () {
    if (localStorage.getItem('recipientHash')) {
      localStorage.removeItem('recipientHash')
    }
    localStorage.setItem('recipientHash', this.getRecipientHash())
    if (this.gifts === undefined) {
      await this.$store.dispatch('eGift/getGifts', this.getRecipientHash())
      if (localStorage.getItem('maxGiftNum')) {
        localStorage.removeItem('maxGiftNum')
      }
      localStorage.setItem('maxGiftNum', this.maxGiftNum)
      if (localStorage.getItem('multiMode')) {
        localStorage.removeItem('multiMode')
      }
      localStorage.setItem('multiMode', this.multiMode)
      if (this.getGiftErrorStatus === 'NotAvailable') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/not-available-gift`
        })
      }
      if (!(Number(this.maxGiftNum) > 1 || (!this.multiMode && !this.getGiftErrorStatus.length))) {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/gift`
        })
      }
      if (this.getGiftErrorStatus === 'Complete') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-redeemed`
        })
      }
      if (this.getGiftErrorStatus === 'Declined') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-declined`
        })
      }
      if (this.getGiftErrorStatus === 'Expired') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-expired`
        })
      }
      if (this.getGiftErrorStatus === 'Canceled') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-canceled`
        })
      }
      if (!this.multiMode) {
        this.setMaxSelectItem(this.gifts.length)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
span{
  font-weight: 900;
}
.multiple-choices{
  position: relative;
  &__sidebar{
    @media only screen and (min-width: 600px) and (max-width: 958px) {
      display: flex;
    }
  }
  .hero-recipient {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 120%;

    @media screen and (min-width: 768px) {
      font-size: 45px;
      font-weight: 300;
    }
  }
}
</style>
