<template>
  <div class="address-autocomplete">
    <ValidationProvider
      v-slot="{ errors, dirty }"
      rules="required|poBox"
      :custom-messages="{
        required: 'Street is a required field.',
        poBox: 'Address must not specify a P.O. Box'
      }"
      ref="addressValidationProvider"
      class="col-12 pa-0"
    >
      <div class="address-autocomplete__input mb-2">
        <input
          type="text"
          ref="searchInput"
          v-model="searchAddressValue"
          @input="searchInputHandler"
          @focus="searchInputFocusHandler"
          @keydown.tab.prevent="tabHandler"
          @keydown.up.prevent="arrowKeyDownHandler"
          @keydown.down.prevent="arrowKeyDownHandler"
          @keydown.enter="enterHandler"
          :placeholder="((dirty && !searchAddressValue.length)
            || (showingAddressInputWithoutSearching && showingFullAddressForm)) && countryForAddressSearch === 'US'
              ? 'We cannot ship to PO Box or Military Addresses'
              : 'Start typing your street address for suggestions'"
          :class="[(errors.length || showDeliveryAddressIsRequired) ? 'is-invalid' : 'is-valid']"
        />
        <p
          v-if="errors.length"
          class="lato-italic f14 mb-2"
        >
          {{ errors[0] }}
        </p>

        <p class="lato-italic f14 mb-2" v-if="showDeliveryAddressIsRequired">
          Delivery address is required. Select from the suggested addresses or click Enter address manually.
        </p>
      </div>
    </ValidationProvider>
    <p
      v-if="!showingFullAddressForm"
      @click="addressManuallyLinkCkickHandler"
      class="ma-0 pointer"
    >
      <span class="green3">Enter address manually</span>
    </p>
    <div
      v-show="formatedAddressesListFromLoqate.length && showSearchItemsList"
      class="address-autocomplete__search-results-list"
      ref="searchAddressList"
    >
      <div class="address-autocomplete__search-results-item--first py-2 pl-2 d-flex aling-center">
        <p class="ma-0 gray2 lato-italic">Keep typing your address to display more results</p>
      </div>
      <div
        v-for="(address, index) in formatedAddressesListFromLoqate"
        :key="address.Id"
        @click="itemClickHandler(address)"
        class="address-autocomplete__search-results-item py-2 pl-2 d-flex align-center pointer"
        :class="[
          address.Type !== 'Address' ? 'with-arrow' : '',
          activeSearchItemIndex === index + 1 ? 'tab-active-item' : ''
        ]"
      >
        <p class="ma-0 d-flex justify-center" v-html="address.labelHtml" />
      </div>
      <div
        @click="addressManuallyLinkCkickHandler"
        class="address-autocomplete__search-results-item--last py-2 pl-2"
      >
        <p class="ma-0 pointer">
          <span class="green3">Enter address manually</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ApiLoqate from '../../axios/apiLoqate'

export default {
  name: 'AddressAutocomplete',
  props: {
    showingAddressInputWithoutSearching: {
      type: Boolean,
      require: false,
      default: false
    },
    showingFullAddressForm: {
      type: Boolean,
      require: false,
      default: false
    },
    countryForAddressSearch: {
      tupe: String,
      require: false,
      default: 'US'
    },
    showDeliveryAddressIsRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    searchAddressValue: '',
    disableSearchMethod: false,
    showSearchItemsList: false,
    activeSearchItemIndex: null
  }),
  methods: {
    ...mapActions('eGift', ['getAddressDataList']),
    addressManuallyLinkCkickHandler () {
      this.$emit('update:showingFullAddressForm', true)
      this.$emit('update:showingAddressInputWithoutSearching', true)
      this.$emit('enableVerificationManuallyEnteredAddress')
      this.showSearchItemsList = false
    },
    arrowKeyDownHandler (eventValue) {
      if (this.showSearchItemsList) {
        if (eventValue.key === 'ArrowDown') {
          this.activeSearchItemIndex++
          if (this.formatedAddressesListFromLoqate.length > 4 && this.activeSearchItemIndex >= 4) {
            this.$refs.searchAddressList.scrollTop = this.$refs.searchAddressList.scrollTop + 50
          }
          if (this.activeSearchItemIndex > this.formatedAddressesListFromLoqate.length) {
            this.activeSearchItemIndex = 1
            this.$refs.searchAddressList.scrollTop = 0
          }
        }
        if (eventValue.key === 'ArrowUp') {
          this.activeSearchItemIndex--
          if (this.activeSearchItemIndex === 0) {
            this.activeSearchItemIndex = this.formatedAddressesListFromLoqate.length
            this.$refs.searchAddressList.scrollTop = this.$refs.searchAddressList.scrollHeight - 250
          }
          if (this.formatedAddressesListFromLoqate.length > 4 && this.activeSearchItemIndex <= this.formatedAddressesListFromLoqate.length - 4) {
            this.$refs.searchAddressList.scrollTop = this.$refs.searchAddressList.scrollTop - 50
          }
        }
      }
    },
    tabHandler (eventValue) {
      if (this.showSearchItemsList) {
        if (!eventValue.shiftKey) {
          this.activeSearchItemIndex++
          if (this.formatedAddressesListFromLoqate.length > 4 && this.activeSearchItemIndex >= 4) {
            this.$refs.searchAddressList.scrollTop = this.$refs.searchAddressList.scrollTop + 50
          }
          if (this.activeSearchItemIndex > this.formatedAddressesListFromLoqate.length) {
            this.activeSearchItemIndex = 1
            this.$refs.searchAddressList.scrollTop = 0
          }
        }
        if (eventValue.shiftKey) {
          this.activeSearchItemIndex--
          if (this.activeSearchItemIndex === 0) {
            this.activeSearchItemIndex = this.formatedAddressesListFromLoqate.length
            this.$refs.searchAddressList.scrollTop = this.$refs.searchAddressList.scrollHeight - 250
          }
          if (this.formatedAddressesListFromLoqate.length > 4 && this.activeSearchItemIndex <= this.formatedAddressesListFromLoqate.length - 4) {
            this.$refs.searchAddressList.scrollTop = this.$refs.searchAddressList.scrollTop - 50
          }
        }
      }
    },
    enterHandler () {
      if (this.activeSearchItemIndex) {
        this.itemClickHandler(this.formatedAddressesListFromLoqate[this.activeSearchItemIndex - 1])
        this.activeSearchItemIndex = null
        this.$refs.searchAddressList.scrollTop = 0
      }
    },
    async searchInputFocusHandler () {
      this.activeSearchItemIndex = null
      this.$refs.searchAddressList.scrollTop = 0
      if (this.searchAddressValue.length && !this.showingAddressInputWithoutSearching && !this.disableSearchMethod) {
        await this.getAddressDataList({
          searchText: this.searchAddressValue,
          country: this.countryForAddressSearch
        })
        this.showSearchItemsList = true
      }
    },
    async searchInputHandler () {
      this.disableSearchMethod = false
      this.activeSearchItemIndex = null
      this.$refs.searchAddressList.scrollTop = 0
      this.$emit('autocompleteInput', this.searchAddressValue)
      const searchInputValid = await this.$refs.addressValidationProvider.validate()
      if (!searchInputValid.valid) return
      if (!this.showingAddressInputWithoutSearching) {
        this.showSearchItemsList = !!this.searchAddressValue.length
        this.getAddressDataList({
          searchText: this.searchAddressValue,
          country: this.countryForAddressSearch
        })
      }
    },
    async itemClickHandler (objectValue) {
      if (objectValue.Type !== 'Address') {
        this.disableSearchMethod = true

        this.getAddressDataList({
          searchText: this.searchAddressValue,
          searchContainerId: objectValue.Id,
          country: this.countryForAddressSearch
        }).then(() => {
          this.$refs.searchInput.focus()
          this.showSearchItemsList = true
        })

        return
      }
      const fullAddressInfo = await ApiLoqate.get('/Capture/Interactive/Retrieve/v1.00/json3ex.ws', {
        params: {
          Id: objectValue.Id
        }
      })
      this.disableSearchMethod = false
      this.activeSearchItemIndex = null
      this.$refs.searchAddressList.scrollTop = 0
      this.$emit('update:showDeliveryAddressIsRequired', false)
      this.$emit('update:showingFullAddressForm', true)
      this.searchAddressValue = fullAddressInfo.data.Items[0]?.Line2 || fullAddressInfo.data.Items[0]?.Line1 || ''
      this.showSearchItemsList = false
      this.$emit('autocompleteItemClickHandler', fullAddressInfo.data.Items[0])
    }
  },
  computed: {
    ...mapState('eGift', ['addressesListFromLoqate']),
    formatedAddressesListFromLoqate () {
      if (this.addressesListFromLoqate.length && this.addressesListFromLoqate.every(item => item.Text)) {
        let list = this.addressesListFromLoqate.filter(item => !item.Text.includes('PO Box'))
        list.forEach(item => {
          this.$set(item, 'labelValue', `${item.Text} ${item.Description.includes(' - ') ? item.Description.split(' - ')[0] : item.Description}`)
          this.$set(item, 'labelHtml', `<span>${item.labelValue.includes(this.searchAddressValue.trim())
            ? `${item.labelValue.replace(this.searchAddressValue, `<span class="gray1">${this.searchAddressValue}</span>`)}`
            : item.labelValue}</span>`
          )
        })
        list = list.filter(item => item.labelValue !== this.searchAddressValue)
        return list.reduce((resArray, listItem) => {
          if (resArray?.find(resArrayItem => resArrayItem.labelValue === listItem.labelValue)) {
            if (listItem.Type === 'Address') return resArray
            if (listItem.Type !== 'Address') {
              resArray.splice(resArray.indexOf(resItem => resItem.labelValue === listItem.labelValue), 1, listItem)
              return resArray
            }
          }
          resArray.push(listItem)
          return resArray
        }, [])
      }
      return []
    }
  },
  mounted () {
    const self = this
    window.onclick = function (event) {
      if (!self.showingAddressInputWithoutSearching) {
        self.showSearchItemsList = !!event.path?.find(item => {
          if (item.classList && item.classList.length) return !!Array.from(item.classList)?.find(item => item === 'address-autocomplete__input' || item === 'address-autocomplete__search-results-item')

          return false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address-autocomplete{
  position: relative;
  &__input{
    input{
      width: 100%;
      height: 40px;
      padding: 11px;
      border: 1px solid #D3D2D2;
      font-size: 15px;
      line-height: 22px;
      outline: none;
      &.is-invalid{
        background: #FBE7E9;
        border: 1px solid #FA5E5E;
      }
      &::placeholder{
        font-family: 'Lato-Italic';
        font-size: 15px;
        color: #979797;
      }
    }
    p{
      margin-top: 10px;
      margin-bottom: 0px;
      color: #FA5E5E;
    }
  }
  &__search-results-list{
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 100000;
    width: 100%;
    max-height: 250px;
    background-color: #fff;
    border: solid 1px #D3D2D2;
    overflow-y: scroll;
  }
  &__search-results-item{
    width: 100%;
    min-height: 48px;
    font-size: 15px;
    border-top: solid 1px #D3D2D2;
    transition: all 0.3s;
    &:hover{
      background-color: #f3f3f3;
      transition: all 0.3s;
    }
    &--last{
      border-top: solid 1px #D3D2D2;
    }
    &.with-arrow {
      background-image: url('/public/images/select-arrow-icon.svg');
      background-size: 25px 25px;
      background-position: 99% 50%;
    }
  }
  .underlining {
    border-bottom: solid 1px #000;
  }
  .tab-active-item{
    border: solid 1px #000;
  }
}
</style>
