<template>
  <div
    class="re-gift-panel"
    :style="{height: '100%'}"
  >
    <v-navigation-drawer
      v-model="show"
      app
      temporary
      right
      width="1065px"
      class="re-gift-panel"
      v-scroll-lock="!$vuetify.breakpoint.mobile && show"
      :style="{height: '100%'}"
    >
      <div v-if="step === 1" class="step-1">
        <close-panel-button
          @close="closeDialog"
          text='Cancel'
        />
        <div class="re-gift-panel__main my-7 my-md-10 px-4 px-md-13">
          <div class="re-gift-panel__header">
            <p class="text-h3 font-weight-light">Re-Gift Panel</p>
            <div class="mt-4 mb-8">
              <p class="gray1 font-italic mb-0 f15">
                <img class="mr-4" src="../../../public/images/img-heart.png">
                Don’t worry, your sender will not know that you forward this gift
              </p>
            </div>
          </div>

          <cg-alert
            type="information"
            background-color="#FFF8D9"
            class="mb-10"
            style="max-width: 480px"
          >
            Your recipient shall be able to redeem this gift only with a USA address
          </cg-alert>

          <div class="re-gift-panel__body col-lg-6 pa-0">
            <ValidationObserver
              ref="formValidation"
              @submit.prevent="sendReGiftRequest()"
            >
              <form
                @submit.prevent="sendReGiftRequest(val)"
                class="re-gift-block"
              >
                <div class="re-gift-inputs-wrapper">
                  <div class="re-gift-input__item mb-8">
                    <div class="re-gift-input__header d-flex mb-3">
                      <p class="re-gift-input__label ma-0">From</p>
                    </div>
                    <div class="re-gift-input__main d-flex">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'Full Name is a required field.'
                        }"
                        class="col-12 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="from.fullName"
                          placeholder="Full Name"
                          :class="[errors.length ? 'is-invalid' : 'is-valid mb-5', 're-gift-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                    <div class="re-gift-input__main d-flex">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|email"
                        :custom-messages="{
                          required: 'Email is a required field.'
                        }"
                        class="col-12 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="from.email"
                          placeholder="Email"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 're-gift-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="re-gift-input__item mb-8">
                    <div class="re-gift-input__header d-flex mb-3">
                      <p class="re-gift-input__label ma-0">To</p>
                    </div>
                    <div class="re-gift-input__main d-flex flex-wrap flex-md-nowrap">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'First Name is a required field.'
                        }"
                        class="col-12 col-md-6 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="to.firstName"
                          placeholder="First Name"
                          :class="[errors.length ? 'is-invalid' : 'is-valid mb-5', 're-gift-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        :custom-messages="{
                          required: 'Last Name is a required field.'
                        }"
                        class="col-12 col-md-6 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="to.lastName"
                          placeholder="Last Name"
                          :class="[errors.length ? 'is-invalid' : 'is-valid mb-5', 're-gift-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                    <div class="re-gift-input__main d-flex">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|email"
                        :custom-messages="{
                          required: 'Email is a required field.'
                        }"
                        class="col-12 pa-0 mb-0"
                      >
                        <input
                          type="text"
                          v-model="to.email"
                          placeholder="Recipient Email"
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 're-gift-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="re-gift-input__item mb-8">
                    <div class="re-gift-input__header d-flex mb-3">
                      <p class="re-gift-input__label ma-0">Greeting</p>
                    </div>
                    <div class="re-gift-input__main d-flex">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="max:250"
                        :custom-messages="{
                          max: 'This message is too long'
                        }"
                        class="col-12 pa-0 mb-0"
                      >
                        <textarea
                          v-model="greetingMessage"
                          placeholder="Write your greeting message!"
                          no-resize
                          auto-grow
                          :class="[errors.length ? 'is-invalid' : 'is-valid', 're-gift-input']"
                        />
                        <p
                          v-if="errors.length"
                          class="lato-italic f14 mb-2"
                        >{{ errors[0] }}</p>
                        <p class="font-italic gray1 ma-0">{{greetingMessage.length}}/250</p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="re-gift-panel__calendar">
                    <h4>When</h4>
                    <date-range-picker
                      :singleDatePicker="true"
                      :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                      :dateRange="dateRange"
                      :autoApply="true"
                      :ranges="false"
                      :min-date="previusDate"
                      @select="setSendDate"
                      class="width-100 mt-4 mb-2 remove-line"
                    />
                    <p class="font-italic gray1 mt-2">Date of eGift notification</p>
                  </div>
                  <div class="re-gift-panel__buttons mt-7">
                    <common-button
                      :width="continueButtonWidth"
                      @click="submitReGiftForm"
                    >
                      Continue
                    </common-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div v-if="step === 2" class="step-2">
        <div v-if="wasReGifted && !regiftStatus" class="error-message py-4 px-13 lighten-4 d-flex align-center"
        >
          <img
            src="@/assets/error-icon.svg"
            class="lighten-1 d-flex justify-center mr-4"
          />
          <p class="mb-0">{{ regiftStatusMessage }} Please try it again</p>
        </div>
        <close-panel-button
          @close="closeDialog"
          text='Close'
        />
        <common-loader v-if="loadingSendReGift"/>
        <div
          v-if="!loadingSendReGift"  class="re-gift-panel__main  my-7 my-md-10 px-4 px-md-13"
        >
          <div class="re-gift-panel__header">
            <p class="text-h3 font-weight-light">Review your eGift</p>
            <div class="mt-4 mb-12">
              <p class="gray1 font-italic mb-0 f15">
                <img class="mr-4" src="../../../public/images/img-heart.png">
                Don’t worry, your sender will not know that you forward this gift
              </p>
            </div>
          </div>
          <div class="re-gift-panel__body re-gift-panel__body--step-2 col-lg-6 pa-0">
            <div class="deliver-info blue lighten-5 px-6 py-6">
              <h6 class="text-h6 mb-4 text-subtitle-1 font-weight-bold">Deliver</h6>
              <div class="deliver-info__list">
                <div
                  v-for="(item, index) in deliveryData"
                  :key="index"
                  class="deliver-info__item d-flex flex-wrap pb-1"
                >
                  <p class="ma-0 label pa-0 pb-1 col-12 col-sm-2 text-body-2 font-weight-medium">{{ item.label }}</p>
                  <p class="ma-0 text-body-2 gray2">{{ item.value }}</p>
                </div>
              </div>
            </div>
            <div class="gifts-list mt-10 mb-10">
              <h6
                v-if="multiMode && gifts.length > 1"
                class="gifts-list__header text-h6 mb-4 text-subtitle-1 font-weight-bold"
              >Recipient will choose {{ maxGiftNum ===  1 ? 'one' : maxGiftNum }} of the items below:</h6>
              <h6
                v-if="!multiMode"
                class="gifts-list__header text-h6 mb-4 text-subtitle-1 font-weight-bold"
              >Recipient will receive 100 GiftCoin to spend</h6>
              <div
                v-if="multiMode"
                class="gifts-list__main">
                <div
                  v-for="gift in gifts"
                  :key="gift.id"
                  class="gifts-list__item d-flex align-center"
                >
                  <img
                    :src="(gift.image && !gift.image.includes('no_selection')) ? gift.image : '/images/empty.png'"
                    :alt="gift.name"
                    class="col-4 col-md-3"
                  >
                  <p class="mb-0 text-subtitle-1 align-self-center font-weight-medium ml-6 col-7 col-md-8">{{ gift.name }}</p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap pb-3 mb-8">
              <common-button
                width="100%"
                outlined
                @click="step = 1"
                class="col-12 col-sm-4 pa-0"
              >
                Back
              </common-button>
              <common-button
                width="100%"
                @click="sendReGiftRequest"
                class="ml-0 ml-sm-4 mt-4 mt-sm-0 col-12 col-sm-4 pa-0"
              >
                Continue
              </common-button>
            </div>
            <div class="policy-info font-italic">
              <p class="gray1 mb-0">
                By clicking “Continue” you agree to the CorporateGift.com
              </p>
              <a
                href="https://corporategift.com/terms-and-conditions/"
                target="_blank"
                class="green3"
              >Terms of Service and Payments Policy.</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 3" class="step-3">
        <div v-if="wasReGifted && regiftStatus && !errorSendThankYouNote" class="success-message py-4 px-13 green lighten-4 d-flex align-center">
          <v-icon
            :dark="true"
            class="icon-wrap green lighten-1 d-flex justify-center mr-4"
          >
            mdi-check
          </v-icon>
          <p class="mb-0">
            eGift created successfully - will be sent on <span class="font-weight-bold">{{ formattedSendDate }}</span> Order ID: #{{ successReGiftNum }}. You will receive a confirmation email soon
          </p>
        </div>
        <div
          v-if="wasReGifted && errorSendThankYouNote"
          class="error-message py-4 px-13 lighten-4 d-flex align-center"
        >
          <img
            src="@/assets/error-icon.svg"
            class="lighten-1 d-flex justify-center mr-4"
          />
          <p class="mb-0">We've encountered a technical error, gift has not been sent. Please try again later.</p>
        </div>
        <close-panel-button
          @close="closeDialog"
          text='Close'
        />
        <common-loader v-if="loadingSendThankYouNote"/>
        <div v-if="!loadingSendThankYouNote" class="thanks-node__main  my-7 my-md-10 px-4 px-md-13">
          <div class="thanks-node__header col-12 col-md-8 pa-0">
            <p class="text-h3 font-weight-light f42 ma-0">Send a Thank-you note to {{ sender }}</p>
          </div>
          <div class="thanks-node__body col-lg-6 pa-0 mt-10 mt-md-15">
            <div class="thanks-node__card col-12 col-md-8 col-lg-12 pa-10 d-flex justify-center align-center">
              <img id="leaf" src="../../assets/node-img-2.png" alt=""><img src="" alt="">
              <img id="flower" src="../../assets/node-img.png" alt=""><img src="" alt="">
              <textarea id="thankYouNodeTextarea" class="font-italic thankyou-node" v-model="noteMessage"></textarea>
            </div>
            <div class="thanks-node__buttons col-12 col-md-8 col-lg-12 d-flex flex-wrap justify-space-between pa-0 mt-9">
              <common-button
                width="100%"
                outlined
                @click="step = 4"
                class="col-12 col-sm-3 pa-0"
              >
                See ideas
              </common-button>
              <div class="d-flex flex-wrap align-center col-12 col-sm-5 pa-0 mt-4 mt-sm-0 flex-row">
                <a
                  class="col-12 col-sm-3 text-center pa-0 green3"
                  @click="skipSendingThankyouNode"
                >Skip</a>
                <common-button
                  width="100%"
                  @click="sendThankyouNote"
                  class="ml-0 ml-sm-4 mt-4 mt-sm-0 col-12 col-sm-8 pa-0"
                >
                  Send
                </common-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 4" class="step-4">
        <div v-if="wasReGifted && regiftStatus" class="success-message py-4 px-13 green lighten-4 d-flex align-center">
          <v-icon
            :dark="true"
            class="icon-wrap green lighten-1 d-flex justify-center mr-4"
          >
            mdi-check
          </v-icon>
          <p class="mb-0">
            eGift created successfully - will be sent on <span class="font-weight-bold">{{ formattedSendDate }}</span>Order ID: #{{ successReGiftNum }}. You will receive a confirmation email soon
          </p>
        </div>
        <close-panel-button
          @close="step = 3"
          text='Close'
        />
        <div class="thank-text__main  my-7 my-md-10 px-4 px-md-13">
          <div class="thank-text__header re-gift-panel__header--step-4">
            <p class="text-h4 text-md-h3 ma-0 font-weight-light">Thank-you messages ideas</p>
          </div>
          <div class="thank-text__body col-lg-10 pa-0 mt-10">
            <div class="masonry gutterless">
              <div
                v-for="(text, index) in thankTextsArray"
                :key="index"
                class="brick thank-text__item pa-3 ma-1 align-self-start"
                :style="{backgroundColor: `${colors[index % colors.length]}`}"
              >
                <p
                  @click="selectThankText(text.name)"
                  class="ma-0"
                >{{ text.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Api from '../../axios/api'
import Vue from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import VScrollLock from 'v-scroll-lock'
import { CgAlert } from '@corporategift/design-system'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.use(VScrollLock)

export default {
  name: 'ReGiftPanel',
  components: {
    CgAlert,
    DateRangePicker,
  },
  props: {
    showPanel: Boolean,
    gifts: Array
  },
  data: () => ({
    show: false,
    from: {
      fullName: '',
      email: ''
    },
    to: {
      firstName: '',
      lastName: '',
      email: ''
    },
    greetingMessage: '',
    snedDate: new Date(),
    dateRange: {
      startDate: new Date(),
      endDate: new Date()
    },
    noteMessage: 'Thank you for your gift!',
    thankTextsArray: [],
    step: 1,
    wasReGifted: false,
    regiftStatus: null,
    regiftStatusMessage: '',
    continueButtonWidth: null,
    colors: [
      '#F4F4F4', '#D8F1E4', '#A5DEC0', '#FAE3E3',
      '#EDCFC3', '#D2F5F3', '#FFF8D9', '#FCEBA4',
      '#E6EBF1', '#CDD8E4', '#DFDDEE', '#D3C5D7'
    ]
  }),
  methods: {
    ...mapMutations('eGift', ['setSuccessReGiftNum', 'setLoadingSendReGift', 'setErrorSendThankYouNote']),
    ...mapActions('eGift', ['sendNote']),
    selectThankText (text) {
      this.noteMessage = text
      this.step = 3
      this.$nextTick(() => {
        document.getElementById('thankYouNodeTextarea').focus()
      })
    },
    skipSendingThankyouNode () {
      this.$router.push({
        path: '/success'
      })
    },
    closeDialog () {
      this.show = !this.show
      this.step = 1
      this.setErrorSendThankYouNote(false)
    },
    setSendDate (val) {
      this.snedDate = new Date(val.startDate.setHours(4, 0))
    },
    async submitReGiftForm () {
      if (!await this.$refs.formValidation.validate()) return
      this.step = 2
    },
    async sendThankyouNote () {
      await this.sendNote({
        note: this.noteMessage,
        sender: null,
        color: null
      })
      if (this.errorSendThankYouNote) return
      this.$router.push({
        path: '/success'
      })
    },
    async sendReGiftRequest () {
      try {
        this.setLoadingSendReGift(true)
        const response = await Api.post(`/recipient/${this.recipientHash}/regift`, {
          fromName: this.from.fullName,
          fromEmail: this.from.email,
          toFirstName: this.to.firstName,
          toLastName: this.to.lastName,
          toEmail: this.to.email,
          message: this.greetingMessage,
          date: `${this.snedDate.getFullYear()}-${this.snedDate.getMonth()}-${this.snedDate.getDate()}`,
          emailSubject: ''
        })
        this.wasReGifted = true
        this.regiftStatus = response.success
        if (response.success) {
          this.setSuccessReGiftNum(response.increment_id)
          this.setLoadingSendReGift(false)
          this.step = 3
          this.$nextTick(() => {
            document.getElementById('thankYouNodeTextarea').focus()
          })
        } else {
          this.regiftStatusMessage = response.message
          this.step = 2
          this.setLoadingSendReGift(false)
        }
      } catch (err) {
        this.step = 2
        this.regiftStatus = false
        this.regiftStatusMessage = 'Something went wrong'
      }
    }
  },
  computed: {
    ...mapState('eGift', ['loadingProductDetail', 'recipientHash', 'thanksNotes', 'id', 'sender', 'successReGiftNum', 'loadingSendReGift', 'loadingSendThankYouNote', 'errorSendThankYouNote', 'errorSendThankYouNoteMessage', 'maxGiftNum', 'multiMode', 'recipientFirstName', 'recipientLastName', 'preEmail']),
    formattedSendDate () {
      return `${this.snedDate.getMonth() + 1}/${this.snedDate.getDate()}/${this.snedDate.getFullYear()}`
    },
    previusDate () {
      return new Date(new Date().setDate(new Date().getDate() - 1))
    },
    deliveryData () {
      return [
        {
          label: 'To',
          value: this.to.email
        },
        {
          label: 'From',
          value: this.from.email
        },
        {
          label: 'When',
          value: this.formattedSendDate
        }
      ]
    }
  },
  watch: {
    showPanel (val) {
      this.show = val
    },
    show (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  async created () {
    await this.$store.dispatch('eGift/getThankyounotes')
    this.thankTextsArray = this.thanksNotes[0]?.notes
    this.continueButtonWidth = window.innerWidth < 768 ? '100%' : 200
    this.from.fullName = `${this.recipientFirstName} ${this.recipientLastName}`
    this.from.email = this.preEmail
  }
}
</script>

<style lang="scss" scoped>
.thankyou-node {
  height: auto;
  min-height: 80px;
  overflow : auto;
  text-align: center;
  border: none;
  color: #16181f;
  @media screen and (max-width: 768px) {
    min-height: 100px;
  }
}
.gifts-list{
  &__item{
    border-bottom: solid 1px #e4e4e4;
  }
}
.thank-text{
  &__item{
    cursor: pointer;
    border-radius: 4px;
    transition: 0.5s;
    &:hover{
      color: #979797;
    }
  }
}
.thanks-node{
  &__card{
    height: 350px;
    position: relative;
    border: 1px solid #D3D2D2;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);
    #leaf{
      left: 20px;
      top: 20px;
    }
    #flower{
      right: 20px;
      bottom: 0;
    }
    img{
      position: absolute;
      width: 15%;
    }
  }
}
.icon-wrap{
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.gifts-list{
  &__main{
    border-top: solid 1px #E4E4E4;
  }
  &__item{
    border-bottom: solid 1px #E4E4E4;
  }
}
a{
  text-decoration: none;
}
.glyphicon.glyphicon-calendar.fa.fa-calendar{
  &::before{
    display: none;
  }
}
.caret{
  display: none;
}
.width-100{
  width: 100%;
}
textarea:focus, input:focus{
    outline: none;
}
input, textarea{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #D3D2D2;
  color: #000;
  &::placeholder{
    font-style: italic;
  }
}
input {
  &:focus{
    height: 32px
  }
  height: 32px
}
textarea{
  height: 180px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #D3D2D2;
  color: #000;
  resize: none;
}
textarea:focus{
  height: 180px;
}
.re-gift-panel {
  padding-left: 0;
  &__body{
    input,textarea{
      border: 1px solid rgba(211,210,210,1);
    }
  }

  &__header{
    font-size: 30px;
    @media screen and (min-width: 768px) {
      font-size: 40px;
    }
    &-message{

    }
  }

  .mobile-mode {
    position: relative;
  }

  @media screen and (min-width: 1024px) {
    // padding-left: 45px;
  }

  .panel-content {
    padding-right: 0;
    margin: 0!important;

    @media screen and (min-width: 1024px) {
      // padding-right: 130px;
      margin-left: 28px!important;
    }

    .preview-wrapper {
      display: none !important;

      @media screen and (min-width: 1024px) {
        display: flex !important;
      }
    }

    .prevImg {
      max-width: 100%;
      max-height: 50vh;

    }

    .prev-min-img {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vw;
      border: 1px solid #E4E4E4;

      @media screen and (min-width: 1024px) {
        height: 80px;
        width: 80px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      &.selected {
        border: 3px solid #42B77A;
      }
    }

    .mobile-mode .prev-min-img {
      border: none;
    }

    .product-name {
      color: #161616;
      font-size: 25px;
      letter-spacing: 0;
      line-height: 35px;
    }

    .sku {
      color: #9F9F9F;
      font-size: 10px;
      letter-spacing: 0.13px;
      line-height: 12px;
    }

    .short_description {
      color: #9F9F9F;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 24px;
    }

    .txt-field {
      width: 100%;
      height: 28px;
      border: 1px solid #d3d2d2;
      padding-left: 10px;
      outline: none;
    }
  }

  .re-gift-input{
    &__label{
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 10px;
      letter-spacing: 0;
    }
    &.is-invalid{
      background-color: #FBE7E9;
      border: 1px solid #FA5E5E;
    }
    &__main{
      input, select, textarea{
        width: 100%;
        height: 40px;
        padding: 11px;
        border: 1px solid #D3D2D2;
        font-size: 15px;
        line-height: 22px;
        outline: none;
        &#state.is-invalid,
        &.is-invalid{
          background: #FBE7E9;
          border: 1px solid #FA5E5E;
        }
        &::placeholder{
          font-family: 'Lato-Italic';
          font-size: 15px;
          color: #979797;
        }
      }
      textarea{
        height: 180px;
      }
      p{
        margin-top: 10px;
        margin-bottom: 0px;
        color: #FA5E5E;
      }
    }
  }
  .item-selector {
    margin-bottom: 10px;

    label {
      color: #959595;
      font-weight: 400;
      margin-bottom: 0;
    }

    .select-attribute {
      padding: 0 20px 0 10px;
      font-weight: 400 !important;
      font-style: normal !important;
      color: #000 !important;
      width: 100%;
      border-radius: 0;
      height: 28px;
      background-color: #fff;
      border: 1px solid #d3d2d2;
      font-size: 14px;
      font-family: "Lato", Verdana, Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .add-button {
    background: #000 !important;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    margin-top: auto;
    border-radius: 0;
    width: 100%;

    span:first-child {
      pointer-events: none;
    }

    span:last-child {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      cursor: pointer;
    }
  }

  .add-button.remove {
    background-color: white !important;
    border: 2px solid black;
  }

  .add-button.disabled {
    width: 100%;
    opacity: 0.3;
  }

  .gift-coin-value {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: none;

    strong {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      font-style: normal;
    }
  }

  .err-txt {
    height: 0;
  }

  .txt-field {
    width: 100%;
    height: 28px;
    border: 1px solid #d3d2d2;
    padding-left: 10px;
    outline: none;
  }
}
.masonry {
  transition: all .5s ease-in-out;
  column-gap: 30px;
  column-fill: initial;
}
.masonry .brick {
  margin-bottom: 30px;
  display: inline-block; /* Fix the misalignment of items */
  vertical-align: top; /* Keep the item on the very top */
}
.masonry.gutterless {
  column-gap: 0;
}
.masonry.gutterless .brick {
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .masonry {
    column-count: 2;
  }
}
@media only screen and (min-width: 1024px) {
  .desc {
    font-size: 1.25em;
  }
  .masonry {
    column-count: 3;
  }
}
.remove-line{
  ::v-deep.daterangepicker option.disabled, ::v-deep.daterangepicker td.disabled{
    text-decoration: none;
  }
}
.error-message{
  background-color: rgba(250,227,227,1);
}

.v-navigation-drawer--temporary {
    z-index: 9999999;
}
</style>
