<template>
  <div class="thank-you-note-preview d-flex flex-column">
    <PreviewPageHeader />
    <div class="px-16">
      <span class="thanks-page_title black1 lato-light">
        Send a <b>Thank you</b> note to {{ previewSender }}
      </span>
      <div class="thanks-content mx-auto">
        <div class="text-area win-mode">
          <textarea
            id="thanksNote"
            v-model="thankYouNote"
            class="thank-you-note f18 font-weight-bold lato-italic"
            no-resize
            auto-grow
            autofocus
          />
          <img class="thanks-note-img-up" src="./../../../assets/thanks-img-1.svg"/>
          <img class="thanks-note-img-down" src="./../../../assets/thanks-img-2.svg"/>
        </div>
        <div class="d-flex flex-wrap justify-space-between mb-10 mt-10 mt-sm-3">
          <div class="pa-0 d-none d-md-block">
            <common-button
              :width="130"
              outlined
              :height="40"
            >
              See ideas
            </common-button>
          </div>
          <div class="pa-0 col-12 col-md-auto d-flex">
            <div class="pa-0 mx-0 mx-md-2 col-6 col-md-auto">
              <common-button
                :width="65"
                outlined
                :height="40"
                @click="$store.commit('preview/setPreviewStep', previewStep + 1)"
              >
                Skip
              </common-button>
            </div>
            <div class="pa-0 col-6 col-md-auto">
              <common-button
                :width="140"
                :height="40"
                @click="$store.commit('preview/setPreviewStep', previewStep + 1)"
              >
                Send note
              </common-button>
            </div>
          </div>
        </div>
      </div>
      <common-footer/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PreviewPageHeader from './../PreviewPageHeader.vue'
export default {
  name: 'ThankYouNotePreview',
  components: {
    PreviewPageHeader
  },
  data: () => ({
    thankYouNote: 'I am touched and grateful, wow, could not a more appreciative of this great gesture!'
  }),
  computed: {
    ...mapState('preview', ['previewSender', 'previewStep'])
  }
}
</script>

<style lang="scss" scoped>
#thanksNote{
  text-align: center;
  &:focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
}
.thanks-page {
  &_title {
    font-size: 28px;
    @media screen and (min-width: 768px) {
      font-size: 42px;
    }
  }
}
.thank-you-note-preview{
  background-color: #fff;
  max-width: 100%;
  .text-area {
    position: relative;
    color: rgba(0,0,0,1);
    font-size: 24px;
    font-style: italic;
    letter-spacing: 0.32px;
    line-height: 29px;
  }
  .thanks-content{
    max-width: 500px;
  }
  .thank-you-note {
    width: 100%;
    min-height: 350px;
    margin-top: 32px;
    margin-bottom: 35px;
    padding: 60px 20px;
    border: 1px solid #D3D2D2;
    border-radius: 8px;
    resize: none;
    @media screen and (min-width: 768px) {
      margin-top: 78px;
      padding: 100px 150px;
    }
    // .v-input__slot {
    //   border: none;
    //   &::before,
    //   &::after {
    //     display: none;
    //   }
    // }
    // textarea {
    //   color: black;
    // }
  }
  .thanks-note-img-up,
  .thanks-note-img-down {
    position: absolute;
  }
  .thanks-note-img-up {
    left: 12px;
    top: -10px;
    width: 36px;
    height: fit-content;
    @media screen and (min-width: 768px) {
      width: 72px;
      left: 27px;
      top: 100px;
    }
  }
  .thanks-note-img-down {
    right: 30px;
    bottom: 35px;
  }
  .showPanel button {
    width: 100% !important;
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  // .skip-note-button {
  //   width: 100%;
  //   margin-left: 0;
    // @media screen and (min-width: 1024px) {
    //   width: 75px;
    //   margin-left: auto;
    // }
  // }
  .send-note-button {
    width: 48%;
    margin-left: auto;
    @media screen and (min-width: 1024px) {
      width: 200px;
      margin-left: 0;
    }
  }
  .thank-you-note-item {
    height: 90vw;
    padding: 6px;
    &.disabled{
      textarea{
        color:#000;
        height: 80%;
      }
    }
    textarea {
      height: 80%;
      width: 100%;
      resize: none !important;
      &:focus{
        background-color: #fff0;
      }
    }
    &_content {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 6.4px;
      border: 0.8px solid #D3D2D2;
      box-shadow: 0 2px 16px 0 rgba(0,0,0,0.06);
      padding: 10px;
      // 21px 15px 16px
      display: flex;
      align-items: center;
      text-align: center;
      font-style: italic;
      .v-text-field {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        height: 100%;
        &__slot{
          textarea{
            height: auto !important;
            max-height: 195px;
          }
        }
      }
      .v-input__control .v-input__slot {
        border: none;
        &::before,
        &::after {
          display: none;
        }
      }
      textarea {
        border: none;
        font-size: 22px;
      }
      .edit-note-button {
        position: absolute;
        right: 15px;
        bottom: 15px;
        cursor: pointer;
      }
    }
  }
  .slick-dots {
    display: flex;
    padding-left: 0;
    li {
      margin: 0;
      width: 10px;
    }
  }
}
</style>
