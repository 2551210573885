<template>
  <div class="more-options pr-md-2">
    <h3 class="my-8">More options:</h3>
    <v-row class="more-options__cards row">
      <v-col
        v-for="option, index in oneGiftOptions"
        :key="index"
        :md="oneGiftOptions.length > 2 ? 4 : 6"
        cols="12"
      >
        <div class="more-options__cards_item d-flex flex-column align-center fill-height justify-space-between">
          <div class="more-options__cards_text d-flex flex-column align-center">
            <h4 class="mt-4">{{ option.header }}</h4>
            <p :class="[option.backendFieldName === 'can_decline' ? 'col-12' : 'col-8', 'mt-1 text-center col-12 pb-0']">{{ option.description }}</p>
          </div>
          <div class="more-options__cards_img-block d-flex flex-column align-center ">
            <img height="50px" class="align-self-center mt-1" :src="option.imgURL" alt="">
            <button
              class="black-border-button my-4 pa-2 text-uppercase "
            >{{ option.buttonText }}</button>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="oneGiftOptions.length && !onlyDecline" class="mb-4 mt-9 row col-12 align-center">
      <p class="f14 gray2 ma-0 px-3">
        <img class="mr-4" src="../../../public/images/img-heart.png">
        {{ sharedText }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PreviewMoreOptions',
  data: () => ({
    allOptions: [
      {
        header: 'Don\'t see anything you like?',
        description: 'Select a gift you like better for an additional fee.',
        imgURL: require('../../../public/images/upgrade_icon.svg'),
        buttonText: 'see upgrade options',
        flagForOpne: 'updateGifts',
        backendFieldName: 'can_upgrade_egift',
        worryText: 'upgrade'
      },
      {
        header: 'Want to Re-Gift it?',
        description: 'Send the gift options above to someone else — for free!',
        imgURL: require('../../../public/images/regift_icon.svg'),
        buttonText: 're-gift it!',
        flagForOpne: 'showReGiftPanel',
        backendFieldName: 'can_regift',
        worryText: 're-gift'
      },
      {
        header: 'Thanks, but I\'m not interested',
        description: "We'll let the sender know you wish to decline this gift. You can send a custom reply to the gift giver in the next step (optional)",
        imgURL: require('../../../public/images/decline_icon.svg'),
        buttonText: 'decline gift',
        flagForOpne: 'showDeclineF',
        backendFieldName: 'can_decline',
        worryText: 'decline'
      }
    ]
  }),
  computed: {
    ...mapState('preview', ['previewCanUpgradeEgift', 'previewCanDecline', 'previewCanRegift', 'previewIsAvailable']),
    settingsShowingOptions () {
      return [
        {
          value: this.previewCanUpgradeEgift,
          optionName: 'can_upgrade_egift'
        },
        {
          value: this.previewCanRegift,
          optionName: 'can_regift'
        },
        {
          value: this.previewCanDecline,
          optionName: 'can_decline'
        }
      ].filter(setting => setting.value && setting.value !== '0')
    },
    oneGiftOptions () {
      const { previewCanDecline, previewCanRegift, previewCanUpgradeEgift, allOptions } = this
      const availableOptions = []

      if (previewCanDecline) availableOptions.push('can_decline')
      if (previewCanRegift) availableOptions.push('can_regift')
      if (previewCanUpgradeEgift) availableOptions.push('can_upgrade_egift')

      return allOptions.filter((option) => availableOptions.includes(option.backendFieldName))
    },
    sharedText () {
      let optionSharedText = ''
      this.oneGiftOptions
        .forEach((item, index) => {
          if (item.worryText !== 'decline') {
            optionSharedText += ` ${item.worryText} ${index !== this.oneGiftOptions.length - 1 ? ' or' : ''}`
          }
        })
      return `Don’t worry, your choice to ${optionSharedText} won’t be shared with anyone.`
    },
    onlyDecline () {
      return Object.values(this.settingsShowingOptions).length === 1 &&
      this.settingsShowingOptions[0].optionName === 'can_decline'
    }
  }
}
</script>

<style lang="scss">
.more-options__cards_item{
  border: solid 8px#F1F1F1;
}
.more-options__cards{
  &_text{
    width: 100%;
  }
  &_img-block{
    width: 100%;
    button{
      width: 50%;
    }
  }
}
.black-border-button {
  border: 2px solid black;
  border-radius: 0;
  color: black;
  font-size: 11px;
  line-height: 11px;
  font-weight: bold;
  letter-spacing: 0;
  width: 150px;
  transition: all 0.5s;
  &:hover{
    border-color: #919191;
    color: #919191;
    transition: all 0.5s;
  }
}
.more-option-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 218px;
  width: 290px;
  border: 8px solid #F1F1F1;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (min-width: 768px) {
    display: none;
  }

  &_title {
    color: #000000;
    font-family: "OpenSans-Bold";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  &_desc {
    color: #000000;
    font-family: 'Lato-Regular';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  }

  &_btn {
    width: 167px;
    height: 33px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-transform: uppercase !important;
    border: 2px solid black !important;
    border-radius: 0   !important;
    font-size: 11px !important;
    color: black !important;
    font-family: 'Lato-Bold' !important;
    font-weight: 700 !important;
    margin-top: 10px !important;
    background-color: white !important;
  }

  p {
    margin-bottom: 10px !important;
  }
}

</style>
