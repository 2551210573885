<template>
  <!-- <div
    class="d-flex justify-start"
    :style="$vuetify.breakpoint.mobile && 'border-bottom: 1px solid #eaeaea'"
  >
    <div
      @click='close'
      class='panel_close-btn pointer d-flex py-md-7 pa-4 px-md-10' -->
  <div class="d-flex justify-start panel_close-btn--wraper pt-4 pt-md-10 pb-3 pl-4 pl-md-13">
    <div
      @click='close'
      class='panel_close-btn pointer d-flex align-center'
    >
      <v-img
        src="../../public/images/arrow_left.svg"
        class="mr-4"
        max-width="12"
        height="18"
        />
      <span class="f14 green3">{{text}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'close-panel-button',
  props: {
    text: {
      default: 'Close',
      type: String
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.panel_close-btn {
  background: #fff9;
  span{
    line-height: 14px;
  }
}
</style>
