<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="none"
    :width="width"
    :height="height"
    :style="`
      min-width: ${width}px;
      min-height: ${height}px;
    `"
  >
    <g id="X">
      <path
        id="Vector"
        d="M0.863328 0.367234C0.979442 0.250826 1.11738 0.158468 1.26924 0.0954521C1.42111 0.032436 1.58391 0 1.74833 0C1.91275 0 2.07555 0.032436 2.22741 0.0954521C2.37927 0.158468 2.51721 0.250826 2.63333 0.367234L9.24833 6.98474L15.8633 0.367234C15.9795 0.251014 16.1175 0.158823 16.2694 0.0959253C16.4212 0.0330276 16.584 0.000654459 16.7483 0.000654459C16.9127 0.000654459 17.0754 0.0330276 17.2273 0.0959253C17.3791 0.158823 17.5171 0.251014 17.6333 0.367234C17.7495 0.483454 17.8417 0.621426 17.9046 0.773275C17.9675 0.925124 17.9999 1.08787 17.9999 1.25223C17.9999 1.41659 17.9675 1.57934 17.9046 1.73119C17.8417 1.88304 17.7495 2.02101 17.6333 2.13723L11.0158 8.75223L17.6333 15.3672C17.7495 15.4835 17.8417 15.6214 17.9046 15.7733C17.9675 15.9251 17.9999 16.0879 17.9999 16.2522C17.9999 16.4166 17.9675 16.5793 17.9046 16.7312C17.8417 16.883 17.7495 17.021 17.6333 17.1372C17.5171 17.2535 17.3791 17.3456 17.2273 17.4085C17.0754 17.4714 16.9127 17.5038 16.7483 17.5038C16.584 17.5038 16.4212 17.4714 16.2694 17.4085C16.1175 17.3456 15.9795 17.2535 15.8633 17.1372L9.24833 10.5197L2.63333 17.1372C2.51711 17.2535 2.37914 17.3456 2.22729 17.4085C2.07544 17.4714 1.91269 17.5038 1.74833 17.5038C1.58397 17.5038 1.42122 17.4714 1.26937 17.4085C1.11752 17.3456 0.979547 17.2535 0.863328 17.1372C0.747108 17.021 0.654917 16.883 0.592019 16.7312C0.529121 16.5793 0.496748 16.4166 0.496748 16.2522C0.496748 16.0879 0.529121 15.9251 0.592019 15.7733C0.654917 15.6214 0.747108 15.4835 0.863328 15.3672L7.48083 8.75223L0.863328 2.13723C0.746919 2.02112 0.654562 1.88318 0.591546 1.73132C0.52853 1.57946 0.496094 1.41665 0.496094 1.25223C0.496094 1.08782 0.52853 0.925013 0.591546 0.77315C0.654562 0.621287 0.746919 0.483348 0.863328 0.367234Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Close',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 18
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    }
  }
}
</script>
