<template>
  <div :class="[!groupedGift ? 'px-xs-3 px-sm-5' : '', 'e-gift e-gift-main']">
    <common-loader v-if="loading || !selectedRoute"/>
    <v-container
      v-if="!loading && selectedRoute && !redeemed && (videoPassed || !videoUrl)"
      fluid
      class="pa-0"
    >
      <v-container
        class="e-gift__header pt-0 px-4 px-md-0"
        :class="{
          'mb-0 mb-md-8 pb-6 pb-md-12': groupedGift,
          'pb-0': redeemPageTitle,
        }"
      >
        <h1 class="hero-recipient black1 lato-light">{{ welcomeMessage }},</h1>

        <template v-if="!redeemPageTitle">
          <h5 v-if="!redeemPageTitle" class="egift-subtext">
            Here is your gift from {{ sender }}
          </h5>

          <h5
            v-if="groupedGift"
            class="f20 black1 lato-light mt-3"
          >
            Enjoy <span class="lato-bold">your</span> gift bundle:
          </h5>
        </template>

        <h5
          v-else
          v-html="redeemPageTitle"
          style="margin-left: 3px"
          class="f20 black1 lato-light px-4 px-md-0"
        />
      </v-container>
      <div class="ma-0">
        <div v-if="gifts.length"
        >
          <v-container class="py-0">
            <SingleGift
              v-if="!loading && gifts.length === 1"
              :gift="gifts[0]"
              :isUnavailable="isSingleGiftUnavailable"
              @selectCard="selectCard"
            />
          </v-container>
          <v-container
            fluid
            :class="[groupedGift ? 'lite-gray' : '', 'pa-0']"
          >
            <FewGifts
              v-if="!loading && gifts.length > 1"
              :gifts="gifts"
              @selectCard="selectCard"
              @selectGroupedGift="selectGroupedGift"
              @showProductDetailPanel="showProductDetailPanel"
            />
          </v-container>
          <v-container>
            <MoreOptions
              @openPanel="openPanel"
            />
          </v-container>
        </div>
      </div>
      <NotAvaibleGiftsModal
        :showDialog="showNotAvaibleGiftsModal"
        :isUnavailable="isSingleGiftUnavailable"
        @loadingUpSellProducts="showUpSellProducts"
        @close="showNotAvaibleGiftsModal = !showNotAvaibleGiftsModal"
      />
      <ReGiftPanel
        :showPanel="showReGiftPanel"
        :gifts="gifts"
        @close="showReGiftPanel = !showReGiftPanel"
      />
      <DeclinePanel
        :showPanel="showDeclineF"
        @close="showDeclineF = !showDeclineF"
        @openReGiftPopUp="showReGiftPanel = !showReGiftPanel"
      />
      <ProductDetailPanel
        :showPanel="showProductDetailPanelF"
        :id="showingProductId"
        @do='selectCard'
        @close="showProductDetailPanelF = !showProductDetailPanelF"
      />
      <UpdateGiftPanel
        v-model="showUpdateGiftsPanel"
      />
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import { mapActions, mapMutations, mapState } from 'vuex'
import NotAvaibleGiftsModal from '../components/eGift/NotAvaibleGiftsModal.vue'
import ReGiftPanel from '../components/eGift/ReGiftPanel.vue'
import DeclinePanel from '../components/eGift/DeclinePanel.vue'
import MoreOptions from '../components/eGift/MoreOptions.vue'
import SingleGift from '../components/eGift/SingleGift.vue'
import FewGifts from '../components/eGift/FewGifts.vue'
import ProductDetailPanel from '../components/eGift/ProductDetailPanel.vue'
import UpdateGiftPanel from '../components/updateGift/UpdateGiftPanel.vue'

Vue.use(VScrollLock)

export default {
  name: 'Egift',
  components: {
    NotAvaibleGiftsModal,
    ReGiftPanel,
    DeclinePanel,
    SingleGift,
    FewGifts,
    MoreOptions,
    ProductDetailPanel,
    UpdateGiftPanel
  },
  data () {
    return {
      imgPathPrefix: process.env.VUE_APP_MAGENTO_URL + '/media/catalog/product/',
      showReGiftPanel: false,
      showWarningF: false,
      showDeclineF: false,
      showProductDetailPanelF: false,
      showUpdateGiftsPanel: false,
      showingProductId: undefined,
      chatEle: null,
      expanded: false,
      showNotAvaibleGiftsModal: false,
      selectedRoute: false
    }
  },
  methods: {
    ...mapMutations('eGift', ['setAllProductsInGiftAreAvailable', 'setSelectedGifts', 'setPreviewMode', 'setMaxSelectItem']),
    ...mapActions('eGift', ['getUpSellProducts']),
    openPanel (flag) {
      this[flag] = !this[flag]
    },
    selectCard (item) {
      this.$store.dispatch('eGift/selectCard', item)
      this.goToNext()
    },
    selectGroupedGift () {
      this.gifts.forEach(gift => {
        this.$store.dispatch('eGift/selectCard', gift)
      })
      this.goToNext()
    },
    addCards () {
      let unSelected = this.gifts
      this.selectedGifts.forEach(gift => {
        unSelected = unSelected.filter(item => item.id !== gift.id)
      })
      this.showWarningF = (unSelected.filter(gift => gift.coins <= this.coinLeft).length > 0)
      if (!this.showWarningF) {
        this.goToNext()
      }
    },
    goToNext () {
      this.$router.push({
        name: 'Setting'
      })
    },
    chatNow () {
      // this.$zendesk.show()
    },
    strongEle () {
      let unSelected = this.gifts
      this.selectedGifts.forEach(gift => {
        unSelected = unSelected.filter(item => item !== gift && item.coins <= this.coinLeft)
      })
      unSelected.forEach(item => {
        const card = document.getElementById('gift' + item.id)
        if (card !== null) {
          card.classList.add('strongCard')
        }
      })
      this.hideAfter = setTimeout(() => {
        unSelected.forEach(item => {
          const card = document.getElementById('gift' + item.id)
          if (card !== null) {
            card.classList.remove('strongCard')
          }
        })
      }, 2000)
    },
    getRecipientHash () {
      const hash = window.location.pathname.replace('/recipient/', '').replace('/gift', '')
      return hash
    },
    showmainGiftDetailPanel () {
      this.showProductDetailPanel(this.mainGift.id)
    },
    showProductDetailPanel (id) {
      this.showProductDetailPanelF = !this.showProductDetailPanelF
      this.showingProductId = id
    },
    reLink () {
      this.$router.push({
        path: `/recipient/${this.recipientHash}/video`
      })
    },
    showUpSellProducts () {
      this.getUpSellProducts()
      if (this.selectedGifts[0]) {
        this.$store.commit('eGift/setCoinLeft', this.coinLeft + this.selectedGifts[0].coins)
      }
      this.$router.push({
        path: `/recipient/${this.recipientHash}/exchange-products`
      })
    }
  },
  async created () {
    if (localStorage.getItem('recipientHash')) {
      localStorage.removeItem('recipientHash')
    }
    localStorage.setItem('recipientHash', this.getRecipientHash())
    if (this.gifts === undefined) {
      await this.$store.dispatch('eGift/getGifts', this.getRecipientHash())
      if (localStorage.getItem('maxGiftNum')) {
        localStorage.removeItem('maxGiftNum')
      }
      localStorage.setItem('maxGiftNum', this.maxGiftNum)
      if (localStorage.getItem('multiMode')) {
        localStorage.removeItem('multiMode')
      }
      localStorage.setItem('multiMode', this.multiMode)
      if (this.getGiftErrorStatus === 'NotAvailable') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/not-available-gift`
        })
      }
      if (this.getGiftErrorStatus === 'Complete') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-redeemed`
        })
      }
      if (this.getGiftErrorStatus === 'Declined') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-declined`
        })
      }
      if (this.getGiftErrorStatus === 'Expired') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-expired`
        })
      }
      if (this.getGiftErrorStatus === 'Canceled') {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/already-canceled`
        })
      }
      if (Number(this.maxGiftNum) > 1 || (!this.multiMode && !this.getGiftErrorStatus.length)) {
        this.$store.commit('eGift/setSelectedGifts', [])
        this.$router.push({
          path: `/recipient/${this.recipientHash}/gift/multiple-choices`
        })
      }
      if (this.$route.path.includes('recipient/preview')) {
        this.setPreviewMode(true)
        this.$router.push({
          path: 'recipient/preview'
        })
      }
      if (this.videoUrl) {
        this.$router.push({
          path: `/recipient/${this.recipientHash}/video`
        })
      }
      if (this.gifts !== undefined) {
        this.chatEle = document.getElementById('launcher')
        this.$store.commit('eGift/setSelectedGifts', [])
        if (!this.multiMode) {
          this.setMaxSelectItem(this.gifts.length)
        }
      }

      this.showNotAvaibleGiftsModal = !this.allProductsInGiftAreAvailable
    }
    this.selectedRoute = true
  },
  computed: {
    ...mapState('eGift', [
      'gifts',
      'recipientHash',
      'redeemPageTitle',
      'sender',
      'coinLeft',
      'selectedGifts',
      'contactNumber',
      'recipientFirstName',
      'recipientLastName',
      'loading',
      'redeemed',
      'maxGiftNum',
      'multiMode',
      'multiSelectMode1',
      'mainGift',
      'detailPanelInfo',
      'loadingProductDetail',
      'upsellProducts',
      'updateGiftsLink',
      'welcomeMessage',
      'videoUrl',
      'allProductsInGiftAreAvailable',
      'getGiftErrorStatus',
      'videoPassed',
      'multiMode',
      'groupedGift'
    ]),
    isSingleGiftUnavailable () {
      const { allProductsInGiftAreAvailable, gifts } = this

      return !allProductsInGiftAreAvailable && gifts?.length === 1
    }
  },
  watch: {
    showWarningF (val) {
      if (!val) {
        this.strongEle()
      }
    }
  }
}
</script>

<style lang="scss">
.e-gift {
  .slider__images-list{
    img{
      box-sizing: border-box;
      border: solid 1px#E4E4E4;
      width: 15%;
      &.active{
        box-sizing: border-box;
        border: solid 3px #42B77A
      }
    }
  }
  .page-logo {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: 12px;
  }

  .page-helper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      cursor: pointer;
    }
  }
  .lite-gray{
    background-color: #f6f6f6;
  }
  .hero-recipient {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 120%;

    @media screen and (min-width: 768px) {
      font-size: 45px;
      font-weight: 300;
    }
  }

  .hero-desc {
    font-weight: 300;
    font-size: 15px;

    @media screen and (min-width: 768px) {
      font-weight: 400;
    }
  }

  .default-gift-wrapper {
    padding-right: 46px;
  }

  .selected-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 262px;
    height: 262px;
    margin-right: 37px;

    img {
      max-width: 262px;
      max-height: 262px;
      object-fit: contain;
    }
  }

  .added-group {
    background-color: #F5F5F5;
    padding: 12px;
    max-height: 80vh;
    overflow: auto;
  }

  .empty-card {
    width: 40%;
    border: 1px dashed #aaa;
    height: 100px;
  }

  .mobile-mode {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: none;

    .min-wrapper {
      display: flex;
      width: 100%;
      height: fit-content;
      align-items: flex-end;
      transition-property: all;
      transition-duration: 0.5s;
      background: #3330;

      &.expanded {
        height: 100vh;
        background: #3335;
      }

      .v-expansion-panel-header {
        padding: 0;

        .v-expansion-panel-header__icon {
          position: absolute;
          top: -20px;
          left: calc(50% - 30px);
          background-color: #dff3f2;
          border-radius: 50%;
          transform: rotate(180deg);

          .v-icon {
            font-size: 40px;
            margin-top: 10px;
            padding: 0 10px;
          }
        }
      }
    }
  }

  .win-mode {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .win-mode {
      display: block;
    }

    .mobile-mode {
      display: none;
    }
  }
}

.min-gifts-wrapper {
  max-height: 60vh;
  overflow: auto;
}
.e-gift-main{
  .container--fluid{
    max-width: 100% !important;
  }
}

</style>

<style lang="scss" scoped>
.egift-subtext {
  font-size: 15px;
  line-height: 18px;
  font-family: 'Lato-Italic', sans-serif;
  font-weight: 400;
  margin-left: 4px;
  color: #A1A1A1;
}
</style>
