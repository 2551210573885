<template>
  <div class="success-page">
    <common-loader v-if="loadingSendGift && !successReGiftNum"/>
    <v-container
      v-if="(!loadingSendGift || successReGiftNum) && (successNum || successReGiftNum)"
      class="success-page mx-auto d-flex flex-column align-center pl-6 pr-6 pa-md-0 mt-n1"
    >
      <div class="success-page__header d-flex flex-column align-center mb-5">
        <h1 class="success-page__title">Woo hoo!</h1>
        <p v-if="successNum && !successReGiftNum" class="success-page__sub-text">
          You’ve redeemed your gift from {{ sender }}
          <br>
        </p>
        <p v-if="!successNum && successReGiftNum" class="success-page__sub-text">
          You’ve regifted your gift from {{ sender }}
        </p>
        <p class="success-page__sub-text"><b>Your confirmation number is {{ orderNumber }}</b> (we’ve emailed you the details)</p>
      </div>

      <template v-if="isPreProduction">
        <p class="success-page__sub-text">ZeroWaste Campaign: Expect 3-6 Weeks for Shipment</p>

        <div class="success-page__alert mb-5">
          <icons-info />

          To significantly reduce waste, we initiate the production of gifts only after all eGift recipients have finalized their selections.
        </div>
      </template>

      <div
        v-if="calendlyLink || freeFormLink.url"
        class="success-main-messages-wrapper col-12 col-md-8 pa-0 mb-5 mb-md-7 d-flex flex-wrap justify-space-between"
      >
        <div
          class="col-12 calendar-background-image success-main-message calendar-link-message pa-0 mb-0 d-flex flex-column align-center justify-end"
        >
          <p class="success-main-message__text mb-5 mb-md-8">
            <template v-if="respondToText">
              <b>{{ respondToText }}</b>
            </template>
            <template v-else>
              <b>Respond to {{ sender }}</b> (optional)
            </template>
          </p>
          <common-button
            v-if="calendlyLink"
            :width="buttonsStyles.width"
            :height="buttonsStyles.height"
            class="success-buttons mb-8 mb-md-12"
            @click="goToBookCalendar"
          >
            Book a meeting
            <template v-slot:rightIcon>
              <v-icon
                light
                dense
                color="#fff"
                class="close-icon ml-3 mb-1 align-center"
              >mdi-calendar-blank-outline</v-icon>
            </template>
          </common-button>

          <common-button
            v-else
            width="200"
            height="46"
            class="rounded-0 mb-8 mb-md-12"
            :href="freeFormLink.url"
            style="text-transform: none !important"
            target="_blank"
          >
            {{ freeFormLink.button }}
          </common-button>
        </div>
      </div>
      <div
        v-if="!calendlyLink && !(orderedGifts.length === 1 && orderedGifts[0].is_virtual)"
        class="delimiter mb-4"
      />
      <template v-if="!giftCardsInfo.length">
        <p
          v-if="!(orderedGifts.length === 1 && orderedGifts[0].is_virtual) && (successNum && !successReGiftNum)"
        >You’ll receive:</p>
        <p
          v-if="!(orderedGifts.length === 1 && orderedGifts[0].is_virtual) && (!successNum && successReGiftNum)"
        >Your recipient will receive:</p>
        <div
          v-if="((pageWidth >= 768 && orderedGifts.length <= 3) || (pageWidth < 768 && orderedGifts.length === 1))"
          class="selected-gifts col-12 col-md-8 d-flex pa-0 mb-5 justify-center"
        >
          <SuccessGiftCard
            v-for="(gift, index) in orderedGifts"
            :key="index"
            :gift="gift"
            class="col-8 col-md-4"
          />
        </div>
      </template>

      <template v-if="giftCardsInfo.length">
        <p class="mt-10 mb-0">Your gift cards:</p>

        <template v-for="(card, index) in giftCardsInfo">
          <div class="selected-gifts col-12 col-md-8 d-flex pa-0 mb-5 mt-10 justify-center" :key="`gift-${index}`">
            <SuccessGiftCard
              :image="giftCardsInfo[index] ? giftCardsInfo[index].image : null"
              :gift="orderedGifts[index]"
              class="col-8 col-md-4"
            />
          </div>

          <div :key="`content-${index}`" class="col-12 col-md-8 d-flex flex-column pa-0 pa-md-3">
            <div
              class="gift-card-code-wrapper d-flex flex-column align-center flex-grow-1 pa-6"
              v-if="card.voucher"
            >
              <div class="mb-3" v-if="voucherHasCode(card.voucher) && !card.voucher.pin_code">
                <span class="lato-bold f18 pa-0">Here is your code: </span>
                <span class="f15 pa-0 d-block d-md-inline-block">
                  {{ card.voucher.redeem_code }}
                </span>
              </div>

              <div
                class="mb-3 f15 d-flex align-center flex-column flex-md-row"
                v-if="voucherHasCode(card.voucher) && card.voucher.pin_code"
              >
                <div>
                  <span class="f18 lato-bold">Your code:</span>
                  <span>
                    {{ card.voucher.redeem_code }}
                  </span>
                </div>
                <span class="px-2" v-if="!$vuetify.breakpoint.mobile">
                  |
                </span>
                <div class="pt-8 pt-md-0">
                  <span class="f18 lato-bold">Pin Code:</span>
                  <span>
                    {{ card.voucher.pin_code }}
                  </span>
                </div>
              </div>

              <div class="mb-3 d-flex flex-column" v-if="!voucherHasCode(card.voucher)">
                <span class="f15 text-center">
                  Redeem the card by enter this URL and follow instructions
                </span>
                <div class="pt-3 f15 d-flex align-center justify-center" v-if="card.voucher.pin_code">
                  <span class="f18 lato-bold pr-1">Pin Code:</span>
                  <span>
                    {{ card.voucher.pin_code }}
                  </span>
                </div>
              </div>

              <Popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0,10px' } }
                }"
                trigger="clickToOpen"
                :delay-on-mouse-out="1000"
                :disabled="!voucherHasCode(card.voucher)"
              >
                <div class="popper d-flex align-center text-left pa-4">
                  <img src="/images/selected_gift.svg" width="24" height="24" class="mr-3">
                  Your code was successfully copied
                </div>
                <div class="mt-auto" style="min-width: 160px" slot="reference">
                  <common-button
                    v-if="voucherHasCode(card.voucher)"
                    @click="copyGiftCardLink(card.voucher)"
                    class="copy-egift-code-button"
                  >
                    Copy Code

                    <icons-copy-document style="margin-top: -4px" />
                  </common-button>

                  <common-button
                    v-else
                    block
                    elevation="0"
                    height="40"
                    :href="card.voucher.redeem_code"
                    target="_blank"
                  >
                    Redeem gift

                    <icons-external-link
                      style="margin-top: -4px; margin-left: 8px"
                    />
                  </common-button>
                </div>
              </Popper>

            </div>

            <div class="error--text text-center" v-if="!card.voucher">
              Error occurred. Please contact our support
            </div>

            <div
              class="gift-card-text-wrapper py-14"
              v-if="card.redemption_instructions || card.terms_and_conditions"
            >
              <template v-if="card.redemption_instructions">
                <h3 class="py-4 py-md-8">
                  How to Redeem/Use:
                </h3>
                <span v-html="card.redemption_instructions" />
              </template>
              <template v-if="card.terms_and_conditions">
                <h3 class="py-4 py-md-8">
                  Terms and conditions:
                </h3>
                <span v-html="card.terms_and_conditions" />
              </template>
            </div>
          </div>
        </template>
      </template>

      <VueSlickCarousel
        v-if="((pageWidth >= 768 && orderedGifts.length > 3) || (pageWidth < 768 && orderedGifts.length > 1))"
        v-model="currentIdx"
        v-bind="settings"
        class="success-selected-gifts-carousel col-10 col-md-8 pa-0 mb-14"
      >
        <SuccessGiftCard
          v-for="(gift, index) in orderedGifts"
          :key="index"
          :gift="gift"
        />
        <template v-slot:prevArrow>
          <v-btn
            x-small
            fab
            shaped
            elevation="0"
            color="#000"
            class="success-selected-gifts-carousel__navigation-button left-arrow"
          >
            <span/>
          </v-btn>
        </template>
        <template v-slot:nextArrow>
          <v-btn
            x-small
            fab
            shaped
            elevation="0"
            color="#000"
            class="success-selected-gifts-carousel__navigation-button right-arrow"
          >
            <span/>
          </v-btn>
        </template>
      </VueSlickCarousel>
    </v-container>
    <v-container v-else class="mx-auto">
      <v-row dense justify="center">
        <v-col cols="12" class="text-center upgrade-success__title pb-15">
          Order completed
        </v-col>

        <v-col cols="12" md="9">
          <v-row dense class="upgrade-success__card">
            <v-col cols="12" md="4">
              <v-row no-gutters class="upgrade-success__product--wrapper">
                <v-col cols="12">
                  <v-img
                    :src="(selectedGifts[0].image && !selectedGifts[0].image.includes('no_selection')) ? selectedGifts[0].image : '/images/empty.png'"
                    :alt="selectedGifts[0].name"
                    contain
                    height="150"
                  />
                </v-col>
                <v-col cols="12" class="upgrade-success__product--title pt-4">
                  {{ selectedGifts[0].name }}
                </v-col>
                <v-col cols="12" class="upgrade-success__product--sku py-3">
                  SKU # {{ selectedGifts[0].sku }}
                </v-col>
                <v-col
                  cols="12"
                  :md="selectedGifts[0].options.length > 1 ? 6 : 12"
                  :key="index"
                  class="upgrade-success__product--option pa-1"
                  v-for="(option, index) in selectedGifts[0].options"
                >
                  <v-row no-gutters align="center">
                    <v-col cols="6">
                      {{ option.title }}:
                    </v-col>

                    <v-col cols="6" v-if="option.type === 'drop_down'">
                      {{ option.values.find((item) => item.id === selectedGifts[0].selectedOptions[`${option.id}`]).title }}
                    </v-col>
                    <v-col cols="6" v-if="option.type === 'field'">
                      {{ selectedGifts[0].selectedOptions[`${option.id}`] }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8">
              <v-row no-gutters class="pa-2 pa-md-8">
                <v-col cols="12" class="card__title">
                  Look for confirmation email in your inbox.
                </v-col>
                <v-col cols="12" class="card__title lato-bold text-center" v-if="orderNumber">
                  Your confirmation number is {{ orderNumber }}
                </v-col>
                <v-col cols="12" class="py-10 card__subtitle">
                  Liked the eGift experience? <br />
                  <a href="https://corporategift.com/request-demo" class="green3">
                    Schedule a demo
                  </a>
                  to see if it's a right fit for your company
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <pre-production-campaign-modal
      v-model="showPreProductionCampaignModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import SuccessGiftCard from '../components/eGift/SuccessGiftCard.vue'
import Popper from 'vue-popperjs'
import PreProductionCampaignModal from '@/components/eGift/ThePreProductionCampaignModal.vue'

export default {
  name: 'Success',
  components: {
    PreProductionCampaignModal,
    VueSlickCarousel,
    SuccessGiftCard,
    Popper
  },
  data: () => ({
    showPopper: false,
    currentIdx: 1,
    settings: {
      arrows: true,
      slidesToShow: 3,
      dots: true,
      dotsClass: 'slick-dots custom-dot-class',
      edgeFriction: 0.35,
      centerPadding: '46px',
      infinite: true,
      speed: 500
    },
    pageWidth: 0,
    showPreProductionCampaignModal: false
  }),
  computed: {
    ...mapState('eGift', [
      'selectedGifts',
      'redeemStatus',
      'successNum',
      'successReGiftNum',
      'gifts',
      'showDemoText',
      'maxGiftNum',
      'isPreProduction',
      'respondToText',
      'multiMode',
      'cardRedeemUrl',
      'cardCode',
      'calendlyLink',
      'sender',
      'giftCardsInfo',
      'loadingSendGift',
      'freeFormLink'
    ]),
    orderedGifts () {
      return this.successNum
        ? this.selectedGifts.filter((gift) => !gift?.hidden)
        : this.gifts.filter((gift) => !gift?.hidden)
    },
    orderNumber () {
      return this.successNum ? this.successNum : this.successReGiftNum
    },
    buttonsStyles () {
      return {
        width: window.innerWidth > 960 ? 200 : 179,
        height: window.innerWidth > 960 ? 46 : 40,
        fontSize: window.innerWidth > 960 ? '15px' : '13px'
      }
    }
  },
  watch: {
    isPreProduction: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.showPreProductionCampaignModal = true
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
      this.pageWidth = window.innerWidth
      if (this.pageWidth < 768) {
        this.settings.slidesToShow = 1
        this.settings.arrows = false
      }
    })
  },
  methods: {
    goToBookCalendar () {
      window.open(this.calendlyLink, '_blank')
    },
    voucherHasCode (voucher) {
      let url = null

      try {
        url = new URL(voucher?.redeem_code)
      } catch (err) {
        return true
      }

      return !url?.protocol?.includes('http') || false
    },
    copyGiftCardLink (voucher) {
      navigator.clipboard.writeText(voucher?.redeem_code)
    }
  }
}
</script>

<style lang="scss">
.success-page {
  .delimiter{
    width: 100%;
    height: 1px;
    background-color: rgb(234,234,234);
  }
  .success-main-message {
    width: 49%;
    background-color: #EDFCFB;
    height: 177px;
    @media screen and (max-width: 959px) {
      height: 280px;
    }
    &.calendar-link-message{
      &.calendar-background-image{
        @media screen and (min-width: 960px) {
          background-image: url('/public/images/success-page-message-background.png'), url('/public/images/success-page-message-background-2.png');
          background-size: auto 120px, auto 150px;
          background-position: 0% 100%, 100% 0%;
        }
      }
      @media screen and (max-width: 959px) {
        height: 137px;
        width: 100%;
      }
    }
    &.card-link-message{
      height: 180px;
      @media screen and (max-width: 425px) {
        height: 200px;
      }
      @media screen and (max-width: 959px) {
        width: 100%;
      }
    }
    .success-buttons button{
      width: 200px;
      height: 46px;
      font-size: 15px;
      @media screen and (max-width: 959px) {
        width: 180px;
        font-size: 13px;
      }
    }
    &__header{
      h1{
        font-weight: 300;
        @media screen and (max-width: 959px) {
          font-size: 32px;
        }
      }
    }
    &__text{
      color: rgba(22,24,31,1);
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      margin: 0 auto;
    }
    &__links{
      width: 100%
    }
    &.only-text{
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
    }
    .v-btn{
      font-size: 15px;
      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 10px;
      justify-content: center;
    }
  }
  .success-selected-gifts-carousel {
    .slick-dots{
      padding: 0;
      left: 0;
      bottom: -42px;
      li > button::before{
        font-size: 10px;
      }
    }
    &__navigation-button{
      background-color: #000;
      position: absolute;
      top: 50%;
      transform: translate(0%, 0%);
      cursor: pointer;
      &.slick-next{
        right: -65px;
      }
      &.left-arrow{
        left: -65px;
        transform: rotate(180deg);
      }
      .v-btn__content{
        &:before, &:after{
          content: '';
          position: absolute;
          width: 10px;
          height: 2px;
          background-color: #fff;
        }
        &:before{
          top: 2px;
          left: 12px;
          transform: rotate(-45deg);
        }
        &:after{
          top: -4px;
          left: 12px;
          transform: rotate(45deg);
        }
      }
    }
  }

  .gift-card-text-wrapper {
    h3 {
      font-size: 18px !important;
      font-family: 'Lato-Bold', 'Lato Bold', sans-serif !important;
      font-weight: 700 !important;
      line-height: 21px;
      color: #000;
    }

    & span {
      * {
        font-size: 15px !important;
        line-height: 23px;
        font-family: 'Lato-Regular', 'Lato Regular', sans-serif !important;
        color: #000;
      }

      strong {
        font-family: 'Lato-Bold', 'Lato Bold', sans-serif !important;
        font-weight: 700 !important;
        display: block;
        padding-top: 12px;
      }

      a {
        color: #42B77A !important;
      }

      p {
        margin: 0 !important;
      }
    }
  }

  .v-btn {
    &__content {
      font-family: 'Lato-Bold', sans-serif !important;
      font-size: 15px;
    }

    &:not(&--outlined):hover {
      background: #919191 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.upgrade-success {
  &__title {
    font-family: 'Lato-Light', sans-serif;
    color: #000;
    line-height: 54px;
    font-size: 45px;
  }

  &__card {
    background: #EDFCFB;
    .card {
      &__title {
        font-family: 'Lato-Regular', sans-serif;
        font-size: 25px;
        line-height: 39px;
        color: #000000;
      }

      &__subtitle {
        font-family: 'Lato-Regular', sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: #16181F;
      }
    }
  }

  &__product {
    &--wrapper {
      height: 100%;
      background: #fff;
      padding: 10px 18px;
    }

    &--title {
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--sku {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 12px;
      line-height: 15px;
      color: #9F9F9F;
    }

    &--option {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #4A4A4A;
    }
  }
}
.gift-card-code-wrapper {
  background-color: #EDFCFB;
}
.copy-egift-code-button {
  width: 160px;
  height: 42px;

  &::v-deep > .v-btn__content {
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    letter-spacing: 0px;

    & > svg {
      width: 20px;
      height: 20px;
      margin-top: -4px;
      color: currentColor;
    }
  }
}

.success-page {
  &__title {
    font-family: 'Lato-Light', 'Lato-Regular', sans-serif;
    font-weight: 300;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    color: #000000;
  }

  &__sub-text {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #000000;

    & > b {
      font-family: 'Lato-Bold', sans-serif;
    }
  }

  &__alert {
    padding: 16px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    background-color: #D2F5F3;

    color: #222325;
    font-family: 'Lato-Regular', 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
