<template>
  <div class="single-ling-campaign-card__images-multiple">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheCampaignImagesMultiple'
}
</script>

<style lang="scss" scoped>
.single-ling-campaign-card__images-multiple {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  background-color: #EDFCFB;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 52px;
  color: #919191;
}
</style>
