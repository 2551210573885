<template>
  <v-col cols="12" md="4">
    <v-card elevation="0">
      <v-img
        :src="(item.image && !item.image.includes('no_selection')) ? item.image : '/images/empty.png'"
        :alt="item.name"
        height="270"
        contain
        max-height="270"
        class="mb-2 mb-md-5"
      />
      <v-card-title class="mt-0 mb-3 mb-md-5">
        {{ item.name }}
      </v-card-title>

      <div class="product-description mb-3" v-html="item.short_description" />

      <span
        class="mb-5 mb-md-6 read-more-link"
        @click="$emit('showProductDetails', item)"
      >
        Read more
      </span>

      <v-form ref="form">
        <div v-for="(option, index) in optionFields" :key="index">
          <v-text-field
            v-model="optionsValues[`${option.id}`]"
            class="rounded-0 thin-border v-input--errors"
            color="black"
            :placeholder="option.title"
            validate-on-blur
            :rules="[
              v => !!v || `${option.title} is a required field`
            ]"
            outlined
            solo
            flat
            dense
            tile
            :height="30"
            @input="setSelectedOptions"
          />
        </div>

        <v-row no-gutters>
          <v-col
            :cols="optionDropDowns.length > 1 ? 6 : 12"
            v-for="(option, index) in optionDropDowns"
            :key="index"
          >
            <TheUpdatePanelGiftCardSelect
              v-model="optionsValues[`${option.id}`]"
              :items="option.values"
              item-value="id"
              item-text="title"
              :placeholder="option.title"
              @input="setSelectedOptions"
              :rules="[
                v => !!v || `${option.title} is a required field`
              ]"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions
        class="pa-0"
      >
        <common-button
          block
          @click="selectGift()"
        >
          <v-row no-gutters>
            <v-col cols="7">
              Get this gift
            </v-col>
            <v-col cols="1"> | </v-col>
            <v-col cols="4">
              +{{
                +item.price | priceFilter({
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0
                })
              }}
            </v-col>
          </v-row>
        </common-button>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import TheUpdatePanelGiftCardSelect from './TheUpdatePanelGiftCardSelect.vue'

export default {
  name: 'TheUpdatePanelGiftCard',
  components: {
    TheUpdatePanelGiftCardSelect
  },
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    optionsValues: {}
  }),
  computed: {
    optionFields () {
      const { item } = this
      return item?.options?.filter(item => item.type === 'field') || []
    },
    optionDropDowns () {
      const { item } = this
      return item?.options?.filter(item => item.type === 'drop_down') || []
    }
  },
  created () {
    this.$set(this.item, 'selectedOptions', null)
  },
  methods: {
    setSelectedOptions () {
      this.$emit('updateOptionsValues', this.optionsValues)
    },
    selectGift () {
      if (this.$refs.form.validate()) {
        this.$emit('selectItem', this.item)
      }
    }
  }
}
</script>

<style lang="scss">
.product-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: 'Lato-Regular', sans-serif;
  line-height: 17px;
  font-family: Lato, sans-serif;
  font-weight: normal;
  color: #aaa;
  & li {
    list-style: unset !important;
  }
  & p {
    margin-bottom: 0px !important;
  }
}
.thin-border {
  fieldset {
    border-width: 1px !important;
  }
}
.v-input--errors {
  & .v-text-field__details {
    padding: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.v-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__title {
    font-size: 18px;
    font-family: 'Lato-Regular', sans-serif;
    line-height: 22px;
    color: #000;
    display: -webkit-box;
    padding: 0;
    margin: 16px 0;
    word-break: break-word;
    height: 44px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .read-more-link {
    font-family: 'Lato-Regular', sans-serif;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: #42B77A;
  }

  &__actions {
    padding: 8px 0;
    margin-top: auto;

    & .row .col {
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: 18px;
    }
  }
}
</style>
