<template>
  <div
    class="decline-panel"
    :style="{height: '100%'}"
  >
    <v-navigation-drawer
      v-model='show'
      app
      temporary
      right
      width='1065px'
      class="decline-panel ma-0 pa-0"
      v-scroll-lock="!$vuetify.breakpoint.mobile && show"
      :style="{height: '100%'}"
    >
      <div v-if="wasDeclined && !statusDecline" class="error-message py-4 px-13 lighten-4 d-flex align-center"
      >
        <img
          src="@/assets/error-icon.svg"
          class="lighten-1 d-flex justify-center mr-4"
        />
        <p class="mb-0">{{ declineErrorMessage }}. Please try it again</p>
      </div>
    <close-panel-button
      @close="show = !show"
      text="Cancel"
    />
    <common-loader v-if="showLoading"/>
    <div
      v-if="!showLoading"
      class="decline-panel__main mt-7 mt-md-10 px-4 px-md-13"
    >
      <div class="re-gift-panel__header">
        <p class="text-h3 font-weight-light">No gift? No problem.</p>
      </div>
      <div class="re-gift-panel__body mt-9 col-lg-6 pa-0">
        <div class="f15 open-sans-bold">
          Please tell us why you're not interested.
        </div>
        <div>
          <label
            v-for="option in optionsArray"
            :key="option.id"
            class="form_radio__custom mt-3"
          >
            <input type="radio" v-model="selectedOptionId" :value="option.id" name="declineReason">
            <span class="radiomark"></span>
            <span class="black1 f15">{{ option.text }}</span>
          </label>
          <div class="re-gift-panel__greeting mt-7">
            <h4>Your feedback <span class="font-weight-light">(optional)</span></h4>
            <textarea
              class="px-2 py-1 mt-4"
              v-model="feedback"
              no-resize
              auto-grow
              placeholder="Let your gift giver know why you declined this gift"
            />
          </div>
          <div class="d-flex mt-7 align-center">
            <common-button
              outlined
              :width="100"
              class="mr-3 mt-2"
              @click="show = !show"
            >
              Cancel
            </common-button>
            <common-button
              class="mt-2"
              @click="doDecline"
            >
              Decline this gift
            </common-button>
          </div>
          <div class="mt-9 f15 gray1 font-italic">
            This gift will no longer be available to redeem after clicking DECLINE THIS GIFT
          </div>
          <div class="d-flex flex-row mt-7">
            <div
              v-if="upgradable === '1' || reGiftable === '1'"
              class="f15 gray1 font-italic mr-4"
            >
              Second thought? you can also:
            </div>
            <div class="d-flex align-center">
              <span v-if="upgradable === '1'">
                <a
                  :href="updateGiftsLink"
                  class="green3 pointer"
                >Upgrade</a>
              </span>
              <span v-if="upgradable === '1' && reGiftable === '1'" class="gray0 f12 mx-2">|</span>
              <span
                v-if="reGiftable === '1'"
              >
                <a
                  @click="openReGiftPopUp"
                  class="green3 pointer"
                >Re-gift</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
  </div>
</template>

<script>
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import { mapState } from 'vuex'
import Api from '../../axios/api'
Vue.use(VScrollLock)

export default {
  name: 'DeclinePanel',
  props: {
    showPanel: Boolean
  },
  data: () => ({
    selectedOptionId: 1,
    feedback: '',
    show: false,
    optionsArray: [],
    wasDeclined: false,
    statusDecline: false,
    declineErrorMessage: '',
    showLoading: false
  }),
  methods: {
    cancelDecline () {},
    async doDecline () {
      this.showLoading = true
      const response = await Api.post(`/recipient/${this.recipientHash}/decline`, {
        reason_id: this.selectedOptionId,
        feedback: this.feedback
      })
      this.wasDeclined = true
      this.statusDecline = response.success
      if (!this.statusDecline) {
        this.showLoading = false
        this.declineErrorMessage = response.message
        return
      }
      this.showLoading = false
      this.$router.push({
        path: '/feedback'
      })
    },
    openReGiftPopUp () {
      this.$emit('openReGiftPopUp')
      this.show = !this.show
    }
  },
  computed: {
    ...mapState('eGift', ['recipientHash', 'updateGiftsLink', 'upgradable', 'declinable', 'reGiftable'])
  },
  async created () {
    await Api.get(`recipient/${this.recipientHash}/decline/reasons`)
      .then((data) => { this.optionsArray = data })
  },
  watch: {
    showPanel (val) {
      this.show = val
    },
    show (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a{
  text-decoration: none;
}
.feedback-thanks{
  img{
    width: 150px;
  }
}
textarea{
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #D3D2D2;
  color: #000;
  resize: none;
  &::placeholder{
    font-style: italic;
    color: #A1A1A1;
  }
}
.error-message{
  background-color: rgba(250,227,227,1);
}
.decline-panel {
  display: flex;
  flex-direction: column;
  background-color: white;
&.expanded {
  height: 100%;
  bottom: 0;
}
.more-options{
  cursor: pointer;
}
  .feedback-note {
    margin-top: 18px;
    margin-bottom: 35px;
    padding: 14px 20px 13px;
    border: 1px solid #D3D2D2;

    @media screen and (min-width: 768px) {
      margin-top: 78px;
      padding: 100px 150px;
    }

    .v-input__slot {
      border: none;

      &::before,
      &::after {
        display: none;
      }
    }

    textarea {
      height: 76px !important;
    }
  }
}
.v-navigation-drawer--temporary {
    z-index: 9999999;
}
</style>
