<template>
  <v-dialog
    v-model='show'
    max-width='475'
    max-height='300'
  >
    <v-card class="modal-content warning-modal pa-9 rounded-0">
      <v-icon @click="show = !show" class='close-icon'>mdi-close</v-icon>
      <h4
        v-if="multiMode"
        class="pb-8 text-left font-weight-medium h6 font-weight-bold"
      >
        Are you sure you want to continue?<br>
        You can still add {{ numberRemainingSlots }} gifts to your package
      </h4>
      <h4
        v-else
        class="pb-8 text-left font-weight-medium h6 font-weight-bold"
      >Are you sure you want to continue?<br>
      You can still add gifts to your package ({{coinLeft}} available Gift{{multiMode ? (coinLeft > 1 ? 's' : '') : 'Coin'}})
      </h4>
      <div class="d-flex flex-sm-row flex-column">
        <div class="col-12 col-sm-6 pa-0 pr-sm-3">
          <common-button
            @click="show = !show"
            width="100%"
          >
            Select more gifts
          </common-button>
        </div>
        <div class="col-12 col-sm-6 pa-0 pl-sm-3">
          <common-button
            width="100%"
            outlined
            @click="$emit('do')"
            class="mt-3 mt-sm-0"
          >
            Continue
          </common-button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SelectedNotAllAvailableProductsPopUp',
  props: ['showDialog', 'numberRemainingSlots'],
  data: () => ({
    show: false
  }),
  computed: {
    ...mapState('eGift', ['multiMode', 'coinLeft'])
  },
  watch: {
    showDialog (val) {
      this.show = val
    },
    show (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
