<template>
  <v-img
    :src="image"
    :alt="name"
    contain
    :max-height="size"
    :max-width="size"
    :height="size"
    :width="size"
  />
</template>

<script>
export default {
  name: 'TheCampaignImage',
  props: {
    image: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: Number,
      required: false,
      default: 180
    }
  }
}
</script>
