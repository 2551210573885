<template>
  <v-container class="thank-you-page d-flex flex-column justify-center align-center">
    <div class="message-sent d-flex flex-column align-center col-12">
      <h1 class="mt-5 text-h3 text-center font-weight-light">Message sent</h1>
      <h3 class="mt-5 text-h5 text-center font-weight-light">Order ID: #{{ id }}. You will receive a confirmation email soon</h3>
      <div class="message-sent__body col-12 col-md-6 d-flex flex-column align-center blue lighten-5 pa-6 mt-10">
        <img src="../assets/cup.png" alt="">
        <p
          class="mt-8 text-center font-weight-bold mt-6"
        >Schedule a meeting with {{ sender }} ?</p>
        <common-button
          @click="goToNext"
          width="100%"
          class="mt-5 col-12 col-sm-8 col-md-6 col-lg-4 pa-0"
        >
          Book
        </common-button>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MessageThankYouPage',
  methods: {
    goToNext () {
      this.$router.push({
        path: `/recipient/${this.recipientHash}/gift`
      })
    }
  },
  computed: {
    ...mapState('eGift', ['recipientHash', 'sender', 'id'])
  }
}
</script>

<style lang="scss">
.thank-you-page{
  height: 100%;
  color: #16181f;
  img{
    width: 30px;
  }
}
.message-sent{
  &__body{
    max-height: 250px;
  }
}
</style>
