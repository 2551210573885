<template>
  <Popper
    class="d-flex"
    :options="{
      placement: 'top',
      modifiers: { offset: { offset: '0,10px' } }
    }">
    <div
      class="popper px-4 py-2 text-left"
      :style="`max-width: ${maxWidth}px`"
    >
      <slot />
    </div>
    <icons-question slot="reference" :width="20" :height="20" />
  </Popper>
</template>

<script>
import Popper from 'vue-popperjs'

export default {
  name: 'TheContactInfoFormPopper',
  components: {
    Popper
  },
  props: {
    maxWidth: {
      type: [String, Number],
      required: false,
      default: null
    },
    iconSize: {
      type: [String, Number],
      required: false,
      default: 20
    }
  }
}
</script>
