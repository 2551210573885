<template>
  <div class="video-preview">
    <PreviewPageHeader />
    <div class="px-16">
      <div class="video-preview__gift-data gift-data d-flex flex-column align-center">
        <div class="video-preview__header">
          <h1 class="hero-recipient black1 lato-light">Hi {{previewFirstname}}!</h1>
          <h5 class="egift-subtext">
            <template v-if="videoOpeningLine">{{ videoOpeningLine }}</template>
            <template v-else>
              You’ve received a gift from {{ previewSender }}! Check out their video greeting:
            </template>
          </h5>
        </div>
        <div class="video-preview__main mt-sm-6 mt-md-12 d-flex flex-column align-center">
          <div v-if="previewVideoUrl" class="iframe-wrap col-12 col-md-10 d-flex flex-column align-center">
            <iframe
              :width="90 + '%'"
              :height="iFrameHeigth"
              :src="videoUrlString"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <common-button
              :width="pageWidth > 425 ? 350 : '100%'"
              class="my-8 px-4 col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4 pa-0 text-wrap"
              @click="$store.commit('preview/setPreviewStep', previewStep + 1)"
            >
              Continue to redeem your gift
              <template v-slot:rightIcon>
                <v-icon
                  v-if="pageWidth > 425"
                  class="ml-3"
                  dark
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </common-button>
          </div>
        </div>
      </div>
      <common-footer/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PreviewPageHeader from './../PreviewPageHeader.vue'

export default {
  name: 'VideoPreview',
  components: {
    PreviewPageHeader
  },
  data: () => ({
    pageWidth: null
  }),
  computed: {
    ...mapState('preview',
      [
        'videoOpeningLine',
        'previewFirstname',
        'previewVideoUrl',
        'previewSender',
        'previewStep'
      ]
    ),
    videoUrlString () {
      try {
        const newUrl = new URL(this.previewVideoUrl)
        if (this.previewVideoUrl.includes('youtu')) {
          if (!newUrl.search) { return `https://www.youtube.com/embed${newUrl.pathname}` }

          function getYoutubeId (url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
            const match = url.match(regExp)

            return (match && match[2].length === 11)
              ? match[2]
              : null
          }
          return `https://www.youtube.com/embed/${getYoutubeId(newUrl.href)}`
        }
        if (this.previewVideoUrl.includes('vimeo')) {
          const array = this.previewVideoUrl.split('/')
          return `https://player.vimeo.com/video/${array[array.length - 1]}`
        }
        if (this.previewVideoUrl.includes('loom')) {
          const array = this.previewVideoUrl.split('/')
          return `https://www.loom.com/embed/${array[array.length - 1]}`
        }
        if (this.previewVideoUrl.includes('vidyard')) {
          const array = this.previewVideoUrl.split('/')
          return `https://play.vidyard.com/${array.at(-1)}?disable_popouts=1&v=4.3.10&type=inline`
        }
        return ''
      } catch (error) {
        console.log(error)
        return 'https://www.youtube.com/'
      }
    },
    iFrameHeigth () {
      return window.innerWidth > 768 ? 500 : 300
    }
  },
  mounted () {
    this.pageWidth = window.innerWidth
  }
}
</script>

<style lang="scss" scoped>
.video-preview{
  background-color: #fff;
  &__main{
    width: 100%;
  }
  &__header{
    width: 100%;
  }
}
.egift-subtext {
  font-size: 15px;
  line-height: 18px;
  font-family: 'Lato-Regular', sans-serif;
  font-weight: 400;
  color: #000;
}
</style>
