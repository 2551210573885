<template>
  <div class="contact-info-card__wrap pa-3">
    <div class="contact-info-card text-center">
      <div class="f16 text-uppercase lato-bold black1">
        We're here to help!
      </div>
      <div class="mt-4 gray2">
        Complete your order with one of our representatives.
      </div>
      <div class="mt-5 pointer">
        <img src="/images/icon-phone.svg" width="25" height="24" />
      </div>
      <div class="f18 black1 pointer">
        {{num}}
      </div>
      <div class="mt-3 pointer">
        <img src="/images/chat_icon.svg" width="30" height="31" />
      </div>
      <common-button
        @click="call"
        :width="115"
        class="mt-2"
      >
        Chat now
      </common-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactInfoCard',
  props: {
    num: {
      type: String,
      default: '646-751-7075'
    }
  },
  methods: {
    call () {
      this.$emit('do')
    }
  }
}
</script>

<style lang="scss">
.contact-info-card {
  background-color: #EDFCFB;
  padding: 28px 28px 24px 24px;
  height: auto;
}
</style>
