<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    dark
    tile
    depressed
    height="44"
    :color="buttonColor"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'CommonButton',
  props: {
    color: {
      type: String,
      required: false,
      default: '#000'
    }
  },
  computed: {
    userButtonColor () {
      return this.$store.state.buttonColor ?? null
    },
    buttonColor () {
      const { color, userButtonColor } = this

      if (userButtonColor) { return userButtonColor }
      return color ?? '#000'
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
  letter-spacing: normal;
  text-transform: none;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 18px;

  &__content {
    font-family: inherit;
    letter-spacing: normal;
  }

  &:not(&--outlined):hover {
    background: #62646A !important;
  }

  &:is(&--text):hover {
    background: inherit !important;
  }

  &--disabled {
    background: #C0C0C0 !important;
    color: #fff !important;
  }

  &--outlined {
    border-width: 2px !important;
    color: #000;

    &:hover {
      color: #62646A !important;
      border-color: #62646A !important;
      background-color: #fff !important;

      &::before {
        background-color: transparent;
      }
    }
  }
}

.theme--light.v-btn.v-btn--disabled {
  background: #C0C0C0 !important;
  color: #fff !important;
}
</style>
