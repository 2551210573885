<template>
  <div class="preview-page-header">
    <div class="page-logo" v-if="logoFromQueryString && logoFromQueryString !== 'null'">
      <img :src="logoFromQueryString">
    </div>
    <v-container class='d-flex flex-column px-16'>
      <div class="page-helper mt-3 mb-4 ml-auto">
        <span class="win-mode">Questions?</span><div class="f14 d-flex green3 ml-2"><span class="win-mode mr-1">View</span>FAQs</div>
      </div>
    </v-container>
    <e-gift-faq-panel/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PreviewPageHeader',
  computed: {
    ...mapState('preview', ['previewLogoUrl']),
    logoFromQueryString () {
      return this.$route.query?.logo_url || this.previewLogoUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.page-logo {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #D3D3D3;
  padding: 12px 0;

  img {
    max-width: 80vw;
    max-height: 40px;

    @media screen and (min-width: 1024px) {
      max-width: 50vw;
    }
  }
}

.page-helper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  div, span {
    cursor: pointer;
  }
}
</style>
