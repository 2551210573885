<template>
  <v-text-field
    v-model="modelValue"
    v-bind="$attrs"
    v-on="$listeners"
    class="rounded-0 thin-border"
    :class="{
      'common-input--filter' : (filterStyle && modelValue)
    }"
    color="black"
    outlined
    solo
    flat
    dense
    :hide-details="hideDetails"
    :validate-on-blur="validateOnBlur"
    tile
    :height="height"
  >
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot" />
      </template>
    </template>
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'CommonInput',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    height: {
      type: [String, Number],
      required: false,
      default: 36
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    filterStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    validateOnBlur: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
input[type=number] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
  -webkit-appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.v-text-field__prefix {
  color: #919191;
}

.common-input--filter {
  & .v-input__slot {
    background-color: rgb(237, 252, 251) !important;
  }
}

.v-input__control {
  .v-input__slot {
    fieldset {
      display: none;
    }
  }
}

.error--text {
  .v-input__slot {
    border-color: #FA5E5E !important;
  }
}

.v-text-field__details {
  padding: 0 !important;
}
</style>
