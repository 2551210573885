import { render, staticRenderFns } from "./CoinStatus.vue?vue&type=template&id=06d3b261&"
import script from "./CoinStatus.vue?vue&type=script&lang=js&"
export * from "./CoinStatus.vue?vue&type=script&lang=js&"
import style0 from "./CoinStatus.vue?vue&type=style&index=0&id=06d3b261&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports