<template>
  <div class="selected-gifts-item__square d-flex flex-column align-center px-md-10">
    <img
      :src="productImage"
      :alt="gift.name"
      class="selected-gifts-item__image mb-4"
    />
    <div class="selected-gifts-item__description d-flex flex-column flex-start">
      <p class="selected-gifts-item__header mb-2 f15 lato-bold black1">{{ gift.name }}</p>
      <div v-if="!gift.is_virtual">
        <div
          v-if="giftOptions"
          class="selected-gifts-item__option-wrapper d-flex flex-wrap align-top"
        >
          <p
            v-for="opt in giftOptions"
            :key="opt.id"
            class="selected-gifts-item__option col-12 ma-0 mb-1 pa-0 d-flex f14 gray4 ma-0"
          >{{opt.title + ': ' + formatedOptionValue(opt)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SuccessGiftCard',
  props: {
    gift: {
      type: Object,
      required: true
    },
    image: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    formatedOptionValue (option) {
      return option.valTitle ? option.valTitle : option.val
    }
  },
  computed: {
    giftOptions () {
      return this.gift.option || this.gift.options || []
    },
    productImage () {
      const { gift, image } = this
      if (image) return image
      if (gift.image && !gift.image.includes('no_selection')) return gift.image
      return '/images/empty.png'
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-gifts-item{
  &__square {
    position: relative;
    display: block;
    padding: 0;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
  &__header{
    overflow-y: hidden;
    height: 40px;
  }
  &__description{
    width: 100%;
    .popper {
      width: 280px;
      img{
        width: 25px;
        height: 25px;
      }
    }
  }
  &__option{
    overflow-wrap: break-word;
    color: rgba(74,74,74,1);
  }
}
</style>
