<template>
  <v-container :class="!$vuetify.breakpoint.mobile && 'px-12 pt-3 py-7'">
    <v-row dense>
      <v-col cols="12" class="thank-you-note--title">
        Send a
        <span class="lato-bold">
          Thank-You
        </span>
        note to {{ sender }}
      </v-col>

      <v-col cols="12" sm="8" md="6" class="pt-10">
        <ThankYouForm
          :color.sync="thankYouColor"
          :message.sync="thankYouNote"
          :sender.sync="thankYouSender"
        />
      </v-col>

      <v-col cols="12" md="8" class="d-flex pt-10">
        <common-button
          outlined
          style="min-width: 100px"
          @click="showSkipModal = true"
        >
          Skip
        </common-button>
        <common-button
          class="ml-3"
          @click="$emit('sendThankYouNote')"
          style="min-width: 148px"
        >
          Send note
        </common-button>
      </v-col>
    </v-row>

    <SkipThanksModal
      :show="showSkipModal"
      in-custom-panel
      @skip="$emit('skipThankYouNote')"
      @close="showSkipModal = false"
      @sendNote="showSkipModal = false"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import SkipThanksModal from '@/components/eGift/SkipThanksModal.vue'
import ThankYouForm from '@/components/ThankYouForm.vue'

export default {
  name: 'ThankYouNote',
  components: {
    ThankYouForm,
    SkipThanksModal
  },
  props: {
    message: {
      type: String,
      required: false,
      default: null
    },
    messageSender: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    showSkipModal: false
  }),
  computed: {
    ...mapState('eGift', ['sender']),
    thankYouNote: {
      get () {
        return this.message
      },
      set (val) {
        this.$emit('update:message', val)
      }
    },
    thankYouSender: {
      get () {
        return this.messageSender
      },
      set (val) {
        this.$emit('update:messageSender', val)
      }
    },
    thankYouColor: {
      get () {
        return this.color
      },
      set (val) {
        this.$emit('update:color', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.thank-you-note {
  &--title {
    font-size: 30px;
    font-family: 'Lato-Light', sans-serif !important;
    line-height: 36px;
    color: #000000;
  }
}

@media (min-width: 960px) {
  .thank-you-note {
    &--title {
      font-size: 42px;
      line-height: 50px;
    }
  }
}
</style>
