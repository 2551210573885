<template>
  <v-container
    :class="!$vuetify.breakpoint.mobile"
    class="pa-0"
  >
    <v-row dense>
      <StepHeader
        class="mb-8 mb-md-10 pa-0"
      >
        <template #title>
          See something you like better?
        </template>
        <template #subtitle>
          Exchange your gift, or re-gift one of these, for an upgrade fee
        </template>
        <template #subtitle-with-heart>
          Don't worry, your choice to upgrade or re-gift won't be shared with anyone
        </template>
      </StepHeader>

      <v-col cols="12" class="pa-0">
        <v-row>
          <TheUpdatePanelGiftCard
            v-for="(gift, index) in items"
            :key="index"
            :item="gift"
            @updateOptionsValues="(val) => setOptionsValues(val, gift)"
            @selectItem="$emit('selectItem', gift)"
            v-on="$listeners"
            class="py-0 mb-13"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheUpdatePanelGiftCard from '../TheUpdatePanelGiftCard.vue'
import StepHeader from '../StepHeader.vue'

export default {
  name: 'ProductSelect',
  components: {
    TheUpdatePanelGiftCard,
    StepHeader
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    setOptionsValues (options, gift) {
      // updated options valTitle for success page
      Object.keys(options).forEach((optionId) => {
        const optionIndex = gift.options.findIndex((option) => option.id === +optionId)
        const optionType = gift.options[optionIndex].type
        const selectedValueForOption = options[`${optionId}`]
        if (optionType === 'drop_down') {
          const optionValues = gift.options[optionIndex].values
          const selectedOptionIndex = optionValues.findIndex((value) => value.id === selectedValueForOption)
          gift.options[optionIndex].valTitle = optionValues[selectedOptionIndex].title
        } else {
          gift.options[optionIndex].valTitle = selectedValueForOption
        }
      })
      // set selectedoptions
      gift.selectedOptions = options
    }
  }
}
</script>
