const setCookie = (name, value, activeDays = 30) => {
  const maxAge = 60 * 60 * 24 * activeDays;
  const expiration = `max-age=${maxAge};`;

  const domainInfo = window.location.host.split('.');
  if (!['staging', 'development', 'dev', 'corporategift'].includes(domainInfo[0])) {
    domainInfo.splice(0, 1);
  }

  let domain = `domain=.${domainInfo.join('.')};`;

  if (window && window.location.host.includes('localhost')) {
    domain = '';
  }

  document.cookie = `${name}=${value};${domain}path=/;${expiration}`;
};

export default setCookie
