<template>
  <v-dialog v-model="show" persistent eager content-class="elevation-0" width="460">
    <div class="pre-production-campaign-modal">
      <div class="pre-production-campaign-modal__content">
        To significantly reduce waste, we initiate the production of gifts only after all eGift recipients have finalized their selections.

        <div class="pre-production-campaign-modal__actions">
          <common-button
            elevation="0"
            height="44"
            @click="show = false"
          >
            Ok
          </common-button>
        </div>
      </div>

      <icons-close @click.native="show = false" class="pointer" />
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'ThePreProductionCampaignModal',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pre-production-campaign-modal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;

  &__content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 20px;

    color: #000;
    font-family: 'Lato', 'Lato-Regular', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 12px;

    &::v-deep > button > span {
      font-size: 18px;
    }
  }
}
</style>
