<template>
  <div class="accept-policy" v-show="readMoreText || gdprTextArray.length">
    <div class="mt-4 wrap">
      <p v-if="showToolTip" class="tooltip-wrap" />
      <p class="ma-0 pa-0 accept-policy-styles" v-html="gdprTextArray[0]" />

      <div class="tooltip">
        <span class="tooltip-hover-point">Read more.</span><br>
        <div class="tooltiptext-wrap pb-8">
          <div
            class="tooltiptext accept-policy-styles"
            v-html="`${readMoreText}<br>${gdprTextArray[1]}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AcceptPolicy',
  props: ['isGdprChecked'],
  data: () => ({
    gdprTextArray: [],
    readMoreText: '',
    showToolTip: false
  }),
  computed: {
    ...mapState('eGift', ['consentContent'])
  },
  methods: {
    ...mapActions('eGift', ['getConsentContent']),
    mouseoverHandler () {
      this.showToolTip = true
    },
    mouseleaveHandler () {
      this.showToolTip = false
    }
  },
  async created () {
    await this.getConsentContent()
    this.gdprTextArray = this.consentContent.gdpr_text.split('Read More')
    this.readMoreText = this.consentContent.read_more_text
  }
}
</script>

<style lang="scss" scoped>
p{
  display: inline;
}
.tooltip {
  position: relative;
  display: inline;

  &-hover-point{
    cursor: pointer;
    white-space: nowrap;
    font-family: 'Lato-Italic', 'Lato', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;

    @media screen and (max-width: 430px) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &:hover{
    .tooltiptext-wrap {
      display: block;
      position: absolute;
      bottom: 0px;
      left: -25px;
      width: 300px;
      text-align: left
    }
  }

  .tooltiptext-wrap {
    display: none;
    max-width: 600px;
    position: absolute;
    z-index: 1;

    .tooltiptext{
      background-color: #fff;
      color: #000;
      padding: 16px;
      position: relative;
      min-width: 330px;
      box-shadow: 0 2px 35px 0 rgba(0,0,0,0.13);

      &::first-letter {
        text-transform: uppercase;
      }

      ::after{
        content: '';
        position: absolute;
        bottom: -10px;
        left: 10%;
        background-color: #fff;
        padding: 10px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}
a{
  color: #27955C
}
span{
  color: #42B77A;
}

.accept-policy-styles {
  font-family: 'Lato-Italic', 'Lato', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #A1A1A1;

  &::v-deep > a {
    color: #42B77A;
    text-decoration: none;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: inherit;
    cursor: pointer;
    line-height: inherit;
  }

  @media screen and (max-width: 430px) {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
