var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',_vm._g(_vm._b({staticClass:"rounded-0 thin-border update-gift-select v-input--errors",attrs:{"items":_vm.items,"attach":_vm.attachTo,"color":"black","validate-on-blur":"","append-icon":"mdi-chevron-down","outlined":"","solo":"","flat":"","dense":"","tile":"","height":30,"menu-props":{
    bottom: true,
    offsetY: true,
    left: true,
    tile: true,
    contentClass: 'update-gift-select-items'
  }},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},'v-select',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }