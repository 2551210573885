var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-form-preview d-flex flex-column"},[_c('PreviewPageHeader'),_c('div',{staticClass:"px-16"},[_c('h1',{staticClass:"heading lato-light pa-3"},[_vm._v("Where should we send your gift?")]),_c('div',{staticClass:"row mt-9 mx-0 px-0"},[_c('div',{staticClass:"col-md-5"},[_c('form',{staticClass:"preview-shipping-block",attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"shipping-inputs-wrapper"},[_c('div',{staticClass:"shipping-input__item mb-8"},[_vm._m(0),_c('div',{staticClass:"shipping-input__main d-flex"},[_c('input',{staticClass:"shipping-input col-6 mb-0",attrs:{"type":"text","placeholder":"First name"},domProps:{"value":_vm.previewFirstname}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.previewLastname),expression:"previewLastname"}],staticClass:"shipping-input col-6 mb-0",attrs:{"type":"text","placeholder":"Last name"},domProps:{"value":(_vm.previewLastname)},on:{"input":function($event){if($event.target.composing)return;_vm.previewLastname=$event.target.value}}})])]),_c('div',{staticClass:"shipping-input__item mb-8"},[_c('div',{staticClass:"shipping-input__header d-flex align-center mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0 mr-2"},[_vm._v("Email")]),_c('Popper',{staticClass:"d-flex mt-n1 pointer",attrs:{"options":{
                    placement: 'top',
                    modifiers: { offset: { offset: '0,10px' } }
                  }}},[_c('div',{staticClass:"popper pa-6 text-left"},[_c('span',[_vm._v("We'll send you order confirmation here.")])]),_c('icons-question',{attrs:{"slot":"reference","width":20,"height":20},slot:"reference"})],1)],1),_c('div',{staticClass:"shipping-input__main"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.previewEmail),expression:"previewEmail"}],staticClass:"shipping-input",attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.previewEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.previewEmail=$event.target.value}}})])]),((_vm.actualPreviewSelectedGifts.length === 1 && !_vm.actualPreviewSelectedGifts[0].is_virtual) || _vm.actualPreviewSelectedGifts.length !== 1)?_c('div',{staticClass:"for-real-gift"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"shipping-input__item mb-8"},[_vm._m(4),_c('div',{staticClass:"shipping-input__main"},[_c('region-select',{ref:"stateSelect",staticClass:"shipping-input select-wrapper with-arrow",attrs:{"id":"state"}})],1)]),_c('div',{staticClass:"shipping-input__item mb-8"},[_vm._m(5),_c('div',{staticClass:"shipping-input__main"},[_c('country-select',{staticClass:"with-arrow shipping-input select-wrapper",attrs:{"id":"country","topCountry":"US"}})],1)]),_c('div',{staticClass:"shipping-input__item mb-8"},[_c('div',{staticClass:"shipping-input__header d-flex align-center mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0 mr-2"},[_vm._v("Phone")]),_c('Popper',{staticClass:"d-flex mt-n1 pointer",attrs:{"options":{
                      placement: 'top',
                      modifiers: { offset: { offset: '0,10px' } }
                    }}},[_c('div',{staticClass:"popper pa-6 text-left"},[_c('span',[_vm._v("It is rare, but in some cases, we may need the recipient’s phone number in order to complete delivery.")])]),_c('icons-question',{attrs:{"slot":"reference","width":20,"height":20},slot:"reference"})],1)],1),_vm._m(6)])]):_vm._e()]),_c('AcceptPolicy'),_c('div',{staticClass:"d-flex flex-wrap col-12 px-0 mt-5"},[_c('common-button',{staticClass:"mr-md-3 mb-3 pa-0",attrs:{"height":40,"outlined":"","width":_vm.pageWidth > 375 ? '110px' : '100%'},on:{"click":function($event){return _vm.setPreviewStep(_vm.previewStep - 1)}}},[_vm._v(" Back ")]),_c('common-button',{staticClass:"pa-0 mr-0 mb-3 mb-md-0",attrs:{"height":40,"width":_vm.pageWidth > 375 ? '185px' : '100%'},on:{"click":function($event){return _vm.setPreviewStep(_vm.previewStep + 1)}}},[_vm._v(" Get your gift ")])],1)],1)]),_c('div',{staticClass:"col-md-2"}),_c('div',{staticClass:"col-md-5"},[(_vm.actualPreviewSelectedGifts.length === 1)?_c('div',{staticClass:"one-selected-gift"},[_c('img',{staticClass:"col-12 mb-3",attrs:{"src":(_vm.actualPreviewSelectedGifts[0].image && !_vm.actualPreviewSelectedGifts[0].image.includes('no_selection'))
              ? _vm.actualPreviewSelectedGifts[0].image
              : '/images/empty.png',"alt":_vm.actualPreviewSelectedGifts[0].name}}),_c('div',{staticClass:"f18 lato-bold black1 mb-3"},[_vm._v(_vm._s(_vm.actualPreviewSelectedGifts[0].name))]),(_vm.actualPreviewSelectedGifts[0].option)?_c('div',_vm._l((_vm.actualPreviewSelectedGifts[0].option),function(opt){return _c('div',{key:opt.title,staticClass:"mt-auto"},[_c('div',{staticClass:"f14 gray4 mb-2"},[_vm._v(_vm._s(opt.title + ': ' + _vm.formatedOptionValue(opt)))])])}),0):_vm._e()]):_vm._e(),(_vm.actualPreviewSelectedGifts.length > 1)?_c('div',{staticClass:"few-selected-gifts"},[_c('div',{staticClass:"f18 open-sans-bold black1 mb-5"},[_vm._v(" "+_vm._s(_vm.actualPreviewSelectedGifts.length > 1 ? 'Selected gifts:' : 'Selected gift:')+" ")]),_c('v-divider'),_vm._l((_vm.actualPreviewSelectedGifts),function(item,index){return _c('div',{key:index,staticClass:"mt-5"},[_c('div',{staticClass:"d-flex mb-4"},[_c('img',{staticClass:"thumb",attrs:{"src":(item.image && !item.image.includes('no_selection')) ? item.image : '/images/empty.png',"alt":"Image"}}),_c('div',{staticClass:"d-flex flex-column flex-start ml-4"},[_c('div',{staticClass:"f15 lato-bold black1"},[_vm._v(_vm._s(item.name))]),(item.option)?_c('div',_vm._l((item.option),function(opt){return _c('div',{key:opt.title,staticClass:"mt-auto"},[_c('div',{staticClass:"f14 gray4"},[_vm._v(_vm._s(opt.title + ': ' + _vm.formatedOptionValue(opt)))])])}),0):_vm._e()])]),_c('v-divider')],1)})],2):_vm._e()])]),_c('common-footer')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-input__header d-flex mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0"},[_vm._v("Full Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-input__item mb-8"},[_c('div',{staticClass:"shipping-input__header d-flex mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0"},[_vm._v("Street Address")])]),_c('div',{staticClass:"shipping-input__main d-flex"},[_c('input',{staticClass:"shipping-input col-7",attrs:{"type":"text","placeholder":"House number and street name"}}),_c('input',{staticClass:"shipping-input col-5 pa-3",attrs:{"type":"text","placeholder":"APT/Suite","id":"apt-suite"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-input__item mb-8"},[_c('div',{staticClass:"shipping-input__header d-flex align-center mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0 mr-2"},[_vm._v("ZIP Code")])]),_c('div',{staticClass:"shipping-input__main"},[_c('input',{staticClass:"shipping-input",attrs:{"type":"text","placeholder":"ZIP Code"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-input__item mb-8"},[_c('div',{staticClass:"shipping-input__header d-flex align-center mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0 mr-2"},[_vm._v("City")])]),_c('div',{staticClass:"shipping-input__main"},[_c('input',{staticClass:"shipping-input",attrs:{"type":"text","placeholder":"City"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-input__header d-flex align-center mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0 mr-2"},[_vm._v("State/Region")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-input__header d-flex align-center mb-3"},[_c('p',{staticClass:"shipping-input__label ma-0 mr-2"},[_vm._v("Country")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-input__main"},[_c('input',{staticClass:"shipping-input",attrs:{"type":"text","placeholder":"(123) 456-7890","id":"phone-number-input"}})])
}]

export { render, staticRenderFns }