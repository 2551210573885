<template>
  <div class="shipping-form-preview d-flex flex-column">
    <PreviewPageHeader />
    <div class="px-16">
      <h1 class="heading lato-light pa-3">Where should we send your gift?</h1>
      <div class="row mt-9 mx-0 px-0">
        <div class="col-md-5">
          <form
            class="preview-shipping-block"
            autocomplete="off"
          >
            <div class="shipping-inputs-wrapper">
              <div class="shipping-input__item mb-8">
                <div class="shipping-input__header d-flex mb-3">
                  <p class="shipping-input__label ma-0">Full Name</p>
                </div>
                <div class="shipping-input__main d-flex">
                  <input
                    type="text"
                    :value="previewFirstname"
                    placeholder="First name"
                    class="shipping-input col-6 mb-0"
                  />
                  <input
                    type="text"
                    v-model="previewLastname"
                    placeholder="Last name"
                    class="shipping-input col-6 mb-0"
                  />
                </div>
              </div>
              <div class="shipping-input__item mb-8">
                <div class="shipping-input__header d-flex align-center mb-3">
                  <p class="shipping-input__label ma-0 mr-2">Email</p>
                  <Popper
                    class="d-flex mt-n1 pointer"
                    :options="{
                      placement: 'top',
                      modifiers: { offset: { offset: '0,10px' } }
                    }">
                    <div
                      class="popper pa-6 text-left"
                    >
                      <span>We'll send you order confirmation here.</span>
                    </div>
                    <icons-question slot="reference" :width="20" :height="20" />
                  </Popper>
                </div>
                <div class="shipping-input__main">
                  <input
                    type="text"
                    v-model="previewEmail"
                    placeholder="Email"
                    class="shipping-input"
                  />
                </div>
              </div>
              <div v-if="(actualPreviewSelectedGifts.length === 1 && !actualPreviewSelectedGifts[0].is_virtual) || actualPreviewSelectedGifts.length !== 1"  class="for-real-gift">
                <div class="shipping-input__item mb-8">
                  <div class="shipping-input__header d-flex mb-3">
                    <p class="shipping-input__label ma-0">Street Address</p>
                  </div>
                  <div class="shipping-input__main d-flex">
                    <input
                      type="text"
                      placeholder="House number and street name"
                      class="shipping-input col-7"
                    />
                    <input
                      type="text"
                      placeholder="APT/Suite"
                      id="apt-suite"
                      class="shipping-input col-5 pa-3"
                    />
                  </div>
                </div>
                <div class="shipping-input__item mb-8">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">ZIP Code</p>
                  </div>
                  <div class="shipping-input__main">
                    <input
                      type="text"
                      placeholder="ZIP Code"
                      class="shipping-input"
                    />
                  </div>
                </div>
                <div class="shipping-input__item mb-8">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">City</p>
                  </div>
                  <div class="shipping-input__main">
                    <input
                      type="text"
                      placeholder="City"
                      class="shipping-input"
                    />
                  </div>
                </div>
                <div class="shipping-input__item mb-8">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">State/Region</p>
                  </div>
                  <div class="shipping-input__main">
                    <region-select
                      id="state"
                      ref="stateSelect"
                      class="shipping-input select-wrapper with-arrow"
                    />
                  </div>
                </div>
                <div class="shipping-input__item mb-8">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">Country</p>
                  </div>
                  <div
                    class="shipping-input__main"
                  >
                    <country-select
                      id="country"
                      topCountry="US"
                      class="with-arrow shipping-input select-wrapper"
                    />
                  </div>
                </div>
                <div class="shipping-input__item mb-8">
                  <div class="shipping-input__header d-flex align-center mb-3">
                    <p class="shipping-input__label ma-0 mr-2">Phone</p>
                    <Popper
                      class="d-flex mt-n1 pointer"
                      :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0,10px' } }
                      }">
                      <div
                        class="popper pa-6 text-left"
                      >
                        <span>It is rare, but in some cases, we may need the recipient’s phone number in order to complete delivery.</span>
                      </div>
                      <icons-question slot="reference" :width="20" :height="20" />
                    </Popper>
                  </div>
                  <div class="shipping-input__main">
                    <input
                      type="text"
                      placeholder="(123) 456-7890"
                      class="shipping-input"
                      id="phone-number-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <AcceptPolicy />
            <div class="d-flex flex-wrap col-12 px-0 mt-5">
              <common-button
                :height="40"
                outlined
                class="mr-md-3 mb-3 pa-0"
                :width="pageWidth > 375 ? '110px' : '100%'"
                @click="setPreviewStep(previewStep - 1)"
              >
                Back
              </common-button>
              <common-button
                :height="40"
                class="pa-0 mr-0 mb-3 mb-md-0"
                :width="pageWidth > 375 ? '185px' : '100%'"
                @click="setPreviewStep(previewStep + 1)"
              >
                Get your gift
              </common-button>
            </div>
          </form>
        </div>
        <div class="col-md-2" />
        <div class="col-md-5">
          <div
            v-if="actualPreviewSelectedGifts.length === 1"
            class="one-selected-gift"
          >
            <img
              :src="(actualPreviewSelectedGifts[0].image && !actualPreviewSelectedGifts[0].image.includes('no_selection'))
                ? actualPreviewSelectedGifts[0].image
                : '/images/empty.png'"
              :alt="actualPreviewSelectedGifts[0].name"
              class="col-12 mb-3"
            >
            <div class="f18 lato-bold black1 mb-3">{{actualPreviewSelectedGifts[0].name}}</div>
            <div v-if="actualPreviewSelectedGifts[0].option">
              <div
                v-for="opt in actualPreviewSelectedGifts[0].option"
                :key="opt.title" class="mt-auto"
              >
                <div class="f14 gray4 mb-2">{{opt.title + ': ' + formatedOptionValue(opt)}}</div>
              </div>
            </div>
          </div>
          <div
            v-if="actualPreviewSelectedGifts.length > 1"
            class="few-selected-gifts"
          >
            <div
              class="f18 open-sans-bold black1 mb-5"
            >
              {{ actualPreviewSelectedGifts.length > 1 ? 'Selected gifts:' : 'Selected gift:' }}
            </div>
            <v-divider></v-divider>
            <div
              v-for="(item, index) in actualPreviewSelectedGifts"
              :key='index'
              class="mt-5">
              <div class="d-flex mb-4">
                <img
                  :src="(item.image && !item.image.includes('no_selection')) ? item.image : '/images/empty.png'"
                  alt='Image' class="thumb"/>
                <div class="d-flex flex-column flex-start ml-4">
                  <div class="f15 lato-bold black1">{{item.name}}</div>
                  <!-- <div class="f12 gray2">{{item.sku}}</div> -->
                  <div v-if="item.option">
                    <div v-for="opt in item.option" :key="opt.title" class="mt-auto">
                      <div class="f14 gray4">{{opt.title + ': ' + formatedOptionValue(opt)}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
        </div>
      </div>
      <common-footer/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import Popper from 'vue-popperjs'
import AcceptPolicy from './../../eGift/AcceptPolicy.vue'
import PreviewPageHeader from './../PreviewPageHeader.vue'

export default {
  name: 'ShippingFormPreview',
  components: {
    PreviewPageHeader,
    Popper,
    AcceptPolicy
  },
  data: () => ({
    pageWidth: null
  }),
  computed: {
    ...mapState('preview', ['previewStep', 'previewFirstname', 'previewLastname', 'previewEmail']),
    ...mapGetters('preview', ['actualPreviewSelectedGifts'])
  },
  methods: {
    ...mapMutations('preview', ['setPreviewStep']),
    formatedOptionValue (option) {
      return option.valTitle ? option.valTitle : option.val
    }
  },
  mounted () {
    this.pageWidth = window.innerWidth
  }
}
</script>

<style lang="scss" scoped>
.shipping-form-preview {
  max-width: 100%;
  background-color: #fff;
  .heading {
    color: #000000;
    font-size: 45px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 54px;
    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }
  .preview-shipping-block {
    width: 100%;
    #state{
      &::placeholder{
        font-family: "Lato-Regular";
        font-style: italic;
        font-size: 13px;
        font-weight: 500;
        color: #a3a3a3;
      }
    }
    #address{
      min-height: 30px !important;
      border: 1px solid #d3d2d2;
      padding: 8px 12px;
      max-height: 30px;
      &::placeholder{
        font-family: "Lato-Regular";
        font-style: italic;
        font-size: 13px;
        font-weight: 500;
        color: #a3a3a3;
      }
      &:focus {
        outline: none !important;
        border: 2px solid #000;
      }
    }
    .select-wrapper{
      position: relative;
      &.with-arrow {
        background-image: url('/public/images/select-arrow-icon.svg');
        background-size: 25px 25px;
        background-position: 99% 30%;
      }
    }
    .popper{
      max-width: 300px;
    }
    .shipping-input{
      &__label{
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 10px;
        letter-spacing: 0;
      }
      &.is-invalid{
        background-color: #FBE7E9;
        border: 1px solid #FA5E5E;
      }
      &__main{
        input, select{
          width: 100%;
          height: 40px;
          padding: 11px;
          border: 1px solid #D3D2D2;
          font-size: 15px;
          line-height: 22px;
          outline: none;
          &#state.is-invalid,
          &.is-invalid{
            background: #FBE7E9;
            border: 1px solid #FA5E5E;
          }
          &::placeholder{
            font-family: 'Lato-Italic';
            font-size: 15px;
            color: #979797;
          }
        }
        p{
          margin-top: 10px;
          margin-bottom: 0px;
          color: #FA5E5E;
        }
      }
    }
  }
  .thumb {
    max-width: 120px;
    max-height: 120px;
  }
}
</style>
